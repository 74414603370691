import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Button,
  Center,
  Show, Spacer
} from '@chakra-ui/react'
import { LogOutButton, LogOutMenuItem } from 'features/sessions'
import { HeaderLayout, MenuItemBasicLinkButton } from 'shared/HeaderParts'
import { forwardRef } from 'react'

const feedbackFormURL = 'https://k1l8hbybse1.typeform.com/to/HeG96yRs'

const ShareFeedbackButton = forwardRef(function ShareFeedbackButton(props, ref) {
  const { t } = useTranslation()
  return (
    <Button
      ref={ref}
      {...props}
      colorScheme="zettaBlue"
      fontWeight="500"
      variant="solid"
      href={feedbackFormURL}
    >
      {t('Share Feedback')}
    </Button>
  )
})

const MobileHeader = () => (
  <>
    <ShareFeedbackButton
      as={MenuItemBasicLinkButton}
    />
    <LogOutMenuItem />
  </>
)

const DesktopHeader = () => (
  <>
    <Spacer />
    <Center>
      <Box mr={2} display="inline-block">
        <ShareFeedbackButton as="a" />
      </Box>
      <LogOutButton />
    </Center>
  </>
)

export const Header = ({ backTo }) => (
  <HeaderLayout backTo={backTo}>
    <Show breakpoint="(max-width: 767px)">
      <MobileHeader />
    </Show>
    <Show above="md">
      <DesktopHeader />
    </Show>
  </HeaderLayout>
)
