import { useTranslation } from 'i18n/TranslationContext'
import { useCurrentCourseQuery } from './useCurrentCourseQuery'
import { useCoursePageContext } from './CoursePageContext'
import { Button } from '@chakra-ui/react'
import { HiPlay } from 'react-icons/hi'
import { WatchingButton } from './WatchingButton'

const PrivateEnrollButton = () => {
  const {
    data: {
      content: {
        enrollForm
      }
    }
  } = useCurrentCourseQuery()
  const { t } = useTranslation()
  return (
    <Button
      as="a"
      href={enrollForm}
      size="lg"
      fontSize="20px"
      fontWeight="normal"
      colorScheme="zettaWhite"
      variant="solid"
      height="60px"
      width={['100%', '100%', 'unset']}
      rightIcon={<HiPlay />}
      iconSpacing="24px"
    >
      {t('Apply Now')}
    </Button>
  )
}

const AppliedStatusButton = () => {
  const { t } = useTranslation()
  return (
    <Button
      as="div"
      size="lg"
      colorScheme="zettaPurple"
      fontWeight="500"
      opacity="0.6"
    >
      {t('Your application in review')}
    </Button>
  )
}

export const MainButton = () => {
  const {
    data: {
      'access?': hasAccess,
      'applied?': hasApplied
    }
  } = useCurrentCourseQuery()
  const { isPartnerExperience } = useCoursePageContext()

  if (hasAccess || isPartnerExperience) {
    return <WatchingButton />
  }

  if (hasApplied) {
    return <AppliedStatusButton />
  }

  return <PrivateEnrollButton />
}
