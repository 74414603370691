import { FormControl, FormHelperText, Flex, Box } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { StyledFormLabel, UploadDragBoxInput } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useBuilderContext } from 'components/builder/BuilderContext'
import DropzoneInput from 'components/forms/DropzoneInput'
import { SERVER_FILE_UPLOAD_FILEDS } from 'constants'

const UploadMediaField = ({ helperText, name, label, fieldRequired=false, mediaType=null, inputProps, s3MediaPaths, onUploadComplete }) => {
  const { formState: { errors }, setValue } = useFormContext()
  const { t } = useTranslation()
  const { handleFileChange, setIsFileUploading, isFileUploading, saveBuilderData } = useBuilderContext()

  const onUpload = async () => {
    const continueProcess = await saveBuilderData()
    continueProcess && onUploadComplete && await onUploadComplete()
  }

  return (
    <FormControl pb="25px" isInvalid={!!errors[name]}>
      <Flex justifyContent="space-between">
        <Box>
          <StyledFormLabel>
            {label}{fieldRequired && <Box as="span" ml="4px" color="#CD0050">*</Box>}
          </StyledFormLabel>
          <FormHelperText fontFamily="Poppins" fontSize="20px" pb="20px">
            {helperText}
          </FormHelperText>
        </Box>
      </Flex>
      {
        SERVER_FILE_UPLOAD_FILEDS.includes(name) ?
          <UploadDragBoxInput
            handleFileChange={(file) => handleFileChange(file, name, setValue)}
            name={`${name}-file`}
            emptyStateLabel={t('Upload {{fileType}}', { fileType: label } )}
            replaceLabel={t('Replace {{fileType}}', { fileType: label } )}
            mediaType={mediaType}
            fieldRequired={fieldRequired}
            mediaUploaded={name.includes('_video') ? !isFileUploading : true}
            {...inputProps}
          />
          :
          <DropzoneInput
            name={name}
            key={name}
            emptyStateLabel={t('Upload {{fileType}}', { fileType: label } )}
            mediaType={mediaType}
            s3MediaPaths={s3MediaPaths}
            setIsFileUploading={setIsFileUploading}
            fieldRequired={fieldRequired}
            isReviewMode={inputProps?.disabled}
            onUpload={onUploadComplete && onUpload}
          />
      }
    </FormControl>
  )
}

export default UploadMediaField
