import { useTranslation } from 'i18n/TranslationContext'
import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import React from 'react'

// This is fine to have 3 steps listed, as the "3rd" step is the email confirmation
// After the profile split, we'll show the 3rd step as the Skills Pane/Bio, then email confirmation
// But since all the code except this page is forked, it happens to work out.
const StepIndicator = ({ currentStep }) => {
  const { t } = useTranslation()
  return (
    <div className="step-indicator">
      <RadioGroup value={`${currentStep}`} verticalAlign>
        <Stack direction="row" spacing={5}>
          <Text fontSize="18px" fontFamily="Poppins" textAlign="center">{t('Step {{currentStep}} of 3', { currentStep })}</Text>
          <Radio value="1" isDisabled></Radio>
          <Radio value="2" isDisabled></Radio>
          <Radio value="3" isDisabled></Radio>
        </Stack>
      </RadioGroup>
    </div>
  )
}

export default StepIndicator
