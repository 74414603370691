import { useSelector } from 'react-redux'

export const useGetOpportunityStatusOptions = (t) => {
  const opportunityData = useSelector(state => state.opportunityBuilder || {})

  if (opportunityData.date_opened) {
    return [
      { value: 'paused', label: t('Paused') },
      { value: 'closed', label: t('Closed') },
      { value: 'open', label: t('Open') },
    ]
  } else {
    return [
      { value: 'draft', label: t('Draft') },
      { value: 'open', label: t('Open') },
    ]
  }
}
