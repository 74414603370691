import { useTranslation } from 'i18n/TranslationContext'
import { PlayerProvider } from './PlayerContext'
import { Box, Button, Center, Flex, Show } from '@chakra-ui/react'
import { useRef } from 'react'
import { useCourseId, useCurrentEpisodeData, useEpisodeNumber, useIsStepsMode } from '../hooks'
import { VideoBlock } from './VideoBlock'
import { Link } from 'react-router-dom'
import { CommentsBlock } from './Comments'
import { usePageTitle } from 'usePageTitle'
import { CourseNavigationBlock } from './CourseNavigationBlock'
import { AdditionalMaterialsBlock } from './AdditionalMaterialsBlock'
import { useCoursePageContext } from '../CoursePage/CoursePageContext'
import SlideDeckBlock from './SlideDeckBlock'
import { COURSE_STEP_MEDIUM } from 'constants'

const EpisodeName = () => useCurrentEpisodeData().name

const PageContent = ({ hasIntroEpisode, episodeNumberLabel, episodeNumber, episodeMedium }) => {
  const { t } = useTranslation()
  const courseId = useCourseId()
  const prefix = hasIntroEpisode && Number(episodeNumber) === 1 ? '' : `${t('episode')} ${episodeNumberLabel} — `

  return (
    <Center
      as="main"
      pb="40px"
      role="main"
      overflowX="hidden"
      className="episode-page__page-content"
    >
      <Box
        flex="1"
        maxWidth="1440px"
        pt="40px" pl="48px" pr="48px"
        zIndex="1"
      >
        <Box pb="38px" className="player-block-holder">
          {episodeMedium === COURSE_STEP_MEDIUM.SLIDE_DECK ?
            <SlideDeckBlock />
            :
            <VideoBlock />
          }
        </Box>
        <Flex
          flexWrap="wrap"
          gap="32px"
          className="comments-materials-navigation-blocks"
        >
          <Box
            flexGrow="1"
            flexBasis="60%"
          >
            <Flex
              pl="48px" pr="48px" pb="48px" pt="48px"
              bgColor="white"
              alignItems="flex-start"
            >
              <Box
                as="h1"
                color="#0F204B"
                fontFamily="Spline Sans Mono"
                fontWeight="bold"
                fontSize="40px"
                textTransform="uppercase"
                flexShrink="2"
              >
                {prefix}<EpisodeName />
              </Box>
              <Show above="md">
                <Box
                  marginLeft="auto"
                >
                  <Button
                    as={Link}
                    to={`/courses/${courseId}`}
                    colorScheme="zettaPurple"
                    fontWeight="500"
                  >
                    {t('Open course profile')}
                  </Button>
                </Box>
              </Show>
            </Flex>
            <CommentsBlock />
          </Box>
          <Box
            position="relative"
            flex="1"
            flexBasis="30%"
          >
            <Box
              position="absolute"
              top="100px"
              right="-30px"
              zIndex="-1"
            >
              <img
                src={`${process.env.PUBLIC_URL}/episode-bg-2.svg`}
                alt=""
              />
            </Box>
            <AdditionalMaterialsBlock />
            <CourseNavigationBlock />
          </Box>
        </Flex>
      </Box>
    </Center>
  )
}

export const EpisodePlayerPage = () => {
  const { t } = useTranslation()
  const playerRef = useRef(null)
  const episodeNumber = useEpisodeNumber()
  const isStepsMode = useIsStepsMode()
  const { name: episodeName, episodeMedium } = useCurrentEpisodeData()
  const { data: { has_intro_episode } } = useCoursePageContext().courseContentData

  const episodeNumberLabel = (has_intro_episode && isStepsMode) ? episodeNumber - 1 : episodeNumber
  const prefix = has_intro_episode && Number(episodeNumber) === 1 ? '' : `${t('Episode')} ${episodeNumberLabel} — `

  usePageTitle(`${prefix}${episodeName}`)

  return (
    <PlayerProvider value={playerRef}>
      <PageContent
        hasIntroEpisode={has_intro_episode}
        episodeNumberLabel={episodeNumberLabel}
        episodeNumber={episodeNumber}
        episodeMedium={episodeMedium}
      />
    </PlayerProvider>
  )
}
