import { useEffect, useState } from 'react'

export const usePasswordCheck = (password, confirm) => {
  const [isMatch, setIsMatch] = useState(false)

  useEffect(() => {
    setIsMatch(password === confirm && password !== '' && password.length >= 6)
  }, [password, confirm])

  return isMatch
}