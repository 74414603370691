import { Box, Flex, FormHelperText } from '@chakra-ui/react'
import StyledFormLabel from './StyledFormLabel'

const FieldHeader = ({ helperText, label }) => {

  return (
    <>
      <Flex justifyContent="space-between">
        <Box>
          <StyledFormLabel>
            {label}
          </StyledFormLabel>
          {helperText && (
            <FormHelperText fontFamily="Poppins" fontSize="20px" pb="20px">
              {helperText}
            </FormHelperText>
          )}
        </Box>
      </Flex>
    </>
  )
}

export default FieldHeader
