import { apiSlice } from './apiSlice'

export const invitationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    inviteUser: builder.mutation({
      query: ({ courseId, email }) => ({
        url: `/courses/${courseId}/course_access_grants`,
        method: 'POST',
        body: { email }
      }),
      invalidatesTags: (result, error) => error ? [] : ['Invitations']
    }),
    listInvitations: builder.query({
      query: ({ courseId, pageNumber }) => {
        const params = new URLSearchParams()

        if (pageNumber) {
          params.set('page', pageNumber)
        }

        return `/courses/${courseId}/course_access_grants?${params.toString()}`
      },
      providesTags: ['Invitations']
    }),
    resendInvitation: builder.mutation({
      query: ({ courseId, id }) => ({
        url: `/courses/${courseId}/course_access_grants/${id}/resend_invitation`,
        method: 'POST'
      }),
    }),
    destroyInvitation: builder.mutation({
      query: ({ courseId, id }) => ({
        url: `/courses/${courseId}/course_access_grants/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error) => error ? [] : ['Invitations']
    })
  })
})

export const {
  useListInvitationsQuery,
  useResendInvitationMutation,
  useDestroyInvitationMutation,
  useInviteUserMutation
} = invitationsApi
