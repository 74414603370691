import React, { useEffect, useState, useRef } from 'react'
import { FormControl } from '@chakra-ui/react'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { useFormContext } from 'react-hook-form'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const TextEditorField = ({ textFieldProps, name, errorVal={}, editorState, setEditorState, toolbar, setRequiredField=null }) => {
  const { formState: { errors }, setValue, setError } = useFormContext()
  const [hasFocus, setHasFocus] = useState(false)
  const [localErrors, setLocalErrors] = useState(errorVal)
  const isCourseBuilder = useRef(true)

  useEffect(() => {
    if(Object.keys(localErrors).length === 0) {
      setLocalErrors(errors)
      isCourseBuilder.current = false
    }
  }, [errors, localErrors])

  useEffect(() => {
    setEditorState(editorState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // only run once on mount

  useEffect(() => {
    // Add roles to editor elements
    const addRolesToEditorElements = () => {

      // Set the parent's role to listbox for menu to comply with Aria rules
      const listboxWrappers = document.querySelectorAll(
        '.rdw-inline-wrapper, .rdw-list-wrapper, .rdw-link-wrapper'
      )
      listboxWrappers.forEach(wrapper => {
        wrapper.setAttribute('role', 'listbox')
      })

      // Set the children's role to option
      const optionWrappers = document.querySelectorAll(
        '.rdw-option-wrapper'
      )
      optionWrappers.forEach(wrapper => {
        wrapper.setAttribute('role', 'option')
      })

      // Set the parent's role to menu and child to menuitem for compliance
      const blockWrappers = document.querySelectorAll('.rdw-block-wrapper')
      blockWrappers.forEach(wrapper => {
        wrapper.setAttribute('role', 'menu')
      })
      const dropdownWrappers = document.querySelectorAll('.rdw-dropdown-wrapper')
      dropdownWrappers.forEach(wrapper => {
        wrapper.setAttribute('role', 'menuitem')
      })
    }

    addRolesToEditorElements()
  }, []) // Run only once on mount

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    const contentState = editorState.getCurrentContent()
    const contentRaw = convertToRaw(contentState)
    const contentHtml = draftToHtml(contentRaw)
    const contentText = contentState.getPlainText()
    const contentEmpty = contentHtml === '<p></p>\n' || contentHtml === '<p></p>'

    // convert the input into html format and set the value so that the form can use the value
    setValue(name, contentEmpty ? '' : contentHtml)
    // Compare the content text length with the requirements
    if (textFieldProps.maxLength && !contentEmpty) {
      let message = null
      if (contentText.length > textFieldProps.maxLength.value) {
        message = textFieldProps.maxLength.message
      }
      setError(name, { type: 'maxLength', message: message })
      setLocalErrors({ ...localErrors, [name]: message })
    }

    setRequiredField && setRequiredField(!contentEmpty)
  }

  return (
    <FormControl pb="40px" isInvalid={!!localErrors[name]} isRequired={textFieldProps.fieldRequired}>
      <FieldHeader courseBuilder={isCourseBuilder} errorVal={localErrors} helperText={textFieldProps.helperText} label={textFieldProps.label} />
      <Editor
        placeholder={!textFieldProps.disabled && textFieldProps.placeholder}
        editorClassName={`text-field-editor ${textFieldProps.disabled ? 'disabled-input' : ''} ${localErrors[name] ? 'error-input' : ''} ${hasFocus ? 'focus-input' : ''}`}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        onFocus={() => !textFieldProps?.disabled && setHasFocus(true)}
        onBlur={() => !textFieldProps?.disabled && setHasFocus(false)}
        readOnly={textFieldProps?.disabled}
        toolbar={{ ...toolbar }}
        toolbarHidden={textFieldProps?.disabled}
      />
      <FormErrorDisplay courseBuilder={isCourseBuilder} name={name} errorVal={localErrors} />
    </FormControl>
  )
}

export default TextEditorField
