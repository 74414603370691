import React from 'react'
import { Box } from '@chakra-ui/react'
import PurpleTape from './PurpleTape'

const PurpleTapeDisplay = ({ text, ...props }) => (
  <Box ml="22px">
    <Box
      pt="10px"
      pb="10px"
      display="inline-flex"
      maxWidth="100%"
      fontFamily="Spline Sans Mono"
      fontWeight="bold"
      textTransform="uppercase"
      alignItems="center"
      position="relative"
      color="white"
      zIndex={21}
      {...props}
    >
      <PurpleTape />
      <Box pl="24px" pr="24px">
        {text}
      </Box>
    </Box>
  </Box>
)

export default PurpleTapeDisplay
