import { apiSlice } from './apiSlice'

export const contactRequestsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContactRequestStatus: builder.query({
      query: ({ id, secret }) => `/contact_requests/${id}?secret=${secret}`,
      providesTags: ['ContactRequest']
    }),
    resolveContactRequest: builder.mutation({
      query: ({ id, resolution, secret }) => ({
        url: `/contact_requests/${id}`,
        method: 'PATCH',
        body: {
          resolution,
          secret
        }
      }),
      invalidatesTags: (result, error) => error ? [] : ['ContactRequest']
    }),
  })
})

export const {
  useGetContactRequestStatusQuery,
  useResolveContactRequestMutation
} = contactRequestsApi
