import { EVENTS } from 'constants'
import { beaconApi } from 'features/beacon/beaconApi'
import { resetLocalChat, userMessageAdded } from 'features/beacon/chatSlice'
import { sessionsApi } from 'features/sessionsApi'
import { skillsApi } from 'features/skillsApi'
import ServiceEmitter from 'services/ServiceEmitter'

/**
 * BeaconService class
 * Used to interact with the Beacon API
  // Usage in NoMessagesElement or similar component
  // const beaconService = new BeaconService('botName', dispatch);

  // Subscribe to the event
  // beaconService.on('initBotCompleted', (result) => {
  //   console.log('initBot completed with result:', result);
  // });
 */

class BeaconService extends ServiceEmitter {
  constructor(botName, dispatch) {
    super()
    this.botName = botName
    this.dispatch = dispatch
  }

  async initBot() {
    const result = await this.dispatch(
      beaconApi.endpoints.initBot.initiate({ botName: this.botName }
      ))
    BeaconService.emit(EVENTS.BEACON.INIT_BOT_COMPLETED, result)
    return result
  }

  sendMessage(message) {
    // Add the user message to the chat
    this.dispatch(userMessageAdded(message))
    // Call API to get the bot's response
    this.dispatch(beaconApi.endpoints.getResponse.initiate(
      { botName: this.botName, message }
    ))
  }

  async fetchSkills() {
    const userId = await this.#fetchUserId()
    await this.dispatch(skillsApi.endpoints.listUserSkills.initiate({ userId },
      { subscribe: false, forceRefetch: true })
    )
  }

  async resetChat() {
    // Call API to reset the bot
    await this.dispatch(beaconApi.endpoints.resetBot.initiate(
      { botName: this.botName }, { subscribe: false, forceRefetch: true }
    ))

    // Reset local state
    await this.dispatch(resetLocalChat())
  }

  async #fetchUserId() {
    const result = await this.dispatch(
      sessionsApi.endpoints.getCurrentUser.initiate()
    )
    return result.data.id
  }
}

export default BeaconService