import { Link } from 'react-router-dom'
import { forwardRef } from 'react'

export const LeaveFormControl = forwardRef( function LeaveFormControl({ children = 'Cancel' , ...props }, ref) {
  return (
    <Link
      ref={ref}
      {...props}
    >
      {children}
    </Link>
  )
})
