import { Box, Button } from '@chakra-ui/react'
import { useGetStepInfo, useStepId } from 'CourseFeature/hooks'
import { TakeQuizButton } from 'components/talent/quiz/TakeQuizButton'
import { ModalBase } from 'components/talent/quiz/sectionCompleteModal/ModalBase'
import { DIRECTION } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'

const SectionCompleteQuizModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const stepId = useStepId()
  const nextStepInfo = useGetStepInfo(stepId, DIRECTION.NEXT)
  const isFinalStep = nextStepInfo?.isAssignment

  const mainCopy = isFinalStep || !nextStepInfo ?
    t('It\'s time to reflect on what you\'ve learned. You must complete this quiz in order to complete the course.') :
    t('It\'s time to reflect on what you\'ve learned. You must complete this quiz in order to continue to the next part of the course.')

  const footerButtons = (
    <Box className="footer-buttons">
      <Button size="lg" fontSize="18px" variant="outline" colorScheme="zettaBlue"
        onClick={onClose} mr={5} borderColor="#5000E8" color="#5000E8">
        {t('Maybe Later')}
      </Button>
      <TakeQuizButton onClick={(evt) => onClose(evt, true)} />
    </Box>
  )

  return (
    <ModalBase isOpen={isOpen} onClose={onClose} size="3xl"
      headerText={t('Section Complete')}
      bodyText={mainCopy}
      footerButtons={footerButtons}
    />
  )
}

export default SectionCompleteQuizModal
