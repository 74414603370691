import React from 'react'
import { Flex, Box } from '@chakra-ui/react'

const PurpleTape = () => (
  <Flex
    position="absolute"
    width="100%"
    height="100%"
    zIndex={-1}
    _before={{
      display: 'block',
      content: '" "',
      width: '14px',
      bgImage: `${process.env.PUBLIC_URL}/tape2-left.svg`,
      bgSize: 'cover',
    }}
    _after={{
      display: 'block',
      content: '" "',
      width: '11px',
      bgImage: `${process.env.PUBLIC_URL}/tape2-right.svg`,
      bgSize: 'cover',
    }}
  >
    <Box bg="#5000E8" opacity="0.85" flex="1" />
  </Flex>
)

export default PurpleTape
