import { useTranslation } from 'i18n/TranslationContext'
import { Link, useMatch } from 'react-router-dom'
import { Box, Button, Center } from '@chakra-ui/react'
import { TalentsTable } from './TalentsTable'
import { useTalentProfileListData } from './useTalentProfileListData'
import { Pagination } from '../Pagination'
import { useCurrentCourseQuery } from '../useCurrentCourseQuery'
import { useContextCourseId } from '../../CourseContext'

const PageOuterContainer = ({ children }) => {
  const match = useMatch('/courses/dashboard/:courseId/assignments')
  const isMain = !!match

  return (
    <Center
      as="main" role="main"
      bg={`url(${process.env.PUBLIC_URL}/partner-bg.svg)`}
      bgRepeat="no-repeat"
      backgroundPosition="top"
      {
        ...(isMain ? {} : {
          'aria-hidden': true,
          as: 'div',
          role: undefined
        })
      }
    >
      <Box
        flexGrow="1"
        maxWidth="1440px"
        paddingTop={['8px', null, '32px']}
        paddingLeft={['8px', null, '36px']}
        paddingRight={['8px', null, '36px']}
      >
        {children}
      </Box>
    </Center>
  )
}

const CourseSubtitle = () => {
  const { t } = useTranslation()
  const { data, isSuccess } = useCurrentCourseQuery()

  if (!isSuccess) { return ' ' }

  return (
    <Box color="#0F204A">
      {t('List of talent who completed assignments of the “{{course_name}}” course.', { course_name: data.name })}
    </Box>
  )
}

export const PageContent = () => {
  const { t } = useTranslation()
  return (
    <PageOuterContainer>
      <Box
        bgColor="white"
        padding={[
          '40px 24px 0',
          null,
          '40px 32px 0'
        ]}
      >
        <Box marginBottom="16px">
          <Button
            as={Link}
            to={`/courses/dashboard/${useContextCourseId()}`}
            colorScheme="zettaPurple"
            fontWeight="500"
          >
            {t('Back')}
          </Button>
        </Box>
        <Box as="h1"
          pl="16px"
          pb="16px"
          fontFamily="Spline Sans Mono"
          fontSize="40px"
          fontWeight="bold"
          textTransform="uppercase"
          color="#0F204A"
        >
          {t('Assignments')}
        </Box>
        <Box as="h2"
          pl="16px"
          color="var(--ContentHover, #070F22)"
          fontFamily="Poppins"
          fontSize="18px"
          fontWeight="500"
        >
          <CourseSubtitle />
        </Box>
        <Box
          padding="24px 0"
        >
          <TalentsTable />
          <Pagination listQuery={useTalentProfileListData()} />
        </Box>
      </Box>
    </PageOuterContainer>
  )
}