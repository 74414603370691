import { useTranslation } from 'i18n/TranslationContext'
import { useLocation } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListAssignmentsQuery } from 'features/submittedAssignmentApi'
import { Box, Button, Center, Flex } from '@chakra-ui/react'
import { AssignmentDetails } from './AssignmentDetails'
import { HiDocumentText } from 'react-icons/hi2'

const CurrentFileView = () => {
  const { pathname } = useLocation()
  const {
    data: {
      talent_profile: { id }
    }
  } = useGetCurrentUserQuery()

  const { data: [{ filename }] } = useListAssignmentsQuery({
    episodePath: pathname,
    talentProfileId: id
  })

  return (
    <Flex>
      <Box
        flex="0.65"
        borderRadius="4px"
        background="var(--design-decorative-surface-light, #CDF3FF)"
      >
        <Center
          flexDirection="column"
          textAlign="center"
          height={['250px', null, '184px']}
        >
          <Center fontSize="24px" mb="16px" color="#5000E8">
            <HiDocumentText />
          </Center>
          <Box mb="4px">
            {filename}
          </Box>
        </Center>
      </Box>
    </Flex>
  )
}

export const AssignmentTurnedIn = () => {
  const { t } = useTranslation()
  return (
    <>
      <AssignmentDetails />
      <CurrentFileView />
      <Box pt="24px">
        <Button as="div"
          colorScheme="zettaPurple"
          fontWeight="normal"
          disabled
        >
          {t('Assignment Submitted')}
        </Button>
      </Box>
    </>
  )
}
