import { Box } from '@chakra-ui/react'

const H2 = ({ children, ...restProps }) => (
  <Box as="h2"
    fontFamily="Spline Sans Mono"
    fontSize="24px"
    fontWeight="bold"
    textTransform="uppercase"
    {...restProps}
  >
    {children}
  </Box>
)

export default H2
