import { useTranslation } from 'i18n/TranslationContext'
import { useLocation } from 'react-router-dom'
import { Box, Show } from '@chakra-ui/react'
import { LeaveFormControl } from '../LeaveFormControl'
import { forwardRef } from 'react'
import { motion } from 'framer-motion'

const LeftDiv = forwardRef(function LeftDiv (props, ref) {
  return (
    <Box
      ref={ref}
      width="100%"
      height="100%"
      zIndex="190"
      position="absolute"
      {...props}
    />
  )
})

const RightDiv = forwardRef(function RightDiv (props, ref) {
  return (
    <Box
      ref={ref}
      position="absolute"
      left={['0', null, '25%']}
      bgColor="white"
      bgImage={`${process.env.PUBLIC_URL}/contact-request-bg.svg`}
      bgRepeat="no-repeat"
      bgSize="102% auto"
      width={['100%', null, '75%']}
      minHeight="100%"
      zIndex="200"
      {...props}
    />
  )
})

const MotionLeftDiv = motion(LeftDiv)
const MotionRightDiv = motion(RightDiv)

export const CustomDrawer = ({ backTo, backRelative='route', children, ...props }) => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <>
      <Show above="md">
        <MotionLeftDiv
          initial={{
            backgroundColor: 'rgba(15, 32, 74, 0)',
            backdropFilter: 'blur(0)'
          }}
          animate={{
            backgroundColor: 'rgba(15, 32, 74, 0.70)',
            backdropFilter: 'blur(2px)',
          }}
          exit={{
            backgroundColor: 'rgba(15, 32, 74, 0)',
            backdropFilter: 'blur(0)'
          }}
        >
          <Box
            as={LeaveFormControl}
            display="block"
            width="100%"
            height="100%"
            to={backTo}
            relative={backRelative}
            title={t('Back to the list')}
          >
            {' '}
          </Box>
        </MotionLeftDiv>
      </Show>
      <MotionRightDiv
        key={location.pathname}
        initial={{
          transform: 'translateX(100%)'
        }}
        animate={{
          transform: 'translateX(0)',
        }}
        exit={{
          transform: 'translateX(100%)'
        }}
        {...props}
      >
        {children}
      </MotionRightDiv>
    </>
  )
}
