import { Flex, Spacer } from '@chakra-ui/react'
import { ContinueSubmitButton } from './ContinueSubmitButton'
import CancelButton from './CancelButton'

export const FormControlsBottom = () => (
  <Flex
    justifyContent="left"
    flexDirection={['column', null, 'row']}
    padding={[
      '24px 0'
    ]}
    marginBottom={[null, null, '1px']}
    bgColor="white"
  >
    <ContinueSubmitButton width={['100%', null, 'unset']} />
    <Spacer flexBasis="8px" flexGrow="0" />
    <CancelButton width={['100%', null, 'unset']} />
  </Flex>
)
