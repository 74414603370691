import React from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { OpportunityDetails, PurpleTapeDisplay } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { parseWithoutStyles } from 'utils/string'
import parse from 'html-react-parser'

const DescriptionSection = ({ opportunity, formattedEmploymentType }) => {
  const { t } = useTranslation()
  const description = opportunity?.description || ''

  return (
    <Grid
      templateColumns={{ base: '1fr', md: '1fr 400px' }}
      gap={{ base: 6, md: 12 }}
      pl={{ base: '5%', md: '7%' }}
      mb={8}
    >
      <Box>
        <PurpleTapeDisplay as="h2" text={t('Opportunity Description')} fontSize="20px" pb="0px" />
        <Box
          mt={6}
          ml={{ base: 0, md: 4 }}
          whiteSpace="pre-wrap"
          fontFamily="Poppins"
          className="about-block__description"
          listStylePosition="inside"
        >
          {parse(description, parseWithoutStyles)}
        </Box>
      </Box>

      <OpportunityDetails opportunity={opportunity} formattedEmploymentType={formattedEmploymentType} />
    </Grid>
  )
}

export default DescriptionSection
