import { useEffect } from 'react'
import {
  useCourseId,
  useStepId,
  useStepMap,
} from '../hooks'
import { Link, useNavigate } from 'react-router-dom'
import { AppLoadingState } from 'AppLoadingState'
import { EpisodePlayerPage } from './EpisodePlayerPage'
import { AssignmentPage } from './AssignmentPage'
import { Box, Button, Card, CardBody, CardHeader, Center, Heading, Text } from '@chakra-ui/react'
import { useCurrentCourseQuery } from '../CoursePage/useCurrentCourseQuery'
import { useCoursePageContext } from '../CoursePage/CoursePageContext'
import { useTranslation } from 'i18n/TranslationContext'
import { useShowEnrollInfoForCourseQuery } from 'features/coursesApi'
import SectionCompleteModal from 'components/talent/quiz/sectionCompleteModal/SectionCompleteModal'
import { QuizModals } from 'components/talent/quiz/QuizModals'
import { usePlaybackEndContext } from 'CourseFeature/EpisodePage/context/PlaybackEndContext'

const BgImage = () => (
  <Box as="img"
    role="presentation"
    objectFit="fill"
    width="100%"
    height="1320px"
    src={`${process.env.PUBLIC_URL}/episode-bg-1.svg`}
    alt=""
  />
)

const PageWrapper = ({ children }) => {
  const {
    isQuizSectionCompleteOpen,
    onQuizSectionCompleteClose,
    isSectionCompleteOpen,
    onSectionCompleteClose,
  } = usePlaybackEndContext()

  return (
    <Box position="relative">
      <SectionCompleteModal isOpen={isSectionCompleteOpen} onClose={onSectionCompleteClose} />

      <QuizModals
        quizEnabled={true}
        isQuizSectionCompleteOpen={isQuizSectionCompleteOpen}
        onQuizSectionCompleteClose={onQuizSectionCompleteClose}
      />

      <Box
        top="0"
        position="absolute"
        width="100%"
        height="100%"
        zIndex="-2"
      >
        <BgImage />
      </Box>
      {children}
    </Box>
  )
}

const LockedEpisodeCard = ({ courseId, isAssignment }) => {
  const { t } = useTranslation()

  const cardText = isAssignment ?
    t('You must be granted access to view this Episode.'):
    t('You must complete the previous Episode to view this Episode.')

  return (
    <PageWrapper>
      <Center>
        <Card width="lg" align="center" mt={7}>
          <CardHeader>
            <Heading fontFamily="Spline Sans Mono" size="lg">{t('Episode Locked')}</Heading>
          </CardHeader>
          <CardBody align="center">
            <Text>{cardText}</Text>
            <Button
              as={Link}
              to={`/courses/${courseId}`}
              size="lg"
              fontSize="20px"
              marginTop="24px"
              fontWeight="normal"
              colorScheme="zettaWhite"
              variant="solid"
              height="60px"
              width={['100%', '100%', 'unset']}
            >
              {t('Back to Course page')}
            </Button>
          </CardBody>
        </Card>
      </Center>
    </PageWrapper>
  )
}

export const EpisodePage = () => {
  const courseId = useCourseId()
  const stepId = useStepId()
  const stepMaps = useStepMap()
  const isDisabled = stepMaps[stepId]?.isDisabled
  const isAssignment = stepMaps[stepId]?.isAssignment
  const { data: { 'enrolled?': isEnrolled }, isFetching } = useCurrentCourseQuery()
  const { isLoading: isLoadingEnrolment } = useShowEnrollInfoForCourseQuery(undefined, { skip: true })
  const { isPartnerExperience } = useCoursePageContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (isFetching) { return }
    if (isEnrolled || isPartnerExperience) { return }

    navigate(`/courses/${courseId}`)
  }, [isEnrolled, isPartnerExperience, navigate, courseId, isFetching])

  useEffect(() => {
    document.querySelector('body').id = 'episode-page'
    return () => {
      document.querySelector('body').removeAttribute('id')
    }
  }, [])

  if (isFetching || isLoadingEnrolment) {
    return <AppLoadingState />
  }

  if (isDisabled) {
    return <LockedEpisodeCard courseId={courseId} isAssignment={isAssignment} />
  }

  if (isAssignment) {
    return (
      <PageWrapper>
        <AssignmentPage />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <EpisodePlayerPage />
    </PageWrapper>
  )
}
