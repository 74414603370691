import React from 'react'
import { Flex, Image } from '@chakra-ui/react'
import OpportunityItem from './OpportunityItem'
import { PartnerOpportunityButton, InactiveButton } from 'components'

const PartnerOpportunity = ({ status, ...props }) => {
  const opportunity = { ...props, status }

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  // Determine the display name based on the status
  const displayName = (status === 'draft' || status === 'pending' || status === 'paused' || status === 'closed')
    ? `${opportunity.name} (${capitalizeFirstLetter(status)})`
    : opportunity.name

  const getButtonsComponent = () => (
    <Flex direction="column">
      <PartnerOpportunityButton status={status} opportunity={opportunity} />
      {(status === 'draft' || status === 'pending' || status === 'open' || status === 'paused') && (
        <InactiveButton opportunity={opportunity} status={status} />
      )}
    </Flex>
  )

  return (
    <OpportunityItem
      partnerView={true}
      displayName={displayName}
      column2Value={opportunity.opportunity_application_applied_count}
      buttonsComponent={getButtonsComponent()}
      {...props}
    >
      {opportunity.imageUrl && (
        <Image src={opportunity.imageUrl} alt={opportunity.name} />
      )}
    </OpportunityItem>
  )
}

export default PartnerOpportunity
