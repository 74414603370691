import { useTranslation } from 'i18n/TranslationContext'
import { Box } from '@chakra-ui/react'
import { SubmitButton } from 'shared/SubmitButton'
import { useLocation } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { FormProvider, useForm } from 'react-hook-form'
import { AssignmentDetails } from './AssignmentDetails'
import { usePageLeaveConfirmation } from 'shared/usePageLeaveConfirmation'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { UploadDragBoxInput } from 'components'
import { useCurrentEpisodeData } from 'CourseFeature/hooks'

const UploadField = () => {
  const { t } = useTranslation()
  const mediaType = UPLOAD_MEDIA_TYPE(t)
  const { assignmentMedia } = useCurrentEpisodeData()
  const mediaConfig = assignmentMedia === 'pdf' ?
    mediaType.ASSIGNMENT.PDF : mediaType.ASSIGNMENT.VIDEO

  return (
    <UploadDragBoxInput
      name="assignment"
      emptyStateLabel={mediaConfig.emptyStateLabel}
      replaceLabel={t('Replace Assignment')}
      mediaType={mediaConfig}
      fieldRequired
    />
  )
}

const AssignmentForm = ({ submitAssignment, children }) => {
  const { pathname } = useLocation()
  const {
    data: {
      talent_profile: { id }
    }
  } = useGetCurrentUserQuery()

  const methods = useForm({
    defaultValues: {
      talentProfileId: id,
      episodePath: pathname,
      assignment: null,
      assignmentFilename: null
    }
  })
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submitAssignment)} className="assignment-form">
        {children}
      </form>
    </FormProvider>
  )
}

const AssignmentFormContent = () => {
  usePageLeaveConfirmation()
  const { t } = useTranslation()

  return (
    <>
      <AssignmentDetails />
      <UploadField />
      <Box pt="24px">
        <SubmitButton
          colorScheme="zettaBlue"
          fontWeight="normal"
        >
          {t('Submit Assignment')}
        </SubmitButton>
      </Box>

    </>
  )
}

export const NewAssignmentUpload = ({ submitAssignment }) => (
  <AssignmentForm submitAssignment={submitAssignment}>
    <AssignmentFormContent />
  </AssignmentForm>
)
