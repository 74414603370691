import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { Header1 } from '../Header1'
import { Details } from '../Details'
import { PartnerSignUpForm } from './PartnerSignUpForm'

export const PartnerPageContent = () => {
  const { t } = useTranslation()
  return (
    <Box
      background="#F0E9E6"
      pt="16px" pb="40px"
    >
      <Box
        padding={[
          '0 24px',
          null,
          '0 140px'
        ]}
      >
        <Center flexDirection="column">
          <Flex width="100%" justifyContent={[null, null, 'center']}>
            <Box maxWidth={['260px', null, '420px']} >
              <Header1>
                {t('Join Making Space as a Partner')}
              </Header1>
            </Box>
          </Flex>
          <Center
            flexDirection="column"
          >
            <Details>
              {t('We are thrilled to see you here, let’s get rolling!')}
            </Details>
            <Box width={['100%', null, '90%']}>
              <PartnerSignUpForm />
            </Box>
          </Center>
        </Center>
      </Box>
    </Box>
  )
}
