import { Box, Link as ChakraLink } from '@chakra-ui/react'
import { formatLinkProtocols } from 'utils/string'

const PersonalLink = ({ url, label }) => {
  const finalUrl = formatLinkProtocols(url)

  return (
    <Box
      fontFamily="Spline Sans Mono"
      fontSize="16px"
      fontWeight={500}
      color="var(--design-primary, #5000E8)"
    >
      <ChakraLink
        display="block"
        href={finalUrl}
        isExternal
        aria-label={`${label} Link`}
      >
        {label}
      </ChakraLink>
    </Box>
  )
}

export default PersonalLink
