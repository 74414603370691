import { VStack, Flex, Text, Icon } from '@chakra-ui/react'
import { HiCheckCircle } from 'react-icons/hi'
import { PiLightbulbFilamentFill } from 'react-icons/pi'
import { useTranslation } from 'i18n/TranslationContext'

const QuizAssessment = ({ quizAssessments }) => {
  const { t } = useTranslation()

  let totalQuestionsAnswered = 0
  let totalQuestionCount = 0
  let totalFirstAttemptCorrectCount = 0

  quizAssessments.forEach(quiz => {
    totalQuestionsAnswered += quiz.questions_answered_count
    totalQuestionCount += quiz.question_count
    totalFirstAttemptCorrectCount += quiz.first_attempt_correct_count
  })

  let firstAttemptCorrectPercentage = (totalFirstAttemptCorrectCount / totalQuestionCount) * 100
  const decimalPart = firstAttemptCorrectPercentage % 1
  if (decimalPart >= 0.1 && decimalPart <= 0.4) {
    firstAttemptCorrectPercentage = Math.floor(firstAttemptCorrectPercentage)
  } else if (decimalPart >= 0.5 && decimalPart <= 0.9) {
    firstAttemptCorrectPercentage = Math.ceil(firstAttemptCorrectPercentage)
  }

  return (
    <VStack align="flex-start" spacing={4} ml={[0, null, 7]} fontFamily="Spline Sans Mono">
      <Text id="quiz-assessment-stats" fontSize="2xl" fontWeight="bold" mb={4}>
        {t('Quiz Assessment Statistics')}
      </Text>
      <Flex alignItems="center" role="listitem">
        <Text fontSize="5xl" fontFamily="Permanent Marker" fontWeight="bold" fontStyle="italic" mr={2}>
          {firstAttemptCorrectPercentage}%
        </Text>
        <Text ml={2} fontWeight="bold">
          {t('of questions answered correctly on first try')}
        </Text>
      </Flex>
      <Flex alignItems="center" role="listitem">
        <Icon as={PiLightbulbFilamentFill} w={6} h={6} mr={2} aria-hidden="true" />
        <Text>{t('{{count}} questions answered', { count: totalQuestionsAnswered })}</Text>
      </Flex>
      <Flex alignItems="center" mb={5} role="listitem">
        <Icon as={HiCheckCircle} w={6} h={6} mr={2} aria-hidden="true" />
        <Text>{t('{{count}} questions answered correctly on first try', { count: totalFirstAttemptCorrectCount })}</Text>
      </Flex>
    </VStack>
  )
}

export default QuizAssessment
