import { Box } from '@chakra-ui/react'

export const ResponsiveImage = ({ imageSrc, srcSet, altText, rootProps={}, ...props }) => {
  return (
    <Box as="picture" {...rootProps} className="splash-image episode-preview-base">
      {srcSet && <source srcSet={srcSet} />}
      <Box
        as="img"
        width="100%"
        alt={altText || ''}
        src={imageSrc}
        maxHeight={['fit-content', 'fit-content', '614px']}
        objectFit="contain"
        position={['absolute', 'absolute', 'static']}
        top={['50%', '50%', 'auto']}
        left={['50%', '50%', 'auto']}
        transform={['translate(-50%, -50%)', 'translate(-50%, -50%)', 'none']}
        {...props}
      />
    </Box>
  )
}