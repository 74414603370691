import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { InputField } from 'components'
import { useFormContext } from 'react-hook-form'
import { useFetchGreenhouseJobsQuery } from 'features/partnerProfilesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

const ATSJobIdField = ({ formDisabled, errors, helperText, placeholder, fieldName }) => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  const {
    data: {
      partner_profile: {
        id: partnerProfileId,
        greenhouse_api_key: greenhouseApiKey,
        greenhouse_api_user_id: greenhouseApiUserId
      } = {}
    } = {}
  } = useGetCurrentUserQuery()

  const { data: greenhouseApiJobs } = useFetchGreenhouseJobsQuery(partnerProfileId, {
    skip: !greenhouseApiUserId || !greenhouseApiKey
  })

  const validateGreenhouseJobId = (value) => {
    if (value === null || value === '') {
      return true
    }
    return greenhouseApiJobs.some(job => String(job.id) === value) || t('Not a valid Greenhouse job ID')
  }

  return (
    greenhouseApiUserId && greenhouseApiKey && (
      <InputField
        label={t('Greenhouse Job ID')}
        name={fieldName}
        width="60%"
        errorVal={errors}
        helperText={!formDisabled && helperText}
        inputProps={{
          disabled: formDisabled,
          placeholder: placeholder,
          ...register(
            fieldName, {
              validate: {
                isValidGreenhouseJobId: validateGreenhouseJobId
              }
            }
          )
        }}
      />
    )
  )
}

export default ATSJobIdField
