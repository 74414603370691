import React from 'react'
import {
  Box,
  Flex,
  Image,
  Heading,
  Link as ChakraLink,
  Icon,
  Wrap,
  WrapItem,
  Tag,
} from '@chakra-ui/react'
import { HiOutlineGlobeAlt, HiOutlineBuildingOffice2 } from 'react-icons/hi2'
import { OrangeTapeDisplay } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { parseWithoutStyles } from 'utils/string'
import parse from 'html-react-parser'

const PartnerSection = ({ partnerProfile }) => {
  const benefitsList = partnerProfile?.company_benefits
    ? partnerProfile.company_benefits.split(',').map(benefit => benefit.trim())
    : []
  const { t } = useTranslation()
  const companyDescription = partnerProfile?.company_description || ''
  const companyAccessibilityPolicy = partnerProfile?.company_accessibility_policy || ''

  return (
    <Box bg="#0A1A40" mx={-8} px={8} py={12} pb={40} mb="-75px" color="white">
      <Box mt={8} pl="7%" pr="7%">
        <OrangeTapeDisplay as="h2" title={t('Company Information')} fontSize="24px" pb="0px" pt="0px" />
        <Flex mt={8} gap={4} alignItems="center">
          {partnerProfile?.photo_url ? (
            <Image
              src={partnerProfile.photo_url}
              alt={partnerProfile?.company_name || 'Company Logo'}
              boxSize="100px"
              borderRadius="full"
              bg="white"
            />
          ) : (
            <Box
              boxSize="100px"
              borderRadius="full"
              bg="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={HiOutlineBuildingOffice2} boxSize="60px" color="gray.700" />
            </Box>
          )}
          <Heading as="h2" size="xl" color="white" textDecoration="underline">
            {partnerProfile?.company_name || t('Company Name Not Available')}
          </Heading>
        </Flex>

        <Box
          mt={4}
          whiteSpace="pre-wrap"
          fontFamily="Poppins"
          className="about-block__description"
          listStylePosition="inside"
        >
          {parse(companyDescription, parseWithoutStyles)}
        </Box>

        <Flex gap={4} mt={6}>
          {partnerProfile?.company_website && (
            <ChakraLink
              href={partnerProfile.company_website}
              _hover={{ opacity: 0.8 }}
              isExternal
              aria-label={`Visit ${partnerProfile.company_name} website`}
            >
              <Icon as={HiOutlineGlobeAlt} boxSize={10} color="white" cursor="pointer" />
            </ChakraLink>
          )}
        </Flex>

        <Box mt={12}>
          <Heading as="h3" size="lg" color="white" mb={6} textTransform="uppercase">
            {t('Benefits')}
          </Heading>
          <Wrap spacing={4}>
            {benefitsList.map(benefit => (
              <WrapItem key={benefit}>
                <Tag
                  size="lg"
                  bg="white"
                  color="gray.700"
                  borderRadius="full"
                  px={6}
                  py={2}
                  fontWeight="bold"
                  border="3px solid"
                  borderColor="white"
                  fontSize="sm"
                >
                  {benefit}
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>

        <Box mt={12}>
          <Heading as="h3" size="lg" color="white" mb={6} textTransform="uppercase">
            {t('How we\'re accessible')}
          </Heading>
          <Box
            maxW="80%"
            whiteSpace="pre-wrap"
            fontFamily="Poppins"
            className="about-block__description"
            listStylePosition="inside"
          >
            {parse(companyAccessibilityPolicy, parseWithoutStyles)}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PartnerSection
