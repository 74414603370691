import { Divider, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, chakra } from '@chakra-ui/react'
import { ChatContainer } from 'components/talent/beacon/modal-elements/ChatContainer'
import { ChatFooter } from 'components/talent/beacon/modal-elements/ChatFooter'
import { SplashContent } from 'components/talent/beacon/modal-elements/splash/SplashContent'
import { ChatHeader } from 'components/talent/modal-chat-ux/ChatHeader'
import { BEACON_MODE } from 'constants'
import { clearMessages } from 'features/beacon/chatSlice'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BeaconService from 'services/BeaconService'

// TODO: Move these to Chakra vars
const BKG_GREY = '#DEE2E8'

const CompassModal = ({ isOpen, onClose }) => {
  const [mode, setMode] = useState(BEACON_MODE.SPLASH)
  const { compassMode } = useSelector((state) => state.beaconChat)
  const dispatch = useDispatch()
  const initialRef = useRef(null)
  const beaconServiceRef = useRef(new BeaconService('compass', dispatch))

  const isSplashMode = mode === BEACON_MODE.SPLASH
  const maxHeight = isSplashMode ? '4xl' : '3xl'
  const modalBgColor = isSplashMode ? BKG_GREY : 'white'

  // Init the Compass service
  useEffect(() => {
    if (isSplashMode) return

    (async () => {
      await beaconServiceRef.current.initBot()
    })()
  }, [isSplashMode, dispatch])

  // Reset to splash on close
  useEffect(() => {
    if (!isOpen) {
      setMode(BEACON_MODE.SPLASH)
      dispatch(clearMessages())
    }
  }, [isOpen, dispatch])

  const CustomModalContent = useMemo(() => chakra(ModalContent, {
    baseStyle: {
      maxWidth: isSplashMode ? '1150px' : '1234px',
      maxHeight: isSplashMode ? '692px' : '90vh',
    },
  }), [isSplashMode])

  return (
    <Modal isOpen={isOpen} onCloseComplete={() => onClose(true)} onClose={onClose} initialFocusRef={initialRef}>
      <ModalOverlay />
      <CustomModalContent paddingTop={0}>
        { !isSplashMode && <ChatHeader label="Making Space AI" onClose={onClose} initialRef={initialRef} /> }
        { !isSplashMode && <Divider /> }

        <ModalBody
          fontFamily="Poppins"
          fontSize="18px"
          maxH={maxHeight}
          overflowY="auto"
          px={isSplashMode ? '40px' : '40px'}
          py={isSplashMode ? '40px' : '0'}
          pb={isSplashMode ? '60px' : '0'}
          bgColor={modalBgColor}
        >
          {isSplashMode ?
            <SplashContent
              compassMode={compassMode}
              onModeChange={setMode}
              onClose={onClose}
            />
            :
            <ChatContainer />
          }
        </ModalBody>

        <Divider />

        { !isSplashMode &&
          <ModalFooter>
            <ChatFooter />
          </ModalFooter>
        }
      </CustomModalContent>
    </Modal>
  )
}

export default CompassModal