import { useState } from 'react'

export const useDownloadFile = (downloadUrl, targetFilename) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const downloadFile = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
  }

  const triggerDownload = () => {
    setIsLoading(true)
    setError(null)

    fetch(downloadUrl)
      .then((response) => response.blob())
      .then((blob) => {
        downloadFile(blob, targetFilename)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }

  return {
    triggerDownload,
    isLoading,
    error
  }
}
