import { useLoadCourseQuery } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

// TODO: look and see if we can combine the CourseFeature/CoursePage version of this hook
export const useCurrentCourseQuery = (courseId) => {
  const { data } = useGetCurrentUserQuery() || {}
  const userId = data ? data.id : null
  const feature = data ? data.feature : null
  const isPartner = feature === 'partner_experience'

  return useLoadCourseQuery({ courseId, userId, isPartner })
}
