import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from '../../usePageTitle'
import { PageContent } from './PageContent'

export const InviteTalentPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Invite talent'))

  return <PageContent />
}
