import React, { useState } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { H2, InputField, UploadMediaField } from 'components'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { HiMiniVideoCamera, HiPresentationChartLine } from 'react-icons/hi2'
import ButtonField from 'components/forms/fields/ButtonField'
import { COURSE_STEP_MEDIUM } from 'constants'
import { useDispatch } from 'react-redux'
import { removeValueFromArray, addMultipleFormFields } from 'features/courses/courseBuilderSlice'
import { useSelector } from 'react-redux'
import { FEATURE_FLAGS } from 'constants'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useGetMediaS3Path } from 'hooks/courseBuilder/useMediaField'
import { useExtractDeckComponentsMutation } from 'features/stepsApi'
import OverlayBlock from 'components/OverlayBlock'

const EpisodeUploadVideoThumbnailForm = ({ episodeNum, formDisabled=false }) => {
  const { isEnabled: slideDeckUploadEnabled } = useFeatureFlags(FEATURE_FLAGS.SLIDE_DECK_UPLOAD)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { register, unregister, watch, trigger, setFocus } = useFormContext() // retrieve all hook methods
  const episodeMedium = useSelector(
    state => state.courseBuilder.episode_steps[episodeNum]?.[`episode_${episodeNum}_medium`]
  ) || COURSE_STEP_MEDIUM.VIDEO
  const [formEpisodeMedium, setFormEpisodeMedium] = useState(episodeMedium)
  const mediaType = React.useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  const { courseSlug } = useCourseBuilderContext()
  const { helperTextVals } = useGetFormTextValues()

  const [extractDeckComponents] = useExtractDeckComponentsMutation()

  const updateEpisodeMedium = (value) => {
    if (formDisabled) return
    const mediumIsVideo = value === COURSE_STEP_MEDIUM.VIDEO
    const stepLinkKey = `episode${episodeNum}UploadAccessibility`

    // Unregister the other medium once a new one is chosen
    // Add or remove the resulted disabled course step depending on choice
    // Update the local state for this new medium
    if (mediumIsVideo) {
      dispatch(removeValueFromArray({ key: 'disabledCourseSteps', value: stepLinkKey }))
      unregister(`episode_${episodeNum}_medium`)
      setFormEpisodeMedium(COURSE_STEP_MEDIUM.VIDEO)
    } else {
      dispatch(addMultipleFormFields({ key: 'disabledCourseSteps', value: stepLinkKey }))
      unregister(`episode_${episodeNum}_video`)
      setFormEpisodeMedium(COURSE_STEP_MEDIUM.SLIDE_DECK)
    }
  }

  const slideDeckPath = useGetMediaS3Path(episodeNum, courseSlug, `episode_${episodeNum}_deck`)
  const videoPath = useGetMediaS3Path(episodeNum, courseSlug, `episode_${episodeNum}_video`)

  return (
    <>
      <InputField
        label={t('Episode Name')}
        name={`episode_${episodeNum}_name`}
        fieldRequired
        helperText={!formDisabled && helperTextVals.episode_name}
        inputProps={{
          disabled: formDisabled,
          maxLength: 55,
          placeholder: t('Cloud Fundamentals'),
          ...register(
            `episode_${episodeNum}_name`, {
              required: t('Episode Name can’t be empty.')
            }
          )
        }}
      />
      <OverlayBlock
        isActive={!(watch(`episode_${episodeNum}_name`))}
        onClick={() => {
          trigger(`episode_${episodeNum}_name`)
          setFocus(`episode_${episodeNum}_name`)
        }}
      >
        <InputField
          label={t('Episode Blurb')}
          name={`episode_${episodeNum}_description`}
          fieldRequired
          helperText={!formDisabled && helperTextVals.episode_blurb}
          inputProps={{
            disabled: formDisabled,
            maxLength: 250,
            placeholder: t('Explore the formation, classification, and role of clouds in the Earth’s atmosphere.'),
            ...register(
              `episode_${episodeNum}_description`, {
                required: t('Episode Blurb can’t be empty.')
              }
            )
          }}
        />
        {slideDeckUploadEnabled && (
          <>
            <H2 marginBottom="1em">{t('Episode Content')}</H2>
            <ButtonField
              label={t('Content Type')}
              name={`episode_${episodeNum}_medium`}
              setValue={updateEpisodeMedium}
              fieldRequired
              radioValues={[
                { label: t('Video'), value: COURSE_STEP_MEDIUM.VIDEO, icon: HiMiniVideoCamera },
                { label: t('Slide Deck'), value: COURSE_STEP_MEDIUM.SLIDE_DECK, icon: HiPresentationChartLine },
              ]}
              defaultValue={episodeMedium}
              inputProps={{
                isDisabled: formDisabled,
              }}
            />
          </>
        )}
        {formEpisodeMedium === COURSE_STEP_MEDIUM.SLIDE_DECK && slideDeckUploadEnabled ? (
          <UploadMediaField
            name={`episode_${episodeNum}_deck`}
            label={t('Episode Slide Deck')}
            helperText={!formDisabled && t('Slide Deck size is limited to a maximum of 25 MB. PPT/PPTX or PDF only.')}
            mediaType={mediaType.SLIDE_DECK}
            s3MediaPaths={slideDeckPath}
            fieldRequired={formEpisodeMedium === COURSE_STEP_MEDIUM.SLIDE_DECK}
            inputProps={{ disabled: formDisabled }}
            onUploadComplete={() => extractDeckComponents(slideDeckPath?.uploadPath?.primary)}
          />
        ) : (
          <UploadMediaField
            name={`episode_${episodeNum}_video`}
            label={t('Episode Video')}
            helperText={!formDisabled && t('Video length is limited to 10 minutes with a maximum file size of 250 MB. MP4 only.')}
            mediaType={mediaType.EPISODE_VIDEO}
            s3MediaPaths={videoPath}
            fieldRequired={formEpisodeMedium === COURSE_STEP_MEDIUM.VIDEO}
            inputProps={{ disabled: formDisabled }}
          />
        )}
        <UploadMediaField
          name={`episode_${episodeNum}_thumbnail`}
          label={t('Episode Thumbnail')}
          helperText={!formDisabled && t('File size is limited to 5MB. PNG or JPG only.')}
          mediaType={mediaType.THUMBNAIL}
          s3MediaPaths={useGetMediaS3Path(episodeNum, courseSlug, `episode_${episodeNum}_thumbnail`)}
          fieldRequired
          inputProps={{ disabled: formDisabled }}
        />
        <InputField
          label={t('Episode Thumbnail Alt Text')}
          name={`episode_${episodeNum}_preview_alt_text`}
          inputProps={{
            disabled: formDisabled,
            maxLength: 250,
            placeholder: t('A woman in a wheelchair stares directly to camera with the Gotham city skyline behind her.'),
            ...register(
              `episode_${episodeNum}_preview_alt_text`
            )
          }}
        />
      </OverlayBlock>
    </>
  )
}

export default EpisodeUploadVideoThumbnailForm
