import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from 'usePageTitle'
import { Flex } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useLoadCourseStatsQuery } from 'features/coursesApi'
import { AppLoadingState } from '../../AppLoadingState'
import { useCourseId } from '../../CourseFeature/hooks'
import { CourseIdProvider } from '../../CourseContext'
import { CourseStatLoadedState } from './CourseStatLoadedState'

const useCurrentCourseQuery = () => (
  useLoadCourseStatsQuery(useParams().courseId)
)

const PageContent = () => {
  const { data, isLoading } = useCurrentCourseQuery()

  if (isLoading) {
    return <AppLoadingState />
  }

  return <CourseStatLoadedState data={data} />
}

const TitleWrapper = ({ children }) => {
  const { t } = useTranslation()
  const { data, isLoading } = useCurrentCourseQuery()
  usePageTitle((data && data.name) ? `"${data.name}" ${t('Course Statistics')}` : t('Loading...'))

  if (isLoading) {
    return <AppLoadingState />
  }

  return children
}

export const CourseStatsPage = () => (
  <CourseIdProvider courseId={useCourseId()}>
    <TitleWrapper>
      <Flex
        flex="1"
        flexDirection="column"
        justifyContent="space-between"
      >
        <PageContent />
      </Flex>
    </TitleWrapper>
  </CourseIdProvider>
)
