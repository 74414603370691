import { useTranslation } from 'i18n/TranslationContext'
import { Center, Spinner, Td, Tr, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'

export const TableItemMap = ({
  collection,
  listQuery: {
    data,
    isFetching,
    isError,
    isSuccess
  },
  emptyStateCopy,
  children
}) => {
  const { t } = useTranslation()
  const toast = useToast()

  useEffect(() => {
    if (!isSuccess) { return }

    document.activeElement.scrollIntoView()
  }, [isSuccess])

  if (isFetching) {
    return (
      <Tr>
        <Td colSpan="3">
          <Center>
            <Spinner />
          </Center>
        </Td>
      </Tr>
    )
  }

  if (isError) {
    toast({
      title: t('Something went wrong'),
      status: 'error',
      position: 'top',
      containerStyle: {
        position: 'absolute',
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })

    return null
  }

  const usedCollection = collection || data.collection

  if (usedCollection.length === 0) {
    return (
      <Tr>
        <Td colSpan="3" border="0">
          <Center
            padding="24px 0"
            color="#0F204B"
            whiteSpace="normal"
            fontFamily="Poppins"
            fontSize="18px"
            fontWeight="500"
          >
            {emptyStateCopy}
          </Center>
        </Td>
      </Tr>
    )
  }

  return usedCollection.map(children)
}
