import { useTranslation } from 'i18n/TranslationContext'
import { useSecretParam } from 'EmailConfirmationPage/EmailConfirmationProcess'
import { useToast } from '@chakra-ui/react'
import { usePerformEmailConfirmationQuery } from 'features/sessionsApi'
import { useEffect } from 'react'
import { AppLoadingState } from 'AppLoadingState'
import { useNavigate } from 'react-router-dom'

export const EmailConfirmationPage = () => {
  const { t } = useTranslation()
  const secret = useSecretParam()
  const navigate = useNavigate()
  const toast = useToast()
  const { isSuccess, isError } = usePerformEmailConfirmationQuery(secret)

  useEffect(() => {
    if (secret) { return }

    navigate('/sign_in')
  }, [secret, navigate])

  useEffect(() => {
    if (!isSuccess) { return }

    toast({
      title: t('Email confirmed'),
      status: 'success',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
    navigate('/sign_in')
  }, [isSuccess, toast, navigate, t])

  useEffect(() => {
    if (!isError) { return }

    toast({
      title: t('Email confirmation link is not valid'),
      status: 'error',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })

    navigate('/sign_in')
  }, [isError, navigate, toast, t])

  return <AppLoadingState />
}
