import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useBuildMultiForm } from 'hooks/opportunityBuilder/useBuildMultiForm'
import { sendFormData } from 'hooks/opportunityBuilder/useSendFormData'
import { getOpportunityStepsContent } from './getOpportunityStepsContent'
import { BuilderProvider, useBuilderContext } from 'components/builder/BuilderContext'
import validateAndContinue from 'OpportunityBuilder/utils/validateAndContinue'
import { useUpsertOpportunityMutation } from 'features/opportunitiesApi'

export const OpportunityBuilderProvider = ({ children }) => {
  const [dateOpened, setDateOpened] = useState(false)
  const [createNewOpportunity] = useUpsertOpportunityMutation()
  const [opportunityDescriptionEditorState, setOpportunityDescriptionEditorState] = useState(null)

  const { data: { id: userId, partner_profile: { id: partnerProfileId } } = {} } = useGetCurrentUserQuery()

  const opportunityData = useSelector(state => state.opportunityBuilder || {})

  const { opportunityStepsContent } = getOpportunityStepsContent()

  const opportunityBuilderValue = {
    userId,
    partnerProfileId,
    dateOpened,
    setDateOpened,
    opportunityDescriptionEditorState,
    setOpportunityDescriptionEditorState
  }

  return (
    <BuilderProvider
      initialStepId="opportunityDetails"
      stepsContent={opportunityStepsContent}
      sendData={sendFormData}
      formHook={useBuildMultiForm}
      validateAndContinue={validateAndContinue}
      builderStateSelector="opportunityBuilder"
      disabledSteps={opportunityData?.disabledSteps}
      builderSpecificValues={opportunityBuilderValue}
      createNew={createNewOpportunity}
    >
      {children}
    </BuilderProvider>
  )
}

export const useOpportunityBuilderContext = useBuilderContext