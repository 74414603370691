import { useCourseId } from '../../CourseFeature/hooks'
import { useListTalentProfilesQuery } from 'features/talentProfilesApi'
import { useMatch, useSearchParams } from 'react-router-dom'
import { unwrapSearchParams } from '../unwrapSearchParams'

const useGetSearchParams = () => {
  let [searchParams] = useSearchParams()
  const isMain = !!useMatch('/courses/dashboard/:courseId/assignments')
  const isContactRequest = !!useMatch('/courses/dashboard/:courseId/assignments/talent_profile/:talentProfileId/view/contact_request')

  if (isMain) { return searchParams }

  searchParams = unwrapSearchParams(searchParams)

  if (!isContactRequest) { return searchParams }

  return unwrapSearchParams(searchParams)
}

export const useTalentProfileListData = () => {
  const searchParams = useGetSearchParams()
  const pageNumber = searchParams.get('page')

  const courseId = useCourseId()

  return useListTalentProfilesQuery({
    pageNumber,
    withAssignments: true,
    courseId
  })
}
