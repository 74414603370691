import { Divider, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react'
import { ChatHeader } from 'components/talent/modal-chat-ux/ChatHeader'
import { QuizContainer } from 'components/talent/quiz/modal-elements/QuizContainer'
import { SplashContent } from 'components/talent/quiz/modal-elements/splash/SplashContent'
import { TALENT_QUIZ_MODAL_MODE } from 'constants'
import { resetState, setModalIsOpen } from 'features/quiz/talentQuizSlice'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useHasCompletedQuiz } from 'utils/quizzes/talentQuiz'

// TODO: Move these to Chakra vars
const BKG_GREY = '#DEE2E8'

const QuizModal = ({ isOpen, onClose, step }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [mode, setMode] = useState(TALENT_QUIZ_MODAL_MODE.SPLASH)
  const initialRef = useRef(null)
  const { hasCompletedQuiz, isLoading: quizLoading } = useHasCompletedQuiz(step.id)

  // Reset state and set modal open/close state
  // We need this extra modal open/close state because
  // QuizContainer needs it, and isOpen doesn't update inside ModalBody
  useEffect(() => {
    if (!isOpen) {
      dispatch(resetState())
    }
    dispatch(setModalIsOpen(isOpen))
  }, [isOpen, dispatch])

  useEffect(() => {
    const{ QUIZ, SPLASH } = TALENT_QUIZ_MODAL_MODE
    setMode(hasCompletedQuiz ? QUIZ : SPLASH)
  }, [hasCompletedQuiz])

  const handleBeginQuiz = () => {
    setMode(TALENT_QUIZ_MODAL_MODE.QUIZ)
  }

  const isSplashMode = mode === TALENT_QUIZ_MODAL_MODE.SPLASH
  const maxHeight = isSplashMode ? '4xl' : '3xl'
  const modalBgColor = isSplashMode ? BKG_GREY : 'white'

  const handleNavigate = (url) => {
    onClose()
    navigate(url)
  }

  if ( quizLoading ) return <Spinner />

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['lg', null, '6xl']} initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent minH="lg" paddingTop={0}>
        { !isSplashMode && <ChatHeader step={step} onClose={onClose} initialRef={initialRef} /> }
        { !isSplashMode && <Divider /> }

        <ModalBody
          fontFamily="Poppins"
          fontSize="18px"
          maxH={maxHeight}
          overflowY="auto"
          px="40px"
          py="40px"
          pb="60px"
          bgColor={modalBgColor}
        >
          {isSplashMode ?
            <SplashContent onBeginQuizClick={handleBeginQuiz} /> :
            <QuizContainer stepId={step.id} onNextStepClick={handleNavigate} />
          }
        </ModalBody>

        <Divider />
      </ModalContent>
    </Modal>
  )
}

export default QuizModal