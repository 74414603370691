import { useTranslation } from 'i18n/TranslationContext'
import React from 'react'
import { Box, Text, Select } from '@chakra-ui/react'
import { useListUserGroupsQuery } from '../../../features/userGroupsApi'
import { useFormContext } from 'react-hook-form'
import { FormLabel } from '@chakra-ui/react'
import { AppLoadingState } from 'AppLoadingState'

const FormContent = () => {
  const { t } = useTranslation()
  const { data: userGroups, isLoading, isError } = useListUserGroupsQuery()
  const { setValue } = useFormContext()
  const [groupMapping, setGroupMapping] = React.useState({})

  const SELECT_PLACEHOLDER = t('I do not wish to answer')

  if (isLoading) {
    return <AppLoadingState />
  }

  if (isError) {
    return <div>{t('Error fetching User Groups')}</div>
  }

  const handleChange = (e, groupId) => {
    const value = e.target.value

    // Update groupMapping with the new selection
    const newGroupMapping = { ...groupMapping, [groupId]: value }

    // Remove entries with placeholder values (e.g., "I do not wish to answer")
    const cleanedMapping = Object.fromEntries(
      Object.entries(newGroupMapping).filter(([_, val]) => val !== SELECT_PLACEHOLDER && val !== '')
    )

    // Flatten the mapping to an array of selected items
    const newSelectedItems = Object.values(cleanedMapping)

    // Update the form context
    setValue('userGroupItemIds', newSelectedItems)

    // Update local state with the new mapping
    setGroupMapping(cleanedMapping)
  }

  return (
    <Box width="full">
      <Text mb={3} fontSize="20px" fontFamily="Poppins" textAlign="center">{t('At Making Space, our goal is to create a more equitable and accessible future of work for Disabled, low income and traditionally underrepresented talent.')}</Text>
      <Text mb={12} fontSize="20px" fontFamily="Poppins" textAlign="center">{t('To help us create a more inclusive platform for our talent, please consider providing the information below. However, you’re welcome to proceed without completing this step.')}</Text>
      {userGroups.map((group) => (
        <Box key={group.id} mb={5}>
          <FormLabel
            htmlFor={`group-select-${group.name}`}
            fontSize="24px"
            fontFamily="Poppins"
            fontWeight="600"
            mb={3}
          >
            {`${group.label}:`}
          </FormLabel>
          <Select id={`group-select-${group.name}`} placeholder={SELECT_PLACEHOLDER} size="lg" mb={10}
            onChange={(e) => handleChange(e, group.id)}
          >
            {group.user_group_items.map((item) => (
              <option key={item.id} value={item.id}>{item.label}</option>
            ))}
          </Select>
        </Box>
      ))}
    </Box>
  )
}

export default FormContent
