import { REGEX_PATTERNS } from 'constants'
import { useFormContext } from 'react-hook-form'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { InputField } from 'components'
import { useSelector } from 'react-redux'

const CourseExpertSocialLinks = ({ t, courseExpertFieldNames, formDisabled = false }) => {
  const { register } = useFormContext() // retrieve all hook methods
  const { placeholderTextVals } = useGetFormTextValues()
  const errors = useSelector(state => state.courseBuilder.errors)

  const socialPlatforms = [
    { label: 'Expert Website', field: courseExpertFieldNames.course_expert_link, required: true },
    { label: 'Linkedin', field: courseExpertFieldNames.course_expert_linkedin, domain: 'linkedin.com' },
    { label: 'Instagram', field: courseExpertFieldNames.course_expert_instagram, domain: 'instagram.com' },
    { label: 'TikTok', field: courseExpertFieldNames.course_expert_tiktok, domain: 'tiktok.com' }
  ]

  const generateInputField = (platform) => (
    <InputField
      label={t(platform.label)}
      key={platform.field}
      name={platform.field}
      errorVal={errors}
      width="100%"
      fieldRequired={platform.required || false}
      inputProps={{
        disabled: formDisabled,
        placeholder: placeholderTextVals[platform.field],
        ...register(
          platform.field, {
            required: platform.required && t(`${platform.label} can't be empty.`),
            validate: platform.domain ? value => !value || value.toLowerCase().includes(platform.domain) || t(`The URL must include ${platform.domain}.`) : undefined,
            pattern: {
              value: REGEX_PATTERNS.URL,
              message: t('Invalid URL')
            }
          }
        )
      }}
    />
  )

  return (
    <>
      {socialPlatforms.map(platform => generateInputField(platform))}
    </>
  )
}

export default CourseExpertSocialLinks
