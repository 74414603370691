import { useCourseData } from '../CourseData'
import { EpisodeNavigation } from './EpisodeNavigation'
import { ChaptersBlock } from './Chapters'

export const CourseNavigationBlock = () => {
  const oneVideoCourses = ['Screenwriting 101', 'Directing 101']
  const { name } = useCourseData()

  if (oneVideoCourses.includes(name)) {
    return <ChaptersBlock />
  }

  return <EpisodeNavigation />
}
