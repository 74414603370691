import { useTranslation } from 'i18n/TranslationContext'
import { PageOuterContainer } from '../PartnerLayout'
import { Box, Button, Flex, Spacer } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { TalentsBlock } from './TalentsBlock'
import { SubmittedAssignmentsBlock } from './SubmittedAssignmentsBlock'
import { InviteTalentButton } from './InviteTalentButton'

export const CourseStatLoadedState = ({
  data: {
    id,
    name,
    visibility,
    submitted_assignments_count: assignmentsCount,
    talents_enrolled: talentsEnrolled,
    'with_assignments?': withAssignments,
  }
}
) => {
  const { t } = useTranslation()
  return (
    <PageOuterContainer>
      <Box
        bgColor="white"
        padding={[
          '40px 24px 40px',
          null,
          '40px 32px 40px'
        ]}
      >
        <Box marginBottom="16px">
          <Button
            as={Link}
            to="/courses"
            colorScheme="zettaPurple"
            fontWeight="500"
          >
            {t('Back')}
          </Button>
        </Box>
        <Box
          display={['block', null, 'flex']}
          alignItems="center"
          flexWrap="wrap"
        >
          <Box
            marginLeft="16px"
            color="var(--navy-blue, #0F204A)"
            fontFamily="Spline Sans Mono"
            fontSize="40px"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {name}
          </Box>
          <Spacer />
          <Box pt={['12px', null, '0']}>
            <Button
              as={Link}
              to={`/courses/${id}`}
              colorScheme="zettaPurple"
              width="100%"
              fontWeight="500"
            >
              {t('View the course')}
            </Button>
          </Box>
        </Box>
        <Flex
          flexDirection={['column', null, 'row']}
          pt="24px"
        >
          <TalentsBlock
            id={id}
            talentsEnrolled={talentsEnrolled}
          />
          {withAssignments &&
            <>
              <Box flexBasis="24px" />
              <SubmittedAssignmentsBlock
                id={id}
                assignmentsCount={assignmentsCount}
              />
            </>
          }
          <>
            <Box flexBasis="24px" />
            <InviteTalentButton />
          </>
        </Flex>
      </Box>
    </PageOuterContainer>
  )
}
