import { apiSlice } from './apiSlice'

export const skillsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listUserSkills: builder.query({
      query: ({ userId }) => `/users/${userId}/skills`,
      providesTags: ['UserSkills']
    }),
  })
})

export const {
  useListUserSkillsQuery,
} = skillsApi
