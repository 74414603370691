import { FormLabel } from '@chakra-ui/react'
import { formStyles } from './formStyles'

const StyledFormLabel = ({ children }) => {
  const { formLabelStyles } = formStyles()

  return (
    <FormLabel
      marginBottom="16px"
      {...formLabelStyles}
    >
      {children}
    </FormLabel>
  )
}

export default StyledFormLabel
