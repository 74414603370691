import { useCurrentEpisodeData } from '../hooks'
import { Box } from '@chakra-ui/react'
import { parseWithoutStyles } from 'utils/string'
import parse from 'html-react-parser'

export const AssignmentDetails = () => {
  const { description } = useCurrentEpisodeData()

  return (
    <>
      <Box
        pb="48px"
        fontFamily="Poppins"
        sx={{
          h3: {
            fontSize: '20px',
            fontWeight: 'bold',
            paddingBottom: '4px'
          },
          p: {
            paddingBottom: '24px'
          },
          ol: {
            paddingLeft: '20px',
            paddingBottom: '24px',
            li: {
              listStyleType: 'inside',
              paddingLeft: '4px'
            }
          }
        }}
      >
        {parse(description || '', parseWithoutStyles)}
      </Box>
    </>
  )
}
