import { useTranslation } from 'i18n/TranslationContext'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListQuery } from 'hooks/opportunities/useListQuery'

export const usePartnerOpportunities = () => {
  const { t } = useTranslation()
  const { data: userData, isLoading: userLoading, error: userError } = useGetCurrentUserQuery()

  const partnerProfileId = userData?.partner_profile?.id

  const {
    data: listData,
    isLoading: listLoading,
    error: listError,
  } = useListQuery('partner_experience', {
    partnerProfileId,
    skip: userLoading || !partnerProfileId,
  })

  if (userLoading || listLoading) {
    return { isLoading: true, message: t('Loading data...') }
  }

  if (userError || listError) {
    const error = userError || listError
    return { error, message: t('Error loading data') }
  }

  if (!userData || !partnerProfileId) {
    return { error: new Error('Partner Profile ID not found'), message: t('Partner Profile ID not found') }
  }

  if (!listData) {
    return { error: new Error('Opportunities data not found'), message: t('Error loading opportunities') }
  }

  return { data: listData, partnerProfileId }
}
