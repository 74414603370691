import { useTranslation } from 'i18n/TranslationContext'
import { useToast, useDisclosure } from '@chakra-ui/react'
import { useListAssignmentsQuery, useSubmitAssignmentMutation } from 'features/submittedAssignmentApi'
import { useLocation } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { AppLoadingState } from 'AppLoadingState'
import { NewAssignmentUpload } from './NewAssignmentUpload'
import { AssignmentTurnedIn } from './AssignmentTurnedIn'
import { useEffect, useCallback } from 'react'
import { usePlaybackEndContext } from 'CourseFeature/EpisodePage/context/PlaybackEndContext'
import SectionCompleteModal from 'components/talent/quiz/sectionCompleteModal/SectionCompleteModal'

export const AssignmentBlock = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const toast = useToast()
  const [submitAssignment, { isSuccess: isSubmitSuccess }] = useSubmitAssignmentMutation()
  const {
    data: {
      talent_profile: { id }
    }
  } = useGetCurrentUserQuery()
  const { data: assignments, isFetching, isSuccess } = useListAssignmentsQuery({
    episodePath: pathname,
    talentProfileId: id
  })

  const { handlePlaybackEnd } = usePlaybackEndContext()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSectionComplete = useCallback(() => {
    onOpen()
    handlePlaybackEnd()
  }, [onOpen, handlePlaybackEnd])

  useEffect(() => {
    if (!isSubmitSuccess) { return }

    // Show the toast
    const toastId = toast({
      title: t('Your Assignment has been submitted'),
      status: 'success',
      position: 'top',
      duration: 2000, // 2 seconds
      isClosable: false,
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      },
    })

    // Set a timeout to open the SectionCompleteModal after the toast closes
    const timer = setTimeout(() => {
      toast.close(toastId)
      handleSectionComplete()
    }, 2000)

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer)
  }, [toast, isSubmitSuccess, t, handleSectionComplete])

  if (isFetching) {
    return <AppLoadingState />
  }

  if (isSuccess && assignments.length > 0) {
    return <AssignmentTurnedIn />
  }

  return (
    <>
      <NewAssignmentUpload submitAssignment={submitAssignment} />
      <SectionCompleteModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
