import { useTranslation } from 'i18n/TranslationContext'
import { Icon } from '@chakra-ui/icons'
import { HiPlay } from 'react-icons/hi'
import { useCourseId, useIsStepsMode, useSteps } from '../hooks'
import { CourseStartButton } from './CourseStartButton'
import { useCoursePageContext } from './CoursePageContext'

const useStartWatchingTo = () => {
  const courseId = useCourseId()
  const isStepsMode = useIsStepsMode()
  const steps = useSteps()

  if (isStepsMode) {
    return `/courses/${courseId}/steps/${steps.order[0]}`
  }

  return `/courses/${courseId}/episodes/1`
}

const WatchingButtonBase = ({ children, ...props }) => (
  <CourseStartButton
    className="watching-button-base"
    size="lg"
    fontSize="20px"
    fontWeight="normal"
    colorScheme="zettaWhite"
    variant="solid"
    height="60px"
    width={['100%', '100%', 'unset']}
    to={useStartWatchingTo()}
    {...props}
  >
    {children}
    <Icon
      as={HiPlay}
      ml="50px"
    />
  </CourseStartButton>
)

const StartWatchingButton = (props) => {
  const { t } = useTranslation()
  return (
    <WatchingButtonBase {...props}>
      {t('Start watching')}
    </WatchingButtonBase>
  )
}

const ContinueWatchingButton = (props) => {
  const { t } = useTranslation()
  return (
    <WatchingButtonBase {...props}>
      {t('Continue watching')}
    </WatchingButtonBase>
  )
}

export const WatchingButton = (props) => {
  const { data: { 'enrolled?': isEnrolled } } = useCoursePageContext().courseContentData

  return isEnrolled ? <ContinueWatchingButton {...props} /> : <StartWatchingButton {...props} />
}
