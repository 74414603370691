import React, { useRef, useEffect, useState } from 'react'
import { FormTemplate } from 'components/builder/FormTemplate'
import { useTranslation } from 'i18n/TranslationContext'
import useSendFormData from 'hooks/opportunityBuilder/useSendFormData'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCloneOpportunityMutation, usePublishOpportunityFromDraftMutation } from 'features/opportunitiesApi'
import { useLoadCourseStatsQuery } from 'features/coursesApi'
import { ReviewAndSubmitContent } from '../FormContent/ReviewAndSubmitContent'

const handleCloneOpportunity = async (cloneOpportunity, id) => {
  try {
    const response = await cloneOpportunity(id).unwrap()
    // Fully refreshing builder state by using window href
    window.location.href = `/opportunities/${response.id}/edit`
  } catch (error) {
    console.error('Failed to clone opportunity', error)
  }
}

const useGetCtaBehavior = (opportunityStatus, associatedCourseStatus, id) => {
  const [cloneOpportunity] = useCloneOpportunityMutation()
  const [publishOpportunity] = usePublishOpportunityFromDraftMutation()

  const navigate = useNavigate()
  const { t } = useTranslation()

  let ctaText = ''
  let ctaAction
  let disabledTooltipText = ''

  switch (opportunityStatus) {
  case 'closed':
    ctaText = t('Clone')
    ctaAction = async () => await handleCloneOpportunity(cloneOpportunity, id)
    break
  case 'draft':
    ctaText = t('Create & Open')
    ctaAction = async () => {
      await publishOpportunity({ id, statusChange: 'open' })
      navigate('/opportunities')
    }
    break
  default: {
    ctaText = t('Save & Publish')
    ctaAction = async () => {
      await publishOpportunity({ id })
      navigate('/opportunities')
    }
  }}

  if (associatedCourseStatus === 'draft') {
    disabledTooltipText = t('Please publish associated course before continuing.')
  }

  return { ctaText, ctaAction, disabledTooltipText }
}

export const ReviewAndSubmit = () => {
  const { t } = useTranslation()
  const [builderCompleted, setBuilderCompleted] = useState(false)
  const opportunityData = useSelector(state => state.opportunityBuilder)
  const navigate = useNavigate()
  const initialized = useRef(false)
  const { data: associatedCourseData } = useLoadCourseStatsQuery(opportunityData.course_id, {
    skip: !opportunityData.course_id
  })

  // When the opportunity form changes, handle the data being sent to the backend
  useSendFormData(opportunityData, initialized)

  const { ctaText, ctaAction, disabledTooltipText } = useGetCtaBehavior(
    opportunityData.status,
    associatedCourseData?.status,
    opportunityData.id
  )

  useEffect(() => {
    const isBuilderComplete = () => {
      let requiredSteps = [
        'opportunityDetails',
        'opportunityDescription',
      ]

      // Check for any invalid course steps
      if (opportunityData.invalid_builder_steps.length > 0) {
        return false
      }

      // Verify all required steps are valid
      const isComplete = requiredSteps.every(step =>
        opportunityData.valid_builder_steps.includes(step) ||
        opportunityData.disabledSteps.includes(step)
      )

      return isComplete
    }

    if (isBuilderComplete()) {
      setBuilderCompleted(true)
    }

  }, [
    setBuilderCompleted,
    navigate,
    opportunityData.invalid_builder_steps.length,
    opportunityData.valid_builder_steps,
    opportunityData.disabledSteps,
  ])


  return (
    <FormTemplate
      formTitle={t('Review')}
      nextStep={ctaAction}
      ctaText={ctaText}
      buttonDisabled={!builderCompleted || !!disabledTooltipText}
      disabledNavTooltipText={disabledTooltipText}
    >
      <ReviewAndSubmitContent />
    </FormTemplate>
  )
}
