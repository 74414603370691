import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const awsApiSlice = createApi({
  reducerPath: 'awsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: '', // No base URL needed since object URLs are complete
    credentials: 'include',
  }),
  endpoints: (builder) => ({
    doesObjectExist: builder.query({
      query: ({ objectURL }) => ({
        url: objectURL,
        method: 'HEAD',
      }),
      transformResponse: (response, meta) => {
        return meta?.response?.ok || false
      },
      providesTags: (result, error, { objectURL }) => [{ type: 'AWSObject', id: objectURL }]
    }),
    getTextObject: builder.query({
      query: ({ objectURL }) => ({
        url: objectURL,
        method: 'GET',
        responseHandler: 'text',
      }),
      transformResponse: (response) => {
        return response
      },
      providesTags: (result, error, { objectURL }) => [{ type: 'AWSObject', id: objectURL }]
    }),
  })
})

export const {
  useDoesObjectExistQuery,
  useGetTextObjectQuery,
} = awsApiSlice