import { Box, Flex } from '@chakra-ui/react'
import { ContinueWatching, NotInterested } from './shared'

export const EnrolledButtons = ({ id }) => (
  <Flex
    flexDirection={['column', null, 'row']}
    justifyContent={[null, null, 'end']}
  >
    <Box
      paddingRight="8px"
      width={['100%', null, 'unset']}
      textAlign={['center', null, 'unset']}
    >
      <NotInterested
        id={id}
        width={['100%', null, 'unset']}
      />
    </Box>
    <ContinueWatching
      id={id}
      width={['100%', null, 'unset']}
    />
  </Flex>
)
