import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useDownloadFile } from 'hooks/useDownloadFile'
import { useSelector } from 'react-redux'
import { useListTalentProfilesQuery } from 'features/talentProfilesApi'
import { useMatch, useSearchParams, useParams } from 'react-router-dom'

export const unwrapSearchParams = (searchParams) => {
  const backTo = searchParams.get('backTo')
  if (!backTo) {
    return searchParams
  }
  try {
    return new URL(backTo, window.location.href).searchParams
  } catch (error) {
    console.error('Invalid backTo URL:', backTo)
    return searchParams
  }
}

export const useGetSearchParams = () => {
  let [searchParams] = useSearchParams()
  const isMain = !!useMatch('/opportunities/applications')

  if (isMain) {
    return searchParams
  }

  searchParams = unwrapSearchParams(searchParams)

  return searchParams
}

// Downloads a CSV file of the talent profiles for the current Opportunity Applications
export const useTalentProfileCsvExport = () => {
  const { opportunityId } = useParams()

  const dtRegExp = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*/
  const timestamp = new Date().toISOString().replace(/[-:.T]/g, '').replace(dtRegExp, 'talent-profiles-$1-$2-$3-$4$5Z')
  const filename = `${timestamp}.csv`
  const { isLoading, triggerDownload } = useDownloadFile(`/api/talent_profiles/csv?opportunity_id=${opportunityId}`, filename)

  return {
    handleExportCsv: () => triggerDownload(),
    isLoading
  }
}

const useGetTalentProfileListArgs = () => {
  const { opportunityId } = useParams()
  const searchParams = useGetSearchParams()
  const pageNumber = searchParams.get('page') || '1'

  const { lastSearchParams } = useSelector((state) => state.talentSearch)

  const {
    data: { partner_profile: { id } }
  } = useGetCurrentUserQuery()

  return {
    pageNumber,
    partnerProfileId: id,
    opportunityId,
    searchDrawerParams: lastSearchParams
  }
}

export const useApplicationListData = () => {
  const args = useGetTalentProfileListArgs()
  return useListTalentProfilesQuery(args)
}
