import { useDisclosure } from '@chakra-ui/react'
import PlaybackEndContext from 'CourseFeature/EpisodePage/context/PlaybackEndContext'
import { useCourseId, useEpisodes, useStepId } from 'CourseFeature/hooks'
import { useCreateAssignmentAccessGrantMutation } from 'features/coursesApi'
import { useFetchQuizByStepIdQuery } from 'features/quiz/quizzesApi'
import { useCallback, useEffect, useRef } from 'react'
import { useHasCompletedQuiz } from 'utils/quizzes/talentQuiz'
import { apiSlice } from 'features/apiSlice'
import { useDispatch } from 'react-redux'


const giveAssignmentAccessGrant = async ({ courseId, createAssignmentAccessGrant, stepId }) => {
  try {
    await createAssignmentAccessGrant({ courseId, stepId })
  } catch (error) {
    console.error('Error creating assignment access grant', error)
  }
}

const PlaybackEndProvider = ({ children }) => {
  const courseId = useCourseId()
  const episodes = useEpisodes()
  const stepId = useStepId()
  const lastStepId = episodes[episodes.length - 1]
  const [createAssignmentAccessGrant] = useCreateAssignmentAccessGrantMutation()
  const { hasCompletedQuiz } = useHasCompletedQuiz(stepId)
  const dispatch = useDispatch()

  // Store state values in useRefs to deal with closure issues
  const { data: thisStepQuizData } = useFetchQuizByStepIdQuery(stepId)
  const thisStepQuizRef = useRef(thisStepQuizData)
  useEffect(() => { thisStepQuizRef.current = thisStepQuizData }, [thisStepQuizData])

  const {
    isOpen: isQuizSectionCompleteOpen,
    onOpen: onQuizSectionCompleteModalOpen,
    onClose: onQuizSectionCompleteClose,
  } = useDisclosure()

  const {
    isOpen: isSectionCompleteOpen,
    onOpen: onSectionCompleteModalOpen,
    onClose: onSectionCompleteClose,
  } = useDisclosure()

  const handleAssignmentAccessGrant = useCallback(() => {
    if (Number(stepId) === Number(lastStepId)) {
      giveAssignmentAccessGrant({ courseId, createAssignmentAccessGrant, stepId })
    }
  }, [stepId, lastStepId, courseId, createAssignmentAccessGrant])

  const handleSectionComplete = useCallback(() => {
    // The URL step has a quiz and feature is enabled
    if (thisStepQuizData) {
      hasCompletedQuiz ? onSectionCompleteModalOpen() : onQuizSectionCompleteModalOpen()
    } else {
      onSectionCompleteModalOpen()
    }
  }, [thisStepQuizData, hasCompletedQuiz, onQuizSectionCompleteModalOpen, onSectionCompleteModalOpen])

  // We're going to get enrolment_step_states from the tracking response, which will update the UI
  const handlePlaybackEnd = useCallback(() => {
    // If this is the last step, send an assignment access grant
    handleAssignmentAccessGrant()

    // Invalidate the course enrolment data
    dispatch(apiSlice.util.invalidateTags(['CourseEnrolment']))

    // determine which 'section complete' modal to show
    handleSectionComplete()
  // }, [triggerCourseEnrolmentQuery, courseId, handleAssignmentAccessGrant, handleSectionComplete])
  }, [handleAssignmentAccessGrant, handleSectionComplete, dispatch])

  const contextValue = {
    isQuizSectionCompleteOpen,
    onQuizSectionCompleteClose,
    isSectionCompleteOpen,
    onSectionCompleteClose,
    handlePlaybackEnd,
  }

  return <PlaybackEndContext.Provider value={contextValue}>{children}</PlaybackEndContext.Provider>
}

export default PlaybackEndProvider