import { Flex, Box, Link as ChakraLink, Tooltip } from '@chakra-ui/react'
import { formStyles } from './formStyles'
import { PrimaryButton } from 'components'

const FormHeader = ({ t, textValue, nextStep, headerDisplay, formType, buttonDisabled, disabledNavTooltipText, ctaText }) => {
  const { formHeaderStyle } = formStyles()
  const renderTooltipText = () => {
    if (buttonDisabled && disabledNavTooltipText) {
      return disabledNavTooltipText
    }
    return textValue.includes('Review & Submit') ? t('Please review your course content and scroll to the bottom of the page to enable') : ''
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      bgColor={headerDisplay ? '#EFEFF0' : '#FAFAFA' }
      border={headerDisplay ? 'none' : '1px solid #AFB1B6' }
      width="100%"
      p="25"
    >
      <Box as="h2"
        pt={!headerDisplay && '10px' }
        fontSize={headerDisplay ? ['18px', null, null, '24px'] : ['12px', null, null, '18px'] }
        fontWeight={headerDisplay ? 'bold' : 'normal' }
        {...formHeaderStyle}
      >
        {textValue}
      </Box>
      <Tooltip label={renderTooltipText()} shouldWrapChildren>
        <PrimaryButton
          as={ChakraLink}
          className="submit-review-course-button"
          isDisabled={buttonDisabled}
          padding="25px"
          textValue={ctaText || t('Next')}
          onClick={(e) => {
            if (buttonDisabled) {
              e.preventDefault()
            } else {
              nextStep()
            }
          }}
        />
      </Tooltip>
    </Flex>
  )
}

export default FormHeader
