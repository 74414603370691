import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { BgVideo, BgVideoControlButton } from 'BgVideo'
import { ShiftedBox } from './shared/Layout'

import { useCourseData } from '../CourseData'

import { useLayoutEffect, useRef, useState } from 'react'
import { useCourseBasePath } from '../hooks'
import { useCoursePageContext } from './CoursePageContext'

const CourseLevelTape = () => (
  <Flex
    position="absolute"
    width="100%"
    height="100%"
    zIndex={-1}
    _before={{
      display: 'block',
      content: '" "',
      width: '14px',
      bgImage: `${process.env.PUBLIC_URL}/tape2-left.svg`,
      bgSize: 'cover'
    }}
    _after={{
      display: 'block',
      content: '" "',
      width: '11px',
      bgImage: `${process.env.PUBLIC_URL}/tape2-right.svg`,
      bgSize: 'cover'
    }}
  >
    <Box
      bg="#5000E8"
      opacity="0.85"
      flex="1"
    />
  </Flex>
)

const CourseLevelBadge = () => {
  const { level } = useCourseData()
  const { t } = useTranslation()

  return (
    <Box>
      <Box
        pt="10px" pb="10px"
        display="inline-flex"
        maxWidth="50%"
        fontFamily="Spline Sans Mono"
        fontWeight="bold"
        textTransform="uppercase"
        alignItems="center"
        position="relative"
        zIndex={20}
      >
        <CourseLevelTape />
        <Box ml={6} bg="#FF7D19" minWidth={13} height={13} borderRadius="50%" mr={2} />
        <Box mr={6}>
          {t('Course level:')} {' '} {level}
        </Box>
      </Box>
    </Box>
  )
}

const CourseNameTape = () => (
  <Flex
    position="absolute"
    width="100%"
    height="100%"
    zIndex={-1}
    _before={{
      display: 'block',
      content: '" "',
      width: '37px',
      bgImage: `${process.env.PUBLIC_URL}/tape1-left.svg`,
      bgSize: 'cover'
    }}
    _after={{
      display: 'block',
      content: '" "',
      width: '28px',
      bgImage: `${process.env.PUBLIC_URL}/tape1-right.svg`,
      bgSize: 'cover'
    }}
  >
    <Box
      bg="#FF7D19"
      opacity="0.85"
      flex="1"
    />
  </Flex>
)

const CourseNameBadge = () => {
  const { data: { name } } = useCoursePageContext().courseContentData

  return (
    <Box
      pr={12}
      mt={-1}
      fontFamily="Spline Sans Mono"
      fontSize={[44, 62]}
      fontWeight="bold"
      lineHeight="60px"
      textTransform="uppercase"
      display="inline-flex"
      position="relative"
      zIndex={10}
    >
      <CourseNameTape />
      <Center
        as="h1"
        pl={['22px', '32px', '40px']}
        pt="16px" pb="18px"
      >
        {name}
      </Center>
    </Box>
  )
}

const CourseDescriptionBlock = () => {
  const { description } = useCourseData()

  return (
    <Box
      pt={8} pb={9}
      pl={['12px', 0]}
      pr={['12px', 0]}
      maxWidth="720px"
      fontFamily="Poppins"
      fontSize={[18, 22]}
    >
      {description}
    </Box>
  )
}

const AuthorTape = ({ transform }) => {
  const tapeRef = useRef(null)

  useLayoutEffect(() => {
    if (!tapeRef.current) { return }

    const theTape = tapeRef.current
    theTape.addEventListener('load', () => {
      const img = theTape.contentDocument.getElementById('img-middle')

      // The following is required for Firefox and Safari, but not for Chrome:
      if (img.width.baseVal.valueAsString === '100%') {
        if (img.width.baseVal.value === 0) {
          setTimeout(() => {
            if (img.width.baseVal.valueAsString === '100%') {
              img.setAttribute('width', img.width.baseVal.value - 85)
            }
          }, 0)
        } else {
          img.setAttribute('width', img.width.baseVal.value - 85)
        }
      }
    })
  }, [])

  return (
    <Flex
      opacity="0.85"
      position="absolute"
      width="100%"
      height="100%"
      zIndex={-1}
      transform={transform}
    >
      {/* eslint-disable-next-line*/}
      <object
        aria-hidden
        ref={tapeRef}
        width="100%"
        data={`${process.env.PUBLIC_URL}/tape3.svg`}
        type="image/svg+xml"
      />

    </Flex>
  )
}

const AuthorOne = () => {
  const [photoLoaded, setPhotoLoaded] = useState(false)
  const basePath = useCourseBasePath()
  const { authors: [{ name, photo }] } = useCourseData()

  return (
    <Box visibility={photoLoaded ? 'visible' : 'hidden'}>
      <Flex
        display="inline-flex"
        pl="54px"
        transform="rotate(-0.96deg);"
        alignItems="center"
        position="relative"
        zIndex="20"
      >
        {
          photoLoaded &&
            <AuthorTape transform="scale(1, -1)" />
        }
        <Box
          pt="11px" pb="10px" pl="52px"
          role="listitem"
        >
          {name}
        </Box>
        <Box as="img"
          width="74px"
          height="74px"
          ml={3} mr={2}
          mt={-6}
          src={`${basePath}/${photo}`}
          alt=""
          onLoad={() => {
            setPhotoLoaded(true)
          }}
        />
      </Flex>
    </Box>
  )
}

const AuthorTwo = () => {
  const [photoLoaded, setPhotoLoaded] = useState(false)
  const basePath = useCourseBasePath()
  const { authors: [, author] } = useCourseData()

  if(!author) {return null}

  const { name, photo } = author

  return (
    <Box ml="-30px" visibility={photoLoaded ? 'visible' : 'hidden'}>
      <Flex
        display="inline-flex"
        transform="rotate(-3.92deg)"
        alignItems="center"
        position="relative"
        zIndex="20"
      >
        {
          photoLoaded &&
            <AuthorTape transform="scale(-1, -1)" />
        }
        <Box as="img"
          width="74px"
          height="74px"
          ml="140px"
          mr={2}
          mt={-6}
          src={`${basePath}/${photo}`}
          alt=""
          onLoad={() => {
            setPhotoLoaded(true)
          }}
        />
        <Box
          pt="11px" pb="10px" pl={3} pr="80px"
          role="listitem"
        >
          {name}
        </Box>
      </Flex>
    </Box>
  )
}

const LedByBlock = () => {
  const { t } = useTranslation()
  return (
    <>
      <ShiftedBox
        aria-hidden
        fontFamily="Comfortaa"
        fontSize="20px"
        lineHeight="26px"
        textTransform="uppercase"
      >
        {t('led by')}
      </ShiftedBox>
      <Box
        role="list"
        aria-label={t('Authors')}
        ml="-14px"
        mt={3}
        pb="128px"
        fontFamily="Comfortaa"
        fontSize="20px"
        fontWeight="bold"
        lineHeight="50px"
        textTransform="uppercase"
      >
        <AuthorOne />
        <AuthorTwo />
      </Box>
    </>
  )
}

const CTABlock = () => {
  const { mainCTAElement, backDashboardButton } = useCoursePageContext()

  return (
    <Box pr={['12px', '12px', '0']} flex="1 1 300px">
      {mainCTAElement}
      {backDashboardButton}
    </Box>
  )
}

const CourseInformationBlock = () => (
  <Center
    width="100%"
    minHeight="1012px"
  >
    <Box
      flex="1"
      maxWidth={['480px', '1440px']}
      width="100%"
    >
      <ShiftedBox pt={105}>
        <CourseLevelBadge />
        <CourseNameBadge />
        <CourseDescriptionBlock />
      </ShiftedBox>
      <LedByBlock />
      <ShiftedBox display="flex" flexWrap="wrap" maxWidth="700px">
        <CTABlock />
        <Box flex="1">
          <BgVideoControlButton />
        </Box>
      </ShiftedBox>
    </Box>
  </Center>
)

export const CourseIntroBlock = () => {
  const { t } = useTranslation()
  return (
    <Box
      bg="linear-gradient(99.39deg, rgba(0, 0, 0, 0.5) 23.96%, rgba(0, 0, 0, 0) 65.18%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))"
      position="relative"
      color="white">
      <BgVideo
        src="https://assets.making-space.com/courses/screenwriting101/bg-video.mp4"
        aria-label={t('Video trailer shows clips from Making Spaces’s Directing and Screenwriting courses featuring Disabled artists and creators.')}
      />
      <CourseInformationBlock />
    </Box>
  )
}
