import { NO_REDIRECT } from 'constants'

const isUserNotSignedIn = () => {
  const userSignedIn = localStorage.getItem('userSignedIn')
  return userSignedIn === 'false' || userSignedIn === null
}

const useRedirection = () => {
  const handleAuthenticatedRedirection = (isFetching, isError, location, navigate) => {
    if (!isFetching && !isError) {
      localStorage.setItem('userSignedIn', 'true')
      const redirectTo = localStorage.getItem('redirectTo')
      if (redirectTo && !NO_REDIRECT.PATHS.includes(location.pathname)) {
        localStorage.removeItem('redirectTo')
        navigate(redirectTo)
      }
    }
  }

  const handleUnauthenticatedRedirection = (location) => {
    if (
      !localStorage.getItem('redirectTo') &&
      !localStorage.getItem('redirectInitialLoadDone') &&
      isUserNotSignedIn() &&
      !NO_REDIRECT.PATHS.includes(location.pathname)
    ) {
      localStorage.setItem('redirectTo', location.pathname + location.search)
      localStorage.setItem('redirectInitialLoadDone', 'true')
    }
  }

  return { handleAuthenticatedRedirection, handleUnauthenticatedRedirection }
}

export default useRedirection
