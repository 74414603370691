import { COURSE_BUILDER } from 'constants'

// Add the additional form fields for course supplemental material
const addAdditionalMaterialFields = (formFields, type='course', amountAdded=2) => {
  if (formFields) {
    for (let index = 0; index <= amountAdded; index++) {
      formFields.fields.push(`${type}_additional_material_name_${index}`)
      formFields.fields.push(`${type}_additional_material_file_name_${index}`)
    }
  }
}

const getQuizFields = (episodeNum) => {
  const quizFields = []
  for (let quizIdx = 0; quizIdx <= 2; quizIdx++) {
    quizFields.push(`episode_${episodeNum}_quiz_question_${quizIdx}`)
    quizFields.push(`episode_${episodeNum}_quiz_question_${quizIdx}_correct_answer`)
    quizFields.push(`episode_${episodeNum}_quiz_question_${quizIdx}_incorrect_answer_1`)
    quizFields.push(`episode_${episodeNum}_quiz_question_${quizIdx}_incorrect_answer_2`)
    quizFields.push(`episode_${episodeNum}_quiz_question_${quizIdx}_incorrect_answer_3`)
  }
  return quizFields
}

// Add the additional form fields for course experts
const addCourseExpertFields = (formFields) => {
  if (formFields) {
    for (let index = 0; index <= 2; index++) {
      formFields.fields.push(`course_expert_name_${index}`)
      formFields.fields.push(`course_expert_image${fileDistinguisher}_${index}`)
      formFields.fields.push(`course_expert_image_alt_text_${index}`)
      formFields.fields.push(`course_expert_title_${index}`)
      formFields.fields.push(`course_expert_bio_${index}`)
      formFields.fields.push(`course_expert_link_${index}`)
      formFields.fields.push(`course_expert_linkedin_${index}`)
      formFields.fields.push(`course_expert_instagram_${index}`)
      formFields.fields.push(`course_expert_tiktok_${index}`)
    }
  }
}

const fileDistinguisher = '-fileFilename'

const formFieldsForBuilder = (formField) => {
  const data = [
    { id: 'courseNameThumbnail', fields: [
      'name',
      `image${fileDistinguisher}`,
      'image_alt_text',
      'opportunity_id'
    ] },
    { id: 'courseDescriptions', fields: ['summary', 'course_skills', 'description'] },
    { id: 'courseExperts', fields: [] },
    { id: 'courseVisibility', fields: ['visibility'] },
    { id: 'assignment', fields: ['assignment_description', 'accepted_assignment_types'] },
    { id: 'introVideoThumbnail', fields: ['intro_video', 'intro_thumbnail', 'intro_thumbnail_alt_text'] },
    { id: 'introAccessibilityFiles', fields: ['intro_transcript', 'intro_audio_description', 'intro_sign_language_video'] }
  ]
  for (let episodeNum = 1; episodeNum <= COURSE_BUILDER.EPISODE_LIMIT; episodeNum++) {
    data.push({
      id: `episode${episodeNum}UploadVideoThumbnail`,
      fields: [`episode_${episodeNum}_name`,
        `episode_${episodeNum}_description`,
        `episode_${episodeNum}_video`,
        `episode_${episodeNum}_thumbnail`,
        `episode_${episodeNum}_preview_alt_text`,
        `episode_${episodeNum}_medium`,
        `episode_${episodeNum}_deck`
      ]
    })
    data.push({ id: `episode${episodeNum}UploadAccessibility`, fields: [
      `episode_${episodeNum}_transcript`,
      `episode_${episodeNum}_audio_description`,
      `episode_${episodeNum}_sign_language_video`,
    ] })
    data.push({ id: `episode${episodeNum}Quiz`, fields: getQuizFields(episodeNum) })
  }

  addAdditionalMaterialFields(data.find(item => item.id === 'courseNameThumbnail'))
  addCourseExpertFields(data.find(item => item.id === 'courseExperts'))
  addAdditionalMaterialFields(data.find(item => item.id === 'assignment'), 'assignment', 4)

  // Iterate over each item in the data array
  for (const item of data) {
    // Check if the formField is in the current item's fields array
    if (item.fields.includes(formField)) {
      // If found, return the id associated with it
      return item.id
    }
  }
}

export default formFieldsForBuilder
