import { Box, Flex } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { H2 } from 'shared/typography'
import { showPlayerCoverSelector, showTranscriptColumnSelector } from '../../features/episode/selectors'
import { Player } from './Player'

const useFullScreen = () => {
  const [isFullScreen, setFullScreen] = useState(!!document.fullscreenElement)

  useEffect(() => {
    const handler = () => {
      setFullScreen(!!document.fullscreenElement)
    }
    document.addEventListener('fullscreenchange', handler)
    return () => { document.removeEventListener('fullscreenchange', handler) }
  }, [])

  return isFullScreen
}

const Transcript = () => {
  const { t } = useTranslation()
  return (
    <Box as="section"
      position="relative"
      height="100%"
    >
      <Flex
        pt="24px"
        height="100%"
        bgColor="white"
        id="transcript"
        flexDirection="column"
      >
        <Box>
          <H2
            pl="20px" pb="16px"
            fontSize="18px"
            color="#0F204B"
          >
            {t('Transcript')}
          </H2>
        </Box>
      </Flex>
      <Box
        position="absolute"
        bottom="0"
        width="calc(100% - 20px);"
        height="70px"
        zIndex="7000"
        background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 15.16%);"
        opacity="0.83"
      />
    </Box>
  )
}

const TranscriptBox = () => {
  const showCover = useSelector(showPlayerCoverSelector)
  const showTranscript = useSelector(showTranscriptColumnSelector)
  const isFullScreen = useFullScreen()
  const fullscreenProps = {
    display: ['none', null, 'block'],
    position: 'absolute',
    width: '25%',
    height: '50%',
    right: '20px',
    top: '22px'
  }
  const nonFullscreenProps = {
    minWidth: [null, '220px'],
    flex: '1 0 30%'
  }
  const styleProps = isFullScreen ? fullscreenProps : nonFullscreenProps

  return (
    <Box
      id="transcript-column"
      { ...styleProps }
      display={showTranscript && !showCover ? '' : 'none'}
    >
      <Transcript />
    </Box>
  )
}

export const PlayerWithTranscript = () => {
  const showCover = useSelector(showPlayerCoverSelector)

  return (
    <Box className="player-with-transcript">
      <Flex aria-hidden={showCover} id="player-box" visibility={showCover ? 'hidden' : 'visible'} gap="32px"
        flexWrap="wrap">
        <Box
          flex="1 0 60%"
          minWidth={[null, '420px']}
        >
          <Player />
        </Box>
        <TranscriptBox />
      </Flex>
    </Box>
  )
}
