import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex, Td } from '@chakra-ui/react'
import { DetailedProfile } from './shared'
import { Link } from 'react-router-dom'
import { DraftButton, SubTitleLabel } from 'components'

const CourseCardDesktop = ({
  id,
  name,
  status,
  poster,
  authors,
  visibility,
  talents_enrolled: enrolledCount
}) => {
  const { t } = useTranslation()
  const deterimineLink = status === 'draft' ? `/courses/${id}/edit` : `/courses/dashboard/${id}`
  return (
    <>
      <Td
        border="0"
        padding={['12px', null, '16px']}
      >
        <Flex>
          <Box marginRight="16px" flexShrink="0" flexBasis="146px">
            <Link
              to={deterimineLink}
              title={t('View course details for "{{name}}" course', { name })}
            >
              <img
                style={{
                  height: '96px',
                  objectFit: 'contain'
                }}
                src={poster}
                alt=""
              />
            </Link>
          </Box>
          <Center whiteSpace="normal">
            <Box>
              <Link to={deterimineLink}>
                <Box
                  fontSize="24px"
                  fontFamily="Poppins"
                  fontWeight="600"
                  color="#0F204A"
                >
                  {status && status !== 'live' ? t('({{status}}) {{name}}', { name, status: status.charAt(0).toUpperCase() + status.slice(1) }) : name}
                </Box>
              </Link>
              <Box
                paddingTop="16px"
                color="#070F22"
                opacity="0.8"
                fontSize="18px"
                fontFamily="Poppins"
                fontWeight="500"
              >
                <SubTitleLabel
                  authors={authors}
                  visibility={visibility}
                />
              </Box>
            </Box>
          </Center>
        </Flex>
      </Td>
      <Td
        padding="0"
        textAlign="right"
        fontSize="24px"
        fontFamily="Poppins"
        color="#0F204A"
        border="0"
      >
        {enrolledCount}
      </Td>
    </>
  )
}

const ButtonsTd = ({ children }) => (
  <Td
    textAlign="right"
    border="0"
    paddingRight="12px"
    maxWidth="484px"
  >
    {children}
  </Td>
)

export const CourseDesktop = ({
  id,
  ...restProps
}) => {
  const { t } = useTranslation()
  return (
    <>
      <CourseCardDesktop
        id={id}
        {...restProps}
      />
      <ButtonsTd>
        {restProps.status === 'draft' ? (
          <DraftButton
            id={id}
            minWidth="178px"
          >
            {t('Edit Course')}
          </DraftButton>
        ) : (
          <DetailedProfile
            id={id}
            minWidth="178px"
          >
            {t('Detailed profile')}
          </DetailedProfile>
        )}
      </ButtonsTd>
    </>
  )
}
