import React from 'react'
import { Box, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text, CloseButton } from '@chakra-ui/react'

const headerStyles = {
  fontFamily: 'Poppins',
  fontSize: '28px',
  fontWeight: '700',
  mb: '20px'
}

const textStyles = {
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontWeight: '400'
}

export const ModalBase = ({ isOpen, onClose, headerText, bodyText, subBodyText, footerButtons, showClose, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent padding="40px" position="relative">
        {showClose && (
          <CloseButton
            position="absolute"
            right="16px"
            top="16px"
            onClick={onClose}
          />
        )}
        <ModalBody>
          <Box as="h2" {...headerStyles}>{headerText}</Box>
          <Text {...textStyles}>{bodyText}</Text>
          <Text pt={4} {...textStyles} fontWeight="bold">{subBodyText}</Text>
          {children}
        </ModalBody>
        <ModalFooter pt={6}>
          {footerButtons}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
