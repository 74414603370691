import { useTranslation } from 'i18n/TranslationContext'
import { Link, useLocation } from 'react-router-dom'
import { Box, Button, IconButton } from '@chakra-ui/react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2'

const usePageAddress = (number) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  searchParams.set('page', number)

  return `${location.pathname}?${searchParams}`
}

const PageButton = ({ number, isCurrent }) => {
  const { t } = useTranslation()
  return (
    <Button
      as={Link}
      colorScheme={isCurrent ? 'zettaBlue' : 'zettaPurple'}
      to={usePageAddress(number)}
      aria-label={isCurrent ? t('Current page {{number}}', { number }) : t('Go to page {{number}}', { number })}
      aria-current={isCurrent ? 'page' : 'false'}
      fontWeight="500"
    >
      {number}
    </Button>
  )
}

const PaginationElement = ({ value, current }) => {
  if (value === 'gap') {
    return '...'
  }

  return (
    <PageButton
      number={value}
      isCurrent={value === current.toString()}
    />
  )
}

const PrevPage = (props) => (
  <Box as="li" marginRight="28px">
    <IconButton
      as={Link}
      colorScheme="zettaBlue"
      icon={<HiChevronLeft />}
      isRound
      {...props}
    />
  </Box>
)

const NextPage = (props) => (
  <Box as="li" marginLeft="28px">
    <IconButton
      as={Link}
      colorScheme="zettaBlue"
      icon={<HiChevronRight />}
      isRound
      {...props}
    />
  </Box>
)

export const PaginationButtons = ({ pagination }) => {
  const { t } = useTranslation()
  const {
    page: current,
    pages,
    prev,
    next,
    last,
    series
  } = pagination
  const prevAddress = usePageAddress(prev)
  const nextAddress = usePageAddress(next)

  if (pages < 2) { return null }

  return (
    <>
      {
        (current !== 1) && (
          <PrevPage
            to={prevAddress}
            aria-label={t('Go to previous page {{prev}}', { prev })}
          />
        )
      }
      {
        series.map((element, index) => (
          <Box as="li"
            key={`${element}#${index}`}
            margin="4px"
          >
            <PaginationElement
              value={element}
              current={current}
            />
          </Box>
        ))
      }
      {
        (current !== last) && (
          <NextPage
            to={nextAddress}
            aria-label={t('Go to next page {{next}}', { next })}
          />
        )
      }
    </>
  )
}
