import { Button } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

export const SubmitButton = ({ children, ...restProps }) => {
  const { formState: { isSubmitting } } = useFormContext()

  return (
    <Button
      {...restProps}
      isLoading={isSubmitting}
      type="submit"
    >
      {children}
    </Button>
  )
}
