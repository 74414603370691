import React from 'react'
import {
  Box,
} from '@chakra-ui/react'

import { HiUser } from 'react-icons/hi'

const HeaderAvatarPlaceholder = ({ color, bgColor, borderRadius, fontSize, className, headerType }) => {
  const defaults = {
    color: headerType === 'partner' ? '#F8F4FF' : '#5000E8',
    bgColor: headerType === 'partner' ? '#5000E8' : '#F8F4FF',
    borderRadius: '50%',
    fontSize: '42px',
  }

  return (
    <Box
      padding="2px 3px 45px 3px"
      height="48px"
      display="block"
      className={className}
      color={color || defaults.color}
      bgColor={bgColor || defaults.bgColor}
      borderRadius={borderRadius || defaults.borderRadius}
      fontSize={fontSize || defaults.fontSize}
    >
      <HiUser aria-hidden />
    </Box>
  )
}

export default HeaderAvatarPlaceholder
