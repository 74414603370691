import { useTranslation } from 'i18n/TranslationContext'
import { useSearchParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { sessionsApi, usePerformEmailConfirmationQuery } from 'features/sessionsApi'
import { useEffect } from 'react'
import { AppLoadingState } from 'AppLoadingState'

export const useSecretParam = () => useSearchParams()[0].get('secret')

export const EmailConfirmationProcess = ({ secret }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useDispatch()
  const { isSuccess } = usePerformEmailConfirmationQuery(secret)

  useEffect(() => {
    if (!isSuccess) { return }

    dispatch(sessionsApi.util.invalidateTags(['CurrentUser']))
    toast({
      title: t('Email confirmed'),
      status: 'success',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [isSuccess, dispatch, toast, t])

  return <AppLoadingState />
}
