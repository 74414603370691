import Markdown from 'react-markdown'

const LinkRenderer = props => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
)

export const MarkdownRenderer = ({ message }) => {
  return (
    <Markdown
      components={{
        a: LinkRenderer,
      }}
    >
      {message}
    </Markdown>
  )
}