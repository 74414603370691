import { Box } from '@chakra-ui/react'
import { AppLoadingState } from 'AppLoadingState'
import { ProfileCard } from 'components/partner/talent/list_page/profile_drawer/ProfileCard'
import { CustomDrawer } from 'shared/CustomDrawer'
import { useBackTo } from '../useBackTo'
import { useLoadTalentProfileFromStore } from '../useLoadTalentProfileFromStore'
import { EnrolledCourses } from 'Partner/TalentProfileView/EnrolledCourses'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListCourseForPartnerQuery } from 'features/coursesApi'

const PageContent = ({ backTo }) => {
  const { data, isFetching } = useLoadTalentProfileFromStore()
  const {
    data: { partner_profile: { id:partnerId } }
  } = useGetCurrentUserQuery()
  const { data: partnerCoursesData } = useListCourseForPartnerQuery(partnerId)

  // Intersect enrolled course Ids with courses from this partner
  const enrolledIds = data?.enrolled_courses?.map(course => course.id) || []
  const partnerCourses = partnerCoursesData?.filter(
    ({ id }) => enrolledIds.includes(id)
  ) || []

  if (isFetching) {
    return <AppLoadingState />
  }

  return (
    <>
      <Box
        paddingTop={['16px', null, '68px']}
        paddingLeft={['24px', null, '64px']}
        paddingRight={['24px', null, '64px']}
        paddingBottom="72px"
        borderBottom="1px rgba(15, 32, 74, 0.1) solid"
        className="talent-profile-page-content"
      >
        <ProfileCard
          data={data}
          backTo={backTo}
        />
      </Box>
      <Box
        paddingTop={['16px', null, '68px']}
        paddingLeft={['24px', null, '64px']}
        paddingRight={['24px', null, '64px']}
      >
        <EnrolledCourses
          courses={partnerCourses}
        />
      </Box>
    </>
  )
}

export const TalentProfileView = () => {
  const backTo = useBackTo()

  return (
    <CustomDrawer
      backTo={backTo}
      background={
        `url(${process.env.PUBLIC_URL}/contact-request-bg.svg) 0 0 / 102% auto no-repeat, linear-gradient( rgba(177, 244, 174, 0.97) 100%, rgba(177, 244, 174, 0.97) 100%), url(${process.env.PUBLIC_URL}/noise.png)`
      }
    >
      <Box as="main" role="main"
        position="relative"
      >
        <PageContent backTo={backTo} />
      </Box>
    </CustomDrawer>
  )
}
