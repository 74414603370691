import React, { useState, useEffect } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from 'usePageTitle'
import { Modal, GridItem } from '@chakra-ui/react'
import BuilderLayout from 'components/builder/BuilderLayout'
import OpportunityStepsAccordion from './OpportunityStepsAccordion'
import { FormRender } from './FormRender'
import { OpportunityBuilderProvider, useOpportunityBuilderContext } from './OpportunityBuilderContext'
import { usePublishOpportunityFromDraftMutation } from 'features/opportunitiesApi'
import DuoButtonModalLayout from 'components/modals/DuoButtonModalLayout'
import { useDisclosure } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IGNORE_NAV_BLOCKER } from 'constants'
import { store } from 'store'
import { useSelector } from 'react-redux'

const getPageTitle = (t) => {
  return window.location.pathname.includes('/new') ?
    t('Build Opportunity') :
    t('Edit Opportunity')
}

const saveAndPublishOpportunity = async (navigate, saveBuilderData, publishOpportunity) => {
  const continueProcess = await saveBuilderData()
  const id = store.getState().opportunityBuilder.id
  if (continueProcess) {
    await publishOpportunity({ id })
    navigate('/opportunities', IGNORE_NAV_BLOCKER)
  }
}

const saveOpportunity = async (navigate, saveBuilderData) => {
  const continueProcess = await saveBuilderData()

  if (continueProcess) {
    navigate('/opportunities', IGNORE_NAV_BLOCKER)
  }
}

const SaveButtonModal = ({ isOpen, setModalOpen }) => {
  const { t } = useTranslation()
  const { onClose } = useDisclosure()
  const navigate = useNavigate()
  const { saveBuilderData } = useOpportunityBuilderContext()
  const [publishOpportunity] = usePublishOpportunityFromDraftMutation()

  const handleCancel = () => {
    setModalOpen(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} size="3xl" isCentered closeOnOverlayClick className="confirm-exit-modal">
      <DuoButtonModalLayout
        primaryButtonAction={() => saveAndPublishOpportunity(navigate, saveBuilderData, publishOpportunity)}
        secondaryButtonAction={() => saveOpportunity(navigate, saveBuilderData)}
        primaryButtonText={t('No, publish instead.')}
        secondaryButtonText={t('Yes, save and exit.')}
        headerText={t('Are you sure?')}
      >
        {t('You made some changes to this Opportunity, but they won’t be published. Do you want to continue?')}
      </DuoButtonModalLayout>
    </Modal>
  )
}

const OpportunityBuilderContent = () => {
  const { t } = useTranslation()
  const pageTitle = getPageTitle(t)
  usePageTitle(pageTitle)
  const navigate = useNavigate()
  const { onOpen } = useDisclosure()
  const [isModalOpen, setModalOpen] = useState(false)
  const { saveBuilderData, setStepId } = useOpportunityBuilderContext()
  const opportunityData = useSelector(state => state.opportunityBuilder || {})

  useEffect(() => {
    // Disable the editable pages if the status is closed
    if (opportunityData?.status === 'closed') {
      setStepId('reviewSubmit')
    }
  }, [opportunityData?.status, setStepId])

  const onSaveAction = () => {
    if (opportunityData?.date_opened) {
      // Only open the modal if the opportunity has already been opened
      setModalOpen(true)
      onOpen()
    } else {
      saveOpportunity(navigate, saveBuilderData)
    }
  }

  return (
    <BuilderLayout
      pageTitle={pageTitle}
      description={`
        ${t('Showcase a career opportunity for top talent to review, express interest, and apply for.')} 
        ${t('Highlight the key role details to attract potential candidates.')}
      `}
      backLinkText={t('Back to Opportunities')}
      backLinkHref="/opportunities"
      onSave={opportunityData?.status !== 'closed' && onSaveAction}
    >
      <SaveButtonModal isOpen={isModalOpen} setModalOpen={setModalOpen} />
      <OpportunityStepsAccordion />
      <GridItem colSpan={4}>
        <FormRender />
      </GridItem>
    </BuilderLayout>
  )
}

export const OpportunityBuilder = () => (
  <OpportunityBuilderProvider>
    <OpportunityBuilderContent />
  </OpportunityBuilderProvider>
)
