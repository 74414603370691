import { REGEX_PATTERNS } from 'constants'
import { InputField, AddSubtractButton } from 'components'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { removeForm } from 'utils/forms'

const SupplementalMaterial = ({ index, supplementalMaterialProp: { assetType, assetCount, setAssetCount, removeTextValue, disabled }, unRegister, errorVal }) => {
  const { t } = useTranslation()
  const { register } = useFormContext() // retrieve all hook methods

  const supplementalMaterialFieldNames = {
    asset_name: `${assetType}_additional_material_name_${index}`,
    file_name: `${assetType}_additional_material_file_name_${index}`,
  }

  return (
    <>
      <Box id={`supplemental-material-${index}`}>
        {assetCount.length > 1 && !disabled && ( // Conditionally render the button if courseMaterialCount is greater than 1
          <AddSubtractButton
            determineType="minus"
            textValue={removeTextValue}
            onClickAction={() => removeForm(index, assetCount, setAssetCount, unRegister, Object.values(supplementalMaterialFieldNames))}
            currentCount={assetCount}
            totalCount={2}
          />
        )}
        <InputField
          label={t('Asset Name')}
          name={supplementalMaterialFieldNames.asset_name}
          errorVal={errorVal}
          width="60%"
          inputProps={{
            disabled: disabled,
            placeholder: t('Cloud Diagram'),
            ...register(
              supplementalMaterialFieldNames.asset_name
            )
          }}
        />
        <InputField
          label={t('Asset Link')}
          errorVal={errorVal}
          name={supplementalMaterialFieldNames.file_name}
          width="60%"
          inputProps={{
            disabled: disabled,
            placeholder: 'http://cloud.diagram.com',
            ...register(
              supplementalMaterialFieldNames.file_name, {
                pattern: {
                  value: REGEX_PATTERNS.URL,
                  message: t('Invalid URL')
                }
              })
          }}
        />
      </Box>
    </>
  )
}

export default SupplementalMaterial
