
import { useListCourseForPartnerQuery } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListOpportunitiesForPartnerQuery } from 'features/opportunitiesApi'
import { useSelector } from 'react-redux'

export const useGetCourseOptions = () => {
  const { data: userData } = useGetCurrentUserQuery()
  const partnerId = userData?.partner_profile?.id
  const currentOpportunityId = useSelector(state => state.opportunityBuilder?.id)

  const { data: courses, isLoading: coursesLoading } = useListCourseForPartnerQuery(partnerId, {
    skip: !partnerId
  })

  if (coursesLoading) {
    return []
  }
  // V1: Remove a course if it already has an opportunity
  return courses
    ?.filter(course => !course.opportunity || course.opportunity.id === currentOpportunityId)
    ?.map(course => ({
      value: course.id,
      label: course.name,
      status: course.status,
      createdAt: course.created_at,
    })) || []
}

export const useGetOpportunityOptions = () => {
  const { data: userData } = useGetCurrentUserQuery()
  const partnerId = userData?.partner_profile?.id
  const currentCourseId = useSelector(state => state.courseBuilder?.id)

  const { data: opportunities, isLoading: opportunitiesLoading } = useListOpportunitiesForPartnerQuery(partnerId, {
    skip: !partnerId
  })

  if (opportunitiesLoading) {
    return []
  }
  const combinedOpportunities = []
  combinedOpportunities.push(...opportunities.inactive)
  combinedOpportunities.push(...opportunities.active)

  return combinedOpportunities
    ?.filter(opportunity => (!opportunity.course_id && opportunity.status !== 'closed') || currentCourseId === opportunity.course_id)
    ?.map(opportunity => ({
      value: opportunity.id,
      label: opportunity.name,
      createdAt: opportunity.created_at,
      status: opportunity.status,
    }))
}
