import { Navigate, Route, Routes } from 'react-router-dom'
import { SignInPage } from './SignInPage'
import { SignUpPage } from './SignUpPage'
import { useEffect } from 'react'
import { ForgotPasswordPage } from './ForgotPasswordPage'
import { PageLayout } from './PageLayout'
import { PageHeader } from './PageHeader'
import { SignUpPublicPage } from './SignUpPublicPage'
import { PasswordResetPage } from './ForgotPasswordPage/PasswordResetPage'
import { NewPasswordPage } from './ForgotPasswordPage/NewPasswordPage'
import { EmailConfirmationPage } from './EmailConfirmationPage'
import { talentProfilesApi } from 'features/talentProfilesApi'
import { apiSlice } from 'features/apiSlice'
import { useDispatch } from 'react-redux'
import { useAutoUpdateLanguage } from 'hooks/useAutoUpdateLanguage'

const AppLayout = ({ children }) => (
  <>
    <PageHeader />
    <PageLayout>
      {children}
    </PageLayout>
  </>
)

const useDropUserApiCaches = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      talentProfilesApi.util.invalidateTags(['TalentList', 'CurrentTalentProfile'])
    )
    dispatch(
      apiSlice.util.invalidateTags(['CurrentAssignment'])
    )
    dispatch(
      apiSlice.util.invalidateTags(['CourseList', 'CurrentCourse'])
    )
    dispatch(
      apiSlice.util.invalidateTags(['ContactRequest'])
    )
    dispatch(
      apiSlice.util.invalidateTags(['Comments'])
    )
  }, [dispatch])
}

export const UnauthenticatedApp = () => {
  useDropUserApiCaches()

  useEffect(() => {
    document.querySelector('body').id = 'sign-in-page'
    return () => {
      document.querySelector('body').removeAttribute('id')
    }
  }, [])

  // Using feature flags, determine whether to force the language to be English or allow auto language detection
  useAutoUpdateLanguage()

  return (
    <AppLayout>
      <Routes>
        <Route
          path="/email_confirmation"
          element={<EmailConfirmationPage />}
        />
        <Route
          path="/sign_in"
          element={<SignInPage />}
        />
        <Route
          path="/sign_up"
          element={<SignUpPublicPage />}
        />
        <Route
          path="/sign_up/:invitationToken"
          element={<SignUpPage />}
        />
        <Route
          path="/forgot_password"
          element={<ForgotPasswordPage />}
        />
        <Route
          path="/forgot_password/reset"
          element={<PasswordResetPage />}
        />
        <Route
          path="/forgot_password/new_password"
          element={<NewPasswordPage />}
        />
        <Route path="*" element={ <Navigate to="/sign_in" replace /> } />
      </Routes>
    </AppLayout>
  )
}
