import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex, FormControl, FormLabel, Input, VisuallyHidden, IconButton, Spinner } from '@chakra-ui/react'
import { HiDocumentText, HiOutlineCloudArrowUp, HiTrash } from 'react-icons/hi2'
import { forwardRef, useEffect, useRef, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { mediaValidationResult } from 'utils/media'

const InvisibleInput = forwardRef(function InvisibleInput({ name, labelText, mediaType, fieldRequired, handleFileChange, disabled }, forwardedRef) {
  const {
    watch,
    register,
    trigger,
    clearErrors,
    getValues
  } = useFormContext()
  const { t } = useTranslation()
  const persistedFilename = getValues(`${name}Filename`)
  const { ref, ...rest } = register(name, {
    ...(fieldRequired && !persistedFilename && { required: t('Cannot be empty.') }),
    validate: async (value) => {
      const validationResult = await mediaValidationResult(value?.[0], mediaType, name, clearErrors)
      if (handleFileChange && validationResult === true && value?.[0]) {
        handleFileChange(value[0]) // Call handleFileChange when the validation passes
      }
      return (validationResult === true) || validationResult // Return true for valid or the error message for invalid
    }
  })

  const fieldValue = watch(name)

  useEffect(() => {
    if (!fieldValue) { return }

    forwardedRef.current.files = fieldValue
    trigger(name)
  }, [fieldValue, trigger, name, forwardedRef])

  return (
    <VisuallyHidden>
      <Input
        ref={(e) => {
          ref(e)
          forwardedRef.current = e
        }}
        aria-labelledby={`${name}-file-input-label`}
        type="file"
        accept={mediaType?.fileType?.accept}
        { ...rest }
        disabled={disabled}
      />
      <Box
        aria-hidden
        id={`${name}-file-input-label`}
      >
        {labelText}
      </Box>
    </VisuallyHidden>
  )
})

const UploadWrapperBase = ({ name, mediaType, handleFileChange, disabled, ...props }) => {
  const { setValue, trigger, setError, clearErrors } = useFormContext()

  const onDrop = async (event) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    const file = files[0]

    const validationResult = await mediaValidationResult(file, mediaType, name, clearErrors)
    if (validationResult === true) {
      setValue(name, files)
      trigger(name)
      handleFileChange && file && handleFileChange(file) // Call handleFileChange when the validation passes
    } else {
      setError(name, {
        type: 'manual',
        message: validationResult,
      })
    }
  }

  return (
    <Center
      width="100%"
      height={['250px', null, '184px']}
      bgColor="#F8F4FF"
      color="#0F204A"
      borderRadius="4px"
      fontWeight="normal"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onDrop={onDrop}
      onDragOver={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const useFieldError = (name) => {
  const {
    formState: {
      errors: { [name]: error }
    }
  } = useFormContext()
  return error
}

const ReuploadFile = ({ name, label, handleFileChange, disabled }) => (
  <UploadWrapperBase name={name} handleFileChange={handleFileChange} disabled={disabled}>
    <Center>
      <Box fontFamily="Poppins" textAlign="center" aria-hidden>
        <Center fontSize="24px" mb="16px">
          <HiOutlineCloudArrowUp />
        </Center>
        <Box fontWeight="500">
          {label}
        </Box>
      </Box>
    </Center>
  </UploadWrapperBase>
)

const useFieldValuePresent = (name) => {
  const { watch, getValues } = useFormContext()
  const value = watch(name)
  const persistedFilename = getValues(`${name}Filename`)

  return (value && value.length > 0) || !!persistedFilename
}

const useFieldFileName = (name) => {
  const { watch, getValues } = useFormContext()
  const value = watch(name)
  const persistedFilename = getValues(`${name}Filename`)

  return (value && value[0] && value[0].name) || persistedFilename
}

const InputContents = ({ name, emptyStateLabel, replaceLabel, mediaType, handleFileChange, disabled }) => {
  const valuePresent = useFieldValuePresent(name)
  const fieldError = useFieldError(name)
  const { t } = useTranslation()

  if (valuePresent) {
    return (
      <ReuploadFile
        name={name}
        label={replaceLabel}
        handleFileChange={handleFileChange}
        disabled={disabled}
      />
    )
  }

  return (
    <UploadWrapperBase name={name} mediaType={mediaType} handleFileChange={handleFileChange} disabled={disabled}>
      <Center>
        <Box fontFamily="Poppins" textAlign="center" aria-hidden>
          <Center fontSize="24px" mb="16px">
            <HiOutlineCloudArrowUp />
          </Center>
          <Box mb="4px" fontWeight="500">
            {emptyStateLabel}
          </Box>
          <Box>
            {!disabled && t('Click here or drag files into this box to upload them.')}
          </Box>
          {fieldError && (
            <Box mt="4px" color="#CD0050">
              {fieldError.message}
            </Box>
          )}
        </Box>
      </Center>
    </UploadWrapperBase>
  )
}

const CurrentFileView = ({ name, onRemove, mediaUploaded }) => {
  const { t } = useTranslation()
  const fieldError = useFieldError(name)
  const filename = useFieldFileName(name)

  return (
    <>
      <Center
        position="relative"
        flexGrow="1"
        flexBasis="0"
        fontFamily="Poppins"
        borderRadius="4px"
        background={
          fieldError ?
            '#F8F4FF' :
            'var(--design-surface-light, #FFED9B)'
        }
        border={fieldError && '2px solid var(--design-decorative-highlight, #CD0050)'}
      >
        <Center
          flexDirection="column"
          textAlign="center"
          height={['250px', null, '184px']}
        >
          <Center fontSize="24px" mb="16px" color="#5000E8">
            <HiDocumentText />
          </Center>
          <Box mb="4px">
            {filename}
          </Box>
          {fieldError && (
            <Box color="#CD0050">
              <Box>
                {fieldError.message}
              </Box>
              <Box>
                {t('Try uploading another file.')}
              </Box>
            </Box>
          )}
        </Center>
        <Box position="absolute" top="16px" right="16px" zIndex="105">
          {mediaUploaded ? (
            <IconButton
              title={t('Delete file')}
              colorScheme="zettaWhite"
              color="#CD0050"
              fontSize="24px"
              borderRadius="2px"
              icon={<HiTrash />}
              onClick={onRemove}
            />
          ) : (
            <Box background="white" opacity=".6" color="red.500" padding="6px">
              <Spinner title="File is Uploading" size="sm" mr="6px" />
              <Box as="p" fontSize="12px" display="inline" fontFamily="Spline Sans Mono" fontWeight="600">
                {t('Uploading...')}
              </Box>
            </Box>
          )}
        </Box>
      </Center>
      <Box
        flexBasis={['16px', null, '26px']}
      />
    </>
  )
}

const MainLabelWrapper = ({ name, children }) => {
  const valuePresent = useFieldValuePresent(name)
  const fieldError = useFieldError(name)

  return (
    <FormLabel
      margin="0"
      border={
        valuePresent ?
          '2px dashed var(--design-content, #0F204B)' :
          (fieldError ?
            '2px solid var(--design-decorative-highlight, #CD0050)' :
            '2px solid #0F204A'
          )
      }
      borderRadius="4px"
      flexGrow="1"
      flexBasis="0"
      _focusWithin={{
        borderStyle: 'solid',
        borderColor: '#3182ce',
        boxShadow: '0 0 0 1px #3182ce'
      }}
    >
      {children}
    </FormLabel>
  )
}

export const UploadDragBoxInput = ({
  name,
  emptyStateLabel,
  replaceLabel,
  mediaType=null,
  fieldRequired=false,
  handleFileChange = () => {},
  disabled=false,
  mediaUploaded=true
}) => {
  const valuePresent = useFieldValuePresent(name)
  const fieldError = useFieldError(name)
  const inputRef = useRef()
  const { setValue, watch, trigger } = useFormContext()
  const file = watch(name)
  const filename = useFieldFileName(name)

  useEffect(() => {
    if (file && file[0]) {
      trigger(name)
      setValue(`delete${name}`, null, { shouldDirty: true })
    }
  }, [file, setValue, trigger, name])

  const onRemove = useCallback(() => {
    setValue(`delete${name}`, true, { shouldDirty: true })
    setValue(name, null, { shouldDirty: true })
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    handleFileChange('clear')
  }, [setValue, name, handleFileChange])

  if (disabled && valuePresent) {
    return (
      <FormControl>
        <Flex>
          <Box
            flex="0.65"
            borderRadius="4px"
            background="var(--design-decorative-surface-light, #CDF3FF)"
          >
            <Center
              flexDirection="column"
              textAlign="center"
              height={['250px', null, '184px']}
            >
              <Center fontSize="24px" mb="16px" color="#5000E8">
                <HiDocumentText />
              </Center>
              <Box mb="4px">
                {filename}
              </Box>
            </Center>
          </Box>
        </Flex>
      </FormControl>
    )
  }

  return (
    <FormControl isInvalid={!!fieldError}>
      <Flex
        flexDirection={[
          'column',
          null,
          'row'
        ]}
      >
        {
          valuePresent && <CurrentFileView name={name} onRemove={onRemove} mediaUploaded={mediaUploaded} />
        }
        {mediaUploaded && (
          <MainLabelWrapper name={name}>
            <InputContents
              name={name}
              emptyStateLabel={emptyStateLabel}
              replaceLabel={replaceLabel}
              mediaType={mediaType}
              handleFileChange={handleFileChange}
              disabled={disabled}
            />
            <InvisibleInput
              ref={inputRef}
              name={name}
              mediaType={mediaType}
              labelText={
                valuePresent ? replaceLabel : emptyStateLabel
              }
              fieldRequired={fieldRequired}
              handleFileChange={handleFileChange}
              disabled={disabled}
            />
          </MainLabelWrapper>
        )}
      </Flex>
    </FormControl>
  )
}

export default UploadDragBoxInput
