import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useUpsertOpportunityMutation } from 'features/opportunitiesApi'
import { addFormField } from 'features/opportunities/opportunityBuilderSlice' // Import the action creator
import { useOpportunityBuilderContext } from 'OpportunityBuilder/OpportunityBuilderContext'

const setFormDataIds = (data, dispatch) => {
  // Set Opportunity ID
  dispatch(addFormField({ key: 'id', value: data.id }))
}

const combineIntoFormData = (obj, fileFormData) => {
  const formData = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(`opportunity[${key}]`, value)
  })
  fileFormData.forEach((value, key) => {
    formData.append(`opportunity[${key}]`, value)
  })
  return formData
}

export const sendFormData = async (
  opportunityData,
  userId,
  upsertOpportunity,
  dispatch,
  fileFormData,
  clearFileFormData,
  skipSettingIds = false
) => {
  try {
    if (opportunityData.name && userId) {
      const { errors, disabledSteps, location, date_opened, 'image-fileFilename': imageFileFilename, ...restOpportunityData } = opportunityData
      const formDataObject = combineIntoFormData(restOpportunityData, fileFormData)
      formDataObject.append('user_id', userId)

      clearFileFormData()
      const { data } = await upsertOpportunity({
        opportunityData: formDataObject, id: restOpportunityData.id
      })
      !skipSettingIds && setFormDataIds(data, dispatch)
      console.log('Opportunity successfully returned:', data)

    }
  } catch (error) {
    console.error('Error creating opportunity:', error)
    clearFileFormData()
  }
}


const useSendFormData = async (opportunityData, initialized) => {
  const dispatch = useDispatch()
  const [upsertOpportunity] = useUpsertOpportunityMutation()
  const { data: { id: userId } } = useGetCurrentUserQuery()
  const { clearFileFormData, fileFormData } = useOpportunityBuilderContext()

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      sendFormData(opportunityData, userId, upsertOpportunity, dispatch, fileFormData, clearFileFormData)
    }
  }, [opportunityData, upsertOpportunity, dispatch, initialized, userId, fileFormData, clearFileFormData])
}


export default useSendFormData
