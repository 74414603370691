import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Center, Flex } from '@chakra-ui/react'
import { CourseImageSet } from './CourseImageSet'
import { Link } from 'react-router-dom'
import { useCoursePageContext } from './CoursePageContext'
import { WatchingButton } from './WatchingButton'
import { parseWithoutStyles } from 'utils/string'
import parse from 'html-react-parser'

const styles = {
  alternative: {
    header:{
      fontSize: '40px'
    },
    body: {
      fontSize: '22px'
    }
  },
  undefined: {
    header: {
      fontSize: '22px',
      letterSpacing: '3px'
    },
    body: {
      fontSize: ['22px', null, null, '28px']
    }
  }
}

export const BlockWrapper = ({ children }) => (
  <Box as="section" color="#0F204A" className="about-block">
    <Center
      pt={['42px', null, null, '96px']}
      pb={[ '54px', null, null, '100px']}
    >
      <Box
        flexGrow="1"
        ml={['0', '32px']}
        mr={['0', '20px']}
        pl={[ '24px', null, '84px']}
        pr={[ '24px', null, '34px']}
        pb="34px"
        position="relative"
        maxWidth="1180px"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
        >
          <img src={`${process.env.PUBLIC_URL}/corner-left.svg`} alt="" />
        </Box>
        <Box
          position="absolute"
          bottom="0"
          right="0"
        >
          <img src={`${process.env.PUBLIC_URL}/corner-right.svg`} alt="" />
        </Box>

        {children}
      </Box>
    </Center>
  </Box>
)

const OpportunityButton = () => {
  const { t } = useTranslation()
  const {
    data: {
      opportunity_id: opportunityId,
      'access?': hasAccess,
      'applied?': hasApplied
    }
  } = useCoursePageContext().courseContentData

  if (!opportunityId) { return null }
  if (hasAccess) { return null }
  if (hasApplied) { return null }

  return (
    <Button
      as={Link}
      to={`/opportunities/${opportunityId}`}
      size="lg"
      colorScheme="zettaBlue"
      fontWeight="500"
    >
      {t('View opportunity details')}
    </Button>
  )
}

const WatchingButtonWrapped = () => {
  const {
    data: {
      'access?': hasAccess
    }
  } = useCoursePageContext().courseContentData

  if (!hasAccess) { return null }

  return (
    <WatchingButton
      colorScheme="zettaBlue"
    />
  )
}

const ButtonsBlock = () => (
  <Box paddingTop="24px">
    <OpportunityButton />
    <WatchingButtonWrapped />
  </Box>
)

export const AboutBlock = ({
  specification: {
    title,
    description,
    photo,
    photoAlt,
    sizeSet
  }
}) => (
  <BlockWrapper>
    <Flex
      flexDirection={['column', null, 'row']}
    >
      <Box>
        <Box
          as="h3"
          pt="98px" pb="24px"
          fontSize="22px"
          fontWeight="bold"
          fontFamily="Spline Sans Mono"
          textTransform="uppercase"
          textAlign={['center', null, null, !photo ? 'center' : 'left']}
          { ...styles[sizeSet].header }
          className="about-block__title"
        >
          {title}
        </Box>
        <Box
          textAlign={['center', null, null, !photo ? 'center' : 'left']}
          fontSize={['22px', null, null, '28px']}
          fontWeight="400"
          fontFamily="Poppins"
          className="about-block__description"
          listStylePosition="inside"
          { ...styles[sizeSet].body }
        >
          {parse(description || '', parseWithoutStyles)}
        </Box>
        {!photo ? (
          <Center>
            <ButtonsBlock />
          </Center>
        ) : (
          <ButtonsBlock />
        )}
      </Box>
      <Box flexBasis="60px" flexShrink="0" />
      { photo && (
        <Box pt={[null, null, '98px']} flexShrink="0">
          <CourseImageSet photoSet={photo} alt={photoAlt} />
        </Box>
      )}
    </Flex>
  </BlockWrapper>
)
