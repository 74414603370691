import { Box, Center } from '@chakra-ui/react'

export const PageOuterContainer = ({ children, ...props }) => (
  <Center
    bg={`url(${process.env.PUBLIC_URL}/partner-bg.svg)`}
    bgRepeat="no-repeat"
    backgroundPosition="top"
    className="page-outer-container"
  >
    <Box
      flexGrow="1"
      as="main" role="main"
      maxWidth="1440px"
      paddingTop="32px"
      paddingLeft={['8px', null, '36px']}
      paddingRight={['8px', null, '36px']}
      {...props}
    >
      {children}
    </Box>
  </Center>
)
