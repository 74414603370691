/* Checking the length of a video file */
export const checkVideoDuration = (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const videoElement = document.createElement('video')
      videoElement.src = URL.createObjectURL(file)

      videoElement.addEventListener('loadedmetadata', function() {
        // Access the video duration
        const videoDuration = videoElement.duration
        // Revoke the object URL to free up memory
        URL.revokeObjectURL(videoElement.src)
        // Resolve the promise with the video duration
        resolve(videoDuration)
      })
    } else {
      reject(new Error('No file provided'))
    }
  })
}

/* Handling media file validation for use with useFormContext validate function */
export const mediaValidationResult = (file, mediaType, name, clearErrors) => {
  return new Promise((resolve, reject) => {
    // Clear the errors for the field
    clearErrors(name)

    // Handle size limit synchronously
    if (file && mediaType?.sizeLimit && file.size > mediaType.sizeLimit.size) {
      return resolve(mediaType.sizeLimit.errorMessage)
    }

    // Check the file type against mediaType?.fileAccept
    const validFileType = mediaType?.fileType?.accept
    if (file && !validFileType?.includes(file.type)) {
      return resolve(mediaType.fileType.errorMessage)
    }

    // Handle length limit asynchronously
    if (file && mediaType?.lengthLimit) {
      checkVideoDuration(file).then(duration => {
        if (duration > mediaType.lengthLimit.size) {
          console.log('Duration exceeds limit')
          resolve(mediaType.lengthLimit.errorMessage)
        } else {
          resolve(true) // Duration is within limit
        }
      }).catch(error => {
        console.error(`Failed to check video duration: ${error.message}`)
        resolve(mediaType.lengthLimit.errorMessage) // Resolve with error message on failure
      })
    } else {
      resolve(true) // If no length limit is set or no file is provided, resolve as true
    }
  })
}

