import { Hide, Show } from '@chakra-ui/react'
import { TableItemDesktop } from './TableItemDesktop'
import { TableItemMobile } from './TableItemMobile'

export const TableItem = (props) => (
  <>
    <Hide above="768px">
      <TableItemMobile
        {...props}
      />
    </Hide>
    <Show above="md">
      <TableItemDesktop
        {...props}
      />
    </Show>
  </>
)

