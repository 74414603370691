import {
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  ModalCloseButton
} from '@chakra-ui/react'

const DuoButtonModalLayout = ({
  primaryButtonAction,
  secondaryButtonAction,
  primaryButtonText,
  secondaryButtonText,
  headerText,
  children
}) => {

  const headerStyles = {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '700',
  }

  const textStyles = {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '400'
  }

  return (
    <>
      <ModalOverlay />
      <ModalContent padding="40px">
        <ModalCloseButton />
        <ModalHeader {...headerStyles}>{headerText}</ModalHeader>
        <ModalBody>
          <Text {...textStyles}>{children}</Text>
        </ModalBody>
        <ModalFooter pt={6}>
          <Button
            size="lg"
            fontSize="18px"
            variant="outline"
            colorScheme="zettaBlue"
            _hover={{
              bgColor: 'rgba(128, 90, 213, 0.1)'
            }}
            onClick={secondaryButtonAction} mr={5}
          >
            {secondaryButtonText}
          </Button>
          <Button size="lg" fontSize="18px" colorScheme="zettaBlue" onClick={primaryButtonAction}>
            {primaryButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  )
}

export default DuoButtonModalLayout
