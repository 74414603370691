import React from 'react'
import { FormControl, Textarea } from '@chakra-ui/react'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { useFormContext } from 'react-hook-form'
import { formStyles } from '../formStyles'

const TextField = ({ textFieldProps, name, inputProps, errorVal={} }) => {
  const { formState: { errors } } = useFormContext()
  const { sharedStyles, textFieldStyles, disabledStyleProp } = formStyles()
  let courseBuilder = true

  if(Object.keys(errorVal).length === 0) {
    errorVal = errors
    courseBuilder = false
  }

  let adjustedInputProps = inputProps
  if (inputProps?.disabled) {
    // Remove placeholder when disabled
    const { placeholder, ...otherProps } = inputProps
    // Apply disabled styles
    adjustedInputProps = {
      ...otherProps,
      style: { ...otherProps.style, ...disabledStyleProp }
    }
  }

  return (
    <FormControl pb="40px" isInvalid={!!errorVal[name]} isRequired={textFieldProps.fieldRequired}>
      <FieldHeader courseBuilder={courseBuilder} errorVal={errorVal} helperText={textFieldProps.helperText} label={textFieldProps.label} />
      <Textarea
        width={textFieldProps.width || '100%'}
        {...adjustedInputProps}
        isInvalid={!!errorVal[name]}
        {...sharedStyles}
        {...textFieldStyles}
      />
      <FormErrorDisplay courseBuilder={courseBuilder} name={name} errorVal={errorVal} textEditor={textFieldProps.textEditor} />
    </FormControl>
  )
}

export default TextField
