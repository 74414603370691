export const commonFormReducers = {
  addFormField: (state, action) => {
    const { key, value } = action.payload
    state[key] = value
  },
  removeMultipleFormFields: (state, action) => {
    const { key } = action.payload
    state[key] = []
  },
  addMultipleFormFields: (state, action) => {
    const { key, value } = action.payload
    if (!state[key].includes(value)) {
      state[key].push(value)
    }
  },
  removeValueFromArray: (state, action) => {
    const { key, value } = action.payload
    const index = state[key]?.indexOf(value)
    if (index >= 0) {
      state[key].splice(index, 1)
    }
  },
  addFormError: (state, action) => {
    // Add a new key-value pair to the errors object
    state.errors = { ...state.errors, ...action.payload }
  },
  removeFormError(state, action) {
    const { key } = action.payload
    delete state.errors[key]
  },
}

export const createResetReducer = (initialState) => (state) => {
  Object.keys(initialState).forEach(key => {
    state[key] = initialState[key]
  })
}

// Export individual reducers
export const {
  addFormField,
  removeMultipleFormFields,
  addMultipleFormFields,
  removeValueFromArray,
  addFormError,
  removeFormError,
} = commonFormReducers