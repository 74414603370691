import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  VisuallyHidden
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { HiTrash } from 'react-icons/hi2'

const maxFileSize = 10485760

const fileValidation = (value, t) => {
  if (!value) { return true }

  const { 0: photo } = value

  if (!photo) { return true }

  const { type, size } = photo

  if (size > maxFileSize) {
    return t('The file is too big')
  }

  switch (type) {
  case 'image/jpeg':
  case 'image/png':
    return true
  default:
    return t('File format is unsupported')
  }
}

const InputButton = ({ children }) => (
  <Button
    as="div"
    colorScheme="zettaWhite"
    fontWeight="500"
    aria-hidden
  >
    {children}
  </Button>
)

const PhotoContainer = ({ children, onRemove, ...props }) => {
  const {
    formState: {
      errors: { photo: photoError }
    }
  } = useFormContext()

  const isError = !!photoError

  return (
    <Box position="relative">
      <FormLabel margin="0">
        <Center
          borderRadius="6px"
          border={
            isError ? '2px solid #CD0050' :
              '2px solid #0F204A'
          }
          style={{ aspectRatio: '260 / 305' }}
          minHeight={['305px', null, '390px']}
          width={[null, null, '100%']}
          cursor="pointer"
          flexDirection="column"
          padding="24px"
          _focusWithin={{
            borderColor: '#3182ce',
            boxShadow: '0 0 0 1px #3182ce'
          }}
          {...props}
        >
          {children}
        </Center>
      </FormLabel>
      <RemoveButton onClick={onRemove} />
    </Box>
  )
}

const DetailsBox = (props) => (
  <Box
    marginTop="auto"
    fontSize="14px"
    fontWeight="500"
    fontFamily="Spline Sans Mono"
    textAlign="center"
    {...props}
  />
)

const SupportedFormats = () => {
  const { t } = useTranslation()
  return (
    <DetailsBox>
      <p>{t('Supported formats: PNG, JPG, JPEG')}</p>
      <p>{t('up to 10mb')}</p>
    </DetailsBox>
  )
}

const Details = () => {
  const {
    formState: {
      errors: { photo: photoErrors }
    }
  } = useFormContext()

  if (photoErrors) {
    const { message: photoError } = photoErrors
    return (
      <DetailsBox color="#CD0050">
        <FormErrorMessage>
          {photoError}
        </FormErrorMessage>
      </DetailsBox>
    )
  }

  return <SupportedFormats />
}

const RemoveButton = ({ onClick: onClickExternal }) => {
  const { t } = useTranslation()
  const { watch, getValues } = useFormContext()
  const photoUrl = getValues('photoUrl')
  const photo = watch('photo')
  const deletePhoto = watch('deletePhoto')
  const { setValue } = useFormContext()

  const onClick = () => {
    setValue('deletePhoto', true, { shouldDirty: true })
    setValue('photo', null, { shouldDirty: true })
    onClickExternal()
  }

  if (deletePhoto) {
    return null
  }

  if (!photoUrl && !photo) {
    return null
  }

  return (
    <Box
      position="absolute"
      top="8px"
      right="8px"
      zIndex="105"
    >
      <IconButton
        title={t('Delete photo')}
        colorScheme="zettaWhite"
        color="#CD0050"
        fontSize="24px"
        borderRadius="2px"
        icon={<HiTrash />}
        onClick={onClick}
      />
    </Box>
  )
}

const EmptyState = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box marginTop="auto">
        <InputButton>
          {t('Upload photo')}
        </InputButton>
      </Box>
      <Details />
    </>
  )
}

const FilledState = () => {
  const { t } = useTranslation()
  return (
    <Box
      margin="auto 0"
      width="100%"
      height="100%"
      textAlign="right"
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
    >
      <InputButton>
        {t('Edit photo')}
      </InputButton>
    </Box>
  )
}

const usePhotoSelected = (photoPreview) => {
  const {
    formState: {
      errors: { photo: photoError }
    },
    watch,
    getValues,
  } = useFormContext()

  const deletePhoto = watch('deletePhoto')
  const photoUrl = getValues('photoUrl')

  return !(deletePhoto || photoError || (!photoUrl && !photoPreview))
}

const WrapperState = ({ photoPreview }) => {
  const isSelected = usePhotoSelected(photoPreview)

  if (isSelected) { return <FilledState /> }

  return <EmptyState />
}

const InvisibleInput = forwardRef(function InvisibleInput({ labelText }, forwardedRef) {
  const { t } = useTranslation()
  const { register } = useFormContext()
  const { ref, ...rest } = register('photo', { validate: fileValidation(t) })

  return (
    <VisuallyHidden>
      <Input
        ref={(e) => {
          ref(e)
          forwardedRef.current = e
        }}
        aria-labelledby="photo-file-input-label"
        type="file"
        accept="image/png, image/jpeg"
        { ...rest }
      />
      <Box
        aria-hidden
        id="photo-file-input-label"
      >
        {labelText}
      </Box>
    </VisuallyHidden>
  )
})

export const PhotoInput = () => {
  const { t } = useTranslation()
  const inputRef = useRef()
  const {
    formState: {
      errors: { photo: photoError }
    },
    watch,
    trigger,
    setValue,
    getValues
  } = useFormContext()
  const [photoPreview, setPhotoPreview] = useState(null)
  const photo = watch('photo')
  const photoUrl = getValues('photoUrl')
  const isSelected = usePhotoSelected(photoPreview)

  useEffect(() => {
    trigger('photo').then((result) => {
      if (!result) { return }
      if (!photo) { return }
      if (!photo[0]) { return }

      const fr = new FileReader()
      fr.readAsDataURL(photo[0])
      fr.onload = function() {
        setPhotoPreview(this.result)
      }
    })

    if (photo && photo[0]) {
      setValue('deletePhoto', null, { shouldDirty: true })
    }
  }, [photo, trigger, setValue])

  const onRemove = useCallback(() => {
    inputRef.current.focus()
  }, [])

  return (
    <FormControl isInvalid={!!photoError}>
      <PhotoContainer
        backgroundColor="#F8F4FF"
        backgroundImage={isSelected ? (photoPreview || photoUrl) : undefined}
        backgroundSize="cover"
        backgroundPosition="center"
        onRemove={onRemove}
      >
        <WrapperState photoPreview={photoPreview} />
        <InvisibleInput
          ref={inputRef}
          labelText={
            isSelected ?
              t('Edit photo') :
              t('Upload photo')
          }
        />
      </PhotoContainer>
    </FormControl>
  )
}
