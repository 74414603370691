import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { FormProvider, useForm } from 'react-hook-form'
import { useTalentProfileId } from 'Partner/useTalentProfileId'

export const RequestFormProvider = ({ children }) => {
  const id = useTalentProfileId()
  const {
    data: { partner_profile: { id: partnerProfileId } }
  } = useGetCurrentUserQuery()

  const methods = useForm({
    defaultValues: {
      id,
      partner_profile_id: partnerProfileId,
      message: ''
    }
  })

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  )
}
