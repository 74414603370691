import React from 'react'
import {
  Box,
  Accordion,
  GridItem,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
} from '@chakra-ui/react'
import { H2 } from 'components'
import { NoisyBackground } from 'CourseFeature/CoursePage/shared/Layout'
import AccordionStepLink from './AccordionStepLink'

const collapsedProps = {
  color: 'white',
  bg: 'linear-gradient(90deg, rgba(232, 225, 222, 0.11) 0%, rgba(232, 225, 222, 0.044) 100%)'
}

const fontSize = ['18px', '18px', '20px']

const AccordionContent = ({ step, currentStepId, onStepClick, disabledSteps, validSteps, invalidSteps, t }) => (
  <AccordionPanel pb={4} pr={0} pl={5}>
    {step.links.map((link, linkIndex) => (
      <AccordionStepLink
        key={linkIndex}
        link={link}
        currentStepId={currentStepId}
        onStepClick={onStepClick}
        isDisabled={disabledSteps.includes(link.id)}
        isValid={validSteps.includes(link.id)}
        isInvalid={invalidSteps.includes(link.id)}
        t={t}
      />
    ))}
  </AccordionPanel>
)

const StepsAccordion = ({
  title,
  steps,
  currentStepId,
  onStepClick,
  disabledSteps,
  validSteps,
  invalidSteps,
  defaultIndex,
  t,
}) => {
  return (
    <GridItem colSpan={2}>
      <Box
        className="form-selection-section"
        h="100%"
        as="section"
        color="#ffffff"
        bgColor="#0F204A"
        position="relative"
        padding={12}
        pt="25px"
        flexDirection="column"
        maxWidth="500px"
        fontFamily="Spline Sans Mono"
      >
        <NoisyBackground
          color="rgba(15, 32, 74, 1)"
          opacity="0.09"
        />
        <H2 marginBottom={5} height="50px" display="flex" alignItems="center">
          {title}
        </H2>
        <Accordion allowToggle maxWidth="1000px" defaultIndex={defaultIndex} key={defaultIndex}>
          {steps.map((step, index) => (
            <AccordionItem key={step.header} border="none" marginY={2}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    paddingY={4}
                    fontFamily="Spline Sans Mono"
                    fontSize={fontSize}
                    fontWeight="500"
                    justifyContent="space-between"
                    position="relative"
                    {...collapsedProps}
                  >
                    <Box flex="1" textAlign="left">
                      {step.header}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionContent
                    step={step}
                    currentStepId={currentStepId}
                    onStepClick={onStepClick}
                    disabledSteps={disabledSteps}
                    validSteps={validSteps}
                    invalidSteps={invalidSteps}
                    t={t}
                  />
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </GridItem>
  )
}

export default StepsAccordion