import { useTranslation } from 'i18n/TranslationContext'
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, useToast } from '@chakra-ui/react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useInviteUserMutation } from 'features/invitationsApi'
import { SubmitButton } from '../../shared/SubmitButton'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo } from 'react'

const StyledFormLabel = ({ children }) => (
  <FormLabel
    fontFamily="Poppins"
    fontSize="18px"
    fontWeight="500"
    marginBottom="8px"
  >
    {children}
  </FormLabel>
)

const InputField = () => {
  const { register, formState: { errors } } = useFormContext()
  const { t } = useTranslation()

  return (
    <FormControl isInvalid={!!errors.email}>
      <Flex
        flexDirection={['column', null, 'row']}
      >
        <Box flexShrink="0" flexBasis="auto" flexGrow="1">
          <Flex justifyContent="space-between">
            <StyledFormLabel>
              {t('Email')}
            </StyledFormLabel>
            <FormErrorMessage
              alignItems="end"
              mt="0"
              paddingBottom="8px"
              line0Height="unset"
              fontSize="16px"
            >
              <Box color="#CD0050" textTransform="uppercase" fontWeight="500">
                {errors.email && errors.email.message}
              </Box>
            </FormErrorMessage>
          </Flex>
          <Flex>
            <Input
              size="lg"
              isInvalid={!!errors.email}
              errorBorderColor="#CD0050"
              _hover={{ borderColor: '#0F204B' }}
              _focusVisible={{ borderColor: '#3182ce', boxShadow: '0 0 0 1px #3182ce' }}
              height="56px"
              textTransform="none"
              fontFamily="Poppins"
              fontWeight="normal"
              fontSize="18px"
              borderRadius="4px"
              border="2px solid #0F204B"
              placeholder="john.doe@example.com"
              type="email"
              {
                ...register(
                  'email', {
                    required: t('Can’t be empty'),
                  }
                )
              }
            />
          </Flex>
        </Box>
        <Box flexBasis={['16px', null, '12px']} />
        <Flex
          alignItems="end"
        >
          <SubmitButton
            width={['100%', null, 'unset']}
            size="lg"
            colorScheme="zettaBlue"
            fontWeight="500"
          >
            {t('Send invitation')}
          </SubmitButton>
        </Flex>
        <Box flexGrow="1" flexBasis="1px" flexShrink="0" />
      </Flex>
    </FormControl>
  )
}

export const SendInvitationForm = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const { courseId } = useParams()
  const [inviteUser, { isSuccess,error }] = useInviteUserMutation()
  const defaultValues = useMemo(() => ({
    courseId, email: ''
  }), [courseId])
  const methods = useForm({ defaultValues })

  const {
    getValues,
    handleSubmit,
    reset
  } = methods


  useEffect(() => {
    if (!isSuccess) { return }
    const { email } = getValues()

    reset(defaultValues, { keepDefaultValues: true })
    toast({
      title: t('Invitation to {{email}} was sent', { email }),
      status: 'success',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [getValues, isSuccess, toast, reset, defaultValues, t])

  useEffect(() => {
    if (!error) { return }

    const title = (error.status === 409) ? t('The user has been invited before') : t('Something went wrong')
    toast({
      title,
      status: 'error',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [error, toast, t])

  return (
    <Box
      pt="40px"
    >
      <FormProvider {...methods}>
        <Flex as="form"
          onSubmit={handleSubmit(inviteUser)}
        >
          <Box flexBasis="100%">
            <InputField />
          </Box>
        </Flex>
      </FormProvider>
    </Box>
  )
}
