import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@chakra-ui/react'

const PrimaryButton = ({ linkValue, textValue, ...props }) => {
  const button = (
    <Button
      colorScheme="zettaBlue"
      fontSize="16px"
      fontWeight="500"
      padding="0 12px"
      {...props}
    >
      {textValue}
    </Button>
  )

  if (linkValue) {
    return (
      <Link to={linkValue}>
        {button}
      </Link>
    )
  } else {
    return button
  }
}

export default PrimaryButton
