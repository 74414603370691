import { Button, Link as ChakraLink } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'i18n/TranslationContext'

export const DownloadAssignmentButton = ({ filename, downloadUrl, assignmentKey, isTalentList }) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState()

  const handleDownloadClick = (e) => {
    if (isLoading) {
      e.preventDefault()
    } else {
      setIsLoading(true)
      // write a user-friendly date/time to localStorage
      const now = new Date()
      const date = now.toLocaleDateString()
      const time = now.toLocaleTimeString()
      localStorage.setItem(assignmentKey, `${date} ${time}`)

      // reset isLoading after 3 seconds
      setTimeout(() => setIsLoading(false), 3000)
    }
  }

  const extraProps = isTalentList ?
    { mt: 2, colorScheme: 'zettaWhiteOutline' } :
    { colorScheme: 'zettaBlue' }

  return (
    <Button
      as={ChakraLink}
      fontWeight="500"
      download={filename}
      href={downloadUrl}
      onClick={handleDownloadClick}
      isLoading={isLoading}
      flexShrink={0}
      { ...extraProps }
    >
      {t('Review Submitted Assignment')}
    </Button>
  )
}
