import { CourseNameAndThumbnail } from 'CourseBuilder/Forms/CourseNameAndThumbnail'
import { BlurbDescriptionAndSkills } from 'CourseBuilder/Forms/BlurbDescriptionAndSkills'
import { CourseExperts } from 'CourseBuilder/Forms/CourseExperts'
import { Assignment } from 'CourseBuilder/Forms/Assignment'
import { ReviewAndSubmit } from 'CourseBuilder/Forms/ReviewAndSubmit'
import { CourseVisibility } from 'CourseBuilder/Forms/CourseVisibility'
import { EpisodeUploadVideoThumbnail } from 'CourseBuilder/Forms/EpisodeUploadVideoThumbnail'
import { EpisodeUploadAccessibility } from 'CourseBuilder/Forms/EpisodeUploadAccessibility'
import { EpisodeQuiz } from 'CourseBuilder/Forms/EpisodeQuiz'
import { IntroVideoThumbnail } from 'CourseBuilder/Forms/IntroVideoThumbnail'
import { IntroAccessibilityFiles } from 'CourseBuilder/Forms/IntroAccessibilityFiles'
import {
  CourseNameAndThumbnailForm,
  BlurbDescriptionAndSkillsForm,
  CourseExpertsForm,
  CourseVisibilityForm,
  AssignmentForm,
  EpisodeUploadVideoThumbnailForm,
  EpisodeUploadAccessibilityForm,
  EpisodeQuizForm,
  IntroVideoThumbnailForm,
  IntroAccessibilityFilesForm,
} from 'components/courseBuilder/formContent'
import i18n from 'i18next'


const episodeStepsContent = (numEpisodes, t) => {
  const episodes = []
  for (let i = 1; i <= numEpisodes; i++) {
    episodes.push({
      header: t('Episode {{i}}', { i }),
      links: [
        {
          id: `episode${i}UploadVideoThumbnail`,
          label: t('Upload Episode File & Thumbnail'),
          props: { episodeNum: i },
          Component: EpisodeUploadVideoThumbnail,
          ReviewComponent: EpisodeUploadVideoThumbnailForm,
        },
        {
          id: `episode${i}UploadAccessibility`,
          label: t('Upload Accessibility Files'),
          props: { episodeNum: i },
          Component: EpisodeUploadAccessibility,
          ReviewComponent: EpisodeUploadAccessibilityForm,
        },
        {
          id: `episode${i}Quiz`,
          label: t('Add Quiz & Additional Episodes'),
          props: { episodeNum: i },
          Component: EpisodeQuiz,
          ReviewComponent: EpisodeQuizForm,
        }
      ]
    })
  }
  return episodes
}

export const getCourseStepsContent = (numEpisodes) => {
  const t = i18n.getFixedT(null, 'common')
  let steps = [
    {
      header: t('Course Information'),
      links: [
        {
          id: 'courseNameThumbnail',
          label: t('Course Name & Thumbnail'),
          Component: CourseNameAndThumbnail,
          ReviewComponent: CourseNameAndThumbnailForm,
          fields: ['course_name', 'course_additional_material']
        },
        {
          id: 'courseDescriptions',
          label: t('Blurb, Description, & Skills'),
          Component: BlurbDescriptionAndSkills,
          ReviewComponent: BlurbDescriptionAndSkillsForm,
          fields: ['course_summary', 'course_description', 'course_skills']
        },
        {
          id: 'courseExperts',
          label: t('Course Experts'),
          Component: CourseExperts,
          ReviewComponent: CourseExpertsForm,
          fields: ['course_expert']
        },
        {
          id: 'courseVisibility',
          label: t('Set Visibility'),
          Component: CourseVisibility,
          ReviewComponent: CourseVisibilityForm,
          fields: ['course_visibility']
        }
      ]
    },
    {
      header: t('Course Introduction'),
      links: [
        {
          id: 'introVideoThumbnail',
          label: t('Upload Video & Thumbnail'),
          Component: IntroVideoThumbnail,
          ReviewComponent: IntroVideoThumbnailForm,
        },
        {
          id: 'introAccessibilityFiles',
          label: t('Upload Accessibility Files'),
          Component: IntroAccessibilityFiles,
          ReviewComponent: IntroAccessibilityFilesForm,
        }
      ]
    },
    ...episodeStepsContent(numEpisodes, t),
    {
      header: t('Assignment'),
      links: [{
        id: 'assignment',
        label: t('Assignment Details'),
        Component: Assignment,
        ReviewComponent: AssignmentForm,
      }]
    },
    {
      header: t('Review & Submit'),
      links: [{
        id: 'reviewSubmit',
        label: t('Review & Submit'),
        Component: ReviewAndSubmit
      }]
    },
  ]

  let headersMap = {}
  let courseStepsContent = []

  steps.forEach((step, index) => {
    step.links.forEach(link => {
      courseStepsContent.push({ ...link, defaultIndex: index })
    })
    headersMap[index] = step.header
  })

  return { courseStepsContent, headersMap }
}
