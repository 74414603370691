import { Box } from '@chakra-ui/react'

export const ShiftedBox = (props) => (
  <Box
    {...props}
    ml={['12px', '26px', 68]}
  />
)

export const NoisyBackground = ({ color, opacity }) => (
  <>
    <Box
      bgColor={color}
      width= "100%"
      height= "100%"
      position= "absolute"
      zIndex= "-2"
      left= "0"
      top= "0"
    />
    <Box
      background={`url(${process.env.PUBLIC_URL}/noise.png)`}
      opacity={opacity}
      width= "100%"
      height= "100%"
      position= "absolute"
      zIndex= "-1"
      left= "0"
      top= "0"
    />
  </>
)
