import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Center, Flex, Show } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useCourseId, useCurrentEpisodeData } from '../hooks'
import { useCoursePageContext } from 'CourseFeature/CoursePage/CoursePageContext.js'
import { CourseNavigationBlock } from './CourseNavigationBlock'
import { AdditionalMaterialsBlock } from './AdditionalMaterialsBlock'
import { AssignmentBlock } from './AssignmentBlock'
import { AssignmentDetails } from './AssignmentDetails'
import { usePageTitle } from '../../usePageTitle'

export const AssignmentPage = () => {
  const { name: episodeName } = useCurrentEpisodeData()
  const { t } = useTranslation()
  const { isPartnerExperience } = useCoursePageContext()
  const courseId = useCourseId()
  const header = `${t('Assignment')} — ${episodeName}`

  usePageTitle(header)

  return (
    <Center
      as="main"
      pb="40px"
      role="main"
      overflowX="hidden"
      className="assignment-page"
    >
      <Box
        flex="1"
        maxWidth="1440px"
        pt="40px" pl="48px" pr="48px"
        zIndex="1"
      >
        <Flex
          flexWrap="wrap"
          gap="32px"
        >
          <Box
            flexGrow="1"
            flexBasis="60%"
          >
            <Flex
              pl="48px" pr="48px" pb="48px" pt="48px"
              bgColor="white"
              alignItems="flex-start"
            >
              <Box
                as="h1"
                color="#0F204B"
                fontFamily="Spline Sans Mono"
                fontWeight="bold"
                fontSize="40px"
                textTransform="uppercase"
                flexShrink="2"
              >
                {header}
              </Box>
              <Show above="md">
                <Box
                  marginLeft="auto"
                >
                  <Button
                    as={Link}
                    to={`/courses/${courseId}`}
                    colorScheme="zettaPurple"
                    fontWeight="500"
                  >
                    {t('Open course profile')}
                  </Button>
                </Box>
              </Show>
            </Flex>
            <Box
              as="section"
              mt="24px"
              pr="48px" pl="48px" pt="48px" pb="60px"
              bgColor="white"
              className="assignment-block"
            >
              {isPartnerExperience ? <AssignmentDetails /> : <AssignmentBlock />}
            </Box>
          </Box>
          <Box
            position="relative"
            flex="1"
            flexBasis="30%"
          >
            <Box
              position="absolute"
              top="100px"
              right="-30px"
              zIndex="-1"
            >
              <img
                src={`${process.env.PUBLIC_URL}/episode-bg-2.svg`}
                alt=""
              />
            </Box>
            <AdditionalMaterialsBlock />
            <CourseNavigationBlock />
          </Box>
        </Flex>
      </Box>
    </Center>
  )
}
