import { useTranslation } from 'i18n/TranslationContext'
import { PageOuterContainer } from '../PartnerLayout'
import { Box, Button, Spacer } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { InvitationsTable } from './InvitationsTable'
import { SendInvitationForm } from './SendInvitationForm'

export const PageContent = () => {
  const { t } = useTranslation()
  const { courseId } = useParams()

  return (
    <PageOuterContainer>
      <Box
        bgColor="white"
        padding={[
          '40px 24px 40px',
          null,
          '40px 50px 40px'
        ]}
        minHeight={[null, null, '720px']}
      >
        <Box
          display={['block', null, 'flex']}
          alignItems="center"
          flexWrap="wrap"
        >
          <Box
            color="var(--navy-blue, #0F204A)"
            fontFamily="Spline Sans Mono"
            fontSize="40px"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {t('Invite talent')}
          </Box>
          <Spacer />
          <Box pt={['12px', null, '0']}>
            <Button
              as={Link}
              to={`/courses/dashboard/${courseId}`}
              colorScheme="zettaPurple"
              width="100%"
              fontWeight="500"
            >
              {t('Cancel')}
            </Button>
          </Box>
        </Box>
        <SendInvitationForm />
        <InvitationsTable />
      </Box>
    </PageOuterContainer>
  )
}
