import { Flex } from '@chakra-ui/react'

export const FormControlContainer = ({ children }) =>
  <Flex
    flexDirection={['column', null, 'row']}
    padding={[
      '24px 0',
      null,
      '32px 42px'
    ]}
    alignItems="center"
    marginBottom={[null, null, '1px']}
    bgColor="white"
  >
    {children}
  </Flex>
