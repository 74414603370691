import React from 'react'
import { Box } from '@chakra-ui/react'

const ListHeader = ({ children, ...props }) => {
  return (
    <Box
      textTransform="uppercase"
      fontSize={['28px', null, '32px']}
      fontWeight="bold"
      fontFamily="Spline Sans Mono"
      paddingBottom={['24px', null, '32px']}
      {...props}
    >
      {children}
    </Box>
  )
}

export default ListHeader
