import { Box, Center } from '@chakra-ui/react'

export const BlockWrapper = ({ children }) => (
  <Box bgColor="white" className="modal-chat-splash-block">
    <Center>
      <Box flexGrow="1">
        <Box
          position="absolute"
          top="10px"
          left="20px"
          opacity={0.8}
        >
          <img src={`${process.env.PUBLIC_URL}/corner-left.svg`} alt="" />
        </Box>
        <Box
          position="absolute"
          bottom="40px"
          right="20px"
        >
          <img src={`${process.env.PUBLIC_URL}/corner-right.svg`} alt="" />
        </Box>

        {children}
      </Box>
    </Center>
  </Box>
)