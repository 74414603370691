import { apiSlice } from './apiSlice'

export const stepsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStepUploadStatus: builder.query({
      query: (stepId) => `steps/${stepId}/upload_status`,
    }),
    trackWatchTime: builder.mutation({
      query: ({ courseId, stepId, timeInSeconds, stepTotalTime }) => ({
        url: `courses/${courseId}/steps/${stepId}/tracking`,
        method: 'POST',
        body: { time_in_seconds: timeInSeconds, step_total_time: stepTotalTime },
      }),
    }),
    extractDeckComponents: builder.mutation({
      query: deckPath => ({
        url: 'steps/extract_deck_components',
        method: 'POST',
        body: { deck_path: deckPath }
      }),
    }),
  })
})

export const {
  useGetStepUploadStatusQuery,
  useTrackWatchTimeMutation,
  useExtractDeckComponentsMutation
} = stepsApi
