import { Box, Center } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'

export const EmailConfirmContent = () => {
  const { t } = useTranslation()
  return (
    <Box
      paddingTop={['0', '60px']}
      paddingBottom={['60px', null, '240px']}
      marginBottom="54px"
      bgColor="white"
      className="email-confirm-content"
    >
      <Center flexDirection="column">
        <Center flexDirection="column" maxWidth="1020px" width="100%">
          <Box
            bg={`url(${process.env.PUBLIC_URL}/onboarding-bg.svg)`}
            bgRepeat="no-repeat"
            backgroundSize={['cover', 'contain']}
            margin={['0 -8px', null, '0 -36px']}
            paddingLeft="32px"
            paddingRight="36px"
          >

            <Box as="h1"
              fontSize={['40px', null, '56px']}
              fontFamily="Spline Sans Mono"
              fontWeight="700"
              textTransform="uppercase"
              textAlign="center"
            >
              {t('Confirm your email')}
            </Box>
          </Box>
        </Center>
        <Box
          maxWidth="660px"
          marginTop="40px"
          textAlign="center"
          fontFamily="Poppins"
          fontSize="20px"
          paddingLeft="16px"
          paddingRight="16px"
        >
          <p>{t('Thanks for the sign up!')}</p>
          <p>
            {t('To proceed, please confirm your email. You will find a confirmation letter in your inbox.')}
          </p>
        </Box>
      </Center>
    </Box>
  )
}