import './jquery-loader'
import '../../vendor/ableplayer/ableplayer.css'
import '../../vendor/ableplayer/ableplayer'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { useDispatch, useSelector } from 'react-redux'
import { useGetAudioDescriptionFileStatus, useEpisodeBathPath, useVTTSrcPath, useShowTakeQuizButton, useVideoCompleted } from '../hooks'
import { setChapters, setTranscriptVisibility, setPlayerLoaded, resetPlayerState, hidePlayerCover, setIsPlaying } from 'features/episode/slice'
import { useShowEnrollInfoForCourseQuery } from 'features/coursesApi'
import { usePlayerContext } from './PlayerContext'
import { useParams } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { useHasCompletedQuiz, handleQuizButtonClick } from 'utils/quizzes/talentQuiz'
import { useVideoPlayer } from 'CourseFeature/EpisodePage/useVideoPlayer'
import { usePlaybackEndContext } from 'CourseFeature/EpisodePage/context/PlaybackEndContext'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import useWatchTimeTracker from 'hooks/episodes/useWatchTimeTracker'

class ExtendedAblePlayer extends window.AblePlayer {
  constructor(mediaObject, { onSetupDone, onTranscriptToggle }) {
    super(mediaObject)
    this.onSetupDone = onSetupDone
    this.onTranscriptToggle = onTranscriptToggle
  }

  setup() {
    super.setup().then(() => {
      this.onSetupDone()
    })

  }

  handleTranscriptToggle() {
    const transcriptColumn = document.getElementById('transcript-column')
    const { display } = window.getComputedStyle(transcriptColumn)
    const transcriptVisible = display !== 'none'

    if (transcriptVisible) {
      this.onTranscriptToggle(false)
      this.transcriptToggleButtonHiddenState()

      this.prefTranscript = 0

      // wait briefly before resetting stopgap var
      // otherwise the keypress used to select 'Close' will trigger the transcript button
      // Benchmark tests: If this is gonna happen, it typically happens in around 3ms; max 12ms
      // Setting timeout to 100ms is a virtual guarantee of proper functionality
      setTimeout(() => {
        this.closingTranscript = false
      }, 100)
    } else {
      this.onTranscriptToggle(true)
      this.transcriptToggleButtonVisibleState()
      this.prefTranscript = 1
    }
    this.updateCookie('prefTranscript')
  }

  transcriptToggleButtonHiddenState() {
    this.$transcriptButton.addClass('buttonOff').attr('aria-label',this.tt.showTranscript)
    this.$transcriptButton.find('span.able-clipped').text(this.tt.showTranscript)
  }

  transcriptToggleButtonVisibleState() {
    this.$transcriptButton.removeClass('buttonOff').attr('aria-label',this.tt.hideTranscript)
    this.$transcriptButton.find('span.able-clipped').text(this.tt.hideTranscript)
  }
}

export const Player = () => {
  const mediaSrcPath = {
    episode: '/episode1.mp4',
    episodeDescribed: '/described-episode1.mp4',
    episodeHLS: '/master.m3u8',
    episodeHLSDescribed: '/HLS-described/master.m3u8',
    signVideo: '/asl.mp4',
  }

  const { i18n, t } = useTranslation()
  const [playerState, setPlayerState] = useState(null)
  const { isPlaying } = useSelector(state => state.episode)
  const playerInstanceRef = usePlayerContext()
  const { pausePlayer } = useVideoPlayer()
  const base = useEpisodeBathPath()
  const dispatch = useDispatch()

  // we need to use refs due to closure issues from player handlers
  // inside the init useEffect
  const autoSeekingRef = useRef(null)
  const playerRef = useRef(null)
  const isPartnerRef = useRef(false)

  const vttSrcPath = useVTTSrcPath(i18n?.language)

  const { data: audioDescriptionData, isFetching: fetchingADData } = useGetAudioDescriptionFileStatus({
    base,
    MP4Path: mediaSrcPath.episodeDescribed,
    VTTPath: vttSrcPath.descriptions
  })


  //determine if user is a partner
  const { data: { id, feature } } = useGetCurrentUserQuery()
  const isPartner = feature === 'partner_experience'
  useEffect(() => {
    isPartnerRef.current = isPartner
  }, [isPartner])

  let { courseId, stepId } = useParams()
  const { data } = useShowEnrollInfoForCourseQuery(courseId, { skip: playerState === 'initialized' })
  const { hasCompletedQuiz } = useHasCompletedQuiz()
  const videoCompleted = useVideoCompleted(stepId)
  const { showTakeQuiz } = useShowTakeQuizButton(stepId)
  const { handlePlaybackEnd } = usePlaybackEndContext()
  const trackVideoTime = useWatchTimeTracker(courseId, stepId, id)

  // Auto-seek to the last watched time when the player is initialized
  useEffect(() => {
    // If value is false, that means autoseek has already happened
    if ( autoSeekingRef.current === false || videoCompleted ) return

    const MIN_SECS_TO_RESUME = 10
    const lastWatchedTime = stepId in (data?.tracking?.step_completion || {}) ?
      data.tracking.step_completion[stepId].time_in_seconds : undefined
    const playerInstance = playerInstanceRef.current

    if (lastWatchedTime && playerInstance && isPlaying) {
      const shouldAutoSeek = lastWatchedTime > MIN_SECS_TO_RESUME
      if (shouldAutoSeek) {
        // Set the auto-seek flag so it doesn't interfere with manual seeking
        autoSeekingRef.current = true
        playerInstance.media.currentTime = lastWatchedTime
      }
    }
  }, [data?.tracking?.step_completion, stepId, isPlaying, playerInstanceRef, videoCompleted])

  // Init the Player and define handlers
  useEffect(() => {
    if (playerState === 'initialized') {
      return () => {
        if (playerInstanceRef.current) {
          playerInstanceRef.current.$media.off('playing')
          playerInstanceRef.current.$media.off('pause')
          playerInstanceRef.current.$media.off('timeupdate')
          playerInstanceRef.current.$media.off('seeking')
          playerInstanceRef.current.$media.off('ended')

          playerInstanceRef.current.pauseMedia()
          playerInstanceRef.current.media.currentTime = 0
          playerInstanceRef.current.deletePlayer()
          playerInstanceRef.current = null
        }
        setPlayerState(null)
        autoSeekingRef.current = null
        dispatch(resetPlayerState())
      }
    }

    const initializing = playerInstanceRef.current !== null
    if ( initializing || fetchingADData ) { return }

    let previousTime = 0

    playerInstanceRef.current = new ExtendedAblePlayer(
      playerRef.current, {
        onSetupDone: () => {
          const playerInstance = playerInstanceRef.current
          const isTranscriptVisible = playerInstance.prefTranscript === 1

          if (playerInstance.$signVideo) {
            // Synchronization logic between main video and sign video
            playerInstance.$signVideo.on('timeupdate', () => {
              const diff = playerInstance.signVideo.currentTime - playerInstance.media.currentTime
              if (diff > 0.6) {
                playerInstance.signVideo.pause()
              }
            })
          }

          // If spacebar starts playback and cover is showing, hide it.
          // Should support other HTML5 media state strings
          playerInstance.$media.on('playing', () => {
            dispatch(hidePlayerCover())
            dispatch(setIsPlaying(true))
          })

          playerInstance.$media.on('pause', () => {
            dispatch(setIsPlaying(false))
          })

          playerInstance.$media.on('timeupdate', () => {
            if (playerInstance.$signVideo) {
              // Synchronization logic between main video and sign video
              const diff = playerInstance.signVideo.currentTime - playerInstance.media.currentTime

              if (!playerInstance.prefSign) { return }

              if (Math.abs(diff) > 0.1) {
                playerInstance.signVideo.currentTime = playerInstance.media.currentTime
                if (playerInstance.signVideo.paused && !playerInstance.media.paused) {
                  playerInstance.signVideo.play()
                }
              }
            }

            let totalTimeSecs = Math.floor(playerRef?.current?.duration)
            let watchedTimeSecs = Math.floor(playerInstance?.media?.currentTime)

            // Track watch time every X seconds
            trackVideoTime(totalTimeSecs, watchedTimeSecs)
          })

          playerInstance.$media.on('seeking', () => {
            if ( isPartnerRef.current ) return
            if (autoSeekingRef.current) {
              // If auto-seeking, allow seeking and reset the flag
              autoSeekingRef.current = false
              return
            }

            const previousCompleteTime = data?.tracking?.step_completion?.[stepId]?.time_in_seconds
            if (playerInstance.media.currentTime > (previousCompleteTime || previousTime)) {
              playerInstance.media.currentTime = previousTime
            }
          })

          playerInstance.$media.on('ended', handlePlaybackEnd)

          if (isTranscriptVisible) {
            playerInstance.transcriptToggleButtonVisibleState()
          } else {
            playerInstance.transcriptToggleButtonHiddenState()
          }

          dispatch(setTranscriptVisibility(isTranscriptVisible))
          dispatch(setChapters(playerInstance.selectedChapters?.cues))

          dispatch(setPlayerLoaded())
          setPlayerState('initialized')
        },

        onTranscriptToggle: (newState) => {
          dispatch(setTranscriptVisibility(newState))
        }
      }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,
    playerState,
    playerInstanceRef,
    base,
    fetchingADData,
    i18n.language,
    courseId,
    stepId,
    data?.tracking?.step_completion,
    handlePlaybackEnd,
  ])

  useEffect(
    () => () => {
      dispatch(resetPlayerState())
    }, [dispatch]
  )

  const takeQuizButtonStyle = { position: 'absolute', top: '20px', right: '20px', zIndex: 5001 }
  takeQuizButtonStyle.display = showTakeQuiz && videoCompleted ? 'block' : 'none'

  const label = hasCompletedQuiz ? t('Review Quiz') : t('Take Quiz')

  return (
    <div style={{ position: 'relative' }}>
      <Button
        size="lg"
        style={takeQuizButtonStyle}
        onClick={() => handleQuizButtonClick({ stepId, hasCompletedQuiz, dispatch, pausePlayer })}
      >
        {label}
      </Button>
      <video
        ref={playerRef}
        id="main-video"
        preload="auto"
        poster={`${base}/poster@2x.jpeg`}
        data-skin="2020"
        data-transcript-div="transcript"
        playsInline
        data-root-path={process.env.PUBLIC_URL || '/'}
        crossOrigin="use-credentials"
        style={{ width: '100%' }}
      >
        <source
          data-sign-src={`${base}/asl.mp4`}
        />
        {audioDescriptionData?.typeExtVideo ? (
          <>
            <source
              type="application/vnd.apple.mpegurl"
              src={base + mediaSrcPath.episodeHLSDescribed}
              data-desc-src={base + mediaSrcPath.episodeHLSDescribed}
              data-orig-src={base + mediaSrcPath.episodeHLS}
            />
            <source
              type="video/mp4"
              src={base + mediaSrcPath.episodeDescribed}
              data-desc-src={base + mediaSrcPath.episodeDescribed}
              data-orig-src={base + mediaSrcPath.episode}
            />
            <track kind="captions"
              src={base + vttSrcPath.describedCaptions}
              srcLang={i18n.language} default
              data-desc
            />
            <track
              kind="chapters"
              src={base + vttSrcPath.describedChapters}
              srcLang={i18n.language}
              data-desc
            />
          </>
        ) : (
          <>
            <source
              src={base + mediaSrcPath.episodeHLS}
              type="application/vnd.apple.mpegurl"
            />
            <source
              src={base + mediaSrcPath.episode}
              type="video/mp4"
            />
          </>
        )}

        <track kind="captions"
          src={base + vttSrcPath.captions}
          srcLang={i18n.language}
          default
        />
        <track
          kind="chapters"
          src={base + vttSrcPath.chapters}
          srcLang={i18n.language}
        />

        {audioDescriptionData?.typeVTT && !audioDescriptionData?.typeExtVideo && (
          <track
            kind="descriptions"
            src={base + vttSrcPath.descriptions}
            srcLang={i18n.language}
          />
        )}

      </video>
    </div>
  )
}
