import React, { useRef } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { FormTemplate } from 'components/builder/FormTemplate'
import useSendFormData from 'hooks/opportunityBuilder/useSendFormData'
import { useSelector } from 'react-redux'
import { OpportunityDescriptionForm } from '../FormContent/OpportunityDescriptionForm'
import { useOpportunityBuilderContext } from 'OpportunityBuilder/OpportunityBuilderContext'

export const OpportunityDescription = ({ nextStep, formForStep }) => {
  const { t } = useTranslation()

  const opportunityData = useSelector(state => state.opportunityBuilder || {})
  const { isFileUploading } = useOpportunityBuilderContext()
  const initialized = useRef(false)

  // When the form changes, handle the data being sent to the backend
  useSendFormData(opportunityData, initialized)

  return (
    <FormTemplate
      formTitle={t('Opportunity Description')}
      nextStep={nextStep}
      formForStep={formForStep}
      buttonDisabled={isFileUploading}
    >
      <OpportunityDescriptionForm />
    </FormTemplate>
  )
}
