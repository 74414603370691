import React from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'i18n/TranslationContext'
import { InputField, TextEditorField, TagInputField } from 'components'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { removeHtmlTags } from 'utils/string'

const BlurbDescriptionAndSkillsForm = ({ formDisabled = false }) => {
  const { t } = useTranslation()
  const { register } = useFormContext() // retrieve all hook methods
  const errors = useSelector(state => state.courseBuilder.errors) // Access errors from the Redux store
  const { helperTextVals, placeholderTextVals } = useGetFormTextValues()
  const {
    skillsValues,
    setSkillsValues,
    courseDescriptionEditorState,
    setCourseDescriptionEditorState,
  } = useCourseBuilderContext()

  return (
    <>
      <InputField
        label={t('Course Blurb')}
        name="summary"
        width="100%"
        fieldRequired={true}
        errorVal={errors}
        helperText={!formDisabled && helperTextVals.course_summary}
        inputProps={{
          disabled: formDisabled,
          placeholder: placeholderTextVals.course_summary,
          ...register(
            'summary', {
              required: t('Course Blurb can’t be empty.'),
              maxLength: { value: 250, message: 'Max 250 characters allowed.' }
            }
          )
        }}
      />
      <TextEditorField
        name="description"
        errorVal={errors}
        editorState={courseDescriptionEditorState}
        setEditorState={setCourseDescriptionEditorState}
        textFieldProps={{
          label: t('Course Description'),
          helperText: !formDisabled && helperTextVals.course_description,
          placeholder: placeholderTextVals.course_description,
          width: '100%',
          fieldRequired: true,
          disabled: formDisabled,
          maxLength: { value: 1000, message: 'Max 1000 characters allowed.' }
        }}
        inputProps={{
          ...register(
            'description', {
              required: t('Course Description can’t be empty.'),
              validate: value => removeHtmlTags(value)?.length > 1000 ? t('Max 1000 characters allowed') : true
            }
          )
        }}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'subscript', 'superscript'],
          },
          blockType: {
            options: ['Blockquote'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          link: {
            options: ['link', 'unlink'],
            showOpenOptionOnHover: true,
          },
        }}
      />
      <TagInputField
        t={t}
        label={t('Skills')}
        name="course_skills"
        errorVal={errors}
        tagsProp={{
          tags: skillsValues,
          setTags: setSkillsValues,
          maxTags: 3,
        }}
        fieldRequired={true}
        helperText={!formDisabled && helperTextVals.course_skills}
        inputProps={{
          disabled: formDisabled,
          width: '100%',
          placeholder: placeholderTextVals.course_skills,
          ...register(
            'course_skills', {
              required: t('Course Skills can’t be empty.')
            }
          )
        }}
      />
    </>
  )
}

export default BlurbDescriptionAndSkillsForm
