import { apiSlice } from './apiSlice'

const convertFormParamsToData = (props) => {
  const formData = new FormData()

  formData.append('assignment', props.assignment[0])
  formData.append('talent_profile_id', props.talentProfileId)

  return formData
}

export const submittedAssignmentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAssignments: builder.query({
      query: ({ episodePath, talentProfileId }) => `${episodePath}/submitted_assignments?talent_profile_id=${talentProfileId}`,
      providesTags: ['CurrentAssignment']
    }),
    submitAssignment: builder.mutation({
      query: ({ episodePath, ...restProps }) => ({
        url: `${episodePath}/submitted_assignments`,
        method: 'POST',
        formData: true,
        body: convertFormParamsToData(restProps)
      }),
      invalidatesTags: (result, error) => error ? [] : ['CurrentAssignment']
    })
  })
})

export const {
  useSubmitAssignmentMutation,
  useListAssignmentsQuery
} = submittedAssignmentsApi
