import { useTranslation } from 'i18n/TranslationContext'
import { Flex, Box, Button, Center, Spinner } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { chaptersSelector } from 'features/episode/selectors'
import { useEpisodeBathPath } from '../hooks'
import { H2 } from 'shared/typography'
import { useVideoPlayer } from './useVideoPlayer'

const Timestamp = ({ seconds }) => (
  new Date(seconds * 1000).toISOString().substring(14, 19)
)

const Chapter = ({ chapter: { start, components: { children: [{ value: chapterName }] } } }) => {
  const { startPlayer } = useVideoPlayer()
  const base = useEpisodeBathPath()

  const onChapterClick = () => { startPlayer({ time: start }) }

  return (
    <Flex
      borderBottom="1px solid #EFEFF0"
      pt="28px" pb="20px"
      fontFamily="Poppins"
      fontSize="18px"
      textTransform="uppercase"
    >
      <Center
        mr="16px"
        flex="0 0 68px"
      >
        <Center
          width="68px"
          height="56px"
          bgColor="black"
          borderRadius="16px"
          overflow="hidden"
        >
          <video
            aria-hidden
            width="68px"
            height="56px"
            type="video/mp4"
            preload="metadata"
          >
            <source
              src={`${base}/thumb.mp4#t=${start}`}
            />
          </video>
        </Center>
      </Center>
      <Center>
        <Box>
          <Button
            colorScheme="zettaBlue"
            fontWeight="500"
            fontSize="18px"
            textDecoration="underline"
            variant="link"
            onClick={onChapterClick}
          >
            <Timestamp seconds={start} />
          </Button>
          {' '}
          <Box display="inline" fontWeight="700">
            {chapterName}
          </Box>
        </Box>
      </Center>
    </Flex>
  )
}

const ChaptersList = () => {
  const chapters = useSelector(chaptersSelector)

  if (!chapters) {
    return (
      <Box pt="28px">
        <Spinner />
      </Box>
    )
  }

  return chapters.map( (chapter) => (
    <Chapter
      key={chapter.id}
      chapter={chapter}
    />
  ))
}

export const ChaptersBlock = () => {
  const { t } = useTranslation()
  return (
    <Box as="section"
      position="relative"
      pt="32px" pl="24px" pr="32px" pb="32px"
      bgColor="white"
      width="100%"
      overflowY="scroll"
    >
      <H2
        fontSize="18px"
        color="#0F204B"
      >
        {t('Chapters')}
      </H2>
      <Box>
        <ChaptersList />
      </Box>
    </Box>
  )
}
