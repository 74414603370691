import { useTranslation } from 'i18n/TranslationContext'
import { Header1 } from '../Header1'
import { Box, Button, Center } from '@chakra-ui/react'
import { Details } from '../Details'
import { Link } from 'react-router-dom'
import { usePageTitle } from 'usePageTitle'

export const ForgotPasswordPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Reset password'))

  return (
    <Box
      background="#F0E9E6"
      pt="84px" pb="40px"
    >
      <Center flexDirection="column">
        <Box pl="64px" pr="64px">
          <Header1>
            {t('Reset password')}
          </Header1>
          <Center
            maxWidth="660px"
            flexDirection="column"
          >
            <Details>
              {t('Lost your password? No need to worry. Click the button below to reset the password.')}
            </Details>
            <Button
              as={Link}
              to="/forgot_password/reset"
              colorScheme="zettaBlue"
              fontWeight="normal"
            >
              {t('Reset password')}
            </Button>
          </Center>
        </Box>
      </Center>
    </Box>
  )
}
