import { Box, Center } from '@chakra-ui/react'
import { CourseImageSet } from './CourseImageSet'
import { SocialMediaLinks } from './SocialMediaLinks'
import parse from 'html-react-parser'

export const CourseAuthor = ({
  author: { name, title, photo, photoAltText, links },
  hoverColor
}) => {
  return (
    <figure>
      <Center className="course-author" mt="50px">
        <Box
          display="flex"
          position="relative"
          role="group"
        >
          <Box
            overflow="hidden"
            borderColor="rgba(15, 32, 74, 1)"
            borderWidth="2px"
            borderRadius="10px"
            maxHeight="340px"
            display="flex"
            alignItems="center"
            width="258px"
          >
            <CourseImageSet
              photoSet={photo}
              alt={photoAltText}
            />
          </Box>
          <Box
            position="absolute"
            top="0"
            width="100%"
            height="100%"
            zIndex="-1"
            color={hoverColor}
            transform="scale(98%)"
            transition="transform 0.5s ease-in-out"
            _groupHover={{
              transform: 'rotate(-7deg)'
            }}
          >
            <svg width="100%" height="100%" aria-hidden>
              <use
                href={`${process.env.PUBLIC_URL}/taught-by-hover.svg#img`}
              />
            </svg>
          </Box>
        </Box>
      </Center>
      <Center
        as="figcaption"
        flexDirection="column"
        pt={['15px', '15px', '38px']}
        fontFamily="Poppins"
      >
        <Box as="h3"
          fontSize="30px"
          fontWeight="500"
        >
          {name}
        </Box>
        <Box as="p"
          fontSize="18px"
          textAlign="center"
        >
          {parse(title)}
        </Box>
        <SocialMediaLinks links={links} />
      </Center>
    </figure>
  )
}
