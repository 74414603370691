import React from 'react'
import { Flex, Box, Button } from '@chakra-ui/react'
import { Body } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useShowTakeQuizButton } from 'CourseFeature/hooks'
import { usePlaybackEndContext } from 'CourseFeature/EpisodePage/context/PlaybackEndContext'
import { useHasCompletedQuiz, handleQuizButtonClick } from 'utils/quizzes/talentQuiz'
import { useDispatch } from 'react-redux'


const CTAButton = ({ stepId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { handlePlaybackEnd } = usePlaybackEndContext()
  let ctaText
  // handlePlaybackEnd handles the scenarios of either needing to open a quiz or guiding to the next step
  let ctaAction = handlePlaybackEnd

  const { showTakeQuiz, isLoading } = useShowTakeQuizButton(stepId)
  const { hasCompletedQuiz } = useHasCompletedQuiz(stepId)

  if (showTakeQuiz) {
    ctaText = hasCompletedQuiz ? t('Review Quiz') : t('Take Quiz')
    if (hasCompletedQuiz) {
      ctaAction = () => handleQuizButtonClick({ stepId, hasCompletedQuiz, dispatch })
    }
  } else {
    ctaText = t('Next Step')
  }

  return (
    <Button isLoading={isLoading} colorScheme="zettaBlue" onClick={() => ctaAction()}>
      {ctaText}
    </Button>
  )
}

const arrowButtonStyles = {
  height: { base: '32px', md: 'auto' },
  width: { base: '50%', md: 'auto' },
  display: 'inline-block',
}

const SlidesContainer = ({ prevSlide, nextSlide, isLastSlide, isFirstSlide, stepId, slideProgressLabel, children }) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" flex="1 0 60%">
      <Flex justifyContent="space-between" alignItems="center" background="#0f214b" padding="12px 24px" mt="0" borderTopRightRadius="32px">
        <Body fontWeight="600" fontSize="17px" padding="0" color="white">{slideProgressLabel}</Body>
        {isLastSlide && <CTAButton stepId={stepId} />}
      </Flex>
      <Flex
        id="slides-container"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        alignItems="stretch"
      >
        <Button
          isDisabled={isFirstSlide}
          onClick={prevSlide}
          aria-label={t('Previous Slide')}
          order={{ base: 2, md: 1 }}
          {...arrowButtonStyles}
        >
          &larr;
        </Button>
        <Box tabIndex="0" order={{ base: 1, md: 2 }}>
          {children}
        </Box>
        <Button
          isDisabled={isLastSlide}
          onClick={nextSlide}
          aria-label={t('Next Slide')}
          order={{ base: 3, md: 3 }}
          {...arrowButtonStyles}
        >
          &rarr;
        </Button>
      </Flex>
    </Flex>
  )
}

export default SlidesContainer
