import { useTranslation } from 'i18n/TranslationContext'
import { useGetInvitation } from './useGetInvitation'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../InputField'
import { Box, useToast } from '@chakra-ui/react'
import { SubmitButton } from '../SubmitButton'
import { useAcceptInvitationMutation } from '../../features/sessionsApi'
import { useEffect } from 'react'

export const PartnerSignUpForm = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [acceptInvitation, { error }] = useAcceptInvitationMutation()
  const {
    data: { email },
    originalArgs: token
  } = useGetInvitation()
  const methods = useForm({
    defaultValues: {
      email,
      token,
      companyName: '',
      name: '',
      password: ''
    }
  })
  const { handleSubmit } = methods


  useEffect(() => {
    if (error) {
      toast({
        title: t('Something went wrong'),
        status: 'error',
        position: 'top',
        containerStyle: {
          position: 'absolute',
          maxWidth: 'unset',
          width: '100%',
          margin: '0'
        }
      })
    }
  }, [error, toast, t])

  return (
    <Box width="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(acceptInvitation)}>
          <InputField
            label={t('Your name')}
            name="name"
            inputProps={{
              autoComplete: 'name'
            }}
          />
          <InputField
            label={t('Your company’s name')}
            name="companyName"
            inputProps={{
              autoComplete: 'organization'
            }}
            required={t('Company’s name can’t be empty')}
          />
          <InputField
            label={t('Your email')}
            name="email"
            inputProps={{
              type: 'email',
              autoComplete: 'email',
              isReadOnly: true
            }}
          />
          <InputField
            label={t('Password')}
            name="password"
            inputProps={{
              type: 'password',
              autoComplete: 'new-password'
            }}
          />
          <Box pb="54px">
            <SubmitButton>{t('Create an account')}</SubmitButton>
          </Box>
        </form>
      </FormProvider>
    </Box>
  )
}

