import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useAddNewCourseMutation } from 'features/coursesApi'
import { courseObjectToFormData } from 'utils/forms'
import { addFormField, addToEpisodeSteps } from 'features/courses/courseBuilderSlice' // Import the action creator
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const setFormDataIds = (data, dispatch) => {
  // Set Course ID
  dispatch(addFormField({ key: 'id', value: data.id }))

  // Set Episode Step IDs
  data?.steps?.forEach(episodeStep =>
    episodeStep.step_type !== 1 // Exclude assignment ids
    && episodeStep.order !== 0 // Exclude intro episode
    && dispatch(addToEpisodeSteps({ key: (episodeStep.order - 1), value: { id: episodeStep.id } }))
  )

  // Set course slug
  dispatch(addFormField({ key: 'slug', value: data.slug }))
}

export const sendCourseData = async (
  courseData,
  userId,
  createCourse,
  dispatch,
  fileFormData,
  clearFileFormData,
  skipSettingIds = false
) => {
  try {
    if (courseData.name && userId) {
      const { episode_steps_add_episode, errors, ...restCourseData } = courseData
      const formDataObject = courseObjectToFormData({ course: restCourseData }, fileFormData)
      formDataObject.append('user_id', userId)

      clearFileFormData()
      const { data } = await createCourse({ courseData: formDataObject })
      !skipSettingIds && setFormDataIds(data, dispatch)
      console.log('Course successfully returned:', data)

    }
  } catch (error) {
    console.error('Error creating course:', error)
    clearFileFormData()
  }
}


const useSendCourseData = async (courseData, initialized) => {
  const dispatch = useDispatch()
  const [createCourse] = useAddNewCourseMutation()
  const { data: { id: userId } } = useGetCurrentUserQuery()
  const { clearFileFormData, fileFormData } = useCourseBuilderContext()

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      sendCourseData(courseData, userId, createCourse, dispatch, fileFormData, clearFileFormData)
    }
  }, [courseData, createCourse, dispatch, initialized, userId, fileFormData, clearFileFormData])
}

export default useSendCourseData
