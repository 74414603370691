import { FormControl, Input } from '@chakra-ui/react'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { useFormContext } from 'react-hook-form'
import { formStyles } from '../formStyles'

const InputField = ({ helperText, label, name, inputProps, width, fieldRequired, errorVal={}, paddingBottom }) => {
  const { sharedStyles, inputFieldStyles, formControlStyles, disabledStyleProp } = formStyles()
  const { formState: { errors } } = useFormContext()
  let courseBuilder = true

  if(Object.keys(errorVal).length === 0) {
    errorVal = errors
    courseBuilder = false
  }

  let adjustedInputProps = inputProps
  if (inputProps?.disabled) {
    // Remove placeholder when disabled
    const { placeholder, ...otherProps } = inputProps
    // Apply disabled styles
    adjustedInputProps = {
      ...otherProps,
      style: { ...otherProps.style, ...disabledStyleProp }
    }
  }

  return (
    <FormControl pb={paddingBottom || '40px'} isInvalid={!!errorVal[name]} isRequired={fieldRequired} {...formControlStyles}>
      <FieldHeader helperText={helperText} label={label} />
      <Input
        width={width || '100%'}
        {...adjustedInputProps}
        isInvalid={!!errorVal[name]}
        {...sharedStyles}
        {...inputFieldStyles}
      />
      <FormErrorDisplay courseBuilder={courseBuilder} name={name} errorVal={errorVal} />
    </FormControl>
  )
}

export default InputField
