import EventEmitter from 'events'

class ServiceEmitter {
  static eventEmitter = new EventEmitter()
  static eventListeners = {}

  // Static method to allow subscription to ServiceEmitter events
  static on(event, listener) {
    if (this.eventListeners[event] === listener) return
    this.eventListeners[event] = listener
    this.eventEmitter.on(event, listener)
  }

  // Static method to allow unsubscribing from ServiceEmitter events
  static off(event, listener) {
    if (this.eventListeners[event] !== listener) return
    delete this.eventListeners[event]
    this.eventEmitter.off(event, listener)
  }

  // Method to emit events
  static emit(event, data) {
    this.eventEmitter.emit(event, data)
  }
}

export default ServiceEmitter