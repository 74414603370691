import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { BgVideo } from '../BgVideo'

export const PageLayout = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Flex as="main"
      position="relative"
      fontFamily="Poppins"
      flexGrow="1"
      flexDirection="column"
      role="main"
    >
      <BgVideo
        src="https://assets.making-space.com/courses/screenwriting101/bg-video.mp4"
        aria-label={t('Video trailer shows clips from Making Spaces’s Directing and Screenwriting courses featuring Disabled artists and creators.')}
      />
      <Center
        flex="1"
      >
        <Box
          margin="54px 16px"
        >
          <Box
            bg={`url(${process.env.PUBLIC_URL}/tape-login.svg) no-repeat`}
            bgSize="100% 100%"
            width="100%"
            height="69px"
          >

          </Box>
          {children}
        </Box>
      </Center>
    </Flex>
  )
}
