import { AppLoadingState } from 'AppLoadingState'
import { useCourseId, useIsStepsMode, useSteps } from './hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ContinueWatching = () => {
  const courseId = useCourseId()
  const navigate = useNavigate()
  const isSteps = useIsStepsMode()
  const steps = useSteps()

  useEffect(() => {
    if (isSteps) {
      navigate(`/courses/${courseId}/steps/${steps.order[0]}`, { replace: true })
      return
    }

    navigate(`/courses/${courseId}/episodes/1`, { replace: true })
  }, [isSteps, navigate, courseId, steps])

  return (
    <AppLoadingState />
  )
}
