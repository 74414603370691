import { useTranslation } from 'i18n/TranslationContext'
import { Button } from '@chakra-ui/react'
import { useDestroySessionMutation } from 'features/sessionsApi'

const CancelButton = () => {
  const { t } = useTranslation()
  const [destroySession] = useDestroySessionMutation()

  const onClick = () => {
    const result = window.confirm(
      t('You are required to fill in your information in order to use Making Space.') + ' ' +
      t('If you cancel now you will be signed off. Please confirm that you want to cancel.')
    )

    if (!result) { return }
    destroySession()
  }

  return (
    <Button
      fontWeight="500"
      colorScheme="zettaWhite"
      onClick={onClick}
    >
      {t('Cancel')}
    </Button>
  )
}

export default CancelButton
