import { useTranslation } from 'i18n/TranslationContext'
import { Link, Route, Routes } from 'react-router-dom'
import { CoursePage } from './CoursePage'
import { EpisodePage } from './EpisodePage'
import { AppLoadingState } from '../AppLoadingState'
import { Header1 } from '../UnauthenticatedApp/Header1'
import { Box, Button, Center } from '@chakra-ui/react'
import { useEffect } from 'react'
import { commentsApi } from '../features/commentsApi'
import { useDispatch } from 'react-redux'
import { ContinueWatching } from './ContinueWatching'
import { useCurrentCourseQuery } from './CoursePage/useCurrentCourseQuery'
import { useCourseId } from './hooks'
import { CourseIdProvider } from '../CourseContext'
import { CoursePageContextProvider } from './CoursePage/CoursePageContext'
import { useGetCurrentUserQuery } from '../features/sessionsApi'
import { MainButton } from './CoursePage/MainButton'
import PlaybackEndProvider from 'CourseFeature/EpisodePage/context/PlaybackEndProvider'

const BackButton = () => {
  const { t } = useTranslation()
  const courseId = useCourseId()
  return (
    <Button
      as={Link}
      to={`/courses/dashboard/${courseId}`}
      size="lg"
      fontSize="20px"
      marginTop="24px"
      fontWeight="normal"
      colorScheme="zettaWhite"
      variant="solid"
      height="60px"
      width={['100%', '100%', 'unset']}
    >
      {t('Back to Course Profile')}
    </Button>
  )
}

const CourseFeatureContent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { data: { feature } } = useGetCurrentUserQuery()
  const isPartnerExperience = feature === 'partner_experience'
  const courseContentData = useCurrentCourseQuery()
  const { isLoading, isError } = courseContentData

  useEffect(
    () => () => {
      dispatch(commentsApi.util.resetApiState())
    }, [dispatch]
  )

  if (isLoading) {
    return <AppLoadingState />
  }

  if(isError) {
    return (
      <Center flexDirection="column">
        <Header1>{t('Not Found')}</Header1>
        <Box>
          <Button as={Link}
            to="/courses"
          >
            {t('Go back')}
          </Button>
        </Box>
      </Center>
    )
  }

  return (
    <CoursePageContextProvider
      value={{
        courseContentData,
        isPartnerExperience,
        mainCTAElement: <MainButton />,
        backDashboardButton: isPartnerExperience && <BackButton />,
      }}
    >
      <Routes>
        <Route
          index
          element={<CoursePage />}
        />
        <Route
          path="/episodes/:episodeNumber"
          element={<PlaybackEndProvider><EpisodePage /></PlaybackEndProvider>}
        />
        <Route
          path="/steps/:stepId"
          element={<PlaybackEndProvider><EpisodePage /></PlaybackEndProvider>}
        />
        <Route
          path="/continue_watching"
          element={<ContinueWatching />}
        />
      </Routes>
    </CoursePageContextProvider>
  )
}

export const CourseFeature = () => {
  const courseId = useCourseId()

  return (
    <CourseIdProvider courseId={courseId}>
      <CourseFeatureContent />
    </CourseIdProvider>
  )
}
