import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'i18n/TranslationContext'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { FormTemplate } from 'components/builder/FormTemplate'
import { CourseNameAndThumbnailForm } from 'components/courseBuilder/formContent'

export const CourseNameAndThumbnail = ({ nextStep, formForStep }) => {
  const { t } = useTranslation()
  const courseData = useSelector(state => state.courseBuilder || {})
  const initialized = useRef(false)

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)

  return (
    <FormTemplate
      formTitle={t('Course Name & Thumbnail')}
      FormContent={CourseNameAndThumbnailForm}
      formForStep={formForStep}
      nextStep={nextStep}
    >
      <CourseNameAndThumbnailForm />
    </FormTemplate>
  )
}
