import { createSelector } from 'reselect'

const episodeSelector = ({ episode }) => episode

export const chaptersSelector = createSelector(
  episodeSelector,
  ({ chapters }) => chapters
)

export const showPlayerCoverSelector = createSelector(
  episodeSelector,
  ({ showPlayerCover }) => showPlayerCover
)

export const showTranscriptColumnSelector = createSelector(
  episodeSelector,
  ({ isTranscriptVisible }) => isTranscriptVisible
)

export const playerLoadedSelector = createSelector(
  episodeSelector,
  ( { playerLoaded }) => playerLoaded
)
