import { configureStore } from '@reduxjs/toolkit'
import { episodeReducer } from './features/episode/slice'
import { beaconChatReducer } from './features/beacon/chatSlice'
import { sessionsApi } from './features/sessionsApi'
import { pageReducer } from './features/page/slice'
import { talentProfilesApi } from './features/talentProfilesApi'
import { apiSlice } from './features/apiSlice'
import { talentSearchReducer } from 'features/talent_search_drawer/talentSearchSlice'
import { courseBuilderSliceReducer } from 'features/courses/courseBuilderSlice'
import { opportunityBuilderSliceReducer } from 'features/opportunities/opportunityBuilderSlice'
import { partnerUxReducer } from 'features/partner_ux/partnerUxSlice'
import { talentQuizReducer } from 'features/quiz/talentQuizSlice'
import { awsApiSlice } from 'features/awsApiSlice'

export const store = configureStore({
  reducer: {
    episode: episodeReducer,
    page: pageReducer,
    talentQuiz: talentQuizReducer,
    talentSearch: talentSearchReducer,
    beaconChat: beaconChatReducer,
    courseBuilder: courseBuilderSliceReducer,
    opportunityBuilder: opportunityBuilderSliceReducer,
    partnerUx: partnerUxReducer,
    [talentProfilesApi.reducerPath]: talentProfilesApi.reducer,
    [sessionsApi.reducerPath]: sessionsApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [awsApiSlice.reducerPath]: awsApiSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(talentProfilesApi.middleware)
      .concat(sessionsApi.middleware)
      .concat(apiSlice.middleware)
      .concat(awsApiSlice.middleware),
})
