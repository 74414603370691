import { apiSlice } from './apiSlice'

export const commentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query({
      query: (episodePath) => `${episodePath}/comments`,
      providesTags: ['Comments']
    }),
    addComment: builder.mutation({
      query: ({ episodePath, ...restProps }) => ({
        url: `${episodePath}/comments`,
        method: 'POST',
        body: restProps
      }),
      invalidatesTags: (result, error) => error ? [] : ['Comments']
    }),
    deleteComment: builder.mutation({
      query: ({ episodePath, id }) => ({
        url: `${episodePath}/comments/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error) => error ? [] : ['Comments']
    })
  })
})

export const {
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useAddCommentMutation
} = commentsApi
