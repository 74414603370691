import { useState } from 'react'
import { Box, Flex, Input, FormControl, HStack, RadioGroup, useRadio, useRadioGroup } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import FieldHeader from '../FieldHeader'
import { formStyles } from '../formStyles'

const RadioCard = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props)
  const input = getInputProps()
  const id = `radio-button-${props.value}`

  return (
    <Box as="label" id={`${id}-label`} aria-label={props.label} {...props.register}>
      <Input aria-labelledby={`${id}-label`} {...input} disabled={props.isDisabled} />
      <Flex
        {...getRadioProps()}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        minWidth="150px"
        transition="all 0.1s"
        _checked={{
          bg: 'zettaBlue.600',
          color: 'white',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        _hover={{
          bg: 'zettaBlue.600',
          opacity: .65,
          color: 'white',
        }}
        px={5}
        py={3}
        aria-hidden="false"
      >
        {props.children}
      </Flex>
    </Box>
  )
}

const ButtonField = ({ helperText, label, name, inputProps, fieldRequired, radioValues, setValue, defaultValue }) => {
  const { formState: { errors }, register } = useFormContext()
  const { radioFieldStyles, formControlStyles } = formStyles()

  const [selectedValue, setSelectedValue] = useState(defaultValue.toString())

  const handleChange = (value) => {
    setSelectedValue(value)
    setValue && setValue(value)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    value: selectedValue,
    onChange: handleChange
  })

  return (
    <FormControl marginTop="24px" marginBottom="48px" isInvalid={!!errors[name]} isRequired={fieldRequired} {...formControlStyles}>
      <FieldHeader helperText={helperText} label={label} />
      <RadioGroup
        value={selectedValue}
        {...radioFieldStyles}
        {...inputProps}
      >
        <HStack {...getRootProps()} justifyContent="center" gap="10%" alignItems="stretch">
          {radioValues.map((item, index) => {
            return (
              <RadioCard
                key={index}
                isDisabled={inputProps.isDisabled}
                {...getRadioProps({ value: item.value.toString() })}
                register={register(name)}
              >
                {item.icon && <item.icon size="48" style={{ marginBottom: '10px' }} />}
                {item.label}
              </RadioCard>
            )
          })}
        </HStack>
      </RadioGroup>
    </FormControl>
  )
}

export default ButtonField
