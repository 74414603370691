import { Box } from '@chakra-ui/react'

const H3 = ({ children, ...restProps }) => (
  <Box as="h3"
    fontFamily="Spline Sans Mono"
    fontSize="20px"
    fontWeight="bold"
    lineHeight="21px"
    {...restProps}
  >
    {children}
  </Box>
)

export default H3
