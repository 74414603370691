import React from 'react'
import { RadioField } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const CourseVisibilityForm = ({ formDisabled = false }) => {
  const { t } = useTranslation()
  const radioValues = [
    { label: t('Public'), value: 'Public' },
    { label: t('Private'), value: 'Private' },
  ]
  const { helperTextVals } = useGetFormTextValues()
  const { visibilityRadioField, setVisibilityRadioField } = useCourseBuilderContext()
  return (
    <RadioField
      label={t('Course Visibility')}
      helperText={!formDisabled && helperTextVals.course_visibility}
      name="visibility"
      radioValues={radioValues}
      setValue={setVisibilityRadioField}
      defaultValue={visibilityRadioField}
      inputProps={{
        disabled: formDisabled,
        width: '20%',
        padding: '10px',
      }}
    />
  )
}

export default CourseVisibilityForm
