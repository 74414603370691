import { useTranslation } from 'i18n/TranslationContext'
import { Box } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const LogoLinkBase = ({ name, ...props }) => {
  const { t } = useTranslation()
  return (
    <Link to="/courses">
      <Box as="img"
        {...props}
        src={`${process.env.PUBLIC_URL}/${name}.png`}
        alt={`Making Space ${t('Home')}`}
      />
    </Link>
  )
}

export const LogoLink = () => (
  <LogoLinkBase
    height="60px"
    name="Logo"
  />
)

export const LogoLinkLight = () => (
  <LogoLinkBase
    height="60px"
    name="Logo_light"
  />
)

export const LogoLinkMobile = () => (
  <LogoLinkBase
    height="38px"
    name="Logo_mobile"
  />
)
