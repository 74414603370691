import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { PartnerOpportunityModal } from 'components'

const InactiveButton = ({ opportunity, status, onStatusUpdate }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const buttonText = status === 'paused' ? t('Close') : t('Mark as Inactive')
  const modalAction = status === 'paused' ? 'close' : 'inactive'

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        variant="solid"
        bgColor="white"
        color="purple.600"
        width={['100%', null, 'fit-content']}
        minWidth={[null, null, '220px']}
        fontWeight="normal"
        _hover={{
          bgColor: 'rgba(128, 90, 213, 0.1)',
        }}
      >
        {buttonText}
      </Button>
      <PartnerOpportunityModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        action={modalAction}
        opportunity={opportunity}
        onStatusUpdate={onStatusUpdate}
      />
    </>
  )
}

export default InactiveButton
