import { withTranslation } from 'react-i18next'
import React from 'react'
import { Notifier } from '@airbrake/browser'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
    this.airbrake = new Notifier({
      projectId: 512094,
      projectKey: '118d7af36d0837f54b7907f861f4facb',
      environment: process.env.NODE_ENV
    })
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true })
    // Send error to Airbrake unless in development
    if (process.env.NODE_ENV !== 'development') {
      this.airbrake.notify({
        error: error,
        params: { info: info }
      })
    }
  }

  render() {
    const { t } = this.props
    if (this.state.hasError) {
      return (
        <div>
          <h1>{t('Something went wrong.')}</h1>
          <button onClick={() => { window.location.reload() }}>{t('Reload the page')}</button>
        </div>
      )
    }
    return this.props.children
  }
}

export default withTranslation('common')(ErrorBoundary)
