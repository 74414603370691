import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useSignUpTrackParams = () => {
  const location = useLocation()

  const { course_id, source, partner_profile_id } = useMemo(() => {
    const queryParams = new URLSearchParams(location.search)
    const course_id = queryParams.get('c')
    const source = queryParams.get('s')
    const partner_profile_id = queryParams.get('p')

    return { course_id, source, partner_profile_id }
  }, [location.search])

  return { course_id, source, partner_profile_id }
}

export default useSignUpTrackParams