import { Button } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

export const SubmitButton = ({ children }) => {
  const { formState: { isSubmitting } } = useFormContext()

  return (
    <Button
      size="lg"
      width="100%"
      colorScheme="zettaBlue"
      fontWeight="500"
      variant="solid"
      isLoading={isSubmitting}
      type="submit"
    >
      {children}
    </Button>
  )
}
