import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import {
  Flex,
  Box,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import NumberField from './NumberField'

const MinMaxNumField = ({ formDisabled, numInputSettings, nameMin, nameMax, maxNum, minNum=0, label, symbol }) => {
  const { t } = useTranslation()
  const { watch, register, formState: { errors } } = useFormContext()

  const minValidationRules = {
    required: t('Salary Minimum can\'t be empty.'),
    min: { value: 1, message: t('Minimum {{label}} must be greater than {{minNum}}.', { minNum, label }) },
    max: {
      value: watch(nameMax) || maxNum,
      message: t('Minimum {{label}} cannot exceed maximum {{lowercaseLabel}}.',
        { label, lowercaseLabel: label.toLowerCase() })
    },
    validate: (value) => value > minNum || t('Minimum {{label}} must be greater than {{minNum}}.', { minNum, label })
  }

  const maxValidationRules = {
    required: t('Salary Maximum can\'t be empty.'),
    min: {
      value: Math.max(watch(nameMin) || minNum, minNum),
      message: t(
        'Maximum {{label}} must be greater than minimum {{lowercaseLabel}} and 0.',
        { label, lowercaseLabel: label.toLowerCase() }
      )
    },
    max: {
      value: maxNum,
      message: t('Maximum {{label}} cannot exceed {{maxNum}}.', { label, maxNum })
    },
    validate: (value) => value > minNum || t('Maximum {{label}} must be greater than {{minNum}}.', { minNum, label })
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} alignItems="center" gap="16px" mb="16px">
      <NumberField
        name={nameMin}
        isDisabled={formDisabled}
        min={minNum}
        max={watch(nameMax) || maxNum}
        placeholder={Number(50000)}
        symbol={symbol}
        register={register}
        numInputSettings={numInputSettings}
        validationRules={minValidationRules}
      />
      <Box display={errors[nameMin] || errors[nameMax] ? 'none' : 'inline'}>-</Box>
      <NumberField
        name={nameMax}
        isDisabled={formDisabled}
        min={Math.max(watch(nameMin) || minNum, minNum)}
        max={maxNum}
        placeholder={Number(65000)}
        symbol={symbol}
        register={register}
        numInputSettings={numInputSettings}
        validationRules={maxValidationRules}
      />
    </Flex>
  )
}

export default MinMaxNumField