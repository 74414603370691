import { apiSlice } from './apiSlice'

export const featureFlagsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlagEnabled: builder.query({
      query: (flagName) => ({
        url: '/feature_flags/enabled',
        method: 'POST',
        body: { flag_name: flagName },
      }),
    }),
  }),
})

export const { useGetFeatureFlagEnabledQuery } = featureFlagsApi