import { Tr } from '@chakra-ui/react'
import { AppLoadingState } from 'AppLoadingState'
import { PageOuterContainer, TableItemMap } from 'shared'
import { WelcomeBar } from '../../WelcomeBar'
import { ListHeader, ListBlock, OpportunitiesTable, TalentOpportunity } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useTalentOpportunities } from 'hooks/opportunities/useTalentOpportunities'

const ActiveOpportunities = () => {
  const { t } = useTranslation()
  const { data, talentUserId, isLoading, error } = useTalentOpportunities()

  if (isLoading || error || !data) {
    return (
      <AppLoadingState />
    )
  }

  const activeOpportunities = data.active || []

  if (activeOpportunities.length === 0) {
    return null
  }

  return (
    <ListBlock>
      <ListHeader as="h1">
        {t('My Opportunities')}
      </ListHeader>
      <OpportunitiesTable headings={[t('opportunity'), t('company name'), t('actions')]}>
        <TableItemMap
          collection={activeOpportunities}
          listQuery={{ data, isLoading: false, error: null }}
          emptyStateCopy=""
        >
          {(props) => (
            <Tr key={props.id}>
              <TalentOpportunity {...props} talentUserId={talentUserId} type="active" />
            </Tr>
          )}
        </TableItemMap>
      </OpportunitiesTable>
    </ListBlock>
  )
}

const AvailableOpportunities = () => {
  const { t } = useTranslation()
  const { data, talentUserId, isLoading, error } = useTalentOpportunities()

  if (isLoading || error || !data) {
    return (
      <AppLoadingState />
    )
  }

  const availableOpportunities = data.available || []

  return (
    <ListBlock>
      <ListHeader as="h1">
        {t('Available Opportunities')}
      </ListHeader>
      <OpportunitiesTable headings={[t('opportunity'), t('company name'), t('actions')]}>
        <TableItemMap
          collection={availableOpportunities}
          listQuery={{ data, isLoading: false, error: null }}
          emptyStateCopy={t(
            'Currently, there are no opportunities available, but they’ll be coming soon! In the meantime, feel free to explore our public courses.'
          )}
        >
          {(props) => (
            <Tr key={props.id}>
              <TalentOpportunity {...props} talentUserId={talentUserId} type="available" />
            </Tr>
          )}
        </TableItemMap>
      </OpportunitiesTable>
    </ListBlock>
  )
}

export const TalentPageContent = () => {
  return (
    <PageOuterContainer>
      <WelcomeBar />
      <ActiveOpportunities />
      <AvailableOpportunities />
    </PageOuterContainer>
  )
}
