export const QuoteSign = ({ color }) => (
  <svg width="70px" height="56px" aria-label="Quote">
    <use
      href={`${process.env.PUBLIC_URL}/quote.svg#img`}
      style={{
        '--color_fill': color
      }}
    />
  </svg>
)
