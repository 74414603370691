import { apiSlice } from './apiSlice'

export const coursesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listCourseForPartner: builder.query({
      query: (id) => `/courses?for_partner=${id}`,
      providesTags: ['CourseList']
    }),

    listCourses: builder.query({
      query: () => '/courses',
      providesTags: ['CourseList']
    }),

    loadCourse: builder.query({
      query: ({ courseId, userId, isPartner=false }) => `/courses/${courseId}?talent_user_id=${userId}${isPartner ? '&mode=partner' : ''}`,
      providesTags: ['CurrentCourse'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: { accessExpiration } } = await queryFulfilled

          if (typeof accessExpiration !== 'number') {
            console.error('Invalid access expiration value. Access rerequest function will not be scheduled.')
            return
          }

          const expiresIn = accessExpiration * 1000 - Date.now()
          setTimeout(
            () => { dispatch(coursesApi.util.invalidateTags(['CurrentCourse'])) },
            expiresIn
          )
        } catch (err) {
          if (err.error?.status === 404) {
            console.error('Course not found:', err)
            // Might dispatch some action here to handle a not found course
          } else {
            // Handle other errors or rethrow them
            console.error('An unknown error occurred:', err)
          }
        }
      },
    }),

    loadCourseStats: builder.query({
      query: (courseId) => `/courses/${courseId}?mode=partner`
    }),

    getCourseMediaUploaded: builder.query({
      query: (courseId) => `/courses/${courseId}/media_uploaded`,
    }),

    enrollToTheCourse: builder.mutation({
      query: ({ courseId, userId }) => ({
        url: `/courses/${courseId}/course_enrolment`,
        method: 'POST',
        body: { user_id: userId, course_enrolment: { course_id: courseId, user_id: userId } }
      }),
      invalidatesTags: (result, error) => (
        error ? [] : ['CourseList', 'CurrentCourse']
      )
    }),

    unEnrollFromTheCourse: builder.mutation({
      query: ({ courseId, userId }) => ({
        url: `/courses/${courseId}/course_enrolment`,
        method: 'DELETE',
        body: { user_id: userId, course_enrolment: { course_id: courseId, user_id: userId } }
      }),
      invalidatesTags: (result, error) => (
        error ? [] : ['CourseList', 'CurrentCourse']
      )
    }),

    showEnrollInfoForCourse: builder.query({
      query: (courseId) => `/courses/${courseId}/course_enrolment/show_for_course`,
      providesTags: ['CourseEnrolment']
    }),

    hasAudioDescriptionFile: builder.query({
      query: (keys) => `/courses/audio_description?keys=${keys}`,
    }),
    getCourse: builder.query({
      query: courseId => `/courses/${courseId}`,
      providesTags: ['CourseList']
    }),
    addNewCourse: builder.mutation({
      query: ({ courseData }) => ({
        url: '/courses',
        method: 'POST',
        body: courseData
      }),
      invalidatesTags: (result, error) => (
        error ? [] : ['CourseList', 'CurrentCourse']
      )
    }),
    submitCourse: builder.mutation({
      query: ({ courseId, userId }) => ({
        url: `/courses/${courseId}/submit`,
        method: 'POST',
        body: { user_id: userId, id: courseId }
      }),
      invalidatesTags: (result, error) => (
        error ? [] : ['CourseList', 'CurrentCourse']
      )
    }),
    updateEnrolmentStepStateQuiz: builder.mutation({
      query: ({ courseId, stepId }) => ({
        url: `/courses/${courseId}/course_enrolment/update_enrolment_step_state_quiz`,
        method: 'POST',
        body: { step_id: stepId }
      }),
      invalidatesTags: (result, error) => (
        error ? [] : ['CourseEnrolment']
      )
    }),
    updateEnrolmentStepStateEp: builder.mutation({
      query: ({ courseId, stepId }) => ({
        url: `/courses/${courseId}/course_enrolment/update_enrolment_step_state_episode`,
        method: 'POST',
        body: { step_id: stepId }
      }),
      invalidatesTags: (result, error) => (
        error ? [] : ['CourseEnrolment']
      )
    }),
    createAssignmentAccessGrant: builder.mutation({
      query: ({ courseId, stepId }) => ({
        url: `/courses/${courseId}/assignment_access_grant`,
        method: 'POST',
        body: { last_step_id: stepId }
      }),
      invalidatesTags: (result, error, { courseId }) => (
        error ? [] : [
          { type: 'AccessGrant', id: courseId },
          { type: 'AccessGrantExists', id: courseId }
        ]
      )
    }),
    getAssignmentGrantExists: builder.query({
      query: ({ courseId }) => `/courses/${courseId}/assignment_access_grant/exists`,
      providesTags: (result, error, { courseId }) => (
        error ? [] : [{ type: 'AccessGrantExists', id: courseId }]
      )
    }),
  })
})

export const {
  useListCourseForPartnerQuery,
  useListCoursesQuery,
  useLoadCourseQuery,
  useLoadCourseStatsQuery,
  useGetCourseMediaUploadedQuery,
  useEnrollToTheCourseMutation,
  useUnEnrollFromTheCourseMutation,
  useShowEnrollInfoForCourseQuery,
  useLazyShowEnrollInfoForCourseQuery,
  useHasAudioDescriptionFileQuery,
  useGetCourseQuery,
  useAddNewCourseMutation,
  useSubmitCourseMutation,
  useUpdateEnrolmentStepStateQuizMutation,
  useUpdateEnrolmentStepStateEpMutation,
  useCreateAssignmentAccessGrantMutation,
  useGetAssignmentGrantExistsQuery,
} = coursesApi
