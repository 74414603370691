import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Center,
  Flex
} from '@chakra-ui/react'
import { NoisyBackground } from '../shared/Layout'
import { TopicsContent } from './TopicsContent'
import { EpisodesContent } from './EpisodesContent'
import { useIsStepsMode } from '../../hooks'
import { H2 } from 'shared/typography'

const CourseContentBody = () => {
  const isStepsMode = useIsStepsMode()

  if (isStepsMode) {
    return <EpisodesContent />
  }

  return <TopicsContent />
}

export const CourseContentBlock = () => {
  const { t } = useTranslation()
  return (
    <Center
      position="relative"
      color="white"
      width="100%"
      pb="124px"
    >
      <NoisyBackground
        color="rgba(15, 32, 74, 1)"
        opacity="0.09"
      />
      <Box
        flex="1"
        maxWidth="1440px"
      >
        <Box pt="118px" ml="40px" mr="40px">
          <H2
            fontSize="54px"
            lineHeight="64px"
          >
            {t('Course content')}
          </H2>
          <Flex
            pt="24px"
          >
            <CourseContentBody />
          </Flex>
        </Box>
      </Box>
    </Center>
  )
}
