import React, { useRef } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { Body } from 'components'
import { FormTemplate } from 'components/builder/FormTemplate'
import { Button } from '@chakra-ui/react'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useSelector } from 'react-redux'
import { IntroAccessibilityFilesForm } from 'components/courseBuilder/formContent'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const IntroText = () => {
  const { Trans } = useTranslation()
  const { introTextVals } = useGetFormTextValues()
  return (
    <>
      <Body>
        {introTextVals.episode_accessibility_files}
      </Body>
      <Body>
        <Trans
          i18nKey="For questions regarding how to generate or obtain each of the below files, please refer to our FAQs or contact our team at <1>support@making-space.com</1>."
          components={{
            1: <Button
              fontSize="18px"
              fontWeight="400"
              fontFamily="Poppins"
              variant="link"
              colorScheme="zettaBlue"
              onClick={() => window.open('mailto:support@making-space.com')}
            />
          }}
        />
      </Body>
    </>
  )
}

export const IntroAccessibilityFiles = ({ formForStep, nextStep }) => {
  const { t } = useTranslation()
  const courseData = useSelector(state => state.courseBuilder || {})
  const initialized = useRef(false)
  const { isFileUploading } = useCourseBuilderContext()

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)

  return (
    <FormTemplate
      introTextComponent={IntroText}
      formTitle={t('Upload Accessibility Files')}
      nextStep={nextStep}
      formForStep={formForStep}
      buttonDisabled={isFileUploading}
      disabledNavTooltipText={t('Please wait for file to finish uploading before proceeding.')}
    >
      <IntroAccessibilityFilesForm />
    </FormTemplate>
  )
}
