import i18n from 'i18next'

const ablePlayerLabels = () => {
  const t = i18n.getFixedT(null, 'common')
  return {
    "audio": t('audio'),

    "video": t('video'),

    "playerHeading": t('Media player'),

    "faster": t('Faster'),

    "slower": t('Slower'),

    "play": t('Play'),

    "pause": t('Pause'),

    "stop": t('Stop'),

    "restart": t('Restart'),

    "prevChapter": t('Previous chapter'),

    "nextChapter": t('Next chapter'),

    "prevTrack": t('Previous track'),

    "nextTrack": t('Next track'),

    "rewind": t('Rewind'),

    "forward": t('Forward'),

    "captions": t('Captions'),

    "showCaptions": t('Show captions'),

    "hideCaptions": t('Hide captions'),

    "captionsOff": t('Captions off'),

    "showTranscript": t('Show transcript'),

    "hideTranscript": t('Hide transcript'),

    "turnOnDescriptions": t('Turn on audio descriptions'),

    "turnOffDescriptions": t('Turn off audio descriptions'),

    "chapters": t('Chapters'),

    "newChapter": t('New chapter'),

    "language": t('Language'),

    "sign": t('Sign language'),

    "ADsignDisclaimer": t('Sign language is turned off because audio description video is on'),

    "showSign": t('Show sign language'),

    "hideSign": t('Hide sign language'),

    "seekbarLabel": t('timeline'),

    "mute": t('Mute'),

    "unmute": t('Unmute'),

    "volume": t('Volume'),

    "volumeHelp": t('Click to access volume slider'),

    "volumeUpDown": t('Volume up down'),

    "volumeSliderClosed": t('Volume slider closed'),

    "preferences": t('Preferences'),

    "enterFullscreen": t('Enter full screen'),

    "exitFullscreen": t('Exit full screen'),

    "fullscreen": t('Full screen'),

    "speed": t('Speed'),

    "and": t('and'),

    "or": t('or'),

    "spacebar": t('spacebar'),

    "transcriptTitle": t('Transcript'),

    "lyricsTitle": t('Lyrics'),

    "autoScroll": t('Auto scroll'),

    "unknown": t('Unknown'),

    "statusPlaying": t('Playing'),

    "statusPaused": t('Paused'),

    "statusStopped": t('Stopped'),

    "statusWaiting": t('Waiting'),

    "statusBuffering": t('Buffering'),

    "statusUsingDesc": t('Using described version'),

    "statusLoadingDesc": t('Loading described version'),

    "statusUsingNoDesc": t('Using non-described version'),

    "statusLoadingNoDesc": t('Loading non-described version'),

    "statusLoadingNext": t('Loading next track'),

    "statusEnd": t('End of track'),

    "selectedTrack": t('Selected Track'),

    "alertDescribedVersion": t('Using the audio described version of this video'),

    "alertNonDescribedVersion": t('Using the non-described version of this video'),

    "fallbackError1": t('Sorry, your browser is unable to play this'),

    "fallbackError2": t('The following browsers are known to work with this media player'),

    "orHigher": t('or higher'),

    "prefMenuCaptions": t('Captions'),

    "prefMenuDescriptions": t('Descriptions'),

    "prefMenuKeyboard": t('Keyboard'),

    "prefMenuTranscript": t('Transcript'),

    "prefTitleCaptions": t('Captions Preferences'),

    "prefTitleDescriptions": t('Audio Description Preferences'),

    "prefTitleKeyboard": t('Keyboard Preferences'),

    "prefTitleTranscript": t('Transcript Preferences'),

    "prefIntroCaptions": t('The following preferences control how captions are displayed.'),

    "prefIntroDescription1": t('This media player supports audio description in two ways: '),

    "prefIntroDescription2": t('The current video has '),

    "prefIntroDescriptionNone": t('The current video has no audio description in either format.'),

    "prefIntroDescription3": t('Use the following form to set your preferences related to text-based audio description.'),

    "prefIntroDescription4": t('After you save your settings, audio description can be toggled on/off using the Description button.'),

    "prefIntroKeyboard1": t('The media player on this web page can be operated from anywhere on the page using keyboard shortcuts (see below for a list).'),

    "prefIntroKeyboard2": t('Modifier keys (Shift, Alt, and Control) can be assigned below.'),

    "prefIntroKeyboard3": t('NOTE: Some key combinations might conflict with keys used by your browser and/or other software applications. Try various combinations of modifier keys to find one that works for you.'),

    "prefIntroTranscript": t('The following preferences affect the interactive transcript.'),

    "prefCookieWarning": t('Saving your preferences requires cookies.'),

    "prefHeadingKeyboard1": t('Modifier keys used for shortcuts'),

    "prefHeadingKeyboard2": t('Current keyboard shortcuts'),

    "prefHeadingDescription": t('Audio description'),

    "prefHeadingTextDescription": t('Text-based audio description'),

    "prefHeadingCaptions": t('Captions'),

    "prefHeadingTranscript": t('Interactive Transcript'),

    "prefAltKey": t('Alt'),

    "prefCtrlKey": t('Control'),

    "prefShiftKey": t('Shift'),

    "escapeKey": t('Escape'),

    "escapeKeyFunction": t('Close current dialog or popup menu'),

    "prefDescFormat": t('Preferred format'),

    "prefDescFormatHelp": t('If both formats are avaialable, only one will be used.'),

    "prefDescFormatOption1": t('alternative described version of video'),

    "prefDescFormatOption1b": t('an alternative described version'),

    "prefDescFormatOption2": t('text-based description, announced by screen reader'),

    "prefDescFormatOption2b": t('text-based description'),

    "prefDescPause": t('Automatically pause video when description starts'),

    "prefDescVisible": t('Make description visible'),

    "prefDescVoice": t('Voice'),

    "prefDescRate": t('Rate'),

    "prefDescPitch": t('Pitch'),

    "prefDescPitch1": t('Very low'),

    "prefDescPitch2": t('Low'),

    "prefDescPitch3": t('Default'),

    "prefDescPitch4": t('High'),

    "prefDescPitch5": t('Very high'),

    "sampleDescriptionText": t('Adjust settings to hear this sample text.'),

    "prefHighlight": t('Highlight transcript as media plays'),

    "prefTabbable": t('Keyboard-enable transcript'),

    "prefCaptionsFont": t('Font'),

    "prefCaptionsColor": t('Text Color'),

    "prefCaptionsBGColor": t('Background'),

    "prefCaptionsSize": t('Font Size'),

    "prefCaptionsOpacity": t('Opacity'),

    "prefCaptionsStyle": t('Style'),

    "serif": t('serif'),

    "sans": t('sans-serif'),

    "cursive": t('cursive'),

    "fantasy": t('fantasy'),

    "monospace": t('monospace'),

    "white": t('white'),

    "yellow": t('yellow'),

    "green": t('green'),

    "cyan": t('cyan'),

    "blue": t('blue'),

    "magenta": t('magenta'),

    "red": t('red'),

    "black": t('black'),

    "transparent": t('transparent'),

    "solid": t('solid'),

    "captionsStylePopOn": t('Pop-on'),

    "captionsStyleRollUp": t('Roll-up'),

    "prefCaptionsPosition": t('Position'),

    "captionsPositionOverlay": t('Overlay'),

    "captionsPositionBelow": t('Below video'),

    "sampleCaptionText": t('Sample caption text'),

    "prefSuccess": t('Your changes have been saved.'),

    "prefNoChange": t('You didn’t make any changes.'),

    "help": t('Help'),

    "helpTitle": t('Help'),

    "save": t('Save'),

    "cancel": t('Cancel'),

    "ok": t('ok'),

    "done": t('Done'),

    "closeButtonLabel": t('Close dialog'),

    "windowButtonLabel": t('Window options'),

    "windowMove": t('Move'),

    "windowMoveAlert": t('Drag or use arrow keys to move the window; Enter to stop'),

    "windowResize": t('Resize'),

    "windowResizeHeading": t('Resize Window'),

    "windowResizeAlert": t('The window has been resized.'),

    "windowClose": t('Close'),

    "width": t('Width'),

    "height": t('Height'),

    "windowSendBack": t('Send to back'),

    "windowSendBackAlert": t('This window is now behind other objects on the page.'),

    "windowBringTop": t('Bring to front'),

    "windowBringTopAlert": t('This window is now in front of other objects on the page.'),

    "resultsSummary1": t('You searched for:'),

    "resultsSummary2": t('Found'),

    "resultsSummary3": t('matching items.'),

    "resultsSummary4": t('Click the time associated with any item to play the video from that point.'),

    "noResultsFound": t('No results found.'),

    "searchButtonLabel": t('Play at'),

    "hour": t('hour'),

    "minute": t('minute'),

    "second": t('second'),

    "hours": t('hours'),

    "minutes": t('minutes'),

    "seconds": t('seconds'),

    "enableKeyboardShortcuts": t('Enable keyboard shortcut'),
  }
}

export default ablePlayerLabels