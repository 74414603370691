import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useGetTalentProfileQuery } from 'features/talentProfilesApi'
import { useSelector } from 'react-redux'

export const useLoadTalentProfileFromStore = () => {
  const {
    data: { partner_profile: { id: partnerProfileId } }
  } = useGetCurrentUserQuery()
  const { currentTalentProfileId } = useSelector(state => state.partnerUx)

  return useGetTalentProfileQuery({
    id: currentTalentProfileId,
    forPartner: partnerProfileId
  })
}
