import { Button } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useEnrollToTheCourseMutation } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useEffect } from 'react'
import { useCurrentCourseQuery } from './useCurrentCourseQuery'
import { useCoursePageContext } from './CoursePageContext'
import { useContextCourseId } from '../../CourseContext'

const EnrollButton = ({ to, ...props }) => {
  const navigate = useNavigate()
  const [enrollToCourse, { isSuccess, isLoading }] = useEnrollToTheCourseMutation()
  const { data: { id: userId } } = useGetCurrentUserQuery()
  const courseId = useContextCourseId()

  useEffect(() => {
    if (!isSuccess) { return }

    navigate(to)
  }, [isSuccess, to, navigate])

  return (
    <Button
      onClick={() => {
        enrollToCourse({
          userId,
          courseId
        })
      }}
      isLoading={isLoading}
      {...props}
    />
  )
}

export const CourseStartButton = (props) => {
  const { data: { 'enrolled?': isEnrolled } } = useCurrentCourseQuery()
  const isPartnerExperience = useCoursePageContext()?.isPartnerExperience

  if (isEnrolled || isPartnerExperience) {
    return (
      <Button
        as={Link}
        {...props}
      />
    )
  }

  return (
    <EnrollButton {...props} />
  )
}
