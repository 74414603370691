import { Box, HStack, Heading, Text } from '@chakra-ui/react'
import { useCurrentEpisodeData, useGetStepById } from 'CourseFeature/hooks'
import { useSelector } from 'react-redux'

// TODO: Move these to Chakra vars
const DARK_BLUE = '#0F204B'

export const CourseDescription = ({ courseName }) => {
  const { name: episodeName } = useCurrentEpisodeData()
  const { quizStepId } = useSelector((state) => state.talentQuiz)
  const customStep = useGetStepById(quizStepId)
  const customName = customStep?.name

  const headerStyles = {
    fontFamily: 'Poppins',
    color: 'white',
    fontWeight: '800'
  }

  const courseEpisodeStyles = {
    ...headerStyles,
    as: 'h2',
    fontSize: ['20px', '28px'],
    textTransform: 'uppercase',
    marginBottom: '20px'
  }
  const textStyles = {
    color: 'white',
    fontSize: ['16px', '18px'],
    fontWeight: '400',
  }

  const textBoxStyles = {
    ml: 2,
    alignSelf: 'flex-start',
    minWidth: '200px'
  }

  return (
    <HStack spacing={4} align="center" bgColor={DARK_BLUE} p="28px" justifyContent="space-evenly">
      <HStack spacing={4} align="center">
        <img src={`${process.env.PUBLIC_URL}/tape-blue-vertical.png`} alt="Decorative blue tape" />
        <Box {...textBoxStyles}>
          {/* Placeholder for dynamic course name */}
          <Heading {...courseEpisodeStyles}>Course</Heading>
          <Text {...textStyles}>{courseName}</Text>
        </Box>
      </HStack>

      <HStack spacing={4} align="center">
        <img src={`${process.env.PUBLIC_URL}/tape-yellow-vertical.png`} alt="" />
        <Box {...textBoxStyles}>
          {/* Placeholder for dynamic episode name */}
          <Heading {...courseEpisodeStyles}>Episode</Heading>
          <Text {...textStyles}>{customName || episodeName}</Text>
        </Box>
      </HStack>
    </HStack>
  )
}