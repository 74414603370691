import React, { useRef } from 'react'
import { COURSE_BUILDER } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'
import { H3, Body } from 'components'
import { FormTemplate } from 'components/builder/FormTemplate'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { InputField, TextField, RadioField } from 'components'
import {
  Box,
} from '@chakra-ui/react'
import { AddSubtractButton, DeleteEpisodeBanner } from 'components'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { useFormContext } from 'react-hook-form'
import { addForm, removeForm } from 'utils/forms'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useSelector } from 'react-redux'
import { getCourseStepsContent } from 'CourseBuilder/getCourseStepsContent'

const AddAdditionalEpisode = ({ setShouldAddEpisode, shouldAddEpisode }) => {
  const { t } = useTranslation()

  const radioValues = [
    { label: t('Yes'), value: true },
    { label: t('No'), value: false },
  ]

  const episodeHelperText = t('You may have up to {{limitHumanReadable}} ({{limit}}) episodes in your course.',
    { limit: COURSE_BUILDER.EPISODE_LIMIT, limitHumanReadable: COURSE_BUILDER.EPISODE_LIMIT_HUMAN_READABLE })

  return (
    <Box className="add-episode">
      <RadioField
        label={<H3 mb="8">{t('Would you like to add another episode?')}</H3>}
        helperText={<Body>{episodeHelperText}</Body>}
        name="episode_steps_add_episode"
        radioValues={radioValues}
        defaultValue={shouldAddEpisode}
        setValue={setShouldAddEpisode}
        inputProps={{
          width: 'min-content',
          border: '1px solid black',
          padding: '10px',
        }}
        horizontalRadio
      />
    </Box>
  )
}

const FormContent = ({ index, questionNumber, quizCount, setQuizCount, episodeNum, unRegister }) => {
  const { t } = useTranslation()
  const { register, watch, trigger } = useFormContext() // retrieve all hook methods
  const { helperTextVals, placeholderTextVals } = useGetFormTextValues()

  // If at least one of the quiz fields is filled out,
  // then the rest of the fields for the quiz are required
  // and it signifies that the user is adding an optional quiz that needs to be completely filled out
  const quizFieldNames = {
    episode_quiz_question: `episode_${episodeNum}_quiz_question_${index}`,
    episode_quiz_answer: `episode_${episodeNum}_quiz_question_${index}_correct_answer`,
    episode_quiz_incorrect_answer_1: `episode_${episodeNum}_quiz_question_${index}_incorrect_answer_1`,
    episode_quiz_incorrect_answer_2: `episode_${episodeNum}_quiz_question_${index}_incorrect_answer_2`,
    episode_quiz_incorrect_answer_3: `episode_${episodeNum}_quiz_question_${index}_incorrect_answer_3`,
  }

  const watchQuizFieldNames = watch(Object.values(quizFieldNames))
  const isAnyFieldFilled = Object.values(watchQuizFieldNames).some(value => value !== '')

  React.useEffect(() => {
    // This is to avoid all the error states from being
    // shown right away when someone fills out at least one field.
    // But will update the register with the correct validation state.
    if (isAnyFieldFilled === false) {
      trigger()
    }
  }, [isAnyFieldFilled, trigger])

  return (
    <>
      <Box id={`episode-quiz-${questionNumber}`}>
        {quizCount.length > 1 && questionNumber !== 1 && ( // Conditionally render the button if quizCount is greater than 1
          <AddSubtractButton
            determineType="minus"
            textValue={t('Delete Question')}
            onClickAction={() => removeForm(index, quizCount, setQuizCount, unRegister, Object.values(quizFieldNames))}
            currentCount={quizCount}
            totalCount={3}
          />
        )}
        <TextField
          name={quizFieldNames.episode_quiz_question}
          textFieldProps={{
            label: t(`Question #${questionNumber}`),
            helperText: helperTextVals.episode_quiz_question,
          }}
          inputProps={{
            isRequired: isAnyFieldFilled,
            placeholder: placeholderTextVals.episode_quiz_question,
            ...register(
              quizFieldNames.episode_quiz_question, {
                required: isAnyFieldFilled ? t('Question can’t be empty.') : false,
                validate: value => value.length <= 250 || 'Max char 250'
              }
            )
          }}
        />
        <InputField
          label={t(`Correct Answer for Question #${questionNumber}`)}
          helperText={helperTextVals.episode_quiz_answer}
          name={quizFieldNames.episode_quiz_answer}
          fieldRequired
          inputProps={{
            placeholder: t('Cumulonimbus'),
            ...register(
              quizFieldNames.episode_quiz_answer, {
                required: isAnyFieldFilled ? t('Correct Answer can’t be empty.') : false,
                validate: value => value.length <= 250 || 'Max char 250'
              }
            )
          }}
        />
        <InputField
          label={t(`Incorrect Answers for Question #${questionNumber}`)}
          helperText={helperTextVals.episode_quiz_incorrect_answer}
          name={quizFieldNames.episode_quiz_incorrect_answer_1}
          fieldRequired
          paddingBottom="4"
          inputProps={{
            placeholder: t('Cirrostratus'),
            ...register(
              quizFieldNames.episode_quiz_incorrect_answer_1, {
                required: isAnyFieldFilled ? t('Incorrect Answer can’t be empty.') : false,
                validate: value => value.length <= 250 || 'Max char 250'
              }
            )
          }}
        />
        <InputField
          name={quizFieldNames.episode_quiz_incorrect_answer_2}
          paddingBottom="4"
          inputProps={{
            placeholder: t('Altocumulus'),
            ...register(
              quizFieldNames.episode_quiz_incorrect_answer_2, {
                required: isAnyFieldFilled ? t('Incorrect Answer can’t be empty.') : false,
                validate: value => value.length <= 250 || 'Max char 250'
              }
            )
          }}
        />
        <InputField
          name={quizFieldNames.episode_quiz_incorrect_answer_3}
          inputProps={{
            placeholder: t('Stratocumulus'),
            ...register(
              quizFieldNames.episode_quiz_incorrect_answer_3, {
                required: isAnyFieldFilled ? t('Incorrect Answer can’t be empty.') : false,
                validate: value => value.length <= 250 || 'Max char 250'
              }
            )
          }}
        />
      </Box>
    </>
  )
}

const IntroText = () => {
  const { introTextVals } = useGetFormTextValues()
  return (
    <>
      <Body>{introTextVals.episode_quiz_1}</Body>
      <Body>{introTextVals.episode_quiz_2}</Body>
    </>
  )
}

const updatedStepsContent = (shouldAddEpisode, setEpisodeCount, episodeCount) => {
  if (shouldAddEpisode) {
    setEpisodeCount(prevCount => prevCount + 1)
    return getCourseStepsContent(episodeCount + 1).courseStepsContent
  }
  return getCourseStepsContent(episodeCount).courseStepsContent
}

export const EpisodeQuiz = ({ nextStep, formForStep, episodeNum }) => {
  const { t } = useTranslation()
  const totalQuizCount = 3
  const { quizCount, setQuizCount, episodeCount, setEpisodeCount } = useCourseBuilderContext()
  const setQuizCountForEpisode = (newQuizCount) => {
    setQuizCount(prevQuizCount => ({
      ...prevQuizCount,
      [episodeNum]: newQuizCount
    }))
  }
  const [shouldAddEpisode, setShouldAddEpisode] = React.useState(false)
  const courseData = useSelector(state => state.courseBuilder || {})
  const initialized = useRef(false)
  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)
  return (
    <FormTemplate
      formTitle={t('Add Quiz')}
      introTextComponent={IntroText}
      formForStep={formForStep}
      nextStep={() => nextStep(
        () => updatedStepsContent(shouldAddEpisode, setEpisodeCount, episodeCount)
      )}
      bannerComponent={episodeNum > 1 && episodeNum === episodeCount && (() => <DeleteEpisodeBanner episodeNum={episodeNum} />)}
    >
      {quizCount[episodeNum].map((index, arrayIndex) => (
        <Box key={index} mb="10">
          <FormContent
            key={index}
            index={index}
            questionNumber={arrayIndex + 1}
            quizCount={quizCount[episodeNum]}
            setQuizCount={setQuizCountForEpisode}
            episodeNum={episodeNum}
            unRegister={formForStep.unregister}
          />
          {arrayIndex === quizCount[episodeNum].length - 1 && (
            <AddSubtractButton
              determineType="add"
              textValue={t('Add Another Question')}
              onClickAction={() => addForm(totalQuizCount, quizCount[episodeNum], setQuizCountForEpisode)}
              currentCount={quizCount[episodeNum]}
              totalCount={2}
              maxAmtTooltipText={t('Maximum questions added.')}
            />
          )}
        </Box>
      ))}
      {episodeCount < COURSE_BUILDER.EPISODE_LIMIT && <AddAdditionalEpisode setShouldAddEpisode={setShouldAddEpisode} shouldAddEpisode={shouldAddEpisode} />}
    </FormTemplate>
  )
}
