import React, { useState } from 'react'
import { Button, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useUpdateOpportunityApplicationMutation } from 'features/opportunitiesApi'
import { buttonStyles } from './buttonStyles'
import { Link } from 'react-router-dom'

const ApplyButton = ({ opportunity, ...props }) => {
  const [updateOpportunityApplication] = useUpdateOpportunityApplicationMutation()
  const { t } = useTranslation()
  const { primaryButtonStyle } = buttonStyles()
  const opportunityApplication = opportunity.opportunity_applications[0]
  const [isApplying, setIsApplying] = useState(false)

  const handleApply = async (status) => {
    if (status === 'applied') {
      setIsApplying(true)
      try {
        await updateOpportunityApplication({
          id: opportunityApplication.id,
          status
        }).unwrap()
      } catch (error) {
        console.error('Failed to apply for opportunity:', error)
      } finally {
        setIsApplying(false)
      }
    } else {
      try {
        await updateOpportunityApplication({
          id: opportunityApplication.id,
          status
        }).unwrap()
      } catch (error) {
        console.error('Failed to update opportunity application:', error)
      }
    }
  }

  const getButtonProps = () => {
    if (opportunityApplication?.status === 'applied') {
      return {
        buttonText: t('Under Review'),
        buttonStatus: '',
        buttonLink: '',
        isDisabled: true,
        showDismissButton: false
      }
    }
    if (opportunityApplication?.course_finished) {
      return {
        buttonText: t('Apply Now'),
        buttonStatus: 'applied',
        buttonLink: '',
        isDisabled: false,
        showDismissButton: true
      }
    }
    if (opportunityApplication?.status === 'created' && !opportunityApplication?.course_started) {
      return {
        buttonText: t('Begin'),
        buttonStatus: 'created',
        buttonLink: `/courses/${opportunity.course_id}`,
        isDisabled: false,
        showDismissButton: true
      }
    }
    if (opportunityApplication?.course_started && !opportunityApplication?.course_finished) {
      return {
        buttonText: t('Continue'),
        buttonStatus: 'started',
        buttonLink: `/courses/${opportunity.course_id}`,
        isDisabled: false,
        showDismissButton: true
      }
    }
    return {
      buttonText: '',
      buttonStatus: '',
      buttonLink: '',
      isDisabled: false,
      showDismissButton: true
    }
  }

  const { buttonText, buttonStatus, buttonLink, isDisabled } = getButtonProps()

  const ButtonComponent = (
    <Button
      onClick={() => handleApply(buttonStatus)}
      {...primaryButtonStyle}
      isDisabled={isDisabled || isApplying}
      {...props}
    >
      {isApplying ? <Spinner size="sm" /> : buttonText}
    </Button>
  )

  return buttonLink ? (
    <Link to={buttonLink}>
      {ButtonComponent}
    </Link>
  ) : (
    ButtonComponent
  )
}

export default ApplyButton
