import { useListOpportunitiesQuery, useListOpportunitiesForPartnerQuery } from 'features/opportunitiesApi'

export const useListQuery = (featureType, options = {}) => {
  const { talentUserId, partnerProfileId, skip } = options

  const {
    data: talentData,
    isLoading: talentLoading,
    error: talentError,
  } = useListOpportunitiesQuery(
    { talentUserId },
    {
      skip: featureType !== 'talent_experience' || skip,
    }
  )

  const {
    data: partnerData,
    isLoading: partnerLoading,
    error: partnerError,
  } = useListOpportunitiesForPartnerQuery(partnerProfileId, {
    skip: featureType !== 'partner_experience' || skip,
  })

  if (featureType === 'talent_experience') {
    return {
      data: talentData,
      isLoading: talentLoading,
      error: talentError,
    }
  } else if (featureType === 'partner_experience') {
    return {
      data: partnerData,
      isLoading: partnerLoading,
      error: partnerError,
    }
  } else {
    return {
      data: null,
      isLoading: false,
      error: new Error(`Unknown feature type: ${featureType}`),
    }
  }
}
