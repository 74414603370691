import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  playerLoaded: false,
  chapters: null,
  showPlayerCover: true,
  isTranscriptVisible: null,
  isPlaying: false,
}

const episodeSlice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    resetPlayerState: (state) => {
      state.showPlayerCover = true
      state.playerLoaded = false
      state.chapters = null
    },
    setPlayerLoaded: (state) => {
      state.playerLoaded = true
    },
    setTranscriptVisibility: (state, action) => {
      state.isTranscriptVisible = action.payload
    },
    setChapters: (state, action) => {
      state.chapters = action.payload
    },
    hidePlayerCover: (state) => {
      state.showPlayerCover = false
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload
    },
  },
})

export const {
  resetPlayerState,
  setChapters,
  hidePlayerCover,
  setPlayerLoaded,
  setTranscriptVisibility,
  setIsPlaying,
} = episodeSlice.actions

export const episodeReducer = episodeSlice.reducer
