import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setPageTitle } from './features/page/slice'

export const usePageTitle = (title) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle(title))
  }, [dispatch, title])
}
