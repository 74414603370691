import { useTranslation } from 'i18n/TranslationContext'
import { PageTitle } from 'shared/TalentsLayout'
import { PageOuterContainer } from 'components'

import { Flex } from '@chakra-ui/react'
import { ProfileEditForm } from './ProfileEditForm'
import { usePageTitle } from '../../usePageTitle'

export const EditProfile = () => {
  const { t } = useTranslation()
  usePageTitle(t('Edit your profile'))

  const locationPath = window.location.pathname
  const isPartner = locationPath.includes('partner')

  return (
    <PageOuterContainer {...(isPartner && { greenBg: 'true' })}>
      <Flex
        justifyContent={['center', null, 'space-between']}
        paddingBottom={['44px', null, '50px']}
      >
        <PageTitle
          as="h1"
          {...(isPartner && { color: 'black' })}
        >
          {t('Edit your profile')}
        </PageTitle>
      </Flex>
      <ProfileEditForm />
    </PageOuterContainer>
  )
}
