import { useEffect, useState } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Flex } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { HiOutlinePause, HiOutlinePlay } from 'react-icons/hi'

const useBgVideoPlaying = () => {
  const [isPaused, setIsPaused] = useState(null)

  useEffect(() => {
    const videoNode = document.getElementById('bgVideo')
    setIsPaused(videoNode.paused)

    const playing = () => { setIsPaused(false) }
    const pause = () => { setIsPaused(true) }

    videoNode.addEventListener('playing', playing)
    videoNode.addEventListener('pause', pause)

    return () => {
      videoNode.removeEventListener('pause', pause)
      videoNode.removeEventListener('playing', playing)
    }
  }, [])

  return isPaused
}

export const BgVideo = (props) => (
  <Flex
    bg="black"
    position="absolute"
    zIndex={-10}
    width="100%"
    height="100%"
    overflow="hidden"
  >
    <Box as="video"
      id="bgVideo"
      tabIndex="0"
      objectFit="cover"
      objectPosition="center top"
      autoPlay={localStorage.getItem('bgVideoState') !== 'paused'}
      loop
      muted
      width="100%"
      playsInline
      {...props}
    />
  </Flex>
)


const pauseBgVideo = () => {
  document.getElementById('bgVideo').pause()
  localStorage.setItem('bgVideoState', 'paused')
}
const resumeBgVideo = () => {
  document.getElementById('bgVideo').play()
  localStorage.setItem('bgVideoState', 'playing')
}

const BgVideoControlButtonBase = ({ onClick, label, ariaLabel, icon }) => (
  <Button
    size="lg"
    ml="144px"
    fontSize="20px"
    fontWeight="normal"
    colorScheme="zettaPlayback"
    variant="ghost"
    onClick={onClick}
    aria-label={ariaLabel}
  >
    <Icon
      mr={3}
      as={icon}
    />
    {label}
  </Button>
)

export const BgVideoControlButton = () => {
  const isPaused = useBgVideoPlaying()
  const { t } = useTranslation()

  if (isPaused) {
    return (
      <BgVideoControlButtonBase
        onClick={resumeBgVideo}
        label={t('Resume')}
        icon={HiOutlinePlay}
        ariaLabel={t('Resume background video')}
      />
    )
  }

  return (
    <BgVideoControlButtonBase
      onClick={pauseBgVideo}
      label={t('Pause')}
      icon={HiOutlinePause}
      ariaLabel={t('Pause background video')}
    />
  )
}
