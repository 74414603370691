import React, { forwardRef, useCallback, useId } from 'react'
import { Box, Input, List, ListItem, Text, Popover, PopoverContent, PopoverAnchor, FormControl } from '@chakra-ui/react'
import { useFormContext, Controller } from 'react-hook-form'
import Downshift from 'downshift'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { formStyles } from '../formStyles'

const SearchableSelect = forwardRef(({
  name,
  control,
  filteredOptions,
  allOptions,
  label,
  helperText,
  placeholder,
  isDisabled,
  fieldRequired,
  onInputChange,
  onChange,
  errorVal = {},
  infoMessage,
}, ref) => {
  const { sharedStyles, formControlStyles, inputFieldStyles } = formStyles()
  const { setValue } = useFormContext()
  const listboxId = useId()

  const getLabelForValue = useCallback((value) => {
    const option = allOptions.find(opt => opt.value === value)
    return option && option.label
  }, [allOptions])

  const handleChange = useCallback((selectedItem) => {
    const value = selectedItem ? selectedItem.value : ''
    setValue(name, value)
    onChange && onChange(value)
  }, [setValue, name, onChange])

  const renderListItem = useCallback((item, index, highlightedIndex, selectedItem) => (
    <ListItem
      key={item.value}
      padding="8px 24px"
      backgroundColor={highlightedIndex === index ? 'lightgray' : 'white'}
      fontWeight={selectedItem === item ? 'bold' : 'normal'}
      _hover={{ bg: 'gray.100' }}
      cursor="pointer"
    >
      <Text>{item.label}</Text>
    </ListItem>
  ), [])

  return (
    <FormControl marginTop="24px" marginBottom="48px" isInvalid={!!errorVal[name]} isRequired={fieldRequired} {...formControlStyles}>
      <FieldHeader helperText={helperText} label={label} />
      <Downshift
        onChange={handleChange}
        itemToString={(item) => (item ? item.label : '')}
        onInputValueChange={onInputChange}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getRootProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          openMenu,
          inputValue,
        }) => (
          <Box {...getRootProps()} position="relative" className="relative-box">
            <Popover
              isOpen={isOpen}
              autoFocus={false}
              matchWidth
            >
              <PopoverAnchor>
                <Box>
                  <Controller
                    name={name}
                    control={control}
                    rules={{ required: fieldRequired }}
                    render={({ field }) => (
                      <Input
                        {...getInputProps({
                          ...field,
                          onChange: (e) => {
                            field.onChange(e)
                            onInputChange(e.target.value)
                          },
                          onFocus: openMenu,
                          value: inputValue || getLabelForValue(field.value) || '',
                        })}
                        placeholder={placeholder}
                        isDisabled={isDisabled}
                        {...inputFieldStyles}
                        {...sharedStyles}
                        ref={ref}
                        role="combobox"
                        aria-controls={listboxId}
                        aria-expanded={isOpen}
                        aria-haspopup="listbox"
                        aria-autocomplete="list"
                        borderColor={isDisabled && '#d3d3d3'}
                      />
                    )}
                  />
                </Box>
              </PopoverAnchor>
              {infoMessage && <Text mt="4px">{infoMessage}</Text>}
              <PopoverContent width="100%" zIndex={1000} position="relative" inset="0">
                <List
                  id={listboxId}
                  {...getMenuProps()}
                  padding="16px 0"
                  fontSize="18px"
                  borderRadius="4px"
                  boxShadow="0px 4px 11px 4px rgba(0, 0, 0, 0.11)"
                  bg="white"
                  role="listbox"
                  aria-label={`${label} options`}
                >
                  {isOpen && filteredOptions.map((item, index) => (
                    <Box key={item.value} {...getItemProps({ item, index })} role="option">
                      {renderListItem(item, index, highlightedIndex, selectedItem)}
                    </Box>
                  ))}
                </List>
              </PopoverContent>
            </Popover>
          </Box>
        )}
      </Downshift>
      <FormErrorDisplay name={name} errorVal={errorVal} />
    </FormControl>
  )
})

SearchableSelect.displayName = 'SearchableSelect'

export default SearchableSelect
