import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { useCourseData } from '../CourseData'
import { NoisyBackground } from './shared/Layout'
import { CourseImageSet } from './CourseImageSet'
import { CourseAuthor } from './CourseAuthor'

const AboutThisCourse = () => {
  const { about } = useCourseData()
  const { t } = useTranslation()

  return (
    <Box as="section" color="#0F204A">
      <Center
        pt={['42px', null, null, '96px']}
        pb={[ '54px', null, null, '100px']}
      >
        <Box
          ml={['0', '32px']}
          mr={['0', '20px']}
          pl={[ '24px', null, '64px']}
          pr={[ '24px', null, '64px']}
          pb="80px"
          position="relative"
        >
          <Box
            position="absolute"
            top="0"
            left="0"
          >
            <img src={`${process.env.PUBLIC_URL}/corner-left.svg`} alt="" />
          </Box>
          <Box
            position="absolute"
            bottom="0"
            right="0"
          >
            <img src={`${process.env.PUBLIC_URL}/corner-right.svg`} alt="" />
          </Box>

          <Box
            as="h3"
            pt="98px" pb="24px"
            fontSize="22px"
            fontWeight="bold"
            fontFamily="Spline Sans Mono"
            textTransform="uppercase"
            textAlign={['center', null, null, 'left']}
          >
            {t('About this course')}
          </Box>
          <Box
            maxWidth="950px"
            textAlign={['center', null, null, 'left']}
            fontSize={['22px', null, null, '28px']}
            fontWeight="500"
            fontFamily="Poppins"
          >
            {about}
          </Box>
        </Box>
      </Center>
    </Box>
  )
}

const authorsHoverColors = [
  '#5000E8',
  '#9EFF00',
  '#FF7D19'
]

export const CourseAuthorsList = ({ taughtBy }) => {

  return taughtBy.map((author, index) => (
    <li key={author.name}>
      <CourseAuthor
        author={author}
        hoverColor={authorsHoverColors[index]}
      />
    </li>
  ))
}

export const CourseAuthorsBlock = ({ taughtBy }) => {
  const { t } = useTranslation()
  return (
    <Box
      as="section"
      color="#0F204A"
      position="relative"
    >
      <NoisyBackground
        color="#E8E1DE"
        opacity="0.05"
      />
      <Center
        pt="120px"
        flexDirection="column"
      >
        <Box
          as="h2"
          id="taught-by-disabled-professionals"
          maxWidth="850px"
          fontFamily="Spline Sans Mono"
          fontWeight="bold"
          fontSize={['44px', '54px']}
          textTransform="uppercase"
          textAlign="center"
        >
          {t('Meet your teachers')}
        </Box>
        <Flex
          as="ul"
          aria-labelledby="taught-by-disabled-professionals"
          mt={['0', '20px']}
          pb="122px"
          flexDirection={['column', 'row']}
          flexWrap={['nowrap', 'wrap']}
          justifyContent="space-around"
          maxWidth="1110px"
          width="100%"
        >
          <CourseAuthorsList taughtBy={taughtBy} />
        </Flex>
      </Center>
    </Box>
  )
}

const quoteColors = [
  '#FF7D19',
]

const QuoteSign = ({ color }) => (
  <svg width="70px" height="56px" aria-label="Quote">
    <use
      href={`${process.env.PUBLIC_URL}/quote.svg#img`}
      style={{
        '--color_fill': color
      }}
    />
  </svg>
)

const Review = ({ review: { name, text, photo, photoAltText }, index }) => (
  <>
    <Center>
      <QuoteSign color={quoteColors[index]} />
    </Center>
    <Box
      pt="26px" pb="48px"
      textAlign="center"
    >
      {text}
    </Box>
    <Center>
      <Box
        pr="16px"
        fontSize={['24px', '30px']}
      >
        {name}
      </Box>
      <Box
        borderRadius="50%"
        overflow="hidden"
      >
        <CourseImageSet
          photoSet={photo}
          alt={photoAltText}
        />
      </Box>
    </Center>
  </>
)

const Reviews = () => {
  const { reviews } = useCourseData()

  return reviews.map((review, index) => (
    <Review key={review.text} review={review} index={index} />
  ))
}

const ReviewsBlock = () => {
  const { t } = useTranslation()
  return (
    <Box as="section" position="relative" aria-label={t('Reviews of the course')}>
      <NoisyBackground
        color="#19284E"
        opacity="0.05"
      />
      <Center>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridTemplateRows="auto 1fr auto"
          columnGap="56px"
          pt="120px" pb="116px"
          maxWidth="860px"
          width="100%"
          color="white"
          fontFamily="Poppins"
          fontSize={['22px', '30px']}
        >
          <Reviews />
        </Box>
      </Center>
    </Box>
  )
}

export const CourseDetails = () => {
  const { taught_by: taughtBy } = useCourseData()
  return (
    <>
      <AboutThisCourse />
      <CourseAuthorsBlock taughtBy={taughtBy} />
      <ReviewsBlock />
    </>
  )
}
