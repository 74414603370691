import { useCallback, useEffect, useRef } from 'react'
import { useTrackWatchTimeMutation } from 'features/stepsApi'

const TRANSMIT_TRACK_INTERVAL_SECS = 30

const useWatchTimeTracker = (courseId, stepId, userId) => {
  const [trackWatchTime] = useTrackWatchTimeMutation()
  const hasSentLocalStorage = useRef(false)
  const lastTrackedSecond = useRef(0)

  const getLocalStorageItem = (key) => {
    const value = localStorage.getItem(key)
    return value ? parseInt(value, 10) : null
  }

  const removeLocalStorageItems = (keys) => {
    keys.forEach((key) => localStorage.removeItem(key))
  }

  const setLocalStorageItems = (items) => {
    items.forEach(([key, value]) => localStorage.setItem(key, value))
  }

  const localStorageKeys = {
    watchedTime: `watchedTimeSecs_${courseId}_${stepId}_${userId}`,
    totalTime: `totalTimeSecs_${courseId}_${stepId}_${userId}`,
  }

  const handleTrackWatchTime = useCallback(async (watchedTimeSecs, totalTimeSecs) => {
    const result = await trackWatchTime({
      courseId,
      stepId,
      timeInSeconds: watchedTimeSecs,
      stepTotalTime: totalTimeSecs,
    })

    if (result?.data) {
      console.log('Tracking data sent successfully:', result.data)
      removeLocalStorageItems([localStorageKeys.watchedTime, localStorageKeys.totalTime])
    }
  }, [courseId, stepId, trackWatchTime, localStorageKeys.watchedTime, localStorageKeys.totalTime])

  // Retrieve lastTrackedSecond from localStorage if it exists and send the information
  useEffect(() => {
    const storedWatchedTimeSecs = getLocalStorageItem(localStorageKeys.watchedTime)
    const storedTotalTimeSecs = getLocalStorageItem(localStorageKeys.totalTime)
    const canSendBackupData = storedWatchedTimeSecs && storedTotalTimeSecs && !hasSentLocalStorage.current

    if (canSendBackupData) {
      hasSentLocalStorage.current = true
      handleTrackWatchTime(storedWatchedTimeSecs, storedTotalTimeSecs)

      console.log('Backup tracking data sent')
    }
  }, [courseId, stepId, userId, trackWatchTime, localStorageKeys.watchedTime, localStorageKeys.totalTime, handleTrackWatchTime])

  const trackVideoTime = async (totalTimeSecs, watchedTimeSecs) => {
    if ( watchedTimeSecs === 0 ) { return }

    const readyToSend = watchedTimeSecs % TRANSMIT_TRACK_INTERVAL_SECS === 0
    const videoEnded = totalTimeSecs === watchedTimeSecs
    const intervalSend = readyToSend && watchedTimeSecs !== lastTrackedSecond.current

    if (intervalSend || videoEnded) {
      lastTrackedSecond.current = watchedTimeSecs

      // Store the updated lastTrackedSecond in localStorage
      if(!videoEnded) {
        setLocalStorageItems([
          [localStorageKeys.watchedTime, watchedTimeSecs],
          [localStorageKeys.totalTime, totalTimeSecs]
        ])
      }

      console.log('Sending tracking data of watched time: ', watchedTimeSecs, ' secs')

      await handleTrackWatchTime(watchedTimeSecs, totalTimeSecs)
    }
  }

  return trackVideoTime
}

export default useWatchTimeTracker
