import React from 'react'
import {
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'
import FormErrorDisplay from 'components/forms/FormErrorDisplay'
import { useFormContext } from 'react-hook-form'

const NumberField = ({
  name,
  isDisabled,
  min,
  max,
  placeholder,
  symbol,
  register,
  numInputSettings,
  validationRules
}) => {
  const { formState: { errors } } = useFormContext()

  return (
    <InputGroup width="auto" size="lg" display="flex" flexWrap="wrap">
      {symbol === 'USD' && <InputLeftAddon>$</InputLeftAddon>}
      <NumberInput
        name={name}
        isDisabled={isDisabled}
        min={min}
        max={max}
        {...numInputSettings}
      >
        <NumberInputField
          placeholder={placeholder}
          borderLeftRadius={symbol === 'USD' ? '0px' : undefined}
          {...register(name, validationRules)}
        />
      </NumberInput>
      {errors[name] && <FormErrorDisplay name={name} errorVal={errors} flex="1 1 100%" />}
    </InputGroup>
  )
}

export default NumberField
