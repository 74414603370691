import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Show } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { HiOutlineRectangleGroup } from 'react-icons/hi2'

const PosterImage = ({ poster }) => (
  poster ?
    <img
      style={{
        height: '96px',
        objectFit: 'contain'
      }}
      src={poster}
      alt=""
    />
    :
    <Box backgroundColor="gray.50" borderRadius="8px">
      <HiOutlineRectangleGroup
        size="96"
        color="#0f214ba8"
        style={{ margin: 'auto' }}
      />
    </Box>
)

const OpportunityPoster = ({ id, name, poster }) => {
  const { t } = useTranslation()
  return (
    <>
      <Show above="md">
        <Box marginRight="16px" flexShrink="0" flexBasis="146px">
          <Link
            to={`/opportunities/${id}/applications`}
            title={t('View opportunity details for "{{name}}" course', { name })}
          >
            <PosterImage poster={poster} />
          </Link>
        </Box>
      </Show>
      <Show below="767px">
        <Center flexShrink="0" marginRight="8px" flexBasis="146px">
          <PosterImage poster={poster} />
        </Center>
      </Show>
    </>
  )
}

export default OpportunityPoster
