import { Route, Routes, Navigate } from 'react-router-dom'
import { OpportunityList } from './OpportunityList'
import { OpportunityPage } from './OpportunityPage'
import { OpportunityApplicationList } from './OpportunityApplicationList'
import { FEATURE_FLAGS } from 'constants'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { AppLoadingState } from '../AppLoadingState'

export const OpportunityFeature = () => {
  const { isEnabled: opportunitiesEnabled, isLoading: isOpportunitiesLoading } = useFeatureFlags(FEATURE_FLAGS.OPPORTUNITIES)

  if (isOpportunitiesLoading) {
    return <AppLoadingState />
  }

  if (!opportunitiesEnabled) {
    return <Navigate to="/courses" replace />
  }

  return (
    <Routes>
      <Route
        index
        element={<OpportunityList />}
      />
      <Route
        path=":opportunityId"
        element={<OpportunityPage />}
      />
      <Route
        path=":opportunityId/applications/*"
        element={<OpportunityApplicationList />}
      />
    </Routes>
  )
}
