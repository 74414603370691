import { Box } from '@chakra-ui/react'
import { AnswerOptions } from 'components/talent/quiz/modal-elements/AnswerOptions'
import { SystemBubble, UserBubble } from 'components/talent/quiz/modal-elements/bubbles'
import { TALENT_QUIZ_MODE } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'
import { useSelector } from 'react-redux'
import { formatAsOrdinal } from 'utils/string'

const getQuestionStats = (question, t) => {
  const attempts = question.talent_answer_round?.attempts || []
  const correctAttempt = attempts.find((attempt) => attempt.correct)

  const attemptNumber = attempts.findIndex((attempt) => attempt.correct) + 1
  const numberWithOrdinal = formatAsOrdinal(attemptNumber)
  return t('You answered this question correctly on the {{numberWithOrdinal}} try.<br/><br/>The correct answer was "<strong>{{correctAnswer}}</strong>".',
    { numberWithOrdinal, correctAnswer: correctAttempt.attempted_answer })
}

export const QuestionAnswerOptions = ({ question }) => {
  const { quizMode } = useSelector((state) => state.talentQuiz)
  const { t } = useTranslation()

  const isReviewMode = quizMode === TALENT_QUIZ_MODE.REVIEW
  const options = <AnswerOptions question={question} />
  const questionContent = isReviewMode ?
    `<strong>${question.content}</strong><br/><br/>${getQuestionStats(question, t)}`
    : `<strong>${question.content}</strong>`

  return (
    <Box key={question.id} className="quiz-question" mb="30px">
      {/* Render question */}
      <SystemBubble message={{ html: questionContent }} />

      {/* Render answers */}
      <div className="answer-options">
        <UserBubble message={{ component: options }} />
      </div>
    </Box>
  )
}