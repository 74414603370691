import { useTranslation } from 'i18n/TranslationContext'
import { Tr } from '@chakra-ui/react'
import { usePageTitle } from '../usePageTitle'
import { useListCoursesQuery } from 'features/coursesApi'
import { AppLoadingState } from '../AppLoadingState'
import { CourseAvailable, CourseEnrolled } from './CourseItems'
import { PageOuterContainer } from '../shared/TalentsLayout'
import { TableWrapper } from '../shared/TableWrapper'
import { WelcomeBar } from '../WelcomeBar'
import { ListHeader, ListBlock } from '../components'
import { TableItemMap } from '../shared/TableItemMap'

const CourseTable = ({ children }) => {
  const { t } = useTranslation()
  return (
    <TableWrapper
      headings={[
        t('course'),
        t('episodes'),
        t('actions')
      ]}
    >
      {children}
    </TableWrapper>
  )
}

const AvailableCoursesList = () => {
  const { t } = useTranslation()
  return (
    <CourseTable>
      <TableItemMap
        collection={(useListCoursesQuery().data || {}).available}
        listQuery={useListCoursesQuery()}
        emptyStateCopy={t('You don\'t have any courses in this list yet')}
      >
        {
          ({ id, episodes_count: episodesCount, ...restProps }) => (
            <Tr key={id}>
              <CourseAvailable
                id={id}
                episodesCount={episodesCount}
                {...restProps}
              />
            </Tr>
          )
        }
      </TableItemMap>
    </CourseTable>
  )
}

const MyCoursesList = () => {
  const { t } = useTranslation()
  return (
    <CourseTable>
      <TableItemMap
        collection={(useListCoursesQuery().data || {}).enrolled}
        listQuery={useListCoursesQuery()}
        emptyStateCopy={t('You don\'t have any courses in this list yet')}
      >
        {
          ({ id, episodes_count: episodesCount, ...restProps }) => (
            <Tr key={id}>
              <CourseEnrolled
                id={id}
                episodesCount={episodesCount}
                {...restProps}
              />
            </Tr>
          )
        }
      </TableItemMap>
    </CourseTable>
  )
}

const PageContent = () => {
  const { t } = useTranslation()
  const { isLoading } = useListCoursesQuery()

  usePageTitle(t('My Courses'))

  if (isLoading) { return <AppLoadingState /> }

  return (
    <PageOuterContainer>
      <WelcomeBar />
      <ListBlock>
        <ListHeader as="h1">
          {t('My courses')}
        </ListHeader>
        <MyCoursesList />
      </ListBlock>
      <ListBlock>
        <ListHeader as="h2">
          {t('Available courses for you')}
        </ListHeader>
        <AvailableCoursesList />
      </ListBlock>
    </PageOuterContainer>
  )
}

export const CourseList = () => (
  <PageContent />
)

