import { Box } from '@chakra-ui/react'

import QuizModal from 'components/talent/quiz/QuizModal'
import SectionCompleteQuizModal from 'components/talent/quiz/sectionCompleteModal/SectionCompleteQuizModal'
import { useGetStepById, useStepId } from 'CourseFeature/hooks'
import { setModalIsOpen } from 'features/quiz/talentQuizSlice'
import { useDispatch, useSelector } from 'react-redux'

export const QuizModals = ({ isQuizSectionCompleteOpen, onQuizSectionCompleteClose }) => {
  const dispatch = useDispatch()
  const { modalIsOpen, quizStepId } = useSelector((state) => state.talentQuiz)
  const urlStepId = useStepId()
  const step = useGetStepById(quizStepId || urlStepId)

  const handleSectionCompleteClose = (evt, showQuiz = false) => {
    onQuizSectionCompleteClose()
    if ( showQuiz ) dispatch(setModalIsOpen(true))
  }

  const handleCloseQuizModal = () => {
    dispatch(setModalIsOpen(false))
  }

  return step && (
    <Box className="quiz-modals">
      <SectionCompleteQuizModal isOpen={isQuizSectionCompleteOpen} onClose={handleSectionCompleteClose} />
      <QuizModal isOpen={modalIsOpen} onClose={handleCloseQuizModal} step={step} />
    </Box>
  )
}