import React from 'react'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { addForm } from 'utils/forms'
import {
  Box,
  Flex,
  FormHelperText,
  FormControl,
} from '@chakra-ui/react'
import { TextEditorField, SupplementalMaterial, AddSubtractButton, StyledFormLabel, CheckboxGroupField } from 'components'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { removeHtmlTags } from 'utils/string'

const AssignmentForm = ({ formDisabled = false }) => {
  const { t } = useTranslation()
  const { register, unregister } = useFormContext() // retrieve all hook methods
  const errors = useSelector(state => state.courseBuilder.errors) // Access errors from the Redux store

  const totalAssignmentMaterialCount = 5
  const { assignmentMaterialCount, setAssignmentMaterialCount } = useCourseBuilderContext()

  const accepted_assignment_types = useSelector(state => state.courseBuilder.accepted_assignment_types)
  const { helperTextVals, placeholderTextVals } = useGetFormTextValues()
  const {
    assignmentDescriptionEditorState,
    setAssignmentDescriptionState,
    assignmentRequired,
    setAssignmentRequired
  } = useCourseBuilderContext()

  const supplementalMaterialProp = {
    assetCount: assignmentMaterialCount,
    setAssetCount: setAssignmentMaterialCount,
    assetType: 'assignment',
    disabled: formDisabled,
    removeTextValue: t('Remove Asset')
  }

  const checkboxValues = {
    accepted_assignment_types: ['PDF', 'JPG', 'PNG', 'XLS/XLSX', 'DOC/DOCX', 'PPT', 'MP3', 'MP4'],
    assignmentRequired: assignmentRequired
  }

  return (
    <>
      <TextEditorField
        name="assignment_description"
        errorVal={errors}
        setRequiredField={setAssignmentRequired}
        editorState={assignmentDescriptionEditorState}
        setEditorState={setAssignmentDescriptionState}
        textFieldProps={{
          label: t('Assignment Details'),
          helperText: !formDisabled && helperTextVals.assignment_description,
          placeholder: placeholderTextVals.assignment_description,
          width: '100%',
          disabled: formDisabled,
          maxLength: { value: 2500, message: 'Max 2500 characters allowed.' }
        }}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript'],
          },
          blockType: {
            options: ['Blockquote'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
        }}
        inputProps={{
          ...register(
            'assignment_description', {
              validate: value => removeHtmlTags(value)?.length > 2500 ? t('Max 2500 characters allowed.') : true
            }
          )
        }}
      />
      <CheckboxGroupField
        name="accepted_assignment_types"
        errorVal={errors}
        defaultValue={accepted_assignment_types}
        label={t('Set Assignment Submission File Type(s)')}
        fieldRequired={assignmentRequired}
        checkboxValues={checkboxValues}
        inputProps={{
          disabled: formDisabled,
        }}
      />
      {
        !formDisabled && (
          <Flex justifyContent="space-between">
            <Box>
              <FormControl pb="40px">
                <StyledFormLabel>
                  {t('Add Assets')}
                </StyledFormLabel>
                <FormHelperText fontSize="20px" pb="20px">
                  {t('Assets are pieces of content that may assist talent while completing an assignment. Please ensure that any assets shared are accessible without a login. Up to five (5) assets can be added.')}
                </FormHelperText>
              </FormControl>
            </Box>
          </Flex>
        )
      }
      {assignmentMaterialCount.map((index, arrayIndex) => (
        <Box key={index}>
          <SupplementalMaterial
            index={index}
            errorVal={errors}
            supplementalMaterialProp={supplementalMaterialProp}
            unRegister={unregister}
          />
          {arrayIndex === assignmentMaterialCount.length - 1 && !formDisabled && (
            <AddSubtractButton
              determineType="add"
              textValue={t('Add Asset')}
              onClickAction={() => addForm(totalAssignmentMaterialCount, assignmentMaterialCount, setAssignmentMaterialCount)}
              currentCount={assignmentMaterialCount}
              totalCount={4}
              maxAmtTooltipText={t('Maximum assets added.')}
            />
          )}
        </Box>
      ))}
    </>
  )
}

export default AssignmentForm
