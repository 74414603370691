import React from 'react'
import { Flex, Show } from '@chakra-ui/react'

const Column2 = ({ column2Value }) => (
  <Show above="md">
    <Flex justifyContent="left" alignItems="left">
      {column2Value}
    </Flex>
  </Show>
)

export default Column2
