import React, { useState, useRef, useEffect } from 'react'
import { Tooltip, Box } from '@chakra-ui/react'

// This component will show a tooltip when the text content overflows
const TooltipOnOverflow = ({ children, label, textProps, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current
      // Compare the full width of the content with the element's client width
      const isOverflowing = element.scrollWidth > element.clientWidth
      setShowTooltip(isOverflowing)
    }

    checkOverflow()
    // Add resize observer or use window resize event to handle dynamic content changes
    window.addEventListener('resize', checkOverflow)
    return () => window.removeEventListener('resize', checkOverflow)
  }, [children])

  return (
    <Tooltip label={label || children} isDisabled={!showTooltip} {...props}>
      <Box ref={textRef} textOverflow="ellipsis" whiteSpace="noWrap" overflow="hidden" {...textProps}>
        {children}
      </Box>
    </Tooltip>
  )
}
export default TooltipOnOverflow
