import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'

export const TakeQuizButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      colorScheme="zettaBlue"
      fontSize="18px"
      fontWeight="600"
      onClick={onClick}
      size="lg"
    >
      {t('Let\'s Go')}
    </Button>
  )
}