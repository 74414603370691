import React from 'react'
import { TableWrapper } from 'shared/TableWrapper'

const OpportunitiesTable = ({ children, headings }) => {
  return (
    <TableWrapper
      headings={headings}
    >
      {children}
    </TableWrapper>
  )
}

export default OpportunitiesTable
