import { apiSlice } from './apiSlice'

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation({
      query: ({ id, ...restProps }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body: { user: restProps }
      }),
      invalidatesTags: (result, error) => error ? [] : ['CurrentUser'],
    }),
    createSignUpTrack: builder.mutation({
      query: (track) => ({
        url: '/users/sign_up_tracks',
        method: 'POST',
        body: { track },
      }),
    }),
  })
})

export const {
  useUpdateUserMutation,
  useCreateSignUpTrackMutation
} = usersApi
