import { Show, Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import { tableBreakpoint } from '../Partner/CoursesList/CourseItems/shared'

export const TableWrapperBase = ({
  children,
  headings
}) => (
  <TableContainer>
    <Table
      borderBottom={[
        null, null,
        '1px solid #D7D7D7'
      ]}
    >
      <colgroup>
        <col></col>
        <Show above={tableBreakpoint}>
          <col width="0"></col>
        </Show>
      </colgroup>
      <Thead>
        <Tr>
          {headings[0]}
          <Show above={tableBreakpoint}>
            {headings[1]}
            {headings[2]}
          </Show>
        </Tr>
      </Thead>
      <Tbody>
        <Tr aria-hidden="true">
          <Td
            border="0"
            padding={[
              '6px 0',
              null,
              '8px 0'
            ]}
          />
        </Tr>
        {children}
        <Show above={tableBreakpoint}>
          <Tr aria-hidden="true"><Td border="0" padding="8px 0" /></Tr>
        </Show>
      </Tbody>
    </Table>
  </TableContainer>
)
