import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { buttonStyles } from './buttonStyles'
import { useCloneOpportunityMutation } from 'features/opportunitiesApi'
import { PartnerOpportunityModal } from 'components'

const PartnerOpportunityButton = ({ status, opportunity }) => {
  const { primaryButtonStyle } = buttonStyles()
  const { id } = opportunity
  const navigate = useNavigate()
  const [cloneOpportunity] = useCloneOpportunityMutation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleEditOpportunity = () => {
    navigate(`/opportunities/${id}/edit`)
  }

  const handleMarkAsActive = () => {
    setIsModalOpen(true)
  }

  const handleCloneOpportunity = async () => {
    try {
      const response = await cloneOpportunity(id).unwrap()
      navigate(`/opportunities/${response.id}/edit`)
    } catch (error) {
      console.error('Failed to clone opportunity', error)
    }
  }

  const statusButtonConfig = {
    'draft': {
      text: 'Edit Opportunity',
      onClick: handleEditOpportunity,
    },
    'pending': {
      text: 'Edit Opportunity',
      onClick: handleEditOpportunity,
    },
    'open': {
      text: 'View',
      component: (
        <Link to={`/opportunities/${id}`}>
          <Button {...primaryButtonStyle}>View</Button>
        </Link>
      ),
    },
    'paused': {
      text: 'Mark as Active',
      onClick: handleMarkAsActive,
    },
    'closed': {
      text: 'Clone',
      onClick: handleCloneOpportunity,
    },
  }

  const buttonConfig = statusButtonConfig[status]
  const modalAction = 'active'

  return (
    <>
      {buttonConfig?.component ? (
        buttonConfig.component
      ) : (
        <Button {...primaryButtonStyle} onClick={buttonConfig?.onClick}>
          {buttonConfig?.text}
        </Button>
      )}
      <PartnerOpportunityModal
        isOpen={isModalOpen}
        opportunity={opportunity}
        onClose={() => setIsModalOpen(false)}
        action={modalAction}
      />
    </>
  )
}

export default PartnerOpportunityButton
