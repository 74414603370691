import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from 'react'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useDispatch } from 'react-redux'
import { store } from 'store'

const BuilderContext = createContext()

export const useBuilderContext = () => useContext(BuilderContext)

export const BuilderProvider = ({
  children,
  initialStepId,
  stepsContent,
  sendData,
  formHook,
  validateAndContinue,
  builderStateSelector,
  disabledSteps,
  createNew,
  fileFormDataPrefix='',
  builderSpecificValues
}) => {
  const [stepId, setStepId] = useState(initialStepId)
  const [defaultIndex, setDefaultIndex] = useState(0)
  const [currentFormDirty, setCurrentFormDirty] = useState(false)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [skillsValues, setSkillsValues] = useState([])

  const dispatch = useDispatch()
  const allBuilderForms = formHook()
  const [builderForms, setBuilderForms] = useState(allBuilderForms)

  // Need a separate FormData object for files uploads
  const fileFormDataRef = useRef(new FormData())

  const setToFileFormData = (fieldName, value) => {
    fileFormDataRef.current.set(`${fileFormDataPrefix}${fieldName}`, value)
  }
  const clearFileFormData = () => {
    fileFormDataRef.current = new FormData()
  }

  const preExistingFileSuffix = '-fileFilename'
  const handleFileChange = (file, name, setValue) => {
    if (name.includes('course_expert')){
      setToFileFormData(`[course_expert][${name}]`, file)
    } else {
      setToFileFormData(`[${name}]`, file)
    }
    if (file === 'clear') {
      setValue(`${name}${preExistingFileSuffix}`, null)
    }
  }

  const {
    data: {
      id: userId,
    } = {}
  } = useGetCurrentUserQuery()

  const saveBuilderData = async () => {
    const continueProcess = await validateAndContinue(dispatch, builderForms, stepId, skillsValues)

    if (continueProcess) {
      const latestData = store.getState()[builderStateSelector] || {}
      await sendData(latestData, userId, createNew, dispatch, fileFormDataRef.current, clearFileFormData, false)
    }
    return continueProcess
  }

  const getDefaultIndex = useCallback((currentStepId) => {
    const link = stepsContent?.find(step => step.id === currentStepId)
    return link ? link.defaultIndex : -1
  }, [stepsContent])

  useEffect(() => {
    setDefaultIndex(getDefaultIndex(stepId))
  }, [stepId, getDefaultIndex])

  const newStepId = (currentStepsContent) => {
    // Check to ensure the next step is not disabled, providing the next non-disabled step index
    const currentIndex = currentStepsContent.findIndex(step => step.id === stepId)

    let nextIndex = currentIndex + 1
    while (nextIndex < currentStepsContent.length) {
      const nextStepId = currentStepsContent[nextIndex]?.id
      if (disabledSteps?.includes(nextStepId)) {
        nextIndex++
      } else {
        return nextStepId
      }
    }
  }

  const nextStep = async (updatedStepsContentFn = null) => {
    const currentStepsContent = updatedStepsContentFn ? updatedStepsContentFn() : stepsContent

    window.scrollTo(0, 100)
    try {
      const continueProcess = await validateAndContinue(dispatch, builderForms, stepId, skillsValues)
      if (continueProcess) {
        const nextNonDisabledStepId = newStepId(currentStepsContent)
        setStepId(nextNonDisabledStepId)
      }
    } catch (error) {
      console.error('Error occurred during validation:', error)
    }
  }

  const jumpToStep = async (jumpCount) => {
    try {
      const continueProcess = await validateAndContinue(dispatch, builderForms, stepId, skillsValues)
      if(continueProcess) {
        setStepId(jumpCount)
      }
    } catch (error) {
      console.error('Error occurred during validation:', error)
    }
  }

  const value = {
    stepId,
    setStepId,
    defaultIndex,
    setDefaultIndex,
    builderForms,
    setBuilderForms,
    currentFormDirty,
    handleFileChange,
    setCurrentFormDirty,
    isFileUploading,
    setIsFileUploading,
    nextStep,
    jumpToStep,
    setToFileFormData,
    clearFileFormData,
    skillsValues,
    setSkillsValues,
    saveBuilderData,
    fileFormData: fileFormDataRef.current,
    ...builderSpecificValues
  }

  return <BuilderContext.Provider value={value}>{children}</BuilderContext.Provider>
}
