import { WarningIcon } from '@chakra-ui/icons'
import { Box, Text, Badge, Spinner, FormControl, Button, VStack, useDisclosure } from '@chakra-ui/react'
import { StyledFormLabel } from 'components'
import CompassModal from 'components/talent/beacon/CompassModal'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListUserSkillsQuery } from 'features/skillsApi'
import { useRef, useState } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { useDispatch } from 'react-redux'
import BeaconService from 'services/BeaconService'

const SkillCard = ({ skill }) => {
  const { label, context, category } = skill

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={3} mb={4}>
      <Text fontWeight="bold" fontSize="lg" mb={2}>
        {label}
      </Text>
      <Text mb={2}>{context}</Text>
      <Badge variant="outline" colorScheme="blue" alignContent="end">
        {category?.label}
      </Badge>
    </Box>
  )
}

const EmptyState = ({ onClick, onReset, resetLoading }) => {
  const { t } = useTranslation()

  return (
    <VStack className="skills-pane" gap={10} backgroundColor="#F8F4FF" p="24px">
      <VStack fontFamily="Poppins" fontSize="18px" gap={10} alignItems="baseline">
        <Text>
          {t(
            'Leverage {{MAKING_SPACE_AI}} to turn your lived experiences in transferable skills.',
            { MAKING_SPACE_AI: 'Making Space AI' }
          )}
        </Text>
        <Text>{t('Click the button to get started.')}</Text>
      </VStack>
      <Button
        colorScheme="zettaBlue"
        onClick={onClick}
        fontWeight={700}
        size="lg"
      >{t('Open Chat')}</Button>

      <Button
        colorScheme="yellow"
        leftIcon={<WarningIcon />}
        onClick={onReset}
        fontWeight={700}
        isLoading={resetLoading}
        size="lg"
      >
        {t('Reset Chat & Skills')}
      </Button>
    </VStack>
  )
}

export const SkillsPane = () => {
  const dispatch = useDispatch()
  const { data: { id: userId } } = useGetCurrentUserQuery()
  const { data: skills, isLoading: loading } = useListUserSkillsQuery({ userId })
  const { isOpen, onOpen: openCompassModal, onClose } = useDisclosure()
  const [resetLoading, setResetLoading] = useState(false)
  const beaconService = useRef(new BeaconService('compass', dispatch))

  const handleClick = () => {
    openCompassModal()
  }

  const handleReset = async () => {
    setResetLoading(true)
    await beaconService.current.resetChat()
    await beaconService.current.fetchSkills()
    setResetLoading(false)
  }

  // optionally fetch skills after closing the modal
  const handleClose = async (doFetchSkills = false) => {
    if( doFetchSkills ) await beaconService.current.fetchSkills()
    onClose()
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <FormControl className="skills-pane">
      <CompassModal isOpen={isOpen} onClose={handleClose} />

      <StyledFormLabel>Skills</StyledFormLabel>

      <Box className="skills-pane-content">
        {skills.length > 0 && skills.map((skill, index) => (
          <SkillCard key={skill.label} skill={skill} />
        ))}
        <EmptyState onClick={handleClick} onReset={handleReset} resetLoading={resetLoading} />
      </Box>
    </FormControl>
  )
}