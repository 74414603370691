import React from 'react'
import {
  Box,
} from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import { HeaderAvatar } from 'components/header'
import { BeaconAvatar } from 'components/talent/beacon/modal-elements/BeaconAvatar'

// TODO: Move these into chakra variables
const DARK_BLUE = '#0F204B'

const Avatar = ({ photoUrl, className, isUser, isBeacon, ...props }) => {
  const avatarStyles = photoUrl || !isUser ? { border: '1px', borderColor: '#0F204B' } : {}

  let icon
  if ( photoUrl && !isBeacon ) {
    icon = <Image src={photoUrl} alt="" boxSize="100%" objectFit="scale-down" borderRadius="50%" />
  } else if ( isBeacon ) {
    icon = <BeaconAvatar />
  } else {
    icon = <HeaderAvatar className="dropdown-display-avatar-wrapper" headerType="talent" />
  }

  return (
    <Box
      className={className}
      width="46px"
      height="46px"
      borderRadius="50%"
      background={isUser ? 'transparent' : DARK_BLUE}
      p={isUser ? 0 : '2px'}
      boxShadow="lg"
      {...avatarStyles}
      {...props}
    >
      { icon }
    </Box>
  )
}

export default Avatar
