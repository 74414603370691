import { useTranslation } from 'i18n/TranslationContext'
import { Box, Flex } from '@chakra-ui/react'
import { RequestForm } from './RequestForm'
import { usePageTitle } from 'usePageTitle'

export const RequestContactDetails = () => {
  const { t } = useTranslation()
  usePageTitle(t('Contact Request'))

  return (
    <Box as="main" role="main"
      position="relative"
      paddingTop={['16px', null, '128px']}
      paddingLeft={['24px', null, '7%']}
      paddingRight="24px"
    >
      <Flex
        flexDirection={['column-reverse', null, 'row']}
        justifyContent="space-between"
      >
        <Box as="h1"
          color="#0F204A"
          fontFamily="Spline Sans Mono"
          fontSize="48px"
          fontWeight="700"
          textTransform="uppercase"
          maxWidth={[null, null, '400px']}
        >
          {t('Customize your request')}
        </Box>
        <Box
          paddingBottom="16px"
          marginLeft={['-32px', null, '0']}
          marginRight={['-22px', null, '0']}
        >
          <Box as="img"
            width="100%"
            opacity="0.85"
            src={`${process.env.PUBLIC_URL}/contact-request-tape.svg`}
            alt={t('Contact request')}
          />
        </Box>
        <Box
          flexBasis="1px"
        />
      </Flex>
      <Box
        paddingTop="28px"
        paddingBottom="30px"
        maxWidth="764px"
        color="#0F204A"
        fontFamily="Poppins"
        fontSize="22px"
      >
        {t('We recommend being detailed and specific when requesting a candidate’s contact details. Providing a clear reason will assist the candidate in determining whether or not to share their information with you.')}
      </Box>
      <RequestForm />
    </Box>
  )
}
