import { Td } from '@chakra-ui/react'

export const TableItemDesktop = ({
  padding = '0',
  paddingColumn2,
  columns: [
    column1,
    column2,
    column3
  ]
}) => (
  <>
    <Td
      border="0"
      padding={padding}
      width="60%"
    >
      {column1}
    </Td>
    <Td
      padding={paddingColumn2 || padding}
      textAlign="right"
      fontSize="24px"
      fontFamily="Poppins"
      width="20%"
      color="#0F204A"
      border="0"
    >
      {column2}
    </Td>
    <Td
      textAlign="right"
      border="0"
      paddingRight="12px"
      width="20%"
      maxWidth="484px"
    >
      {column3}
    </Td>
  </>
)

