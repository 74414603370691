import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addFormField, addMultipleFormFields, resetCourseBuilder, addToEpisodeSteps } from 'features/courses/courseBuilderSlice'
import { useListCourseForPartnerQuery } from 'features/coursesApi'
import { useParams } from 'react-router-dom'
import { useCurrentCourseQuery } from 'hooks/useCurrentCourseQuery'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import formFieldsForBuilder from 'utils/courseBuilder/formFieldsForBuilder'
import { convertHtmlToEditorState } from 'utils/builder'

const useRebuildCourseData = (newInitialized, initialized, isNewCourse) => {
  const dispatch = useDispatch()
  let { courseId } = useParams()
  const courseData = useCurrentCourseQuery(courseId)
  const courseSliceData = useSelector(state => state.courseBuilder || {})
  const [loading, setLoading] = useState(true)
  const {
    builderForms: courseBuilderForms,
    setCourseMaterialCount,
    setAssignmentMaterialCount,
    setExpertCount,
    setCourseDescriptionEditorState,
    setAssignmentDescriptionState,
    setSkillsValues,
    setCourseNameList,
    partnerProfileId,
    setVisibilityRadioField,
    setEpisodeCount,
    setQuizCount,
  } = useCourseBuilderContext()
  const courseList = useListCourseForPartnerQuery(partnerProfileId)

  const setCourseNamesFiltered = useCallback((courses, courseName = null ) => {
    const coursesFiltered = courseName ? courses.filter(course => course.name !== courseName) : courses
    setCourseNameList(coursesFiltered.map(course => course.name))
  }, [setCourseNameList])

  const setMultiValueStates = useCallback((key, index) => {
    if (index === 0) return
    if (key === 'course_additional_material') {
      // Create sets to eliminate duplicate values in the slice
      setCourseMaterialCount(prevItems => {
        const newItems = new Set([...prevItems, index])
        return Array.from(newItems)
      })
    } else if (key === 'course_expert') {
      setExpertCount(prevItems => {
        const newItems = new Set([...prevItems, index])
        return Array.from(newItems)
      })
    } else if (key === 'assignment_additional_material') {
      setAssignmentMaterialCount(prevItems => {
        const newItems = new Set([...prevItems, index])
        return Array.from(newItems)
      })
    }
  }, [setCourseMaterialCount, setExpertCount, setAssignmentMaterialCount])

  const processSingleValue = useCallback((key, value) => {
    if (!value || value === '') return
    if (key === 'course_skills') {
      // if the value is course_skills we need to set the store
      setSkillsValues(value)
    } else if (key === 'description' || key === 'assignment_description') {
      // Convert HTML string to editor state
      const editorState = convertHtmlToEditorState(value)
      key.includes('assignment') ? setAssignmentDescriptionState(editorState) : setCourseDescriptionEditorState(editorState)
    } else if (key === 'visibility') {
      setVisibilityRadioField(value)
    }

    let formId = formFieldsForBuilder(key)
    if(formId) {
      // Rehydrate the slice value to match the database returned values
      dispatch(addFormField({ key, value }))

      // Rehydrate the form values to match the database returned values
      courseBuilderForms[formId].setValue(key, value)
    }
  }, [
    setCourseDescriptionEditorState,
    setAssignmentDescriptionState,
    setSkillsValues,
    setVisibilityRadioField,
    dispatch,
    courseBuilderForms
  ])

  const processEpisodeSteps = useCallback((values) => {
    if (values.length <= 0) return
    setEpisodeCount(values.length)

    values.forEach(item => {
      let episodeNum = item.order - 1
      Object.entries(item).forEach(([itemKey, itemValue]) => {
        if (itemKey === 'quiz' && itemValue) {
          const questionKey = `episode_${episodeNum}_quiz_question`

          itemValue.questions.forEach((question, questionIndex) => {
            setQuizCount(prevItems => {
              const newItems = new Set([...prevItems[episodeNum], questionIndex])
              return {
                ...prevItems,
                [episodeNum]: Array.from(newItems)
              }
            })
            const incorrectAnswers = question.incorrect_answers
            const formId = `episode${episodeNum}Quiz`
            // Set Quiz values into the Form builder
            courseBuilderForms[formId].setValue(`${questionKey}_${questionIndex}`, question.content)
            courseBuilderForms[formId].setValue(`${questionKey}_${questionIndex}_correct_answer`, question.correct_answer)
            incorrectAnswers.forEach((incorrectAnswer, incorrectAnswerIndex) => {
              courseBuilderForms[formId].setValue(`${questionKey}_${questionIndex}_incorrect_answer_${incorrectAnswerIndex + 1}`, incorrectAnswer)
            })
            // Dispatch the Quiz values into the slice
            dispatch(addToEpisodeSteps({
              key: episodeNum,
              value: {
                [`${questionKey}_${questionIndex}`]: question.content,
                [`${questionKey}_${questionIndex}_correct_answer`]: question.correct_answer,
                [`${questionKey}_${questionIndex}_incorrect_answer_1`]: incorrectAnswers[0],
                [`${questionKey}_${questionIndex}_incorrect_answer_2`]: incorrectAnswers[1],
                [`${questionKey}_${questionIndex}_incorrect_answer_3`]: incorrectAnswers[2]
              }
            }))
          })
        } else {
          const episodeItemKey = `episode_${episodeNum}_${itemKey}`
          let formId = formFieldsForBuilder(episodeItemKey)
          if(formId) {
            const valueObject = { [episodeItemKey]: itemValue }
            // Dispatch the Episode Steps values into the slice
            dispatch(addToEpisodeSteps({ key: episodeNum, value: valueObject }))
            // Rehydrate the form values to match the database returned values
            courseBuilderForms[formId].setValue(episodeItemKey, itemValue)
            if (itemKey === 'medium' && itemValue === 'deck') {
              dispatch(addMultipleFormFields({ key: 'disabledCourseSteps', value: `episode${episodeNum}UploadAccessibility` }))
            }
          } else if (itemKey === 'id') {
            dispatch(addToEpisodeSteps({ key: episodeNum, value: { id: itemValue } }))
          }
        }
      })
    })
  }, [setEpisodeCount, dispatch, courseBuilderForms, setQuizCount])

  const processMultipleValues = useCallback((key, value) => {
    if (!value || value?.length <= 0) return
    // found an array of values, iterate over them and add to slice
    value.forEach((item, index) => {
      // add the index to the specific store unless the index is 0 (already exists in store)
      setMultiValueStates(key, index)
      Object.entries(item).forEach(([itemKey, itemValue]) => {
        const multipleValueKey = `${key}_${itemKey}_${index}`
        let valueObject
        let formId = formFieldsForBuilder(multipleValueKey)
        if(formId) {
          if (itemKey === 'image-fileFilename') {
            const newImageNameKey = `${key}_image_${index}-fileFilename`
            valueObject = { [newImageNameKey]: itemValue }
            // Rehydrate the form values to match the database returned values
            courseBuilderForms[formId].setValue(newImageNameKey, itemValue)
          } else {
            valueObject = { [multipleValueKey]: itemValue }
            // Rehydrate the form values to match the database returned values
            courseBuilderForms[formId].setValue(multipleValueKey, itemValue)
          }
          // Rehydrate the slice value to match the database returned values
          dispatch(addMultipleFormFields({ key: key, value: valueObject }))
        }
      })
    })
  }, [setMultiValueStates, dispatch, courseBuilderForms])

  useEffect(() => {
    const processCourseData = (data) => {
      const multipleValues = ['course_additional_material', 'course_expert', 'assignment_additional_material']
      // Look through all the returned course data and process based on single/multiple values
      Object.entries(data).forEach(([key, value]) => {
        if (multipleValues.includes(key)) {
          processMultipleValues(key, value)
        } else if (['id','invalid_course_steps', 'valid_course_steps'].includes(key)) {
          dispatch(addFormField({ key, value }))
        } else if (key === 'episode_steps') {
          processEpisodeSteps(value)
        } else if (key in courseSliceData) {
          processSingleValue(key, value)
        }
      })
    }

    if (!initialized.current && courseData.isSuccess && courseList.isSuccess) {
      // Used to process and rehydrate course slice based on backend data
      dispatch(resetCourseBuilder())
      console.log('Course data is successful:', courseData.data)
      setCourseNamesFiltered(courseList.data, courseData.data.name)
      processCourseData(courseData.data)
      initialized.current = true
      setLoading(false)
    } else if (!initialized.current && !courseData.isSuccess && isNewCourse && courseList.isSuccess) {
      // Used to reset the course slice when the id is not found in path (new course)
      if (!newInitialized.current) {
        newInitialized.current = true
        dispatch(resetCourseBuilder())
        setCourseNamesFiltered(courseList.data)
        setLoading(false)
      }
    }
  }, [courseData,
    dispatch,
    initialized,
    newInitialized,
    courseSliceData,
    processMultipleValues,
    processSingleValue,
    processEpisodeSteps,
    isNewCourse,
    setCourseNamesFiltered,
    courseList])

  return loading
}

export default useRebuildCourseData
