import React from 'react'
import { formStyles } from '../forms/formStyles'
import { Flex } from '@chakra-ui/react'
import { H3 } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { AddSubtractButton } from 'components'
import { useDeleteEpisode } from 'hooks/courseBuilder/useModifyEpisodeSteps'

const DeleteEpisodeBanner = ({ episodeNum }) => {
  const { bannerGray } = formStyles()
  const { t } = useTranslation()

  const deleteAction = useDeleteEpisode(episodeNum)

  return (
    <Flex {...bannerGray} padding={2} pl={50} alignItems="center">
      <H3 mr="2.5">{t('Delete Episode')}</H3>
      <AddSubtractButton
        determineType="minus"
        textValue={t('Delete')}
        onClickAction={() => deleteAction()}
      />
    </Flex>
  )
}

export default DeleteEpisodeBanner
