import React, { useMemo } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { TextEditorField, TagInputField } from 'components'
import { useOpportunityBuilderContext } from 'OpportunityBuilder/OpportunityBuilderContext'
import { removeHtmlTags } from 'utils/string'

const getPlaceholderText = (t) => {
  return t('We’re looking for our next great Graphic Designer.' +
    ' Success in this role looks like producing high-quality artistic renderings' +
    ' for stakeholders to review, receiving and applying constructive feedback, and delivering' +
    ' exceptional assets for projects across the organization...')
}

const getHelperText = (t) => {
  return t('This description appears on the opportunity description page and should' +
    ' give talent a more in-depth view of what’s required for the role.')
}

export const OpportunityDescriptionForm = ({ formDisabled = false }) => {
  const { t } = useTranslation()
  const { register } = useFormContext()
  const errors = useSelector(state => state.opportunityBuilder.errors || {})

  const {
    skillsValues,
    setSkillsValues,
    opportunityDescriptionEditorState,
    setOpportunityDescriptionEditorState,
  } = useOpportunityBuilderContext()

  const placeholderText = useMemo(() => getPlaceholderText(t), [t])
  const helperText = useMemo(() => getHelperText(t), [t])

  return (
    <>
      <TextEditorField
        name="description"
        errorVal={errors}
        editorState={opportunityDescriptionEditorState}
        setEditorState={setOpportunityDescriptionEditorState}
        textFieldProps={{
          label: t('Opportunity Description'),
          helperText: !formDisabled && helperText,
          placeholder: placeholderText,
          width: '100%',
          fieldRequired: true,
          disabled: formDisabled,
          maxLength: { value: 2500, message: 'Max 2500 characters allowed.' }
        }}
        inputProps={{
          ...register(
            'description', {
              required: t('Opportunity Description can’t be empty.'),
              validate: value => removeHtmlTags(value)?.length > 2500 ? t('Max 2500 characters allowed') : true
            }
          )
        }}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript'],
          },
          blockType: {
            options: ['Blockquote'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
        }}
      />
      <TagInputField
        t={t}
        label={t('Skills')}
        name="skills"
        errorVal={errors}
        tagsProp={{
          tags: skillsValues,
          setTags: setSkillsValues,
          maxTags: 15,
        }}
        fieldRequired
        helperText={!formDisabled && t(
          'Identify the skill(s) that are required for this opportunity. Add up to 15 skills.'
        )}
        inputProps={{
          disabled: formDisabled,
          width: '100%',
          placeholder: t('Add Skill…'),
          ...register(
            'skills', {
              required: t('Opportunity Skills can’t be empty.')
            }
          )
        }}
      />
    </>
  )
}
