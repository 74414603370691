import { Show } from '@chakra-ui/react'
import { CourseMobile } from './mobile'
import { CourseDesktop } from './desktop'
import { tableBreakpoint } from './shared'

export const CourseItem = (props) => (
  <>
    <Show below={tableBreakpoint}>
      <CourseMobile
        {...props}
      />
    </Show>
    <Show above={tableBreakpoint}>
      <CourseDesktop
        {...props}
      />
    </Show>
  </>
)

