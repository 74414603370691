import { createSlice } from '@reduxjs/toolkit'
import { commonFormReducers, createResetReducer } from '../common/formReducers'

const initialState = {
  id: '',
  slug: '',
  name: '',
  opportunity_id: '',
  image_alt_text: '',
  visibility: '',
  summary: '',
  description: '',
  course_skills: '',
  assignment_description: '',
  accepted_assignment_types: '',
  course_expert: [],
  course_additional_material: [],
  assignment_additional_material: [],
  episode_steps: {},
  errors: {},
  valid_course_steps: [],
  invalid_course_steps: [],
  disabledCourseSteps: [],
  'image-fileFilename': null,
  intro_video: null,
  intro_thumbnail: null,
  intro_thumbnail_alt_text: '',
  intro_transcript: null,
  intro_audio_description: null,
  intro_sign_language_video: null,
}

const courseBuilderSlice = createSlice({
  name: 'courseBuilder',
  initialState,
  reducers: {
    ...commonFormReducers,
    addToEpisodeSteps(state, action) {
      const { key, value } = action.payload
      state.episode_steps[key] = { ...state.episode_steps[key], ...value }
    },
    removeFromEpisodeSteps(state, action) {
      const { key, subKeyString } = action.payload
      const episodeSubKeys = state.episode_steps[key] && Object.keys(state.episode_steps[key])
      const subKeys = episodeSubKeys && episodeSubKeys.filter(subKey => subKey.includes(subKeyString))
      subKeys && subKeys.forEach(subKey => {
        delete state.episode_steps[key][subKey]
      })
    },
    deleteShiftEpisodeSteps(state, action) {
      const { key } = action.payload
      delete state.episode_steps[key]
      const episodeKeys = Object.keys(state.episode_steps)
      const lastEpKey = parseInt(episodeKeys[episodeKeys.length - 1])

      // If there are following episodes, shift the fieldnames of the episode_steps object back
      for (let i = key; i < lastEpKey; i++) {
        state.episode_steps[i] = {}
        Object.entries(state.episode_steps[i + 1]).forEach(([key, value]) => {
          const newFieldName = key.replace(`episode_${i + 1}`, `episode_${i}`)
          state.episode_steps[i][newFieldName] = value
        })
        delete state.episode_steps[i + 1]
      }
    },
    removeFormError(state, action) {
      const { key } = action.payload
      delete state.errors[key]
    },
    resetCourseBuilder: createResetReducer(initialState),
  },
})

export const {
  addFormField,
  removeMultipleFormFields,
  addMultipleFormFields,
  removeValueFromArray,
  addFormError,
  removeFormError,
  resetCourseBuilder,
  addToEpisodeSteps,
  deleteShiftEpisodeSteps,
  removeFromEpisodeSteps,
} = courseBuilderSlice.actions

export const courseBuilderSliceReducer = courseBuilderSlice.reducer
