import { useTranslation } from 'i18n/TranslationContext'
import { Box } from '@chakra-ui/react'

export const NewCourseBadge = () => {
  const { t } = useTranslation()
  return (
    <Box
      display="inline-block"
      marginRight="8px"
      padding="4px 10px"
      borderRadius="16px"
      backgroundColor="var(--design-decorative-action, #ADFF00)"
      fontFamily="Poppins"
      fontSize="14px"
      fontWeight="600"
      color="var(--design-content, #0F204B)"
      opacity="1"
    >
      {t('New')}
    </Box>
  )
}
