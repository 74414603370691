import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { WelcomeBar } from '../WelcomeBar'
import { ListHeader, ListBlock, H2 } from '../components'
import { usePageTitle } from 'usePageTitle'
import { PageOuterContainer } from 'shared/TalentsLayout'
import { NoisyBackground } from 'CourseFeature/CoursePage/shared/Layout'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link,
  Button
} from '@chakra-ui/react'

const FAQs = () => {
  const { t, Trans } = useTranslation()
  const faqContent = [
    {
      question: t('How does Making Space help me find job opportunities?'),
      answer: t('On Making Space, you can access courses tailored to jobs at partner companies. By completing a course and associated quizzes/assignments, you join the talent pipeline for that employer. Your profile, quiz scores, and assignment data become visible to the company, showcasing your capabilities and potential fit for relevant roles. This gives you the opportunity to get your foot in the door with employers seeking candidates like you.')
    },
    {
      question: t('How does the video player work within a course episode?'),
      answer: t('Near the bottom of each video, you can adjust the captions to your size, color, and font preferences, turn on and off sign language interpretation, change your keyboard navigation settings, and turn on and off the transcript that can either auto-scroll or not. Note: The player experience is not yet fully optimized for mobile devices so, if you opened the page on your mobile device and something isn’t working for you as expected, try opening the player from a desktop browser.')
    },
    {
      question: t('What happens after I complete a course on Making Space?'),
      answer: t('Once you complete a course, employers can easily browse your profile, quiz and assignment data, and screening questions to identify and connect with ideal candidates like you who have demonstrated job readiness. Making Space streamlines hiring by matching pre-qualified talent directly to partner employers.')
    },
    {
      question: t('How does Making Space benefit both me as a candidate and employers?'),
      answer: t('The Making Space model is mutually beneficial for talent and employers. As a candidate, you get the opportunity to upskill, showcase your qualifications, and get visibility with relevant employers on your own terms. For employers, Making Space gives them access to skilled, driven candidates that may have otherwise been overlooked. It opens up new pathways to employment for candidates while streamlining hiring for employers.')
    },
    {
      question: t('Is employment guaranteed if I complete a course on Making Space?'),
      answer: t('Completing a course and quizzes on Making Space gives you priority access to partner employers, but does not guarantee employment. The primary goal is to equip you with in-demand skills and showcase your capabilities to employers. Making Space gives you a strong opportunity to get your foot in the door and be considered for relevant roles based on your demonstrated skills and potential fit.')
    },
    {
      question: t('What’s next for Making Space?'),
      answer: (
        <Trans
          i18nKey="We’re still quite new but have big plans for additional features and opportunities in the very near-term future. Make sure you’re following us on <1>LinkedIn</1>, <2>Instagram</2>, and <3>Twitter</3> for the latest updates."
          components={{
            1: <Link href="https://www.linkedin.com/company/makingspacework/" isExternal textDecoration="underline" />,
            2: <Link href="https://www.instagram.com/_making_space__/" isExternal textDecoration="underline" />,
            3: <Link href="https://twitter.com/_makingspace_" isExternal textDecoration="underline" />
          }}
        />
      )
    }
  ]
  return (
    faqContent.map(faq => (
      <FAQAccordion key={faq.question} faq={faq} />
    ))
  )
}

const expandedProps = {
  color: '#0F204B',
  bg: '#FF7D19',
  _hover: {
    bg: '#FF7D19'
  }
}

const collapsedProps = {
  color: 'white',
  bg: 'linear-gradient(90deg, rgba(232, 225, 222, 0.11) 0%, rgba(232, 225, 222, 0.044) 100%)'
}

const fontSize = ['18px', '18px', '20px']

const FAQAccordion = ({ faq: { question, answer } }) => (
  <AccordionItem key={question} border="none" marginY={2}>
    {({ isExpanded }) => (
      <>
        <AccordionButton
          paddingY={4}
          fontFamily="Poppins"
          fontSize={fontSize}
          fontWeight="500"
          justifyContent="space-between"
          position="relative"
          {...(isExpanded ? expandedProps : collapsedProps)}
        >
          <Box flex="1" textAlign="left">
            {question}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <Box
            textAlign="left"
            fontSize={fontSize}
            fontWeight="400"
            fontFamily="Poppins"
            padding="12px"
          >
            {answer}
          </Box>
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
)

export const SupportPage = () => {
  const { t, Trans } = useTranslation()
  usePageTitle(t('Support'))
  return (
    <PageOuterContainer greenBg>
      <WelcomeBar />
      <ListBlock>
        <ListHeader as="h1">
          {t('Support')}
        </ListHeader>
        <Box
          className="faq-section"
          as="section"
          color="#ffffff"
          bgColor="#0F204A"
          width="100%"
          position="relative"
          padding={12}
          flexDirection="column"
        >
          <NoisyBackground
            color="rgba(15, 32, 74, 1)"
            opacity="0.09"
          />
          <H2 paddingBottom={5}>
            {t('Frequently Asked Questions')}
          </H2>
          <Accordion allowToggle maxWidth="900px">
            <FAQs />
          </Accordion>
        </Box>
        <Box
          className="contact-us"
          paddingY={12}
          maxWidth="900px"
        >
          <H2 paddingBottom={3}>
            {t('Contact Us')}
          </H2>
          <Box fontFamily="Poppins" fontSize={fontSize}>
            <Trans
              i18nKey="If you have questions, comments, or concerns you’d like to send to our team, please reach out to us at <1>support@making-space.com</1>. Our team will be in touch within 2 business days."
              components={{
                1: <Button
                  fontSize={fontSize}
                  fontWeight="400"
                  fontFamily="Poppins"
                  variant="link"
                  colorScheme="zettaBlue"
                  onClick={() => window.open('mailto:support@making-space.com')}
                />
              }}
            />
          </Box>
        </Box>
      </ListBlock>
    </PageOuterContainer>
  )
}
