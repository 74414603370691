import { FormControl, Stack, Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { formStyles } from '../formStyles'

const CheckboxGroupField = ({ helperText, label, name, inputProps, errorVal={}, fieldRequired, checkboxValues, horizontalCheckbox, defaultValue=[] }) => {
  const { formState: { errors }, register } = useFormContext()
  const { checkboxFieldStyles, formControlStyles } = formStyles()
  let courseBuilder = true

  if(Object.keys(errorVal).length === 0) {
    errorVal = errors
    courseBuilder = false
  }

  return (
    <FormControl pb="40px" isInvalid={!!errorVal[name]} isRequired={fieldRequired} {...formControlStyles}>
      <FieldHeader helperText={helperText} label={label} />
      <CheckboxGroup defaultValue={defaultValue} {...inputProps}>
        <Stack className={`checkbox-form-field ${errorVal[name] ? 'error-input' : ''}`} direction={horizontalCheckbox ? 'row' : 'column'} {...checkboxFieldStyles} >
          {checkboxValues[name].map((textVal, index) => (
            <Checkbox
              key={`${name}.${textVal}`}
              name={textVal}
              value={textVal}
              isDisabled={inputProps?.disabled}
              {...register(
                name, {
                  required: checkboxValues.assignmentRequired && checkboxValues.assignmentRequired ? 'You must select at least one checkbox option.' : false
                }
              )}
            >
              {textVal}
            </Checkbox>
          ))}
        </Stack>
        <FormErrorDisplay courseBuilder={courseBuilder} name={name} errorVal={errorVal} />
      </CheckboxGroup>
    </FormControl>
  )
}

export default CheckboxGroupField
