import React from 'react'
import { domToReact } from 'html-react-parser'

const voidElements = new Set([
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr'
])

export const parseWithoutStyles = {
  replace(domNode) {
    if (domNode.type === 'tag') {
      const attribs = { ...domNode.attribs }
      if (attribs.style) delete attribs.style

      if (voidElements.has(domNode.name)) {
        return React.createElement(domNode.name, attribs)
      } else {
        return React.createElement(
          domNode.name,
          attribs,
          domNode.children ? domToReact(domNode.children, parseWithoutStyles) : null
        )
      }
    }
    if (domNode.type === 'text') return domNode.data
  }
}

// Returns the ordinal of a number: 1st, 2nd, 3rd, 4th, etc.
export const formatAsOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + ((v % 100 >= 11 && v % 100 <= 13) ? s[0] : s[v % 10] || s[0])
}

export const removeHtmlTags = (htmlString) => {
  return htmlString?.replace(/<[^>]*>?/gm, '').trim()
}

export const formatLinkProtocols = (url) => {
  if (!url) return null
  const lowercaseUrl = url.toLowerCase()
  const hasProtocol = lowercaseUrl.startsWith('http://') || lowercaseUrl.startsWith('https://')
  return hasProtocol ? url : `https://${url}`
}
