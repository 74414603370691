import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Flex, Show, Tr, useToast } from '@chakra-ui/react'
import { TableItemMap } from 'shared/TableItemMap'
import {
  useDestroyInvitationMutation,
  useListInvitationsQuery,
  useResendInvitationMutation
} from 'features/invitationsApi'
import { useParams, useSearchParams } from 'react-router-dom'
import { TableItem } from 'shared/TableItem'
import { TableWrapperBase } from 'shared/TableWrapperBase'
import { StyledTh } from 'shared/StyledTh'
import { Pagination } from '../Pagination'
import { useEffect } from 'react'

const AvatarPlaceholder = () => (
  <Box as="img"
    width="48px"
    height="48px"
    src={`${process.env.PUBLIC_URL}/invitation-avatar-placeholder.svg`}
    alt=""
  />
)

const Avatar = ({ photoUrl, photoAltText }) => {
  if (!photoUrl) {
    return (
      <AvatarPlaceholder />
    )
  }

  return (
    <Box as="img"
      width="48px"
      height="48px"
      borderRadius="50%"
      src={photoUrl}
      alt={photoAltText}
    />
  )
}

const MakingSpaceBadge = () => {
  const { t } = useTranslation()
  return (
    <Box
      as="img"
      position="absolute"
      src={`${process.env.PUBLIC_URL}/invitation-masp-badge.png`}
      alt={t('Sourced by Making Space')}
      width="31px"
      height="30px"
      top="-12px"
      right="-6px"
    />
  )
}

const NameAndPhotoColumn = ({
  name,
  email,
  invited_by_the_user: invitedByTheUser,
  photo_url: photoUrl,
  photo_alt_text: photoAltText
}) => (
  <Flex paddingLeft={['12px', null, '16px']}>
    <Box position="relative" flexShrink="0">
      { !invitedByTheUser && <MakingSpaceBadge /> }
      <Avatar
        photoUrl={photoUrl}
        photoAltText={photoAltText}
      />
    </Box>
    <Flex
      paddingTop={['8px', null, '0']}
      paddingLeft="16px"
      flexDirection="column"
      justifyContent="center"
      fontFamily="Spline Sans Mono"
      fontSize={['16px', null, '20px']}
    >
      <Box>
        {name}
      </Box>
      <Show below="767px">
        <Box
          paddingTop="8px"
          fontFamily="Poppins"
          fontSize="16px"
        >
          {email}
        </Box>
      </Show>
    </Flex>
  </Flex>
)

const EmailColumn = ({ email }) => (
  <Show above="md">
    <Box
      fontFamily="Poppins"
      fontSize="20px"
      textAlign="left"
    >
      {email}
    </Box>
  </Show>
)

const toastProps = {
  position: 'top',
  containerStyle: {
    maxWidth: 'unset',
    width: '100%',
    margin: '0'
  }
}

const DeleteButton = ({ id, courseId, email }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [destroyInvitation, { isLoading }] = useDestroyInvitationMutation()

  const onClick = () => {
    const result = window.confirm(t('Are you sure you want to remove course access from {{email}}', { email }))
    if (!result) { return }

    destroyInvitation({ id, courseId }).then((result) => {
      if (result.error) {
        toast({
          title: t('Something went wrong'),
          status: 'error',
          ...toastProps
        })
        return
      }

      toast({
        title: t('Access is removed'),
        status: 'success',
        ...toastProps
      })
    })
  }

  return (
    <Button
      colorScheme="zettaPurple"
      fontWeight="500"
      color="#CD0050"
      onClick={onClick}
      isLoading={isLoading}
    >
      {t('Delete')}
    </Button>
  )
}

const ResendButton = ({ id, courseId }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [resendInvitation, { isLoading, isSuccess, isError }] = useResendInvitationMutation()

  useEffect(() => {
    if (!isSuccess) { return }

    toast({
      title: t('Invitation resent'),
      status: 'success',
      ...toastProps
    })
  }, [isSuccess, toast, t])

  useEffect(() => {
    if (!isError) { return }

    toast({
      title: t('Something went wrong'),
      status: 'error',
      ...toastProps
    })
  }, [isError, toast, t])

  const onClick = () => {
    const result = window.confirm(t('Please keep in mind that the previously sent invitation links will no longer be valid. Are you sure you want to resend the invitation email?'))
    if (!result) { return }

    resendInvitation({ id, courseId })
  }

  return (
    <Button
      colorScheme="zettaPurple"
      marginRight="16px"
      fontWeight="500"
      isLoading={isLoading}
      onClick={onClick}
    >
      {t('Resend invitation')}
    </Button>
  )
}

const ActionsColumn = ({
  id,
  course_id: courseId,
  email,
  invited_by_the_user: invitedByTheUser,
  'user_invitation_accepted?': invitationAccepted
}) => (
  <>
    {
      (invitedByTheUser && !invitationAccepted) && (
        <ResendButton id={id} />
      )
    }
    <DeleteButton
      id={id}
      courseId={courseId}
      email={email}
    />
  </>
)

const NameAndPhotoHeading = () => {
  const { t } = useTranslation()
  return (
    <StyledTh
      padding="16px"
    >
      {t('talent')}
    </StyledTh>
  )
}

const EmailHeading = () => {
  const { t } = useTranslation()
  return (
    <StyledTh
      textAlign="left"
      borderColor="#D7D7D7"
      padding="0"
    >
      {t('email')}
    </StyledTh>
  )
}

const ActionsHeading = () => {
  const { t } = useTranslation()
  return (
    <StyledTh
      key={3}
      textAlign="right"
      width="30%"
    >
      {t('actions')}
    </StyledTh>
  )
}

export const InvitationsTable = () => {
  const { t } = useTranslation()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const pageNumber = searchParams.get('page')
  const listQuery = useListInvitationsQuery({ courseId, pageNumber })

  return (
    <Box pt="32px">
      <TableWrapperBase
        headings={[
          <NameAndPhotoHeading key={1} />,
          <EmailHeading key={2} />,
          <ActionsHeading key={3} />
        ]}
      >
        <TableItemMap
          listQuery={listQuery}
          emptyStateCopy={t('You haven’t invited any talent yet')}
        >
          {
            (props) => (
              <Tr key={props.id}>
                <TableItem
                  padding="10px 0"
                  columns={[
                    <NameAndPhotoColumn key={1} {...props} />,
                    <EmailColumn key={2} {...props} />,
                    <ActionsColumn key={3} {...props} />
                  ]}
                />
              </Tr>
            )
          }
        </TableItemMap>
      </TableWrapperBase>
      <Pagination listQuery={listQuery} />
    </Box>
  )
}
