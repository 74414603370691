import React, { useState, useMemo } from 'react'
import { useGetOpportunityOptions } from 'hooks/useFormOptions'
import SearchableSelect from 'components/forms/fields/SearchableSelect'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'

const OpportunitySelectorField = ({ formDisabled, errorVal }) => {
  const [inputValue, setInputValue] = useState('')
  const allOpportunityOptions = useGetOpportunityOptions()
  const { control } = useFormContext()
  const { t } = useTranslation()

  const filteredOpportunityOptions = useMemo(() => {
    let options = allOpportunityOptions
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    if (inputValue) {
      options = options.filter(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    }

    return options.slice(0, 3)
  }, [allOpportunityOptions, inputValue])

  const handleInputChange = (value) => {
    setInputValue(value)
  }

  return (
    <SearchableSelect
      name="opportunity_id"
      control={control}
      filteredOptions={filteredOpportunityOptions}
      allOptions={allOpportunityOptions}
      label={t('Associated Opportunity')}
      placeholder={t('Select one.')}
      helperText={!formDisabled && (
        t('Associate your course with an opportunity on {{MAKING_SPACE_NAME}}.', { MAKING_SPACE_NAME: 'Making Space' }) + ' ' +
        t('Candidates will be required to complete this course before they can apply.')
      )}
      isDisabled={formDisabled}
      errorVal={errorVal}
      onInputChange={handleInputChange}
    />
  )
}

export default OpportunitySelectorField
