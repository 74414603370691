import { PageContent } from './PageContent'
import { Global } from '@emotion/react'
import { GlobalFooter } from '../../GlobalFooter'

export const CoursePage = () => (
  <>
    <Global
      styles={{
        body: {
          backgroundColor: '#ffffff'
        }
      }}
    />
    <PageContent />
    <GlobalFooter
      mt="-24px"
    />
  </>
)
