import React from 'react'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { InputField, UploadMediaField } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { useGetMediaS3Path } from 'hooks/courseBuilder/useMediaField'

const IntroVideoThumbnailForm = ({ formDisabled=false, id=null }) => {
  const { t } = useTranslation()
  const mediaType = React.useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  const { courseSlug } = useCourseBuilderContext()
  const { register } = useFormContext()

  return (
    <>
      <UploadMediaField
        name="intro_video"
        label={t('Intro Video')}
        helperText={!formDisabled && t('Video length is limited to 10 minutes with a maximum file size of 250 MB. MP4 only.')}
        mediaType={mediaType.EPISODE_VIDEO}
        fieldRequired
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(0, courseSlug, 'intro_video')}
      />

      <UploadMediaField
        name="intro_thumbnail"
        label={t('Intro Video Thumbnail')}
        helperText={!formDisabled && t('This thumbnail is used in the course outline on the course description page. File size is limited to 5MG. PNG or JPG only.')}
        mediaType={mediaType.THUMBNAIL}
        fieldRequired
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(0, courseSlug, 'intro_thumbnail')}
      />
      <InputField
        label={t('Video Thumbnail Alt Text')}
        name="intro_thumbnail_alt_text"
        inputProps={{
          disabled: formDisabled,
          maxLength: 250,
          placeholder: t('A woman in a wheelchair stares directly to camera with the Gotham city skyline behind her.'),
          ...register(
            'intro_thumbnail_alt_text'
          )
        }}
      />
    </>
  )

}

export default IntroVideoThumbnailForm
