import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentTalentProfileId: null,
}

const partnerUxSlice = createSlice({
  name: 'partnerUx',
  initialState,
  reducers: {
    setCurrentTalentProfileId: (state, action) => {
      state.currentTalentProfileId = action.payload
    }
  }
})

export const {
  setCurrentTalentProfileId
} = partnerUxSlice.actions

export const partnerUxReducer = partnerUxSlice.reducer
