import { Box } from '@chakra-ui/react'

export const Details = ({ children }) => (
  <Box
    pb="32px"
    fontFamily="Poppins"
    fontSize="20px"
    textAlign={[null, null, 'center']}
  >
    {children}
  </Box>
)
