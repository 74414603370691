import { HStack, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { updateFormValues } from 'features/talent_search_drawer/talentSearchSlice'
import { useTalentSearch } from 'hooks/partnerUx/useTalentSearch'
import { useDispatch } from 'react-redux'

const FilterChip = ({ label, onRemove, ...props }) => {
  return (
    <Tag
      size="lg"
      borderRadius="full"
      variant="solid"
      colorScheme="green"
      fontFamily="Poppins"
      {...props}
    >
      <TagLabel>{label}</TagLabel>
      <TagCloseButton onClick={() => onRemove(label)} />
    </Tag>
  )
}

export const FilterChipRow = ({ switchConfigs }) => {
  const dispatch = useDispatch()
  const { formValues, lastSearchParams, searchFormValues } = useTalentSearch()

  // Function to handle the removal of a filter chip
  const handleRemoveChip = (chipLabel) => {
    const switchConfig = switchConfigs.find(config => config.label === chipLabel)
    if (switchConfig) {
      // Handle boolean filters
      dispatch(updateFormValues({ [switchConfig.name]: false }))
    } else {
      // Remove the keyword from the keywords string
      const newKeywords = formValues.keywords.split(/[\s,]+/).filter(kw => kw !== chipLabel).join(', ')
      dispatch(updateFormValues({ keywords: newKeywords }))
    }

    // Trigger a refetch
    searchFormValues()
  }

  // Generate the chips array from lastSearchParams
  const chips = Object.entries(lastSearchParams).reduce((chipLabels, [key, value]) => {
    if (typeof value === 'boolean' && value) {
      const switchConfig = switchConfigs.find(config => config.name === key)
      chipLabels.push(switchConfig ? switchConfig.label : key) // Add the friendly label for boolean filters
    } else if (key === 'keywords' && value.length) {
      chipLabels.push(...value) // Add all keywords
    }
    return chipLabels
  }, [])

  return (
    <HStack py="16px" px="16px" spacing="16px">
      {chips.map((chip) => (
        <FilterChip
          key={chip}
          label={chip}
          onRemove={() => handleRemoveChip(chip)}
        />
      ))}
    </HStack>
  )
}