import React, { useState, useEffect } from 'react'
import UnsavedChangesModal from 'components/courseBuilder/modals/UnsavedChangesModal'
import { useNavigate, useBlocker } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
import { IGNORE_NAV_BLOCKER } from 'constants'

const useNavBlocker = (message, shouldBlock = true) => {
  const { onClose, onOpen } = useDisclosure()
  const [isModalOpen, setModalOpen] = useState(false)
  const [pendingPath, setPendingPath] = useState(null)
  const navigate = useNavigate()

  // Block navigation on React navigation
  useBlocker(
    (transition) => {
      if (!shouldBlock) return
      if (transition.nextLocation?.state === IGNORE_NAV_BLOCKER.state) {
        return false
      }
      setModalOpen(true)
      onOpen()
      setPendingPath(window.location.origin + transition.nextLocation.pathname)
      return true
    },
    shouldBlock
  )

  // Block navigation on link clicks
  useEffect(() => {
    if (!shouldBlock) return

    const handleLinkClick = (event) => {
      const target = event.target.closest('a[href]')
      if (target && !event.defaultPrevented) {
        event.preventDefault()
        setPendingPath(target.href)
        setModalOpen(true)
        onOpen()
      }
    }

    document.addEventListener('click', handleLinkClick)

    return () => {
      document.removeEventListener('click', handleLinkClick)
    }
  }, [message, shouldBlock, onOpen])

  // if the user confirms navigation, navigate to the pending path
  const confirmNavigation = () => {
    setModalOpen(false)
    if (pendingPath) {
      const url = new URL(pendingPath)
      if (url.origin === window.location.origin) {
        navigate(url.pathname, IGNORE_NAV_BLOCKER)
      } else {
        window.location.href = pendingPath
      }
    }
    onClose()
  }

  // if the user cancels navigation, close the modal and clear the pending path
  const cancelNavigation = () => {
    setModalOpen(false)
    onClose()
    setPendingPath(null)
  }

  return {
    ConfirmExitModalComponent: () => (
      <UnsavedChangesModal
        isOpen={isModalOpen}
        message={message}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    )
  }
}

export default useNavBlocker
