import { useMemo } from 'react'
import { MEDIA_UPLOAD_PATHS, MEDIA_DELETE_PATHS } from 'constants'

export const useGetMediaS3Path = (episodeNum, courseSlug, mediaItem) => {
  const paths = useMemo(() => ({
    upload: MEDIA_UPLOAD_PATHS(episodeNum, courseSlug),
    delete: MEDIA_DELETE_PATHS(episodeNum, courseSlug)
  }), [episodeNum, courseSlug])

  return {
    uploadPath: paths.upload[mediaItem],
    deletePath: paths.delete[mediaItem]
  }
}
