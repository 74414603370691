import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { HiUserPlus } from 'react-icons/hi2'

export const InviteTalentButton = () => {
  const { t } = useTranslation()
  return (
    <Box
      as={Link}
      to="invite"
      display="block"
      flex="1"
      maxWidth={[null, null, '400px']}
    >
      <Center
        padding="52px 0"
        flexDirection="column"
        bgColor="rgba(80,0,224,0.1)"
        color="var(--design-primary, #5000E8)"
        fontFamily="Spline Sans Mono"
        fontSize="16px"
        height="100%"
      >
        <Center fontSize="24px">
          <HiUserPlus />
        </Center>
        <Box pt="12px">
          {t('Invite more talent')}
        </Box>
      </Center>
    </Box>
  )
}
