import { Td } from '@chakra-ui/react'

export const TableItemMobile = ({
  border = '0',
  borderBottom,
  padding = '24px 10px',
  columns: [
    column1,
    column2,
    column3
  ]
}) => (
  <Td
    border={border}
    borderBottom={borderBottom}
    padding={padding}
  >
    {column1}
    {column2}
    {column3}
  </Td>
)
