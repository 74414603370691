import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { InputField, TextField } from 'components'
import {
  Box,
} from '@chakra-ui/react'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const EpisodeQuizForm = ({ episodeNum, formDisabled=false }) => {
  const { t } = useTranslation()
  const { register } = useFormContext() // retrieve all hook methods
  const { helperTextVals, placeholderTextVals } = useGetFormTextValues()
  const { quizCount } = useCourseBuilderContext()

  return (
    <>
      {quizCount[episodeNum].map((index, arrayIndex) => (
        (() => {
          const questionNumber = arrayIndex + 1
          const quizFieldNames = {
            episode_quiz_question: `episode_${episodeNum}_quiz_question_${index}`,
            episode_quiz_answer: `episode_${episodeNum}_quiz_question_${index}_correct_answer`,
            episode_quiz_incorrect_answer_1: `episode_${episodeNum}_quiz_question_${index}_incorrect_answer_1`,
            episode_quiz_incorrect_answer_2: `episode_${episodeNum}_quiz_question_${index}_incorrect_answer_2`,
            episode_quiz_incorrect_answer_3: `episode_${episodeNum}_quiz_question_${index}_incorrect_answer_3`,
          }
          return (
            <Box id={`episode-quiz-${questionNumber}`} key={`episode-quiz-${questionNumber}`}>
              <TextField
                name={quizFieldNames.episode_quiz_question}
                textFieldProps={{
                  label: t(`Question #${questionNumber}`),
                  helperText: !formDisabled && helperTextVals.episode_quiz_question,
                }}
                inputProps={{
                  disabled: formDisabled,
                  placeholder: placeholderTextVals.episode_quiz_question,
                  ...register(
                    quizFieldNames.episode_quiz_question, {
                      validate: value => value.length <= 250 || 'Max char 250.'
                    }
                  )
                }}
              />
              <InputField
                label={t(`Correct Answer for Question #${questionNumber}`)}
                helperText={!formDisabled && helperTextVals.episode_quiz_answer}
                name={quizFieldNames.episode_quiz_answer}
                fieldRequired
                inputProps={{
                  disabled: formDisabled,
                  placeholder: t('Cumulonimbus'),
                  ...register(
                    quizFieldNames.episode_quiz_answer, {
                      validate: value => value.length <= 250 || 'Max char 250.'
                    }
                  )
                }}
              />
              <InputField
                label={t(`Incorrect Answers for Question #${questionNumber}`)}
                helperText={!formDisabled && helperTextVals.episode_quiz_incorrect_answer}
                name={quizFieldNames.episode_quiz_incorrect_answer_1}
                fieldRequired
                paddingBottom="4"
                inputProps={{
                  disabled: formDisabled,
                  placeholder: t('Cirrostratus'),
                  ...register(
                    quizFieldNames.episode_quiz_incorrect_answer_1, {
                      validate: value => value.length <= 250 || 'Max char 250.'
                    }
                  )
                }}
              />
              <InputField
                name={quizFieldNames.episode_quiz_incorrect_answer_2}
                paddingBottom="4"
                inputProps={{
                  disabled: formDisabled,
                  placeholder: t('Altocumulus'),
                  ...register(
                    quizFieldNames.episode_quiz_incorrect_answer_2, {
                      validate: value => value.length <= 250 || 'Max char 250.'
                    }
                  )
                }}
              />
              <InputField
                name={quizFieldNames.episode_quiz_incorrect_answer_3}
                inputProps={{
                  disabled: formDisabled,
                  placeholder: t('Stratocumulus'),
                  ...register(
                    quizFieldNames.episode_quiz_incorrect_answer_3, {
                      validate: value => value.length <= 250 || 'Max char 250.'
                    }
                  )
                }}
              />
            </Box>
          )
        })()
      ))}
    </>
  )
}

export default EpisodeQuizForm
