import React from 'react'
import { Box, Center } from '@chakra-ui/react'

const PageOuterContainer = ({ children, greenBg }) => {
  const bgSvg = greenBg ? 'partner-bg.svg' : 'catalog-bg.svg'
  return (
    <Center
      bg={`url(${process.env.PUBLIC_URL}/${bgSvg})`}
      bgRepeat="no-repeat"
      backgroundPosition="top"
    >
      <Box
        flexGrow="1"
        as="main" role="main"
        maxWidth="1440px"
        paddingTop="32px"
        paddingLeft={['8px', null, '36px']}
        paddingRight={['8px', null, '36px']}
      >
        {children}
      </Box>
    </Center>
  )
}

export default PageOuterContainer
