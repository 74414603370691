import { useStepId } from 'CourseFeature/hooks'
import { useFetchQuizByStepIdQuery } from 'features/quiz/quizzesApi'
import { openQuizForStepId, setQuizMode } from 'features/quiz/talentQuizSlice'
import { TALENT_QUIZ_MODE } from 'constants'

export const hasCorrectAnswer = (question) => {
  return question.talent_answer_round?.attempts.some((attempt) => attempt.correct)
}

export const hasCompletedQuiz = (quiz) => {
  return quiz?.questions?.every((question) => hasCorrectAnswer(question))
}

export const attemptsContainsAnswer = (attempts, answer) => attempts.some((attempt) => attempt.attempted_answer === answer)

export const remainingQuestions = (quiz) => {
  return quiz?.questions?.filter((question) => !hasCorrectAnswer(question)) || []
}

export const useHasCompletedQuiz = (id) => {
  const stepId = useStepId()
  const validStepId = id ? String(id) : stepId
  const { data: quizData, isLoading } = useFetchQuizByStepIdQuery(validStepId)

  return { hasCompletedQuiz: hasCompletedQuiz(quizData), isLoading }
}

export const handleQuizButtonClick = ({ stepId, hasCompletedQuiz, dispatch, additionalAction=null }) => {
  if ( hasCompletedQuiz ) dispatch(setQuizMode(TALENT_QUIZ_MODE.REVIEW))
  additionalAction && additionalAction()
  dispatch(openQuizForStepId(stepId))
}
