import { Box } from '@chakra-ui/react'

const FormErrorDisplay = ({ courseBuilder=false, name, errorVal={} }) => {
  const errorMessage = courseBuilder ? errorVal[name] : (errorVal[name] && errorVal[name].message)
  return (
    <Box mt="5px" fontSize="16px">
      <Box color="#CD0050" textTransform="uppercase" fontWeight="500">
        {errorMessage}
      </Box>
    </Box>
  )
}

export default FormErrorDisplay
