import React from 'react'
import { PartnerFormContent } from './PartnerFormContent'
import { TalentFormContent } from './TalentFormContent'

export const FormContent = () => {
  const locationPath = window.location.pathname
  if (locationPath.includes('partner')) {
    return <PartnerFormContent />
  } else {
    return <TalentFormContent />
  }
}
