import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Box } from '@chakra-ui/react'
import { HiOutlineRectangleGroup } from 'react-icons/hi2'
import { useTranslation } from 'i18n/TranslationContext'

const OpportunitiesButton = ({ to = '/opportunities', icon = HiOutlineRectangleGroup, label = 'Opportunities', variant = 'link', ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      as={NavLink}
      end
      to={to}
      variant={variant}
      leftIcon={<Box as={icon} fontSize="24px" />}
      display="flex"
      fontWeight="500"
      style={{
        textDecoration: 'none'
      }}
      _activeLink={{
        color: '#5000E8',
        opacity: '1'
      }}
      color="rgba(15, 32, 75, 0.62)"
      {...props}
    >
      {t(label)}
    </Button>
  )
}

export default OpportunitiesButton
