import { useTranslation } from 'i18n/TranslationContext'
import { Box } from '@chakra-ui/react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { TalentView } from './TalentView'
import { AnimatePresence } from 'framer-motion'
import { usePageTitle } from 'usePageTitle'
import { TalentProfileView } from '../TalentProfileView'
import { ContactDetails } from '../TalentListPage/ContactDetails'
import { PageContent } from './PageContent'
import { useCourseId } from '../../CourseFeature/hooks'
import { CourseIdProvider } from '../../CourseContext'

const FallbackRoute = () => {
  const { t } = useTranslation()
  usePageTitle(t('Assignments'))

  return null
}

const DrawerRoutes = () => {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/talent_profile/:talentProfileId"
          element={<TalentView />}
        />
        <Route
          path="/talent_profile/:talentProfileId/view"
          element={
            <TalentProfileView />
          }
        />
        <Route
          path="/talent_profile/:talentProfileId/view/contact_request"
          element={
            <ContactDetails />
          }
        />
        <Route
          path="*"
          element={<FallbackRoute />}
        />
      </Routes>
    </AnimatePresence>
  )
}

export const AssignmentsListPage = () => (
  <CourseIdProvider courseId={useCourseId()}>
    <Box position="relative" flex="1">
      <DrawerRoutes />
      <PageContent />
    </Box>
  </CourseIdProvider>
)
