import { EditorState, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

export const convertHtmlToEditorState = (value) => {
  // used to convert HTML string to usable state for Editor Block
  const blocksFromHtml = htmlToDraft(value)
  const { contentBlocks, entityMap } = blocksFromHtml
  const editorContent = ContentState.createFromBlockArray(contentBlocks, entityMap)

  return EditorState.createWithContent(editorContent)
}
