import { useTranslation } from 'i18n/TranslationContext'
import { Text, Box, Flex, Spinner, Textarea, useToast, Button } from '@chakra-ui/react'
import { SubmitButton } from 'shared/SubmitButton'
import { FormProvider, useForm } from 'react-hook-form'
import { useAddCommentMutation, useDeleteCommentMutation, useGetCommentsQuery } from 'features/commentsApi'
import { useLocation } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { HiUser } from 'react-icons/hi'
import { H2 } from 'shared/typography'
import { useEpisodeNumber, useIsStepsMode, useStepId } from '../hooks'
import { useCurrentCourseQuery } from '../CoursePage/useCurrentCourseQuery'

dayjs.extend(relativeTime)

const FromNow = ({ time }) => dayjs(time).fromNow()

const useCommentsData = () => {
  const { data: { id } } = useCurrentCourseQuery()
  const episodeNumber = useEpisodeNumber()
  const stepId = useStepId()
  const isSteps = useIsStepsMode()
  const episodePath = isSteps ? `steps/${stepId}` : `episodes/${episodeNumber}`

  return useGetCommentsQuery(`/courses/${id}/${episodePath}`)
}

const useFormErrorToast = (error, message) => {
  const { t } = useTranslation()
  const messageResult = message || t('Something went wrong')
  const toast = useToast()
  useEffect(() => {
    if (error) {
      toast({
        title: messageResult,
        status: 'error',
        position: 'top',
        containerStyle: {
          position: 'absolute',
          maxWidth: 'unset',
          width: '100%',
          margin: '0'
        }
      })
    }
  }, [error, toast, message, t, messageResult])
}

const AvatarPlaceholder = () => (
  <Box
    padding={1}
    color="gray"
    bgColor="lightgrey"
    borderRadius="16px"
    fontSize="48px"
  >
    <HiUser aria-hidden />
  </Box>
)

const DeleteButton = ({ id }) => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [deleteComment, { error }] = useDeleteCommentMutation()

  useFormErrorToast(error)

  const onDeleteClicked = () => {
    if (window.confirm(t('Are you sure you want to delete the comment?'))) {
      deleteComment({
        episodePath: pathname,
        id
      })
    }
  }


  return (
    <Box pr="16px">
      <Button
        size="sm"
        colorScheme="zettaBlue"
        fontWeight="normal"
        variant="link"
        textDecoration="underline"
        onClick={onDeleteClicked}
      >
        {t('Delete')}
      </Button>
    </Box>
  )
}

const Avatar = ({ photoUrl, photoAltText }) => {
  if (!photoUrl) {
    return <AvatarPlaceholder />
  }

  return (
    <Box as="img"
      width="56px"
      height="56px"
      borderRadius="16px"
      src={photoUrl}
      alt={photoAltText || ''}
    />
  )
}

const Comment = ({
  comment: {
    id,
    can_delete: canDelete,
    created_at: timestamp,
    content,
    talent_profile: {
      name,
      photo_url: photoUrl,
      photo_alt_text: photoAltText
    }
  }
}) => {
  const { t } = useTranslation()
  return (
    <Flex
      role="comment"
      fontFamily="Poppins"
      fontSize="16px"
      color="#0F204B"
      pb="48px"
    >
      <Box
        flexShrink="0"
        mr="16px"
      >
        <Avatar
          photoUrl={photoUrl}
          photoAltText={photoAltText}
        />
      </Box>
      <Box>
        <Box>
          <Text as="b">{name} </Text>

          {t('said')}
        </Box>
        <Box pt={1}>
          {content}
        </Box>
        <Flex
          pt={1}
          fontSize="14px"
        >
          { canDelete && <DeleteButton id={id} /> }
          <FromNow time={timestamp} />
        </Flex>
      </Box>
    </Flex>
  )
}

const Comments = () => {
  const { data, isLoading } = useCommentsData()

  if (isLoading) {
    return <Spinner />
  }

  return data.map((comment) => (
    <Comment
      key={comment.id}
      comment={comment}
    />
  ))
}

const CommentsList = () => {
  return (
    <Box
      pt="32px"
    >
      <Comments />
    </Box>
  )
}

const NewCommentForm = () => {
  const { pathname } = useLocation()
  const [addComment, { error, isSuccess }] = useAddCommentMutation()
  useFormErrorToast(error)
  const { t } = useTranslation()

  const defaultValues = useMemo(() => ({
    episodePath: pathname,
    content: ''
  }), [pathname])
  const methods = useForm({ defaultValues })
  const { handleSubmit, register, reset } = methods

  useEffect(() => {
    if (!isSuccess) { return }

    reset(defaultValues, { keepDefaultValues: true })
  }, [isSuccess, reset, defaultValues])

  return (
    <Box
      pt="24px"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(addComment)}>
          <Box pb="16px">
            <Textarea
              placeholder={t('Write your comment')}
              { ...register('content', { required: true }) }
            />
          </Box>
          <SubmitButton
            colorScheme="zettaBlue"
            size="lg"
            fontWeight="500"
          >
            {t('Submit comment')}
          </SubmitButton>
        </form>
      </FormProvider>
    </Box>
  )
}

const CommentsCounter = () => {
  const { data } = useCommentsData()
  if (data) { return `(${data.length})` }

  return null
}

export const CommentsBlock = () => {
  const { t } = useTranslation()
  return (
    <Box
      as="section"
      mt="24px"
      pr="24px" pl="24px" pt="30px" pb="24px"
      bgColor="white"
    >
      <Box
        pb="32px"
        borderBottom="1px solid #EFEFF0"
      >
        <H2 color="#0F204B" fontSize="20px">
          {t('Comments')}{' '}<CommentsCounter />
        </H2>
        <NewCommentForm />
      </Box>
      <CommentsList />
    </Box>
  )
}
