import { useTranslation } from 'i18n/TranslationContext'
import { Flex, Text } from '@chakra-ui/react'
import { PersonalLink } from 'components/partner/talent_profile_view'

const PortfolioLinks = ({ portfolioUrl, otherUrl }) => {
  const { t } = useTranslation()
  return (
    <Flex direction="column" mt={50} gap={17} className="portfolio-links">
      <Text
        fontFamily="Spline Sans Mono"
        fontSize="18px"
        color="var(--design-content, #0F204A)"
        opacity="0.7"
        fontWeight={600}
      >
        {t('Professional Links')}
      </Text>

      {portfolioUrl && <PersonalLink url={portfolioUrl} label={t('Portfolio')} />}
      {otherUrl && <PersonalLink url={otherUrl} label={t('Additional Link')} />}
    </Flex>
  )
}

export default PortfolioLinks
