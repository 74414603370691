export const ImageSet = ({ photoSet, alt, tabIndex, ...props }) => (
  <picture>
    <source
      srcSet={`
          ${photoSet[0]} 2x,
          ${photoSet[1]}
      `}
    />
    <img
      alt={alt}
      src={photoSet[0]}
      tabIndex={tabIndex}
      {...props}
    />
  </picture>
)
