import React from 'react'
import { Flex, Box } from '@chakra-ui/react'

const OrangeTape = () => (
  <Flex
    position="absolute"
    width="100%"
    height="100%"
    zIndex={-1}
    _before={{
      display: 'block',
      content: '" "',
      width: '37px',
      bgImage: `${process.env.PUBLIC_URL}/tape1-left.svg`,
      bgSize: 'cover',
    }}
    _after={{
      display: 'block',
      content: '" "',
      width: '28px',
      bgImage: `${process.env.PUBLIC_URL}/tape1-right.svg`,
      bgSize: 'cover',
    }}
  >
    <Box bg="#FF7D19" opacity="0.85" flex="1" />
  </Flex>
)

export default OrangeTape
