import React from 'react'
import {
  Box,
} from '@chakra-ui/react'
import HeaderAvatarPlaceholder from './HeaderAvatarPlaceholder'

const HeaderAvatar = ({ photoUrl, className, headerType, widthHeight }) => {
  if (!photoUrl) {
    return (
      <HeaderAvatarPlaceholder className={className} headerType={headerType} />
    )
  }

  return (
    <Box as="img"
      className={className}
      width={widthHeight || '48px'}
      height={widthHeight || '48px'}
      borderRadius="50%"
      display="block"
      margin="2px"
      src={photoUrl}
      alt=""
      objectFit="cover"
    />
  )
}

export default HeaderAvatar
