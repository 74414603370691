import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from 'usePageTitle'
import { PageOuterContainer } from 'shared/TalentsLayout'
import { AppLoadingState } from 'AppLoadingState'
import { useGetTalentProfileQuery } from 'features/talentProfilesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { OnboardingForm } from './OnboardingForm'

const PageContent = () => {
  const { t } = useTranslation()
  usePageTitle(t('Tell us about yourself'))
  const { data: { talent_profile: { id } } } = useGetCurrentUserQuery()
  const { isSuccess } = useGetTalentProfileQuery({ id })

  if (!isSuccess) {
    return <AppLoadingState />
  }

  return (
    <PageOuterContainer>
      <OnboardingForm />
    </PageOuterContainer>
  )
}

export const TalentOnboard = () => {
  const { isSuccess } = useGetCurrentUserQuery()

  if(!isSuccess) { return null }

  return (
    <PageContent />
  )
}
