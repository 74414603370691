import React from 'react'
import { SlideDeckPlayer } from 'components/slideDeckPlayer'
import { useCurrentEpisodeData, useGetSlideDeckData } from '../hooks'
import { Box } from '@chakra-ui/react'

const SlideDeckBlock = () => {
  const slides = useGetSlideDeckData()
  const currentEpisode = useCurrentEpisodeData()
  const stepId = currentEpisode.id


  return (
    <Box position="relative">
      <SlideDeckPlayer key={stepId} slides={slides} stepId={stepId} />
    </Box>
  )
}

export default SlideDeckBlock
