import { Box, Center, Flex, HStack, Hide, Show } from '@chakra-ui/react'
import { CoursePoster } from './CoursePoster'
import { Link } from 'react-router-dom'
import { CourseSubtitle } from './CourseSubtitle'
import { NewCourseBadge } from 'shared/NewCourseBadge'

const isWithinLast60Days = (date) => {
  const today = new Date()
  const sixtyDaysAgo = new Date(today.setDate(today.getDate() - 60))
  const updatedAt = new Date(date)
  return updatedAt >= sixtyDaysAgo
}

const shouldShowNewCourseBadge = (updated_at, status, slug) => {
  const restrictedSlugs = ['screenwriting101', 'directing101', 'netflix_key-art']
  return (
    isWithinLast60Days(updated_at) &&
      status === 'live' &&
      !restrictedSlugs.includes(slug)
  )
}

const Column1Desktop = ({ id, name, poster, visibility, authors, slug, updated_at, status }) => {
  return (
    <Flex className="column1-desktop__course-list-item">
      <CoursePoster
        id={id}
        name={name}
        poster={poster}
      />
      <Center whiteSpace="normal">
        <Box>
          <HStack spacing={4}>
            <Box
              fontSize="24px"
              fontFamily="Poppins"
              fontWeight="600"
              color="#0F204A"
            >
              <Link to={`/courses/${id}`}>{name}</Link>
            </Box>
            { shouldShowNewCourseBadge(updated_at, status, slug) && <NewCourseBadge /> }
          </HStack>

          <CourseSubtitle
            authors={authors}
            visibility={visibility}
            slug={slug}
            updated_at={updated_at}
            status={status}
          />
        </Box>
      </Center>
    </Flex>
  )
}

const Column1Mobile = ({ id, name, episodesCount, poster, visibility, authors, slug, updated_at, status }) => (
  <Link to={`/courses/${id}`}>
    <Flex paddingBottom="12px">
      <CoursePoster
        id={id}
        name={name}
        poster={poster}
      />
      <Flex
        whiteSpace="normal"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box
          fontSize="20px"
          fontFamily="Spline Sans Mono"
          fontWeight="700"
        >
          {name}
        </Box>
        { shouldShowNewCourseBadge(updated_at, status, slug) && <Box mt={2}><NewCourseBadge /></Box> }
        <CourseSubtitle
          authors={authors}
          visibility={visibility}
        />
        <Box
          fontSize="16px"
          fontFamily="Poppins"
          fontWeight="500"
          mt={2}
        >
          Episodes: {episodesCount}
        </Box>
      </Flex>
    </Flex>
  </Link>
)

export const Column1 = (props) => {
  return (
    <>
      <Hide above="768px">
        <Column1Mobile
          {...props}
        />
      </Hide>
      <Show above="md">
        <Column1Desktop
          {...props}
        />
      </Show>
    </>
  )
}
