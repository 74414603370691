import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react'
import ProfileContent from 'components/partner/talent/list_page/profile_drawer/ProfileContent'

const ProfileDrawer = ({ isOpen, onClose }) => {
  const bodyStyle = {
    background: `url(${process.env.PUBLIC_URL}/contact-request-bg.svg) 0 0 / 102% auto no-repeat local, 
                 linear-gradient( rgba(177, 244, 174, 0.97) 100%, rgba(177, 244, 174, 0.97) 100%), 
                 url(${process.env.PUBLIC_URL}/noise.png)`
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="xl"
      className="talent-profile-drawer"
      boxShadow="lg"
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody style={bodyStyle} p="0">
            <ProfileContent onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default ProfileDrawer
