import { useTranslation } from 'i18n/TranslationContext'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from 'UnauthenticatedApp/InputField'
import { Box, useToast } from '@chakra-ui/react'
import { SubmitButton } from 'UnauthenticatedApp/SubmitButton'
import { EmailTakenMessage } from 'UnauthenticatedApp/EmailTakenMessage'
import { useEffect, useState } from 'react'
import useHideShowPassword from 'UnauthenticatedApp/hooks/useHideShowPassword'

const UserForm = ({ error, onSubmit, token, email, isEmailReadOnly = false, additionalFields }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [emailTaken, setEmailTaken] = useState(false)
  const methods = useForm({
    defaultValues: {
      email,
      token,
      first_name: '',
      last_name: '',
      password: ''
    }
  })
  const { handleSubmit, watch, trigger } = methods

  const {
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    isPasswordMatch,
    password,
  } = useHideShowPassword(watch, trigger)

  useEffect(() => {
    if (error) {
      setEmailTaken(!!error?.data?.errors?.duplicate_email)
      toast({
        title: t('Something went wrong'),
        status: 'error',
        position: 'top',
        containerStyle: {
          position: 'absolute',
          maxWidth: 'unset',
          width: '100%',
          margin: '0'
        }
      })
    }
  }, [error, toast, t])

  return (
    <Box width="100%">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label={t('First Name')}
            name="first_name"
            inputProps={{
              autoComplete: 'first_name',
              placeholder: 'Jane'
            }}
          />
          <InputField
            label={t('Last Name')}
            name="last_name"
            inputProps={{
              autoComplete: 'last_name',
              placeholder: 'Smith'
            }}
          />
          <InputField
            label={t('Your email')}
            name="email"
            inputProps={{
              type: 'email',
              autoComplete: 'email',
              placeholder: 'jane.smith@abc123.com',
              isReadOnly: isEmailReadOnly
            }}
          />
          {emailTaken && <EmailTakenMessage />}
          <InputField
            label={t('Password')}
            name="password"
            inputProps={{
              type: showPassword ? 'text' : 'password',
              autoComplete: 'new-password',
              placeholder: t('Minimum 6 characters.')
            }}
            clickShowPw={{
              setShowPw: handleShowPassword,
              showPw: showPassword
            }}
          />
          <InputField
            label={t('Confirm Password')}
            name="confirmation"
            inputProps={{
              type: showConfirmPassword ? 'text' : 'password',
              autoComplete: 'new-password',
              placeholder: t('Minimum 6 characters.')
            }}
            clickShowPw={{
              setShowPw: handleShowConfirmPassword,
              showPw: showConfirmPassword
            }}
            passwordMatch={isPasswordMatch}
            validate={value => value === password || t('passwords do not match')}
          />
          <Box pt="16px">
            <SubmitButton>{t('Start watching')}</SubmitButton>
          </Box>
          {additionalFields && additionalFields()}
        </form>
      </FormProvider>
    </Box>
  )
}

export default UserForm
