import { apiSlice } from '../apiSlice'

const createMessageHash = (message) => (
  { text: message.content[0].text.value, role: message.role, id: message.id }
)

const transformBeaconResponse = (response) => {
  const messages = response.data.map((message) => createMessageHash(message))
  return { messages, currentStep: response.current_step }
}

export const beaconApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initBot: builder.mutation({
      query: ({ botName }) => ({
        url: '/beacon/init_bot',
        method: 'POST',
        body: { bot_name: botName }
      }),
      transformResponse: transformBeaconResponse
    }),
    resetBot: builder.query({
      query: ({ botName }) => ({
        url: `/beacon/${botName}/reset_bot`,
        method: 'POST'
      })
    }),
    getResponse: builder.mutation({
      query: ({ botName, message }) => ({
        url: `/beacon/${botName}/prompt_bot`,
        method: 'POST',
        body: { message }
      }),
      transformResponse: transformBeaconResponse
    })
  })
})

