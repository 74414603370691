import React from 'react'
import { SimpleGrid, Tag } from '@chakra-ui/react'

const SkillsGrid = ({ skills }) => (
  <SimpleGrid columns={2} spacingX={2} spacingY={4} mt={4} ml={4} width="fit-content">
    {skills.map(skill => (
      <Tag
        key={skill}
        size="md"
        bg="white"
        color="gray.700"
        borderRadius="full"
        px={6}
        py={2}
        fontWeight="bold"
        border="3px solid"
        borderColor="gray.200"
        fontSize="sm"
        display="inline-flex"
        justifyContent="flex-start"
        _hover={{ borderColor: 'gray.300' }}
      >
        {skill}
      </Tag>
    ))}
  </SimpleGrid>
)

export default SkillsGrid
