import { useCourseBasePath } from '../../hooks'
import { useCourseData } from '../../CourseData'
import { Box } from '@chakra-ui/react'

export const TopicPreview = ({ topicId, ...props }) => {
  const basePath = useCourseBasePath()
  const { topics } = useCourseData()
  const { previewAltText } = topics[topicId]

  return (
    <picture>
      <source
        srcSet={`
          ${basePath}/topics/${topicId+1}/poster@2x.jpg 2x,
          ${basePath}/topics/${topicId+1}/poster.jpg
        `}
      />
      <Box as="img"
        width="100%"
        alt={previewAltText}
        src={`${basePath}/topics/${topicId+1}/poster@2x.jpg`}
        {...props}
      />
    </picture>
  )
}
