import { useTranslation } from 'i18n/TranslationContext'

const VisibilityLabel = ({ visibility }) => {
  const { t } = useTranslation()
  if (visibility === 'visible_for_all_users') {
    return t('Public')
  }
  return t('Private')
}

export default VisibilityLabel
