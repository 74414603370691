import { useCourseBasePath } from '../hooks'
import { ImageSet } from './shared/ImageSet'

export const CourseImageSet = ({ photoSet, ...props }) => {
  const base = useCourseBasePath()

  return (
    <ImageSet
      {...props}
      photoSet={photoSet.map(p => `${base}/${p}`)}
    />
  )
}
