import { useMemo } from 'react'
import { useTranslation } from 'i18n/TranslationContext'

export const useGetFormTextValues = () => {
  const { t } = useTranslation()

  const placeholderTextVals = useMemo(() => ({
    partner_name: t('Charlotte Smith'),
    company_name: 'Making Space',
    company_website: 'https://making-space.com',
    greenhouse_api_key: t('Place API key here'),
    greenhouse_api_user_id: t('Select Greenhouse User'),
    company_description: t('Making Space brings highly competitive learning and career opportunities to the Disabled community. We do this by putting education at the frontend of the hiring process, which leads to better outcomes for both employers and job-seekers.'),
    company_accessibility_policy: t('We provide each of our employees with the tools needed for success from day 1 by offering suggestions of what’s available instead of waiting for them to tell us what’s needed.'),
  }), [t])

  return { placeholderTextVals }
}
