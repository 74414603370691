import React from 'react'
import { HiPlusCircle, HiMinusCircle } from 'react-icons/hi'
import {
  Button,
  Link as ChakraLink,
  Tooltip
} from '@chakra-ui/react'

const buttonStyle = (determineType) => ({
  float: determineType === 'minus' ? 'right' : null,
  color: determineType === 'minus' ? '#CD0050' : null,
})

const AddSubtractButton = ({ determineType, textValue, onClickAction, currentCount, totalCount, maxAmtTooltipText }) => {
  const reachedMaxLimit = (totalCount && currentCount) && currentCount.length > totalCount && determineType === 'add'
  return (
    <Tooltip
      label={maxAmtTooltipText}
      // Don't show this tooltip if maxAmtTooltipText isn't provided or if the user hasn't reached the max limit
      isDisabled={!maxAmtTooltipText || !reachedMaxLimit}
    >
      <Button
        fontSize={['18px', '18px', '20px']}
        as={ChakraLink}
        {...buttonStyle(determineType)}
        zIndex="10"
        fontWeight="400"
        fontFamily="Poppins"
        textDecoration="none"
        variant="link"
        isDisabled={reachedMaxLimit}
        onClick={onClickAction}
        colorScheme="zettaBlue"
      >
        {determineType === 'add' ? <HiPlusCircle style={{ marginRight: '5px' }} /> : <HiMinusCircle style={{ marginRight: '5px' }} />}
        {textValue}
      </Button>
    </Tooltip>
  )
}

export default AddSubtractButton
