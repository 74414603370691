import React, { useRef } from 'react'
import { FormTemplate } from 'components/builder/FormTemplate'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useSelector } from 'react-redux'
import { Body } from 'components'
import { AssignmentForm } from 'components/courseBuilder/formContent'

const IntroText = () => {
  const { introTextVals } = useGetFormTextValues()
  return (
    <>
      <Body>
        {introTextVals.course_assignment}
      </Body>
    </>
  )
}

export const Assignment = ({ nextStep, formForStep }) => {
  const { t } = useTranslation()
  const courseData = useSelector(state => state.courseBuilder)
  const initialized = useRef(false)

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)

  return (
    <FormTemplate
      introTextComponent={IntroText}
      formTitle={t('Assignment Details')}
      FormContent={AssignmentForm}
      formForStep={formForStep}
      nextStep={nextStep}
    >
      <AssignmentForm />
    </FormTemplate>
  )
}

