import React from 'react'
import { FormControl, Select } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import FieldHeader from '../FieldHeader'
import FormErrorDisplay from '../FormErrorDisplay'
import { formStyles } from '../formStyles'

const SelectField = ({
  helperText,
  label,
  name,
  control,
  options,
  width,
  fieldRequired,
  errorVal = {},
  paddingBottom,
  isDisabled,
  placeholder,
  defaultValue
}) => {
  const { sharedStyles, selectFieldStyles, formControlStyles, disabledStyleProp } = formStyles()

  let builderErrors = Object.keys(errorVal).length === 0
  if (builderErrors) {
    errorVal = {}
  }

  return (
    <FormControl
      pb={paddingBottom || '40px'}
      isInvalid={!!errorVal[name]}
      isRequired={fieldRequired}
      {...formControlStyles}
    >
      <FieldHeader helperText={helperText} label={label} />
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            placeholder={placeholder}
            isDisabled={isDisabled}
            width={width || '100%'}
            {...sharedStyles}
            {...selectFieldStyles}
            style={isDisabled ? { ...disabledStyleProp } : {}}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
      <FormErrorDisplay courseBuilder={builderErrors} name={name} errorVal={errorVal} />
    </FormControl>
  )
}

export default SelectField
