import { Box } from '@chakra-ui/react'

const Body = ({ children, ...restProps }) => (
  <Box
    as="p"
    color="#0F204B"
    fontSize="20px"
    fontFamily="Poppins"
    lineHeight="27px"
    pb="24px"
    {...restProps}
  >
    {children}
  </Box>
)

export default Body
