import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from 'usePageTitle'
import { Box, Center, useToast } from '@chakra-ui/react'
import { Header1 } from '../Header1'
import { Details } from '../Details'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../InputField'
import { SubmitButton } from '../SubmitButton'
import { useSetNewPasswordMutation } from 'features/sessionsApi'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const NewPasswordForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()
  const [setPassword, { isSuccess, error } ] = useSetNewPasswordMutation()
  const token = useSearchParams()[0].get('token')

  const methods = useForm({
    defaultValues: {
      password: '',
      token
    }
  })
  const { handleSubmit } = methods

  useEffect(() => {
    if (token) { return }

    navigate('/forgot_password')
  }, [token, navigate])

  useEffect(() => {
    if (!isSuccess) { return }

    toast({
      title: t('Your Password was successfully changed!'),
      status: 'success',
      position: 'top',
      containerStyle: {
        position: 'absolute',
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })

    navigate('/sign_in')
  }, [isSuccess, toast, navigate, t])

  useEffect(() => {
    if (!error) { return }

    toast({
      title: t('Something went wrong'),
      status: 'error',
      position: 'top',
      containerStyle: {
        position: 'absolute',
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [error, toast, t])

  return (
    <FormProvider {...methods}>
      <Box as="form"
        pb="54px"
        minWidth={[null, null, '440px']}
        onSubmit={handleSubmit(setPassword)}
      >
        <InputField
          label={t('New Password')}
          name="password"
          inputProps={{
            type: 'password',
            autoComplete: 'new-password'
          }}
        />
        <Box pt="16px">
          <SubmitButton>{t('Reset password')}</SubmitButton>
        </Box>
      </Box>
    </FormProvider>
  )
}


const PageContent = () => {
  const { t } = useTranslation()
  return (
    <>
      <Header1>
        {t('Enter a new password')}
      </Header1>
      <Center
        maxWidth="660px"
        flexDirection="column"
      >
        <Details>
          {t('Enter a new password for your account')}
        </Details>
        <NewPasswordForm />
      </Center>
    </>
  )
}

export const NewPasswordPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Set new password'))

  return (
    <Box
      background="#F0E9E6"
      pt="84px" pb="40px"
    >
      <Center flexDirection="column">
        <Box pl="64px" pr="64px">
          <PageContent />
        </Box>
      </Center>
    </Box>
  )
}
