import React from 'react'
import AuthorsLabel from './AuthorsLabel'
import VisibilityLabel from './VisibilityLabel'

const SubTitleLabel = ({ visibility, authors }) => (
  <>
    <AuthorsLabel authors={authors} /> • <VisibilityLabel visibility={visibility} />
  </>
)

export default SubTitleLabel
