import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { deleteShiftEpisodeSteps, removeValueFromArray } from 'features/courses/courseBuilderSlice'
import { getCourseStepsContent } from 'CourseBuilder/getCourseStepsContent'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const shiftUpdateForms = (courseStepsContent, defaultIndex, episodeNum, episodeCount, courseBuilderForms, setCourseBuilderForms, currentEpIds, dispatch) => {
  let newForms = { ...courseBuilderForms }

  // if episodeNum is not the last episode added, then we need to shift the form values to the preceding form ids
  if (episodeNum !== episodeCount) {
    let pointerDefaultIdx = defaultIndex
    for (let i = episodeNum; i < episodeCount; i++) {
      // Find the base set of form ids to shift into
      const baseEpIds = courseStepsContent
        // eslint-disable-next-line no-loop-func
        .filter(step => step.defaultIndex === pointerDefaultIdx)
        .map(step => step.id)

      // Find the next set of form ids
      const nextEpIds = courseStepsContent
        // eslint-disable-next-line no-loop-func
        .filter(step => step.defaultIndex === pointerDefaultIdx + 1)
        .map(step => step.id)

      // If there are nextEpIds, then we need to shift the form values to the previous set of form ids
      nextEpIds.forEach((nextEpId, idx) => {
        // Get the form instance and values for the next set of form ids
        const nextFormInstance = newForms[nextEpId]
        const currentFormInstance = newForms[baseEpIds[idx]]
        const nextFormVals = nextFormInstance.getValues()
        // Set the form values to the preceding form ids (episode_2... -> episode_1...) and unregister the old form ids
        Object.keys(nextFormVals).forEach(key => {
          const newKey = key.replace(`episode_${i + 1}`, `episode_${i}`)
          currentFormInstance.setValue(newKey, nextFormVals[key], { shouldDirty: true, shouldValidate: true })
          nextFormInstance.unregister(key)
        })
        // Set the new form instance to the newForms object
        newForms[baseEpIds[idx]] = currentFormInstance
        dispatch(removeValueFromArray({ key: 'invalid_course_steps', value: nextEpId }))
        dispatch(removeValueFromArray({ key: 'valid_course_steps', value: nextEpId }))
      })
      if (i === episodeCount - 1) {
        // reset the last set of forms
        nextEpIds.forEach(nextEpId => {
          newForms[nextEpId].reset()
        })
      }
      pointerDefaultIdx++
    }
  } else {
    // Find all the form keys within the forms and delete them
    currentEpIds.forEach(epId => {
      newForms[epId].reset()
    })
  }

  setCourseBuilderForms(newForms)
}

export const useDeleteEpisode = (episodeNum) => {
  const dispatch = useDispatch()
  const { builderForms: courseBuilderForms, setBuilderForms: setCourseBuilderForms, setStepId, setEpisodeCount, episodeCount, defaultIndex } = useCourseBuilderContext()
  const { courseStepsContent } = getCourseStepsContent(episodeCount)

  return useCallback(() => {
    const currentEpIds = courseStepsContent
      .filter(step => step.defaultIndex === defaultIndex)
      .map(step => step.id)

    dispatch(deleteShiftEpisodeSteps({ key: episodeNum }))
    shiftUpdateForms(courseStepsContent, defaultIndex, episodeNum, episodeCount, courseBuilderForms, setCourseBuilderForms, currentEpIds, dispatch)
    const { courseStepsContent: newCourseStepsContent } = getCourseStepsContent(episodeCount - 1)
    setEpisodeCount(prevCount => prevCount - 1)
    const newStep = newCourseStepsContent.find(step => step.defaultIndex === defaultIndex).id
    setStepId(newStep)

    // Remove the validation from state
    currentEpIds.forEach(epId => {
      dispatch(removeValueFromArray({ key: 'invalid_course_steps', value: epId }))
      dispatch(removeValueFromArray({ key: 'valid_course_steps', value: epId }))
    })
  }, [courseStepsContent, defaultIndex, episodeNum, episodeCount, courseBuilderForms, setCourseBuilderForms, setEpisodeCount, setStepId, dispatch])
}
