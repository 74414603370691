import { useTranslation } from 'i18n/TranslationContext'
import { useState } from 'react'
import { Box, Show } from '@chakra-ui/react'
import { TopicList } from './TopicList'
import { TopicPreview } from './TopicPreview'

export const TopicsContent = () => {
  const { t } = useTranslation()
  const [expandedIndex, setExpandedIndex] = useState(0)

  return (
    <>
      <Box
        width="100%"
        flexGrow={[1, 1, 0]}
        flexBasis="25%"
      >
        <Box
          as="h3"
          fontSize="18px"
          pb="12px"
        >
          {t('Topics')}
        </Box>
        <TopicList onChange={setExpandedIndex } />
      </Box>
      <Show above="md">
        <Box
          pl="30px"
          flexGrow="1"
        >
          <Box
            position="sticky"
            top="100px"
          >
            <TopicPreview topicId={expandedIndex} />
          </Box>
        </Box>
      </Show>
    </>
  )
}
