import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center } from '@chakra-ui/react'
import { CourseImageSet } from './CourseImageSet'
import { NoisyBackground } from './shared/Layout'
import { QuoteSign } from './QuoteSign'

const Review = ({ signature, text, photo, photoAltText }) => (
  <>
    <Center>
      <QuoteSign color="#FF7D19" />
    </Center>
    <Box
      pt="26px" pb="48px"
      textAlign="center"
    >
      {text}
    </Box>
    <Center>
      <Box
        pr="16px"
        fontSize={['24px', '30px']}
      >
        {signature}
      </Box>
      <Box
        borderRadius="50%"
        overflow="hidden"
      >
        <CourseImageSet
          photoSet={photo}
          alt={photoAltText}
        />
      </Box>
    </Center>
  </>
)

export const QuoteBlock = ({
  specification: { type, ...specification }
}) => {
  const { t } = useTranslation()
  return (
    <Box as="section" position="relative" aria-label={t('Reviews of the course')}>
      <NoisyBackground
        color="#19284E"
        opacity="0.05"
      />
      <Center>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridTemplateRows="auto 1fr auto"
          columnGap="56px"
          pt="120px" pb="116px"
          maxWidth="860px"
          width="100%"
          color="white"
          fontFamily="Poppins"
          fontSize={['22px', '30px']}
        >
          <Review {...specification} />
        </Box>
      </Center>
    </Box>
  )
}
