import { useTranslation } from 'i18n/TranslationContext'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListQuery } from 'hooks/opportunities/useListQuery'

export const useTalentOpportunities = () => {
  const { t } = useTranslation()
  const { data: userData, isLoading: userLoading, error: userError } = useGetCurrentUserQuery()

  const talentUserId = userData?.talent_profile?.id

  const {
    data: listData,
    isLoading: listLoading,
    error: listError,
  } = useListQuery('talent_experience', {
    talentUserId,
    skip: userLoading || !talentUserId,
  })

  if (userLoading || listLoading) {
    return { isLoading: true, message: t('Loading data...') }
  }

  if (userError || listError) {
    const error = userError || listError
    return { error, message: t('Error loading data') }
  }

  if (!userData || !talentUserId) {
    return { error: new Error('Talent User ID not found'), message: t('Talent User ID not found') }
  }

  if (!listData) {
    return { error: new Error('Opportunities data not found'), message: t('Error loading opportunities') }
  }

  return { data: listData, talentUserId }
}
