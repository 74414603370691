import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton, MenuItem,
  MenuList,
  Show,
  Spacer
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { forwardRef } from 'react'
import { LogoLinkLight, LogoLinkMobile } from '../shared/LogoLink'

const HeaderBox = ({ children }) => (
  <Box as="header"
    role="banner"
    bgColor={['white', null, '#0F204A']}
    boxShadow="0px 0px 30px -5px rgba(20, 18, 17, 0.4)"
  >
    <Flex
      pt={5} pb={5}
      mr={[3, 3, 3, 16]}
    >
      {children}
    </Flex>
  </Box>
)

const MobileHeader = ({ backTo, children }) => {
  const { t } = useTranslation()
  return (
    <>
      {backTo && (
        <Center pl={['24px']}>
          <IconButton
            as={Link}
            to={backTo}
            icon={<ArrowBackIcon />}
            isRound
            aria-label="Back"
          />
        </Center>
      )
      }
      <Center pl={['24px']}>
        <LogoLinkMobile />
      </Center>
      <Spacer />
      <Menu gutter={20}>
        <MenuButton as={Button} colorScheme="zettaBlue">
          {t('Menu')}
        </MenuButton>
        <MenuList
          fontFamily="Spline Sans Mono"
          fontWeight="500"
          borderRadius="0"
          border="0"
          borderTop="1px"
          borderTopColor="rgba(80, 0, 232, 1)"
          rootProps={{
            zIndex: '10001',
            width: '100%'
          }}
          padding="0"
        >
          {children}
        </MenuList>
      </Menu>
    </>
  )
}

const DesktopHeader = ({ children }) => (
  <>
    <Center pl={['24px', null, null, '64px']} flexShrink="0">
      <LogoLinkLight />
    </Center>
    {children}
  </>
)

export const HeaderLayout = ({ backTo, children }) => (
  <HeaderBox>
    <Show breakpoint="(max-width: 767px)">
      <MobileHeader backTo={backTo}>
        {children}
      </MobileHeader>
    </Show>
    <Show above="md">
      <DesktopHeader>
        {children}
      </DesktopHeader>
    </Show>
  </HeaderBox>
)

export const MenuItemBase = forwardRef(function MenuItemBase(props, ref) {
  return (
    <MenuItem
      {...props}
      justifyContent="center"
      paddingTop="12px"
      paddingBottom="12px"
    />
  )
})

export const MenuItemBasicLinkButton = forwardRef(function MenuItemBasicLinkButton({ href, ...props }, ref) {
  return (
    <MenuItemBase
      ref={ref}
      as="a"
      href={href}
    >
      <Button
        {...props}
        as="div"
        width="100%"
      />
    </MenuItemBase>
  )
})

export const MenuItemRouterLinkButton = ({ to, ...props }) => (
  <MenuItemBase as={Link} to={to}>
    <Button
      {...props}
      as="div"
      width="100%"
    />
  </MenuItemBase>
)
