import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex, Text } from '@chakra-ui/react'
import TooltipOnOverflow from 'components/TooltipOnOverflow'
import { EpisodePreviewBase } from '../../CourseFeature/CoursePage/CourseContentBlock/EpisodePreview'
import { useParams } from 'react-router-dom'
import { H2 } from 'shared/typography'
import { useListAssignmentsQuery } from 'features/submittedAssignmentApi'
import { AppLoadingState } from 'AppLoadingState'
import { useContextCourseId } from '../../CourseContext'
import { useCurrentCourseQuery } from '../useCurrentCourseQuery'
import { DownloadAssignmentButton } from 'components/buttons/DownloadAssignmentButton'
import { getAssignmentKey } from 'utils/storage'

const DownloadButtonRow = ({ downloadUrl, filename, assignmentKey }) => {
  const { t } = useTranslation()
  const downloadedAt = localStorage.getItem(assignmentKey)

  return (
    <Flex alignItems="center" gap="14px" direction={{ base: 'column', xl: 'row' }}>
      <DownloadAssignmentButton filename={filename} downloadUrl={downloadUrl} assignmentKey={assignmentKey} />

      {/* Show help info next to the download button */}
      <Flex direction="column" minWidth="0" maxWidth="100%">
        <TooltipOnOverflow hasArrow placement="top" label={filename} textProps={{ fontSize: '14px', as: 'p' }}>
          {t('Submitted File Name: {{filename}}', { filename })}
        </TooltipOnOverflow>
        {downloadedAt && <Text fontSize="14px" lineHeight="20px">{t('Last Downloaded At: {{downloadedAt}}', { downloadedAt })}</Text> }
      </Flex>
    </Flex>
  )
}

const Assignment = ({ step_id: stepId, download_url: downloadUrl, filename, talentProfileId }) => {
  const assignmentKey = getAssignmentKey(talentProfileId, stepId)

  const { t } = useTranslation()

  const {
    steps: {
      [stepId]: {
        description,
        name
      }
    }
  } = useCurrentCourseQuery().data.content

  const el = document.createElement('div')
  el.innerHTML = description

  return (
    <Box
      width={['100%', null, '85%']}
      padding="24px"
      marginBottom="24px"
      borderRadius="2px"
      backgroundColor="#F8F4FF"
      className="assignment"
    >
      <Flex>
        <Center
          flexShrink="0"
          width="182px"
          height="198px"
          display={{ base: 'none', xl: 'inherit' }}
        >
          <EpisodePreviewBase
            courseQueryData={useCurrentCourseQuery()}
            rootProps={{ height: '100%' }}
            height="100%"
            objectFit="cover"
            stepId={stepId}
            isPartner
          />
        </Center>
        <Box
          flexShrink="0"
          flexBasis="24px"
        />
        <Flex justifyContent="center" direction="column" minWidth="0">
          <H2 color="var(--design-content, #0F204B)" textTransform="none">
            {t('Assignment — {{name}}', { name })}
          </H2>
          <Box
            marginTop="8px"
            marginBottom="16px"
            fontFamily="Poppins"
            color="var(--navy-blue, #0F204A)"
          >
            <Text noOfLines={3}>
              {
                el.textContent
              }
            </Text>
          </Box>

          <DownloadButtonRow
            downloadUrl={downloadUrl} filename={filename}
            assignmentKey={assignmentKey}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

export const AssignmentsList = () => {
  const courseId = useContextCourseId()
  const talentProfileId = useParams().talentProfileId
  const { isSuccess: courseIsSuccess } = useCurrentCourseQuery()

  const { data: assignments, isFetching, isSuccess } = useListAssignmentsQuery({
    episodePath: `/courses/${courseId}`,
    talentProfileId
  })

  if (isFetching || !isSuccess || !courseIsSuccess) {
    return <AppLoadingState />
  }

  return assignments.map((props) => (
    <Assignment
      key={props.step_id}
      talentProfileId={talentProfileId}
      {...props}
    />
  ))
}
