import React, { useState, useRef, useEffect } from 'react'
import { Flex } from '@chakra-ui/react'
import Slide from './Slide'
import SpeakerNotes from './SpeakerNotes'
import SlidesContainer from './SlidesContainer'
import { useTranslation } from 'i18n/TranslationContext'
import { useUpdateEnrolmentStepStateEpMutation } from 'features/coursesApi'
import { useParams } from 'react-router-dom'

const SlideDeckPlayer = ({ slides, stepId }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const { courseId } = useParams()
  const slideRefs = useRef([])
  const { t } = useTranslation()
  const [updateEnrolmentStepState] = useUpdateEnrolmentStepStateEpMutation()

  useEffect(() => {
    // Focus the current slide when the index changes
    slideRefs.current[currentIndex]?.focus()
  }, [currentIndex])

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowRight') {
      nextSlide()
    } else if (e.key === 'ArrowLeft') {
      prevSlide()
    }
  }

  const isFirstSlide = currentIndex === 0
  const isLastSlide = currentIndex === slides.length - 1

  useEffect(() => {
    if (isLastSlide) {
      updateEnrolmentStepState({ courseId, stepId })
    }
  }, [isLastSlide, stepId, courseId, updateEnrolmentStepState])

  const nextSlide = () => {
    !isLastSlide && setCurrentIndex((prevIndex) => (prevIndex + 1))
  }

  const prevSlide = () => {
    !isFirstSlide && setCurrentIndex((prevIndex) => (prevIndex - 1))
  }

  return (
    <Flex
      role="region"
      flexWrap="wrap"
      flexDirection={['column', 'column', 'column', 'row']}
      gap="32px"
      aria-roledescription={t('Slide Show')}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      alignItems="stretch"
    >
      <SlidesContainer
        prevSlide={prevSlide}
        nextSlide={nextSlide}
        isLastSlide={isLastSlide}
        isFirstSlide={isFirstSlide}
        stepId={stepId}
        slideProgressLabel={t('Slide {{slideNum}} of {{totalSlides}}', { slideNum: currentIndex + 1, totalSlides: slides.length })}
      >
        {slides.map((slide, index) => (
          <Slide
            key={index}
            slide={slide}
            index={index}
            isCurrentSlide={currentIndex === index}
            slideRefs={slideRefs}
            totalLength={slides.length}
          />
        ))}
      </SlidesContainer>
      <SpeakerNotes slide={slides[currentIndex]} key={currentIndex} />
    </Flex>
  )
}

export default SlideDeckPlayer
