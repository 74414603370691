import { resetFormValues, updateFormValues, updateLastSearchValues } from 'features/talent_search_drawer/talentSearchSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

// Provides the form values and functions to handle the talent search form
export const useTalentSearch = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { formValues, lastSearchFilterCount, lastSearchParams } = useSelector((state) => state.talentSearch)

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target
    const isCheckbox = type === 'checkbox'
    const inputValue = isCheckbox ? checked : value

    dispatch(updateFormValues( { [name]: inputValue }))
  }

  // Update the URL search parameters when the form values change
  useEffect(() => {
    const currentParams = new URLSearchParams(location.search)
    const newParams = new URLSearchParams(lastSearchParams)
    const curPage = currentParams.get('page')
    const curCourseId = currentParams.get('course_id')
    if ( curPage ) newParams.set('page', curPage)
    if ( curCourseId ) newParams.set('course_id', curCourseId)

    navigate(location.pathname + '?' + newParams.toString())
  }, [formValues, lastSearchParams, location.pathname, location.search, navigate])

  const searchFormValues = () => {
    // Convert formValues to lastSearch state values
    dispatch(updateLastSearchValues())
  }

  const resetFormSearch = () => {
    dispatch(resetFormValues())
    dispatch(updateLastSearchValues())
  }

  return {
    formValues,
    lastSearchFilterCount,
    handleInputChange,
    searchFormValues,
    resetFormSearch,
    lastSearchParams
  }
}
