import { Modal } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import DuoButtonModalLayout from 'components/modals/DuoButtonModalLayout'

const UnsavedChangesModal = ({ isOpen, onConfirm, onCancel, message }) => {
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={onCancel} size="3xl" isCentered closeOnOverlayClick={false} className="confirm-exit-modal">
      <DuoButtonModalLayout
        primaryButtonAction={onConfirm}
        secondaryButtonAction={onCancel}
        primaryButtonText={t('Yes, continue')}
        secondaryButtonText={t('No, take me back')}
        headerText={t('Are you sure?')}
      >
        {message}
      </DuoButtonModalLayout>
    </Modal>
  )
}

export default UnsavedChangesModal
