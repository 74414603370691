import { useLoadCourseQuery } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useContextCourseId } from '../../CourseContext'

export const useCurrentCourseQuery = () => {
  const { data: { id: userId, feature } } = useGetCurrentUserQuery()
  const isPartner = feature === 'partner_experience'
  const payload = useLoadCourseQuery({ courseId: useContextCourseId(), userId, isPartner })
  return { ...payload, isPartner }
}
