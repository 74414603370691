import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Center, Flex, Spinner, useToast } from '@chakra-ui/react'
import {
  useGetContactRequestStatusQuery,
  useResolveContactRequestMutation
} from 'features/contactRequestsApi'
import { AppLoadingState } from 'AppLoadingState'
import { useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { HiCheckBadge } from 'react-icons/hi2'
import { ReactComponent as DeclinedIcon } from './Frame.svg'
import { usePageTitle } from 'usePageTitle'

const useContactRequestParams = () => {
  const [searchParams] = useSearchParams()
  const { requestId: id } = useParams()

  return {
    id,
    secret: searchParams.get('secret')
  }
}

const useContactRequest = () => {
  const { id, secret } = useContactRequestParams()
  return useGetContactRequestStatusQuery({
    id, secret
  })

}

const useCompanyName = () => {
  const {
    data: {
      partner_profile: {
        company_name: companyName
      }
    }
  } = useContactRequest()

  return companyName
}

const PageWrapper = ({ children, ...props }) => (
  <Center as="main"
    flexGrow="1"
    margin="30px 36px 42px"
    bgColor="white"
    flexDirection="column"
    {...props}
  >
    {children}
  </Center>
)

const Heading = ({ children }) => (
  <Box as="h1"
    fontFamily="Spline Sans Mono"
    fontSize="40px"
    fontWeight="bold"
    textTransform="uppercase"
    color="color: var(--design-content, #0F204B)"
    maxWidth="480px"
    textAlign="center"
  >
    {children}
  </Box>
)

const RequestButtons = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const { id, secret } = useContactRequestParams()

  const [resolveRequest, { isLoading, isError } ] = useResolveContactRequestMutation()

  useEffect(() => {
    if (!isError) { return}

    toast({
      title: t('Something went wrong'),
      status: 'error',
      position: 'top',
      containerStyle: {
        position: 'absolute',
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [toast, isError, t])

  if (isLoading) { return <Spinner /> }

  return (
    <Flex
      minWidth="340px"
      flexDirection="column"
    >
      <Button
        colorScheme="zettaBlue"
        width="100%"
        onClick={() => {
          resolveRequest({
            id,
            secret,
            resolution: 'approved'
          })
        }}
      >
        {t('Confirm the request')}
      </Button>
      <Box flexBasis="8px" />
      <Button
        colorScheme="zettaWhite"
        width="100%"
        onClick={() => {
          resolveRequest({
            id,
            secret,
            resolution: 'declined'
          })
        }}
      >
        {t('Decline')}
      </Button>
    </Flex>
  )
}

const PageContent = () => {
  const { t, Trans } = useTranslation()
  usePageTitle(t('Confirm Contact Request'))
  const companyName = useCompanyName()

  return (
    <PageWrapper>
      <Heading>
        {t('Confirm the request')}
      </Heading>
      <Box
        padding="24px 0"
        textAlign="center"
        maxWidth="700px"
        fontFamily="Poppins"
        fontSize="24px"
        color="var(--design-content, #0F204B)"
      >
        <Trans
          i18nKey="<1 /> sent you a request for your contact details. Specifically: <strong>email</strong>. Are you ready to share it with them, to have a further communication?"
          components={{ 1: companyName }}
        />
      </Box>
      <RequestButtons />
    </PageWrapper>
  )
}

const ResultPage = ({ children, ...props }) => {
  const { t } = useTranslation()
  return (
    <PageWrapper
      justifyContent="initial"
      paddingTop="70px"
      {...props}
    >
      {children}
      <Center
        minWidth="340px"
        paddingTop="16px"
      >
        <Button
          as={Link}
          to="/courses"
          colorScheme="zettaBlue"
          width="100%"
          fontWeight="500"
        >
          {t('Back to exploring')}
        </Button>
      </Center>
    </PageWrapper>
  )
}

const ApprovedContent = () => {
  const { t } = useTranslation()
  usePageTitle(t('Contact Request Approved'))

  return (
    <ResultPage>
      <Box
        fontSize="44px"
        color="#007037"
        paddingBottom="16px"
      >
        <HiCheckBadge />
      </Box>
      <Heading>
        {t('You’ve shared your contact details')}
      </Heading>
    </ResultPage>
  )
}

const RequestDeclinedText = () => {
  const { t } = useTranslation()
  const companyName = useCompanyName()

  return t('Request to {{companyName}} was declined', { companyName })
}

const DeclinedContent = () => {
  const { t } = useTranslation()
  usePageTitle(t('Contact Request Declined'))

  return (
    <ResultPage>
      <Box
        aria-hidden
        fontSize="44px"
        color="#007037"
        paddingBottom="16px"
      >
        <DeclinedIcon />
      </Box>
      <Heading>
        <RequestDeclinedText />
      </Heading>
    </ResultPage>
  )
}

const InvalidLinkError = () => {
  const { t } = useTranslation()
  usePageTitle(t('Invalid Link'))

  return (
    <ResultPage justifyContent="center">
      <Heading>
        {t('The link appears to be invalid')}
      </Heading>
    </ResultPage>
  )
}

const ContactRequestStatus = () => {
  const { data, isError, isFetching } = useContactRequest()

  if (isFetching) {
    return <AppLoadingState />
  }

  if (isError) {
    return <InvalidLinkError />
  }

  const { resolution } = data

  switch (resolution) {
  case null:
    return <PageContent />
  case 'approved':
    return <ApprovedContent />
  case 'declined':
    return <DeclinedContent />
  default:
    return <InvalidLinkError />
  }
}


export const ContactRequest = () => {
  const { error, isSuccess } = useGetCurrentUserQuery()

  if ((typeof error == 'undefined') && !isSuccess) {
    return <AppLoadingState />
  }

  return <ContactRequestStatus />
}
