import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ModalBase, PrimaryButton, SelectField } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useUpdateOpportunityApplicationMutation } from 'features/opportunitiesApi'
import { talentProfilesApi } from 'features/talentProfilesApi'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

const PartnerOpportunityApplicationModal = ({
  isOpen,
  onClose,
  opportunityApplication,
  onConfirm,
}) => {
  const { t } = useTranslation()
  const [updateOpportunityApplication] = useUpdateOpportunityApplicationMutation()

  const selectOptions = [
    { value: 'applied', label: 'Reviewing' },
    { value: 'interviewing', label: 'Interviewing' },
    { value: 'offer_sent', label: 'Offer Sent' },
    { value: 'hired', label: 'Hired' },
    { value: 'rejected', label: 'Rejected' },
  ]

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      status: opportunityApplication.status,
    },
  })

  const selectedStatus = watch('status')
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const isSaveDisabled = selectedStatus === opportunityApplication.status
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true)
      await updateOpportunityApplication({
        id: opportunityApplication.id,
        status: data.status,
      }).unwrap()
      onClose()
      dispatch(talentProfilesApi.util.invalidateTags(['TalentList']))
    } catch (error) {
      console.error('Failed to update opportunity application status', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const modalConfig = {
    header: t('Change Application Status'),
    body: t(
      'Update the status of this application. Note: Rejecting an application in Making Space will also reject the application in any connected applicant tracking system.'
    ),
    primaryCTA: t('Save'),
    primaryAction: 'save',
    tertiaryCTA: t('Cancel'),
    tertiaryAction: 'cancel',
  }

  const footerButtons = (
    <Flex width="100%" justifyContent="space-between">
      <PrimaryButton
        onClick={onClose}
        variant="solid"
        bgColor="white"
        fontSize="18px"
        color="purple.600"
        fontWeight="normal"
        _hover={{
          bgColor: 'rgba(128, 90, 213, 0.1)',
        }}
        textValue={t('Cancel')}
        size="lg"
      />
      <PrimaryButton
        onClick={handleSubmit(onSubmit)}
        textValue={modalConfig.primaryCTA}
        fontSize="18px"
        fontWeight="600"
        fontFamily="Spline Sans Mono"
        colorScheme="zettaBlue"
        size="lg"
        isDisabled={isSaveDisabled || isSubmitting}
      />
    </Flex>
  )

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      headerText={modalConfig.header}
      bodyText={modalConfig.body}
      footerButtons={footerButtons}
      showClose={true}
    >
      <SelectField
        name="status"
        control={control}
        options={selectOptions}
        width="40%"
      />
    </ModalBase>
  )
}

export default PartnerOpportunityApplicationModal
