import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from '../../usePageTitle'
import { TalentPageContent } from './TalentPageContent'
import { PartnerPageContent } from './PartnerPageContent'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

export const OpportunityList = () => {
  const { t } = useTranslation()
  const { data: { feature } } = useGetCurrentUserQuery()
  usePageTitle(t('Opportunities'))

  switch(feature) {
  case 'talent_experience':
    return <TalentPageContent />
  case 'partner_experience':
    return <PartnerPageContent />
  default:
    return t('Unexpected error: feature {{feature}} unknown', { feature })
  }
}
