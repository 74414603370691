import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex, FormControl, FormLabel, IconButton, Input, VisuallyHidden } from '@chakra-ui/react'
import { StyledFormLabel } from 'components'
import { HiDocumentText, HiOutlineCloudArrowUp, HiTrash } from 'react-icons/hi2'
import { forwardRef, useCallback, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

const maxFileSize = 10485760

const fileValidation = (value, t) => {
  if (!value) { return true }

  const { 0: resume } = value

  if (!resume) { return true }

  const { type, size } = resume

  if (size > maxFileSize) {
    return t('The file is too big')
  }

  switch (type) {
  case 'application/pdf':
    return true
  default:
    return t('File format is unsupported')
  }
}

const InvisibleInput = forwardRef(function InvisibleInput({ labelText }, forwardedRef) {
  const {
    watch,
    register,
    setValue,
    trigger
  } = useFormContext()
  const { t } = useTranslation()
  const { ref, ...rest } = register('resume', {
    validate: fileValidation(t)
  })
  const resume = watch('resume')

  useEffect(() => {
    forwardedRef.current.files = resume
  }, [forwardedRef, resume])

  useEffect(() => {
    trigger('resume')
    if (resume && resume[0]) {
      setValue('deleteResume', null, { shouldDirty: true })
    }
  }, [resume, setValue, trigger])

  return (
    <VisuallyHidden>
      <Input
        ref={(e) => {
          ref(e)
          forwardedRef.current = e
        }}
        aria-labelledby="resume-file-input-label"
        type="file"
        accept="application/pdf"
        { ...rest }
      />
      <Box
        aria-hidden
        id="resume-file-input-label"
      >
        {labelText}
      </Box>
    </VisuallyHidden>
  )
})

const UploadWrapperBase = (props) => {
  const { setValue } = useFormContext()

  return (
    <Center
      width="100%"
      height={['250px', null, '184px']}
      bgColor="#F8F4FF"
      color="#0F204A"
      borderRadius="4px"
      fontWeight="normal"
      cursor="pointer"
      onDrop={(event) => {
        event.preventDefault()
        setValue('resume', event.dataTransfer.files)
      }}
      onDragOver={(event) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const useResumeError = () => {
  const {
    formState: {
      errors: { resume: resumeError }
    }
  } = useFormContext()
  return resumeError
}

const ReuploadFile = () => {
  const { t } = useTranslation()
  return (
    <UploadWrapperBase>
      <Center>
        <Box textAlign="center" aria-hidden>
          <Center fontSize="24px" mb="16px">
            <HiOutlineCloudArrowUp />
          </Center>
          <Box>
            {t('Replace resume')}
          </Box>
        </Box>
      </Center>
    </UploadWrapperBase>
  )
}

const useResumeValuePresent = () => {
  const { watch, getValues } = useFormContext()
  const value = watch('resume')
  const persistedFilename = getValues('resumeFilename')
  const deleteResume = watch('deleteResume')

  if (deleteResume) {
    return false
  }

  return (value && value.length > 0) || !!persistedFilename
}

const RemoveButton = ({ onClick: onClickExternal }) => {
  const { t } = useTranslation()
  const { watch, getValues } = useFormContext()
  const persistedFilename = getValues('resumeFilename')
  const resume = watch('resume')
  const deleteResume = watch('deleteResume')
  const { setValue } = useFormContext()


  const onClick = () => {
    setValue('deleteResume', true, { shouldDirty: true })
    setValue('resume', null, { shouldDirty: true })
    onClickExternal()
  }

  if (deleteResume) {
    return null
  }

  if (!persistedFilename && !resume) {
    return null
  }

  return (
    <Box
      position="absolute"
      top="16px"
      right="16px"
      zIndex="105"
    >
      <IconButton
        title={t('Delete resume')}
        colorScheme="zettaWhite"
        color="#CD0050"
        fontSize="24px"
        borderRadius="2px"
        icon={<HiTrash />}
        onClick={onClick}
      />
    </Box>
  )
}

const useResumeFileName = () => {
  const { watch, getValues } = useFormContext()
  const value = watch('resume')
  const persistedFilename = getValues('resumeFilename')

  return (value && value[0] && value[0].name) || persistedFilename
}

const InputContents = () => {
  const { t } = useTranslation()
  const valuePresent = useResumeValuePresent()

  if (valuePresent) {
    return (
      <ReuploadFile />
    )
  }

  return (
    <UploadWrapperBase>
      <Center>
        <Box textAlign="center" aria-hidden>
          <Center fontSize="24px" mb="16px">
            <HiOutlineCloudArrowUp />
          </Center>
          <Box mb="4px">
            {t('Upload resume in PDF up to 10mb.')}
          </Box>
          <Box>
            {t('Click here or drag files into this box to upload them')}
          </Box>
        </Box>
      </Center>
    </UploadWrapperBase>
  )
}

const CurrentFileView = ({ onRemove }) => {
  const { t } = useTranslation()
  const resumeError = useResumeError()
  const filename = useResumeFileName()

  return (
    <>
      <Center
        position="relative"
        flexGrow="1"
        flexBasis="0"
        borderRadius="4px"
        background={
          resumeError ?
            '#F8F4FF' :
            'var(--design-surface-light, #FFED9B)'
        }
        border={resumeError && '2px solid var(--design-decorative-highlight, #CD0050)'}
      >
        <Center
          flexDirection="column"
          textAlign="center"
          height={['250px', null, '184px']}
        >
          <Center fontSize="24px" mb="16px" color="#5000E8">
            <HiDocumentText />
          </Center>
          <Box mb="4px">
            {filename}
          </Box>
          {resumeError && (
            <Box color="#CD0050">
              <Box>
                {resumeError.message}
              </Box>
              <Box>
                {t('Try uploading another file')}
              </Box>
            </Box>
          )}
        </Center>
        <RemoveButton
          onClick={onRemove}
        />
      </Center>
      <Box
        flexBasis={['16px', null, '26px']}
      />
    </>
  )
}

const CurrentFile = ({ onRemove }) => {
  const valuePresent = useResumeValuePresent()

  if (valuePresent) {
    return <CurrentFileView onRemove={onRemove} />
  }

  return null
}

const MainLabelWrapper = ({ children }) => {
  const valuePresent = useResumeValuePresent()

  return (
    <FormLabel
      margin="0"
      border={
        valuePresent ?
          '2px dashed var(--design-content, #0F204A)' :
          '2px solid #0F204A'
      }
      borderRadius="4px"
      flexGrow="1"
      flexBasis="0"
      _focusWithin={{
        borderStyle: 'solid',
        borderColor: '#3182ce',
        boxShadow: '0 0 0 1px #3182ce'
      }}
    >
      {children}
    </FormLabel>
  )
}

const LabelText = () => {
  const { t } = useTranslation()
  const valuePresent = useResumeValuePresent()

  if (valuePresent) {
    return t('Replace resume')
  }

  return t('Upload resume in PDF up to 10mb.')
}

export const UploadResumeInput = () => {
  const { t } = useTranslation()
  const resumeError = useResumeError()
  const inputRef = useRef()

  const onRemove = useCallback(() => {
    inputRef.current.focus()
  }, [])

  return (
    <FormControl isInvalid={!!resumeError}>
      <StyledFormLabel>
        {t('Upload your resume')}
      </StyledFormLabel>
      <Flex
        flexDirection={[
          'column',
          null,
          'row'
        ]}
      >
        <CurrentFile onRemove={onRemove} />
        <MainLabelWrapper>
          <InputContents />
          <InvisibleInput
            ref={inputRef}
            labelText={<LabelText />}
          />
        </MainLabelWrapper>
      </Flex>
    </FormControl>
  )
}
