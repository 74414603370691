import { createContext, useContext } from 'react'

const CourseContext = createContext()

const useCourseContext = () => useContext(CourseContext)

const CourseContextProvider = CourseContext.Provider

export const CourseIdProvider = ({ courseId, children }) => (
  <CourseContextProvider
    value={{ courseId }}
  >
    {children}
  </CourseContextProvider>
)

export const useContextCourseId = () => useCourseContext().courseId
