import { useFormContext } from 'react-hook-form'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { InputField, UploadMediaField, TextField } from 'components'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const CourseExpertInfo = ({ t, courseExpertFieldNames, formDisabled=false }) => {
  const { register } = useFormContext() // retrieve all hook methods
  const { helperTextVals, placeholderTextVals } = useGetFormTextValues()

  const mediaType = useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  const errors = useSelector(state => state.courseBuilder.errors) // Access errors from the Redux store
  return (
    <>
      <InputField
        label={t('Expert Name')}
        name={courseExpertFieldNames.course_expert_name}
        errorVal={errors}
        helperText={!formDisabled && helperTextVals.course_expert_name}
        width="100%"
        fieldRequired={true}
        inputProps={{
          disabled: formDisabled,
          placeholder: placeholderTextVals.course_expert_name,
          ...register(
            courseExpertFieldNames.course_expert_name, {
              required: t('Expert Name can’t be empty.')
            }
          )
        }}
      />
      <UploadMediaField
        label={t('Expert Photo')}
        name={courseExpertFieldNames.course_expert_image}
        fieldRequired={true}
        mediaType={mediaType.THUMBNAIL}
        helperText={!formDisabled && helperTextVals.course_expert_image}
        inputProps={{ disabled: formDisabled }}
      />
      <InputField
        label={t('Expert Photo Alt Text')}
        name={courseExpertFieldNames.course_expert_image_alt_text}
        inputProps={{
          disabled: formDisabled,
          maxLength: 250,
          placeholder: t('Barbara Gordon, a white woman with red hair, is wearing a purple bodysuit with yellow accents while sitting in a wheelchair.'),
          ...register(
            courseExpertFieldNames.course_expert_image_alt_text
          )
        }}
      />
      <InputField
        label={t('Expert Profession/Job Title')}
        name={courseExpertFieldNames.course_expert_title}
        errorVal={errors}
        width="100%"
        fieldRequired={true}
        inputProps={{
          disabled: formDisabled,
          placeholder: placeholderTextVals.course_expert_title,
          ...register(
            courseExpertFieldNames.course_expert_title, {
              required: t('Expert Profession can\'t be empty.')
            }
          )
        }}
      />
      <TextField
        name={courseExpertFieldNames.course_expert_bio}
        errorVal={errors}
        textFieldProps={{
          label: t('Expert Bio'),
          width: '100%',
          fieldRequired: true,
          textEditor: false,
        }}
        inputProps={{
          disabled: formDisabled,
          placeholder: placeholderTextVals.course_expert_bio,
          ...register(
            courseExpertFieldNames.course_expert_bio, {
              required: t('Expert Bio can\'t be empty.')
            }
          )
        }}
      />
    </>
  )
}

export default CourseExpertInfo
