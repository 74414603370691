import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Show
} from '@chakra-ui/react'
import { useCourseData } from '../../CourseData'
import { TopicPreview } from './TopicPreview'
import { useCourseId } from '../../hooks'
import { PlayEpisodeButton } from './PlayEpisodeButton'

const expandedProps = {
  color: '#0F204B',
  bg: '#FF7D19',
  _hover: {
    bg: '#FF7D19'
  }
}

const collapsedProps = {
  color: 'white',
  bg: 'linear-gradient(90deg, rgba(232, 225, 222, 0.11) 0%, rgba(232, 225, 222, 0.044) 100%)'
}

const CustomAccordionButton = ({ isExpanded, name, number }) => {
  const extraProps = isExpanded ? expandedProps : collapsedProps

  return (
    <AccordionButton
      pt={4} pb={4}
      fontFamily="Spline Sans Mono"
      fontSize="20px"
      fontWeight="500"
      justifyContent="space-between"
      position="relative"
      {...extraProps}
    >
      <Box
        textAlign="left"
      >
        {number}. {name}
      </Box>
      <AccordionIcon />
    </AccordionButton>
  )
}

const PlayFirstEpisodeButton = () => (
  <PlayEpisodeButton
    to={`/courses/${useCourseId()}/episodes/1`}
  />
)

const Topic = ({ name, description, number }) => (
  <AccordionItem
    pt={2}
    border={0}
  >
    {({ isExpanded }) => (
      <>
        <CustomAccordionButton isExpanded={isExpanded} name={name} number={number} />
        <AccordionPanel
          bg="#FF7D19"
          color="#0F204B"
          fontFamily="Comfortaa"
        >
          <Box>
            {description}
          </Box>
          <PlayFirstEpisodeButton />
          <Show below="md">
            <Box
              mt="12px"
              borderRadius="8px"
              border="2px solid #0A1A42"
              overflow="hidden"
              boxShadow="0px 30px 50px -40px #000"
              position="relative"
              background="black"
            >
              <TopicPreview
                topicId={number - 1}
                opacity="0.6"
              />
            </Box>
          </Show>
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
)

const TopicItems = () => {
  const { topics } = useCourseData()

  return topics.map(({ name, description }, index) => (
    <Topic
      key={name}
      name={name}
      description={description}
      number={index + 1}
    />
  ))
}

export const TopicList = ({ onChange }) => (
  <Box pb={4}>
    <Accordion defaultIndex={0} onChange={onChange}>
      <TopicItems />
    </Accordion>
  </Box>
)
