import { UserForm } from 'components'
import { useAcceptInvitationMutation } from 'features/sessionsApi'
import { useGetInvitation } from './useGetInvitation'
import { Box } from '@chakra-ui/react'

export const SignUpForm = () => {
  const [acceptInvitation, { error }] = useAcceptInvitationMutation()
  const {
    data: { email },
    originalArgs: token
  } = useGetInvitation()

  const handleAcceptInvitation = (data) => {
    acceptInvitation(data)
  }

  return (
    <UserForm
      error={error}
      token={token}
      onSubmit={handleAcceptInvitation}
      email={email}
      isEmailReadOnly={true}
      additionalFields={() => (
        <Box pt="16px" pb="54px">
          {/* Any additional fields specific to the invitation form can go here */}
        </Box>
      )}
    />
  )
}
