import { Button } from '@chakra-ui/react'
import { useGetStepInfo } from 'CourseFeature/hooks'
import { DIRECTION } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'

export const NextStepButton = ({
  stepId,
  courseId,
  onNextStepClick,
  talentUserId,
  opportunityId,
  isCourseComplete,
  hasOpportunity,
  onApply
}) => {
  const nextStepInfo = useGetStepInfo(stepId, DIRECTION.NEXT)
  const { t } = useTranslation()

  const handleGoToNextStep = () => {
    onNextStepClick(`/courses/${courseId}/steps/${nextStepInfo.id}`)
  }

  const handleGoToCourses = () => {
    onNextStepClick('/courses')
  }

  const buttonBase = {
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: 'Spline Sans Mono',
    colorScheme: 'zettaBlue',
    size: 'lg',
  }

  if (nextStepInfo) {
    if (nextStepInfo.isAssignment && !nextStepInfo.isDisabled) {
      return <Button {...buttonBase} onClick={handleGoToNextStep}>{t('Go to Assignment')}</Button>
    } else if (!nextStepInfo.isAssignment) {
      return <Button {...buttonBase} onClick={handleGoToNextStep}>{t('Go to Next Episode')}</Button>
    }
  }

  if (isCourseComplete && hasOpportunity && talentUserId && opportunityId) {
    return <Button {...buttonBase} onClick={onApply}>{t('Apply Now')}</Button>
  }

  return <Button {...buttonBase} onClick={handleGoToCourses}>{t('Return to My Courses')}</Button>
}
