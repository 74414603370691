import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const DraftButton = ({ id, ...props }) => {
  return (
    <Button
      as={Link}
      to={`/courses/${id}/edit`}
      bgColor="#0F204A"
      color="white"
      fontWeight="normal"
      fontSize="16px"
      padding="0 12px"
      _hover={{
        bgColor: '#070F22'
      }}
      {...props}
    />
  )
}

export default DraftButton
