import { useSelector } from 'react-redux'
import { Box, Button, Flex } from '@chakra-ui/react'
import { EpisodePreview } from 'CourseFeature/CoursePage/CourseContentBlock/EpisodePreview'
import { useCoursePageContext } from 'CourseFeature/CoursePage/CoursePageContext'
import { CourseDescription } from 'components/talent/quiz/modal-elements/splash/CourseDescription'
import { useTranslation } from 'i18n/TranslationContext'
import { useStepId } from 'CourseFeature/hooks'
import { BlockWrapper } from 'components/talent/modal-chat-ux/BlockWrapper'

const BeginQuizButton = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Button
      colorScheme="zettaBlue"
      onClick={onClick}
      fontWeight={600}
      size="lg"
      my="44px"
    >
      {t('Begin Quiz')}
    </Button>
  )
}

export const SplashContent = ({ onBeginQuizClick }) => {
  const { t } = useTranslation()
  const {
    data: {
      name: courseName,
    }
  } = useCoursePageContext().courseContentData
  const { quizStepId } = useSelector(state => state.talentQuiz)
  const urlStepId = useStepId()
  const stepId = quizStepId || urlStepId

  return (
    <BlockWrapper p={4}>
      <Flex
        flexDirection={['column', null, 'row']}
        boxShadow="md"
        className="modal-splash-content"
      >
        <Box p="40px">
          <Box
            as="h3"
            mb="24px"
            fontSize="40px"
            fontWeight="700"
            fontFamily="Spline Sans Mono"
            textTransform="uppercase"
            textAlign={['center', null, null, 'left']}
            className="quiz-splash__title"
          >
            {t('Begin Your Quiz')}
          </Box>
          <Box
            textAlign={['center', null, null, 'left']}
            fontSize={['14px', null, null, '18px']}
            fontWeight="400"
            fontFamily="Poppins"
            className="quiz-splash__description"
          >
            {t('Ready to begin your quiz? Now\'s your opportunity to test your understanding of the materials in this episode. Each quiz contains up to three questions.')}
          </Box>
          <BeginQuizButton onClick={onBeginQuizClick} />

          <CourseDescription courseName={courseName} />
        </Box>

        {/* Episode Preview Image */}
        <EpisodePreview stepId={stepId} />
      </Flex>
    </BlockWrapper>
  )
}
