import { forwardRef } from 'react'
import { Textarea } from '@chakra-ui/react'

export const StyledTextArea = forwardRef(function StyledTextArea(props, ref) {
  return (
    <Textarea
      ref={ref}
      fontWeight="normal"
      fontSize={['14px', null, '18px']}
      borderRadius="4px"
      border="2px solid #0F204B"
      paddingTop="24px"
      paddingBottom="24px"
      _hover={{ borderColor: '#0F204B' }}
      {...props}
    />
  )
})
