import { apiSlice } from './apiSlice'

export const userGroupsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listUserGroups: builder.query({
      query: () => '/user_groups',
      providesTags: ['UserGroups']
    }),
    assignUserGroupItems: builder.mutation({
      query: ({ userId, userGroupItemIds }) => ({
        url: `/users/${userId}/user_group_items`,
        method: 'POST',
        body: { user_group_item_ids: userGroupItemIds }
      }),
      invalidatesTags: (result, error) => error ? [] : ['UserGroups']
    }),
  })
})

export const {
  useListUserGroupsQuery,
  useAssignUserGroupItemsMutation,
} = userGroupsApi
