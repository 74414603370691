import { apiSlice } from './apiSlice'

export const s3Api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSignedUrl: builder.mutation({
      query: ({ path, method }) => ({
        url: 'create_presigned_url',
        method: 'POST',
        body: { path, method },
      }),
    }),
  })
})

export const {
  useCreateSignedUrlMutation,
} = s3Api
