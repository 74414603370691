import { useTranslation } from 'i18n/TranslationContext'
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, FormHelperText } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { InputRightElement, Button } from '@chakra-ui/react'
import { HiOutlineEye, HiEye } from 'react-icons/hi'

export const InputField = ({
  label,
  name,
  inputProps,
  required,
  validate,
  passwordMatch = false,
  clickShowPw = null
}) => {
  const { t } = useTranslation()
  const { register, formState: { errors } } = useFormContext()

  return (
    <FormControl pb="16px" isInvalid={!!errors[name]}>
      <Flex justifyContent="space-between">
        <FormLabel textTransform="uppercase">
          {label}
        </FormLabel>
        {errors[name] ? (
          <FormErrorMessage
            mt="0"
            lineHeight="unset"
            display="block"
            fontSize="16px"
          >
            <Box color="#CD0050" textTransform="uppercase" fontWeight="500" textAlign="right">
              {errors[name] && errors[name].message}
            </Box>
          </FormErrorMessage>
        ) : (
          passwordMatch &&
            <FormHelperText
              mt="0"
              lineHeight="unset"
              display="block"
              fontSize="16px"
              color="green.500"
              fontWeight="500"
              textTransform="uppercase"
            >
              {t('passwords match')}
            </FormHelperText>
        )}
      </Flex>
      <InputGroup size="lg">
        <Input
          {...inputProps}
          {
            ...register(
              name, {
                required: required || `${name.replace(/_/g, ' ')} ${t('can’t be empty')}`,
                validate: validate
              }
            )
          }
          isInvalid={!!errors[name]}
          errorBorderColor="#CD0050"
          _hover={{ borderColor: '#0F204B' }}
          _focusVisible={{ borderColor: '#3182ce', boxShadow: '0 0 0 1px #3182ce' }}
        />
        {clickShowPw &&
          <InputRightElement width="4.5rem">
            <Button
              variant="unstyled"
              display="flex"
              onClick={clickShowPw?.setShowPw}
              aria-label={clickShowPw?.showPw ? t('Hide password') : t('Show password')}
            >
              {clickShowPw.showPw ? <HiOutlineEye /> : <HiEye />}
            </Button>
          </InputRightElement>
        }
      </InputGroup>
    </FormControl>
  )
}
