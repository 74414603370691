import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center } from '@chakra-ui/react'
import { SignInForm } from './SignInForm'
import { Header1 } from '../Header1'
import { Details } from '../Details'
import { usePageTitle } from '../../usePageTitle'

export const SignInPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Sign in'))

  return (
    <Box
      background="#F0E9E6"
      pt="84px" pb="40px"
    >
      <Box
        pl={['28px', '28px', '176px']}
        pr={['28px', '28px', '176px']}
      >
        <Center flexDirection="column">
          <Header1>
            {t('Sign in to Making Space')}
          </Header1>
          <Details
          >
            {t('Enter details you used to create an account')}
          </Details>
        </Center>
        <SignInForm />
      </Box>
    </Box>
  )
}
