import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react'
import { inputAnatomy, alertAnatomy } from '@chakra-ui/anatomy'

import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const { definePartsStyle: definePartsStyleAlert, defineMultiStyleConfig: defineMultiStyleConfigAlert } =
  createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    fontWeight: '500',
    borderColor: '#0F204B',
    _hover: {
      borderColor: '#0F204B'
    }
  },
})

const inputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    lg: definePartsStyle({
      field: {
        borderRadius: 0,
      }
    })
  }
})

const zettaWhite = {
  _hover: {
    bg: '#F8F4FF'
  },

  bg: 'white',
  color: '#5000E8'
}
const buttonTheme = {
  baseStyle: {
    borderRadius: 0,
    fontFamily: 'Spline Sans Mono'
  },
  variants: {
    solid: (props) => {
      switch (props.colorScheme) {
      case 'zettaWhite': return zettaWhite
      case 'zettaWhiteOutline': return {
        ...zettaWhite,
        border: '#5000E8 solid 2px'
      }
      case 'zettaPurple': return {
        _hover: {
          color: 'white'
        },
        _disabled: {
          _hover: {
            color: '#5000E8'
          }
        },
        color: '#5000E8'
      }
      default: return
      }
    },
    outline: (props) => {
      if (props.colorScheme !== 'zettaWhite') { return }

      return {
        _hover: {
          bg: '#fff',
          color: '#0F204B'
        }
      }
    },
    link: (props) => {
      if (props.colorScheme !== 'zettaBlue') { return }

      return {
        textDecoration: 'underline',
        _hover: {
          color: '#3E00B3'
        }
      }
    },
    ghost: (props) => {
      if (props.colorScheme !== 'zettaPlayback') { return }

      return {
        _hover: {
          color: '#F0E9E6'
        }
      }
    }
  },
  sizes: {
    lg: {
      h: '56px'
    }
  }
}

const textAreaTheme = defineStyleConfig({
  variants: {
    outline: defineStyle({
      height: '152px',
      borderColor: 'rgba(175, 177, 182, 1)',
      borderRadius: 0,
      fontFamily: 'Poppins'
    })
  },
})

const alertColors = {
  error: 'red.500',
  success: 'rgba(0, 112, 55, 1)'
}

const alertTheme = defineMultiStyleConfigAlert({
  baseStyle: definePartsStyleAlert((props) =>({
    container: {
      pt: '40px',
      pb: '40px',
      justifyContent: 'center',
      borderRadius: '0',
      bgColor: alertColors[props.status],
      textTransform: 'uppercase'
    },
    title: {
      fontFamily: 'Poppins',
      fontWeight: '500'
    }
  }))
})

export const theme = extendTheme({
  colors: {
    zettaBlue: {
      50: '#ffffff',
      500: '#5000E8',
      600: '#3E00B2'
    },
    zettaDark: {
      500: 'rgba(15, 32, 75, 1)',
      600: 'rgba(7, 15, 34, 1)'
    },
    zettaPurple: {
      500: '#F8F4FF',
      600: '#5000E8'
    },
    red: {
      500: '#CD0050'
    }
  },
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Textarea: textAreaTheme,
    Alert: alertTheme,
    Tag: {
      variants: {
        solid: (props) => ({
          container: {
            bg: props.colorScheme === 'green' && 'green.600'
          }
        })
      }
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            background: 'rgb(29,41,76)'
          },
        },
      },
    },
  },
})
