import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Show
} from '@chakra-ui/react'
import { useCourseData } from '../../CourseData'
import { useCourseId, useStepMap } from '../../hooks'
import { PlayEpisodeButton } from './PlayEpisodeButton'
import { EpisodePreview } from './EpisodePreview'
import { useCoursePageContext } from '../CoursePageContext'

const expandedProps = {
  color: '#0F204B',
  bg: '#FF7D19',
  _hover: {
    bg: '#FF7D19'
  }
}

const collapsedProps = {
  color: 'white',
  bg: 'linear-gradient(90deg, rgba(232, 225, 222, 0.11) 0%, rgba(232, 225, 222, 0.044) 100%)'
}

const CustomAccordionButton = ({ isExpanded, name, prefix }) => {
  const extraProps = isExpanded ? expandedProps : collapsedProps

  return (
    <AccordionButton
      pt={4} pb={4}
      fontFamily="Spline Sans Mono"
      fontSize="20px"
      fontWeight="500"
      justifyContent="space-between"
      position="relative"
      {...extraProps}
    >
      <Box
        textAlign="left"
      >
        {`${prefix}${name}`}
      </Box>
      <AccordionIcon />
    </AccordionButton>
  )
}

const PlayEpisodeButtonForStep = ({ id }) => {
  const courseId = useCourseId()
  const {
    data: { 'access?': hasAccess }
  } = useCoursePageContext().courseContentData
  const stepMap = useStepMap()
  const isDisabled = stepMap[id].isDisabled

  if (!hasAccess || isDisabled) { return null }

  return (
    <Box paddingBottom="20px">
      <PlayEpisodeButton
        to={`/courses/${courseId}/steps/${id}`}
      />
    </Box>
  )
}

const Episode = ({ id, name, description, number }) => {
  const {
    data: { has_intro_episode }
  } = useCoursePageContext().courseContentData

  let prefix
  if ( has_intro_episode ) {
    prefix = Number(number) === 1 ? '' : `${number - 1}. `
  } else {
    prefix = `${number}. `
  }

  return (
    <AccordionItem
      pt={2}
      border={0}
      className="episode-accordion-item"
    >
      {({ isExpanded }) => (
        <>
          <CustomAccordionButton isExpanded={isExpanded} name={name} prefix={prefix} />
          <AccordionPanel
            paddingTop="0"
            paddingBottom="0"
            bg="#FF7D19"
            color="#0F204B"
            fontFamily="Comfortaa"
            py={4}
          >
            <Box>
              {description}
            </Box>
            <PlayEpisodeButtonForStep id={id} />
            <Show below="md">
              <Box
                paddingBottom="20px"
              >
                <Box
                  borderRadius="8px"
                  border="2px solid #0A1A42"
                  overflow="hidden"
                  boxShadow="0px 30px 50px -40px #000"
                  position="relative"
                  background="black"
                  aspectRatio={['1.5', null, null]}
                >
                  <EpisodePreview
                    stepId={id}
                    opacity="0.6"
                  />
                </Box>
              </Box>

            </Show>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )}

const Steps = ({ episodes }) => {
  const { steps } = useCourseData()

  return episodes.map((stepId, index) => (
    <Episode
      key={stepId}
      id={stepId}
      number={index + 1}
      {...steps[stepId]}
      className="step-episode"
    />
  ))
}

export const EpisodeList = ({ episodes, onChange }) => {
  return (
    <Box pb={4} className="episode-list">
      <Accordion defaultIndex={0} onChange={onChange}>
        <Steps episodes={episodes} />
      </Accordion>
    </Box>
  )
}
