import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'i18n/TranslationContext'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Link as ChakraLink,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react'
import { HiPencil } from 'react-icons/hi2'
import { getOpportunityStepsContent } from 'OpportunityBuilder/getOpportunityStepsContent'
import { useOpportunityBuilderContext } from 'OpportunityBuilder/OpportunityBuilderContext'

const fontSize = ['18px', '18px', '20px']

export const ReviewAndSubmitContent = () => {
  const { t } = useTranslation()
  const { jumpToStep, defaultIndex, builderForms: courseBuilderForms } = useOpportunityBuilderContext()
  const { opportunityStepsContent } = getOpportunityStepsContent()
  const { disabledSteps } = useSelector(state => state.opportunityBuilder)

  const filteredContent = opportunityStepsContent.filter(entry => entry.id !== 'reviewSubmit')

  const collapsedProps = {
    color: 'white',
    bg: '#0F204A',
    hoverbg: '#0F204A'
  }

  return (
    <Accordion allowMultiple maxWidth="1000px" defaultIndex={Object.entries(filteredContent).map((_, index) => index)} key={defaultIndex}>
      {Object.entries(filteredContent).map(([index, step]) => {
        const episodeNum = step.id.includes('episode') ? step.id.match(/\d+/g) : null
        const ReviewComponent = step.ReviewComponent
        return (
          <AccordionItem key={step.id} border="none" marginY={2}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  paddingY={4}
                  fontFamily="Poppins"
                  fontSize={fontSize}
                  fontWeight="500"
                  justifyContent="space-between"
                  position="relative"
                  {...(collapsedProps)}
                  _hover={{
                    bg: collapsedProps.hoverbg // Ensures the hover background color matches the default
                  }}
                >
                  <Box flex="1" textAlign="left" fontFamily="Spline Sans Mono">
                    {episodeNum ? `Episode ${episodeNum} - ${step.label}` : step.label}
                    {!disabledSteps.includes(step.id) &&
                      <Button
                        fontSize={['18px', '18px', '20px']}
                        as={ChakraLink}
                        zIndex="2000"
                        marginLeft="5%"
                        fontWeight="400"
                        fontFamily="Poppins"
                        textDecoration="none"
                        variant="link"
                        textAlign="left"
                        colorScheme="#fff"
                        onClick={() => jumpToStep(step.id)}
                      >
                        <HiPencil style={{ marginRight: '5px' }} />
                        {t('Edit')}
                      </Button>
                    }
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pt={10} pb={4} pr={0} pl={5}>
                  <FormProvider {...courseBuilderForms[step.id]}>
                    {isExpanded && ReviewComponent && <ReviewComponent formDisabled={true} episodeNum={episodeNum} />}
                  </FormProvider>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}
