import React, { useEffect, useRef } from 'react'
import { getCourseStepsContent } from 'CourseBuilder/getCourseStepsContent'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import useRebuildCourseData from 'hooks/courseBuilder/useRebuildCourseData'
import { AppLoadingState } from 'AppLoadingState'

export const FormRender = () => {
  const { nextStep, builderForms, episodeCount, stepId, setCurrentFormDirty } = useCourseBuilderContext()
  const { courseStepsContent } = getCourseStepsContent(episodeCount)
  const formForStep = builderForms[stepId]

  // Setting form is dirty state
  const dirtyFields = formForStep?.formState?.dirtyFields || {}
  const dirtyFieldsKeys = Object.keys(dirtyFields)
  useEffect(() => {
    const isAnyFieldDirty = dirtyFieldsKeys.length > 0
    setCurrentFormDirty(isAnyFieldDirty)
  }, [dirtyFieldsKeys, setCurrentFormDirty])

  // Decide if this is a new course or an existing course
  const isNewCourse = window.location.pathname === '/courses/new'

  const currentStep = courseStepsContent.find(step => step.id === stepId)

  const initialized = useRef(false)
  const newInitialized = useRef(false)

  const isLoading = useRebuildCourseData(newInitialized, initialized, isNewCourse)

  if (isLoading) { return <AppLoadingState /> }

  if (!currentStep) {
    return null
  }

  const { Component, props } = currentStep
  return (
    <Component
      formForStep={formForStep}
      nextStep={nextStep}
      key={stepId}
      {...props}
    />
  )
}
