import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom/client'
import Dropzone from 'dropzone'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'
import { useCreateSignedUrlMutation } from 'features/s3Api'
import { Box, Center, IconButton, Spinner } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { HiOutlineCloudArrowUp } from 'react-icons/hi2'
import { mediaValidationResult } from 'utils/media'
import { HiTrash } from 'react-icons/hi2'
import 'dropzone/dist/dropzone.css'

const requestFileData = async (fileS3Path, accept, handleSignedUrlRequest) => {
  if (accept === 'image/png, image/jpeg') {
    return await handleSignedUrlRequest(fileS3Path, 'get_object')
  } else {
    return await handleSignedUrlRequest(fileS3Path, 'head')
  }
}

const DropzoneInput = ({
  name,
  emptyStateLabel,
  fieldRequired,
  mediaType,
  s3MediaPaths,
  setIsFileUploading,
  isReviewMode,
  onUpload: onUploadComplete,
}) => {
  const [createSignedUrl] = useCreateSignedUrlMutation()
  const { clearErrors, setError, register, getValues, setValue, formState: { errors } } = useFormContext()
  const { t } = useTranslation()
  const fileNameRef = useRef(getValues()[name])
  const dropzoneRef = useRef(null)
  const [loadingExistingFileData, setLoadingExistingFileData] = useState(false)
  const isUploadedError = useRef(false)

  let dropzone = null

  const setRemoveButton = (file) => {
    const customRemoveButton = (
      <IconButton
        title={t('Delete file')}
        className="remove-file-button"
        backgroundColor="white"
        padding="8px"
        _hover={{ backgroundColor: 'rgba(128, 128, 128, 0.25)' }}
        id={`remove-button-${file.name}`}
        color="#CD0050"
        fontSize="24px"
        borderRadius="2px"
        icon={<HiTrash />}
        position="absolute"
        right="16px"
        top="16px"
        cursor="pointer"
        zIndex="105"
        onClick={() => dropzone.removeFile(file)}
      />
    )

    // Create a div to render the IconButton into
    const div = document.createElement('div')
    document.querySelector(`form[name=${name}]`)?.appendChild(div)

    // Render the IconButton into the div using createRoot
    ReactDOM.createRoot(div).render(customRemoveButton)
  }

  const handleSignedUrlRequest = async (mediaPath, method, dropzone=null, file=null, xhr=null) => {
    const { data } = await createSignedUrl({ path: mediaPath, method })
    const url = data?.url
    if (method === 'put') {
      const isSecondaryUpload = !xhr
      isSecondaryUpload && (xhr = new XMLHttpRequest())
      xhr.open('PUT', url, true)
      xhr.setRequestHeader('Content-Type', file.type)

      xhr.upload.onprogress = function(e) {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100
          dropzone.emit('uploadprogress', file, progress)
        }
      }

      xhr.onload = function() {
        if (xhr.status === 200) {
          dropzone.emit('success', file)
          dropzone.emit('complete', file)
          if (method === 'put' && !!onUploadComplete) {
            // Run additional functions if provided by the parent component
            onUploadComplete()
          }
          setIsFileUploading(false)
        } else {
          dropzone.emit('error', file, 'Upload failed.')
          setIsFileUploading(false)
          dropzone.removeFile(file)
        }
      }

      xhr.onerror = function() {
        dropzone.emit('error', file, 'Upload failed.')
        dropzone.removeFile(file)
      }

      xhr.send(file)
    } else {
      const callMethod = (
        (method === 'get_object' && 'GET') || method.toUpperCase()
      )
      const response = await fetch(url, {
        method: callMethod,
      })
      return response
    }
  }

  useEffect(() => {
    const initializeDropzone = () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dropzone = new Dropzone(dropzoneRef.current, {
        url: '/', // Placeholder, actual URL is set dynamically
        autoProcessQueue: false, // Manually process the queue
        paramName: name,
        acceptedFiles: mediaType?.fileType?.accept,
        addRemoveLinks: false,
        maxFiles: 1,
      })
      register(name, { validate: value => fieldRequired && (!value || value === 'clear') ? t('Cannot be empty.') : true })

      dropzone.on('addedfile', async (file) => {
        if (fileNameRef.current) {
          return
        }

        !isReviewMode && setRemoveButton(file)

        setIsFileUploading(true)
        const validationResult = await mediaValidationResult(file, mediaType, name, clearErrors)
        if (validationResult !== true) {
          dropzone.emit('error', file, validationResult)
          dropzone.emit('complete', file, true)
          setError(name, { type: 'fileType', message: validationResult })
          isUploadedError.current = true
          setIsFileUploading(false)
          return
        }

        const primaryXhr = new XMLHttpRequest()
        await handleSignedUrlRequest(s3MediaPaths.uploadPath.primary, 'put', dropzone, file, primaryXhr)
        s3MediaPaths.uploadPath.secondary && await handleSignedUrlRequest(s3MediaPaths.uploadPath.secondary, 'put', dropzone, file)

        // WCAG - Set the thumbnail role to presentation
        const thumbnail = document.querySelector(`form[name="${name}"] [data-dz-thumbnail]`)
        if (thumbnail) {
          thumbnail.setAttribute('role', 'presentation')
        }
      })

      dropzone.on('removedfile', async (file) => {
        await handleSignedUrlRequest(s3MediaPaths.deletePath.primary, 'delete')
        s3MediaPaths.deletePath.secondary && await handleSignedUrlRequest(s3MediaPaths.deletePath.secondary, 'delete')

        clearErrors(name)
        setValue(`${name}`, 'clear')

        // Remove the custom remove button
        const removeButton = document.getElementById(`remove-button-${file.name}`)
        if (removeButton) {
          removeButton.remove()
        }
        isUploadedError.current = false
        setIsFileUploading(false)
        fileNameRef.current = null
      })

      dropzone.on('complete', (file, isError=false) => {
        if (isError) return
        setValue(`${name}`, file.name)
      })
    }

    const fetchExistingFileData = async () => {
      try {
        fileNameRef.current = fileNameRef.current === 'clear' ? null : fileNameRef.current
        if (fileNameRef.current) {
          setLoadingExistingFileData(true)
          const response = await requestFileData(s3MediaPaths.uploadPath.primary, mediaType?.fileType?.accept, handleSignedUrlRequest)
          setLoadingExistingFileData(false)
          const fileType = response.headers.get('Content-Type')

          let file
          if (fileType.startsWith('image/')) {
            const fileBlob = await response.blob()
            file = new File([fileBlob], fileNameRef.current)
            dropzone.createThumbnail(file, dropzone.options.thumbnailWidth, dropzone.options.thumbnailHeight, dropzone.options.thumbnailMethod, true, function (thumbnail) {
              dropzone.emit('thumbnail', file, thumbnail)
            })
          } else {
            // Create a mock file object
            file = {
              name: fileNameRef.current,
              size: response.headers.get('Content-Length'),
              type: fileType,
              status: Dropzone.ADDED,
              accepted: true,
            }
          }
          // Add the file to the Dropzone
          dropzone.emit('addedfile', file)
          dropzone.emit('complete', file)
          dropzone.files.push(file)
          !isReviewMode && setRemoveButton(file)
          dropzone.emit('success', file)
        }
      } catch (error) {
        console.error('Error fetching file from S3:', error)
      }
    }

    if (dropzoneRef.current.dropzone) {
      dropzone = dropzoneRef.current.dropzone
    } else {
      initializeDropzone()
      fetchExistingFileData()
    }
  }, [])

  return (
    <Box as="form" name={name} ref={dropzoneRef}
      className={classNames('dropzone dropzone-field', {
        'current-error-view': isUploadedError.current,
        'review-mode': isReviewMode
      })}
      minHeight={['250px', null, '184px']}
      borderColor={errors[name] ? '#CD0050' : 'initial'}
    >
      {loadingExistingFileData ?
        <Spinner title="File data is loading." />
        :
        <Box className="dz-message">
          <Center fontSize="24px" mb="16px">
            <HiOutlineCloudArrowUp />
          </Center>
          <Box as="label" htmlFor={`${name}_input`} fontWeight="500">{emptyStateLabel}</Box>
          <br />
          {!isReviewMode && t('Click here or drag files into this box to upload them.')}
          <Box color="#CD0050">{errors[name]?.message}</Box>
        </Box>
      }
      <div className="dz-error-message"><span data-dz-errormessage></span></div>
      <input
        type="file"
        id={`${name}_input`}
        name={`${name}_input`}
        hidden
        onChange={e => dropzoneRef.current.dropzone.addFile(e.target.files[0])}
      />
    </Box>
  )
}

export default DropzoneInput
