import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const SubmittedAssignmentsBlock = ({ id, assignmentsCount }) => {
  const { t } = useTranslation()
  return (
    <Box
      flex="1"
      maxWidth={[null, null, '400px']}
      padding="40px 32px 32px"
      bgColor="rgba(80,0,224,0.1)"
    >
      <Box
        fontFamily="Spline Sans Mono"
        fontSize="14px"
        fontWeight="500"
        textTransform="uppercase"
      >
        {t('Submitted assignments')}
      </Box>
      <Box
        marginTop="12px"
        marginBottom="84px"
        color="var(--navy-blue, #0F204A)"
        fontSize={['40px', null, '64px']}
        fontWeight="700"
      >
        {assignmentsCount}
      </Box>
      <Box mr="20px">
        <Button
          as={Link}
          to={`/courses/dashboard/${id}/assignments`}
          colorScheme="zettaDark"
          fontWeight="normal"
          width="100%"
        >
          {t('Check assignments')}
        </Button>
      </Box>
    </Box>
  )
}