import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { useListTalentProfilesQuery, useSendContactRequestMutation } from 'features/talentProfilesApi'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Textarea, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useEffect } from 'react'
import { AppLoadingState } from 'AppLoadingState'
import { LeaveFormControl } from 'LeaveFormControl'
import { useBackTo } from '../../useBackTo'
import { usePageLeaveConfirmation } from 'shared/usePageLeaveConfirmation'
import { useLoadTalentProfileFromStore } from '../../useLoadTalentProfileFromStore'

const RequestFormLoaded = () => {
  const { t } = useTranslation()
  const backTo = useBackTo()
  usePageLeaveConfirmation()
  const toast = useToast()
  const navigate = useNavigate()

  const { data: { name: talentName } } = useLoadTalentProfileFromStore()

  const [sendContactRequest, { isSuccess }] = useSendContactRequestMutation()
  const methods = useFormContext()
  const {
    reset,
    formState: {
      isSubmitted, isDirty, isSubmitSuccessful, errors, isSubmitting
    }
  } = methods

  useEffect(() => {
    if (!isSubmitSuccessful) { return }
    if (isSuccess) { return }

    toast({
      title: t('Something went wrong'),
      status: 'error',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
    return
  }, [toast, isSuccess, isSubmitSuccessful, t])

  useEffect(() => {
    if (!isSuccess) { return }

    reset({}, { keepValues: true, keepIsSubmitted: true })
    toast({
      title: t('Request for contact details was sent to {{talentName}}', { talentName }),
      status: 'success',
      position: 'top',
      containerStyle: {
        maxWidth: 'unset',
        width: '100%',
        margin: '0'
      }
    })
  }, [toast, isSuccess, talentName, reset, t])

  useEffect(() => {
    if (!isSubmitted) { return }
    if (!isSuccess) { return }
    if (isDirty) { return }

    navigate(backTo)
  }, [isSubmitted, isSuccess, isDirty, navigate, backTo])

  return (
    <form onSubmit={methods.handleSubmit(sendContactRequest)}>
      <FormControl isInvalid={!!errors.message}
        maxWidth="668px"
      >
        <Flex
          justifyContent="space-between"
          fontFamily="Poppins"
        >
          <FormLabel
            color="#0F204A"
            fontSize="20px"
            fontWeight="500"
          >
            {t('Explain why you’re reaching out:')}
          </FormLabel>
          <FormErrorMessage
            mt="auto"
            mb="8px"
            lineHeight="unset"
            display="block"
            fontSize="16px"
          >
            <Box color="#CD0050" textTransform="uppercase" fontWeight="500" textAlign="right">
              {errors.message && errors.message.message}
            </Box>
          </FormErrorMessage>
        </Flex>
        <Textarea
          className="contact-request-textarea"
          width="100%"
          padding="16px 24px"
          borderRadius="4px"
          border="2px solid var(--navy-blue, #0F204A)"
          _hover={{ borderColor: '#0F204B' }}
          fontSize="18px"
          fontFamily="Poppins"
          placeholder={t('Hi, I’m Charlotte and I’m the People Operations Manager at Netflix. I came across your profile while looking for candidates for a Production Assistant role we’re looking to fill and I think you might be a great fit. Would you be open to sharing your contact details with me so we can set up a time to chat in the coming days?')}
          isInvalid={!!errors.message}
          errorBorderColor="#CD0050"
          { ...methods.register('message', { required: t('can\'t be empty') }) }
        />
        <Flex paddingTop="24px" pb="48px">
          <Button
            colorScheme="zettaBlue"
            fontWeight="500"
            isLoading={isSubmitting}
            type="submit"
          >
            {t('Send a request')}
          </Button>
          <Box flexBasis="8px" />
          <Button
            as={LeaveFormControl}
            to={backTo}
            colorScheme="zettaWhite"
            fontWeight="500"
          >
            {t('Cancel')}
          </Button>
        </Flex>
      </FormControl>
    </form>
  )
}

export const RequestForm = () => {
  const {
    data: { partner_profile: { id } }
  } = useGetCurrentUserQuery()
  const { isSuccess } = useListTalentProfilesQuery({ partnerProfileId: id })

  if (!isSuccess) {
    return <AppLoadingState />
  }

  return (
    <RequestFormLoaded />
  )
}
