import { useEffect } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { useSetUserLanguageMutation } from 'features/sessionsApi'

// If Auto Update Language Feature Flag is disabled, and user is not admin, force the language to be English
export const useAutoUpdateLanguage = (adminStatus=false, userLanguage=null, isUser=false) => {
  const { i18n } = useTranslation()
  const [setUserLanguageMutation] = useSetUserLanguageMutation()

  useEffect(() => {
    // If the user has a language preference, and it's different from the current language, change the language
    if (userLanguage && i18n.language !== userLanguage) {
      i18n.changeLanguage(userLanguage)
      // set the language to the user's preference if there is a user
    } else if (isUser && !userLanguage) {
      setUserLanguageMutation(i18n.language)
    }
  }, [i18n, adminStatus, userLanguage, setUserLanguageMutation, isUser])
}
