import { useTranslation } from 'i18n/TranslationContext'
import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { Button } from '@chakra-ui/react'
import { useOmniauthCallbackMutation } from 'features/sessionsApi'
import { useCreateSignUpTrackMutation } from 'features/usersApi'
import useSignUpTrackParams from 'hooks/useSignUpTrackParams'


const ConfiguredButton = ({ isSignUp }) => {
  const { t } = useTranslation()
  const [omniauthCallback] = useOmniauthCallbackMutation()
  const [createSignUpTrack] = useCreateSignUpTrackMutation()
  const userTrackParams = useSignUpTrackParams()

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await omniauthCallback(codeResponse)
      const newUserId = response?.data?.id

      if ( newUserId ) {
        // ensure track params have at least one value
        if (Object.values(userTrackParams).some(x => x)) {
          createSignUpTrack(userTrackParams)
        }
      } else if (!response?.data?.success) {
        console.error('Unable to complete signup/signin: ', response)
      }
    },
    flow: 'auth-code',
  })

  // Consult https://developers.google.com/identity/branding-guidelines before changing any button visuals!
  return (
    <Button
      onClick={login}
      size="lg"
      width="100%"
      background="#FFFFFF"
      border="1px solid #4600cd"
      color="#1F1F1F"
      fontFamily={'"Roboto", sans-serif;'}
      fontWeight="500"
      colorScheme="zettaWhite"
      leftIcon={<img src={`${process.env.PUBLIC_URL}/google-logo.svg`} width="20" alt="" />}
      variant="solid"
    >
      {isSignUp ? t('Sign up with Google') : t('Sign in with Google')}
    </Button>
  )
}
export const GoogleOauthButton = ({ isSignUp }) => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
    >
      <ConfiguredButton isSignUp={isSignUp} />
    </GoogleOAuthProvider>
  )
}
