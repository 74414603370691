import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { HiClipboardDocument } from 'react-icons/hi2'
import { H2 } from 'shared/typography'
import { useCurrentEpisodeData, useEpisodeBathPath } from '../hooks'
import { Link as ChakraLink } from '@chakra-ui/react'
import { useCurrentCourseQuery } from '../CoursePage/useCurrentCourseQuery'
import { formatLinkProtocols } from 'utils/string'

export const AdditionalMaterialThumbnail = ({ customBgColor, isLarge, customIcon }) => {
  const iconDimensions = isLarge ? {
    width: '100px',
    height: '86px',
    fontSize: '40px'
  } : {
    width: '70px',
    height: '56px',
    fontSize: '24px'
  }

  return (
    <Center
      borderRadius="16px"
      width={ iconDimensions.width }
      height={ iconDimensions.width }
      bgColor={ customBgColor || '#B9FCB6' }
      color="#3E00B2"
      fontSize={ iconDimensions.fontSize }
      alt=""
      className="additional-material-thumbnail"
    >
      { customIcon || <HiClipboardDocument /> }
    </Center>
  )
}

const AdditionalMaterial = ({ name, type, ...props }) => (
  <Box
    position="relative"
    zIndex={0}
    pl="8px" pb="24px"
    color="#0F204B"
    className="additional-material"
    { ...props }
  >
    <Box
      position="absolute"
      top="10px" left="12px"
      zIndex={-1}
      pt="15px" pb="12px" pl="16px" pr="16px"
      minWidth="110px" minHeight="49px"
      bg={`url(${process.env.PUBLIC_URL}/next-episode-tape-bg.svg) no-repeat`}
      backgroundSize="cover"
      fontFamily="Spline Sans Mono"
      fontWeight="500"
      fontSize="14px"
      textTransform="uppercase"
    >
      {type}
    </Box>
    <Flex
      position="relative"
      alignItems="center"
      pt="48px"
    >
      <AdditionalMaterialThumbnail />
      <Box
        pl="16px"
        fontFamily="Spline Sans Mono"
        fontSize="18px"
        fontWeight="bold"
        textTransform="uppercase"
      >
        {name}
      </Box>
    </Flex>
  </Box>
)

const AdditionalMaterials = ({ additionalMaterials }) => {
  const base = useEpisodeBathPath()

  return additionalMaterials.map(({ name, type, fileName, file_name: courseMaterialFileName, material_type: courseMaterialType }) => {
    const isExternalLink = type === 'External Link' || courseMaterialType === 'External Link'
    const href = isExternalLink ? formatLinkProtocols(fileName || courseMaterialFileName) : `${base}/${fileName}`
    return (
      <ChakraLink
        display="block"
        key={name}
        href={href}
        download={!isExternalLink}
        isExternal
        aria-label={`${type || courseMaterialType} ${name}`}
        className="additional-material-link"
      >
        <AdditionalMaterial
          name={name}
          type={type || courseMaterialType}
          borderBottom={
            additionalMaterials.length > 1 ?
              '1px solid #EFEFF0' : 'none'
          }
        />
      </ChakraLink>
    )
  })
}

export const AdditionalMaterialsBlock = () => {
  const { t } = useTranslation()
  const { additionalMaterials: episodeAdditionalMaterials } = useCurrentEpisodeData()
  const { data: { course_additional_material: courseAdditionalMaterials } } = useCurrentCourseQuery()
  const additionalMaterials = [...courseAdditionalMaterials || [], ...episodeAdditionalMaterials || []]

  const paddingBottom = additionalMaterials.length === 1 ? '14px' : '32px'
  return additionalMaterials.length > 0 ? (
    <Box
      mb="24px"
      pt="32px" pl="24px" pr="32px" pb={paddingBottom}
      bgColor="white"
      width="100%"
      className="additional-materials-block"
    >
      <H2 fontSize="18px">
        {t('Additional materials')}
      </H2>
      <AdditionalMaterials additionalMaterials={additionalMaterials} />
    </Box>
  ) : null
}
