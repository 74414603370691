import { useTranslation } from 'i18n/TranslationContext'
import { useDestroySessionMutation } from 'features/sessionsApi'
import { useContext } from 'react'
import { CableContext } from 'context/cable'

export const useHandleLogout = () => {
  const { t } = useTranslation()
  const [destroySession] = useDestroySessionMutation()
  const cableContext = useContext(CableContext)

  const logoutUser = () => {
    destroySession()

    // Remove all webSocket subscriptions
    const cableSubscriptions = cableContext?.cable?.subscriptions
    cableSubscriptions?.subscriptions.forEach(subscription => {
      cableSubscriptions.remove(subscription)
    })

    // Disconnect the WebSocket connection
    cableContext?.cable?.disconnect()

    // remove the redirect initial load done session
    localStorage.removeItem('redirectInitialLoadDone')
    localStorage.removeItem('redirectTo')
    localStorage.setItem('userSignedIn', 'false')
  }

  return { logoutUser, t }
}
