import { createSlice } from '@reduxjs/toolkit'

const url = window.location.href
const params = new URLSearchParams(new URL(url).search)

// Define an empty search form state
export const emptySearchForm = {
  keywords: '',
  resumeUploaded: false,
  hasPortfolioLink: false,
  courseCompleted: false,
  assignmentSubmitted: false,
  applied: false,
  interviewing: false,
  offer_sent: false,
  hired: false,
  rejected: false,
}

// Get the default form values from the URL
export const defaultTalentSearchForm = Object.fromEntries(
  Object.entries(emptySearchForm).map(([key, value]) => {
    // if params.get(key) is 'true' or 'false', convert to boolean
    if (params.get(key) === 'true' || params.get(key) === 'false') {
      return [key, params.get(key) === 'true']
    } else {
      return [key, params.get(key) || value]
    }
  })
)

// Converts form values to values that can be used in the URL
export const valuesToSearchParams = (values) => {
  const { keywords, ...booleanPairs } = values
  const keywordsArray = keywords.split(/[\s,]+/).filter(Boolean)

  // Convert string 'true' to boolean true and filter out false values
  const booleanParams = Object.fromEntries(
    Object.entries(booleanPairs).filter(([_, value]) => value === true || value === 'true')
      .map(([key, value]) => [key, !!value])
  )

  const booleanCount = Object.values(booleanParams).length
  const lastSearchParams = {
    ...booleanParams,
    ...(keywordsArray.length > 0 ? { keywords: keywordsArray } : {}),
  }
  const lastSearchFilterCount = booleanCount + keywordsArray.length

  return {
    lastSearchParams,
    lastSearchFilterCount,
  }
}

// Generate initial state from the URL
const { lastSearchParams, lastSearchFilterCount } = valuesToSearchParams(defaultTalentSearchForm)

export const talentSearchInitialState = {
  formValues: defaultTalentSearchForm,
  lastSearchParams: lastSearchParams || {},
  lastSearchedAt: null,
  lastSearchFilterCount: lastSearchFilterCount || 0,
}

const talentSearchSlice = createSlice({
  name: 'talentSearch',
  initialState: talentSearchInitialState,
  reducers: {
    resetFormValues: (state) => {
      state.formValues = emptySearchForm
    },
    updateFormValues: (state, action) => {
      state.formValues = { ...state.formValues, ...action.payload }
    },
    updateLastSearchValues: (state) => {
      const { lastSearchParams, lastSearchFilterCount } = valuesToSearchParams(state.formValues)

      // Define the store values that drive the query
      state.lastSearchParams = lastSearchParams
      state.lastSearchedAt = new Date().toISOString()
      state.lastSearchFilterCount = lastSearchFilterCount
    },
  },
})

export const { updateFormValues, updateLastSearchValues, resetFormValues } = talentSearchSlice.actions
export const talentSearchReducer = talentSearchSlice.reducer
