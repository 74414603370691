import { useTranslation } from 'i18n/TranslationContext'
import { useFormState } from 'react-hook-form'
import { useEffect } from 'react'
import { unstable_usePrompt } from 'react-router-dom'


export const usePageLeaveConfirmation = () => {
  const { t } = useTranslation()
  const { isDirty } = useFormState()
  const leaveConfirmText = t('The form contains unsaved changes. If you leave the page now the changes will be lost. Confirm leave?')

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => leaveConfirmText // this text is not actually used in most browsers
    }

    return () => {
      window.onbeforeunload = undefined
    }
  }, [isDirty, leaveConfirmText])

  unstable_usePrompt({
    when: isDirty,
    message: leaveConfirmText
  })
}
