import { Th } from '@chakra-ui/react'

export const StyledTh = (props) => (
  <Th
    color="#070F22"
    opacity="0.8"
    fontSize="18px"
    fontFamily="Poppins"
    fontWeight="500"
    borderTop="1px"
    borderColor="#D7D7D7"
    {...props}
  />
)
