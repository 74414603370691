import { useForm } from 'react-hook-form'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

export const useUserGroupForm = () => {
  const { data: { id } } = useGetCurrentUserQuery()

  return useForm({
    defaultValues: {
      userId: id,
      userGroupItemIds: []
    }
  })
}
