import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from '../../usePageTitle'
import { Box, Center } from '@chakra-ui/react'
import { Header1 } from '../Header1'
import { Details } from '../Details'
import { SignUpForm } from './SignUpForm'

export const SignUpPublicPage = () => {
  const { t } = useTranslation()
  usePageTitle('Sign up')

  const MakingSpace = 'Making Space'
  return (
    <Box
      background="#F0E9E6"
      pt="84px" pb="40px"
    >
      <Box
        padding={[
          '0 24px',
          null,
          '0 140px'
        ]}
      >
        <Center flexDirection="column">
          <Header1>
            {t('Tell us more about yourself')}
          </Header1>
          <Center
            maxWidth="700px"
            flexDirection="column"
          >
            <Details>
              {t('Welcome to {{MakingSpace}}! Please share some more details about yourself so we can create a profile for you.', { MakingSpace })}
            </Details>
            <Center
              maxWidth="460px"
              width="100%"
            >
              <SignUpForm />
            </Center>
          </Center>
        </Center>
      </Box>
    </Box>
  )
}
