import { createSlice } from '@reduxjs/toolkit'
import { COMPASS_STEPS } from 'constants'
import { COMPASS_MODE } from 'constants'
import { beaconApi } from 'features/beacon/beaconApi'

const initialState = {
  messages: [],
  isLoading: false,
  error: null,
  compassMode: COMPASS_MODE.BARRIERS_ANALYSIS,
  compassStep: COMPASS_STEPS.GET_BARRIERS,
}

const loadingReducer = (state) => {
  state.isLoading = true
  state.error = null
}

const errorReducer = (state, action) => {
  state.isLoading = false
  state.error = action.error
}

const messagesReceivedReducer = (state, action) => {
  state.isLoading = false
  state.error = null
  state.messages = [...action.payload.messages, ...state.messages]
  if ( action.payload.currentStep ) state.compassStep = action.payload.currentStep
}

// These messages are Message objects from the Beacon API
export const chatSlice = createSlice({
  name: 'beaconChat',
  initialState,
  reducers: {
    clearMessages(state) {
      state.messages = []
    },
    resetLocalChat(state) {
      state.messages = []
      state.compassMode = COMPASS_MODE.BARRIERS_ANALYSIS
    },
    userMessageAdded(state, action) {
      state.messages.splice(0, 0, { role: 'user', text: action.payload }) },
    setCompassMode(state, action) {
      state.compassMode = action.payload
    },
    setCompassStep(state, action) {
      state.compassStep = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(beaconApi.endpoints.initBot.matchPending, loadingReducer)
      .addMatcher(beaconApi.endpoints.initBot.matchFulfilled, messagesReceivedReducer)
      .addMatcher(beaconApi.endpoints.initBot.matchRejected, errorReducer)
      .addMatcher(beaconApi.endpoints.getResponse.matchPending, loadingReducer)
      .addMatcher(beaconApi.endpoints.getResponse.matchFulfilled, messagesReceivedReducer)
      .addMatcher(beaconApi.endpoints.getResponse.matchRejected, errorReducer)
  }

})

export const { userMessageAdded, setCompassMode, resetLocalChat, clearMessages } = chatSlice.actions
export const beaconChatReducer = chatSlice.reducer
