import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'i18n/TranslationContext'
import { useOpportunityBuilderContext } from './OpportunityBuilderContext'
import { getOpportunityStepsContent } from './getOpportunityStepsContent'
import StepsAccordion from 'components/builder/StepsAccordion'

const OpportunityStepsAccordion = () => {
  const { t } = useTranslation()
  const { stepId, jumpToStep, defaultIndex, isFileUploading } = useOpportunityBuilderContext()
  const { opportunityStepsContent, headersMap } = getOpportunityStepsContent()
  const { disabledSteps, valid_builder_steps, invalid_builder_steps } = useSelector(state => state.opportunityBuilder)

  const steps = Object.entries(headersMap).map(([index, header]) => ({
    header,
    links: opportunityStepsContent.filter(link => link.defaultIndex === parseInt(index))
  }))

  return (
    <StepsAccordion
      title={t('Steps')}
      steps={steps}
      currentStepId={stepId}
      onStepClick={!isFileUploading ? jumpToStep : undefined}
      disabledSteps={disabledSteps}
      validSteps={valid_builder_steps}
      invalidSteps={invalid_builder_steps}
      defaultIndex={defaultIndex}
      t={t}
    />
  )
}

export default OpportunityStepsAccordion
