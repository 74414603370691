import { Box, Divider, Flex, HStack, VStack, useDisclosure, Link as ChakraLink } from '@chakra-ui/react'
import { FilterChipRow } from 'Partner/TalentListPage/FilterChipRow'
import { ExportCsvButton } from 'components/partner/talent/list_page/ExportCsvButton'
import { SearchProfilesButton } from 'components/partner/talent/list_page/SearchProfilesButton'
import SearchDrawer from 'components/partner/talent/list_page/search_drawer/SearchDrawer'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'i18n/TranslationContext'
import { Route, Routes, useLocation, useParams, Link as RouterLink } from 'react-router-dom'
import { usePageTitle } from 'usePageTitle'
import { PageOuterContainer, ApplicationTable } from 'components'

// TODO: move these to their own components for reuse
import { TalentProfileView } from 'Partner/TalentProfileView'
import { ContactDetails } from 'Partner/TalentListPage/ContactDetails'
import { Pagination } from 'Partner/Pagination'

import { useShowOpportunityForPartnerQuery } from 'features/opportunitiesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useApplicationListData, useTalentProfileCsvExport } from 'hooks/opportunities/useApplicationListData'
import { useOpportunityAppListSwitchConfig } from 'hooks/partnerUx/useSwitchConfigs'

const CustomizedPageContainer = (props) => (
  <PageOuterContainer
    greenBg="true"
    {...props}
  />
)

const ViewProfileRoutes = () => {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/view_profile/:talentProfileId"
          element={
            <TalentProfileView />
          }
        />
        <Route
          path="/view_profile/:talentProfileId/contact_request"
          element={
            <ContactDetails />
          }
        />
      </Routes>
    </AnimatePresence>
  )
}

const PageContent = () => {
  const { opportunityId } = useParams()
  const { data: userData } = useGetCurrentUserQuery()
  const partnerProfileId = userData?.partner_profile?.id

  const { data } = useShowOpportunityForPartnerQuery({ id: opportunityId, partnerId: partnerProfileId })

  const { isOpen, onOpen, onClose } = useDisclosure() // for search drawer
  const { t } = useTranslation()
  const switchConfigs = useOpportunityAppListSwitchConfig()

  return (
    <CustomizedPageContainer>
      <SearchDrawer isOpen={isOpen} onClose={onClose} switchConfigs={switchConfigs} />
      <Box
        bgColor="white"
        pt={['32px', null, null, '64px']}
        pl={['16px', null, null, '48px']}
        pr={['16px', null, null, '48px']}
        className="page-content"
      >
        <Flex justifyContent="space-between" alignItems="center" direction={['column', null, 'row']}>
          <VStack display="block">
            <Box as="h1"
              fontFamily="Spline Sans Mono"
              fontSize={['40px', null, null, '48px']}
              fontWeight="bold"
              textTransform="uppercase"
            >
              {t('Talent Applications')}
            </Box>
            <Box gap="30px"
              fontFamily="Spline Sans Mono"
              fontSize={['14px', null, null, '18px']}
              fontWeight="bold"
              textTransform="uppercase"
              float="left"
            >
              {data?.name && (
                <ChakraLink
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                  as={RouterLink}
                  to={`/opportunities/${data.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data.name}
                </ChakraLink>
              )}
            </Box>
            {data?.opportunity_application_applied_count && (
              <Box
                mt="4"
                bg="gray.100"
                p="3"
                borderRadius="md"
                fontFamily="Spline Sans Mono"
                fontWeight="bold"
                fontSize="16px"
                float="left"
                sx={{ clear: 'both' }}
              >
                Total Applications: {data.opportunity_application_applied_count}
              </Box>
            )}
          </VStack>

          <Divider display={['block', null, 'none']} my={5} />

          <HStack spacing="16px">
            <SearchProfilesButton onOpen={onOpen} />
            <ExportCsvButton useExportOptions={useTalentProfileCsvExport} />
          </HStack>
        </Flex>

        <FilterChipRow switchConfigs={switchConfigs} />

        <Box pb="128px">
          <ApplicationTable />
          <Pagination listQuery={useApplicationListData()} />
        </Box>
      </Box>
    </CustomizedPageContainer>
  )
}

export const OpportunityApplicationList = () => {
  const { t } = useTranslation()
  usePageTitle(t('Talent Applications'))

  return (
    <Flex
      position="relative"
      flex="1"
      flexDirection="column"
      justifyContent="space-between"
      className="talent-list-page"
    >
      <ViewProfileRoutes />
      <PageContent />
    </Flex>
  )
}
