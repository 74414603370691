import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Button,
  Center,
  Show
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { HiTv, HiOutlineRectangleGroup } from 'react-icons/hi2'
import { LogOutMenuItem } from 'features/sessions'
import { FaLightbulb } from 'react-icons/fa'
import { HeaderLayout, MenuItemBase, MenuItemBasicLinkButton } from './shared/HeaderParts'
import { HeaderDropdown, ShareFeedbackButton, OpportunitiesButton } from './components'
import { useGetTalentProfileQuery } from 'features/talentProfilesApi'
import { useGetCurrentUserQuery } from './features/sessionsApi'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { AppLoadingState } from 'AppLoadingState'
import { FEATURE_FLAGS } from 'constants'

const MenuItem = ({ children, destination }) => (
  <MenuItemBase
    as={NavLink} to={destination} end
    color="rgba(15, 32, 75, 1)"
    opacity="0.4"
    _activeLink={{
      color: 'rgba(80, 0, 232, 1)',
      opacity: '1'
    }}>
    {children}
  </MenuItemBase>
)

const MobileHeader = () => {
  const { t } = useTranslation()
  const { isEnabled: opportunitiesEnabled } = useFeatureFlags(FEATURE_FLAGS.OPPORTUNITIES)

  return (
    <>
      <MenuItem destination="/courses">
        <HiTv /> <Box paddingLeft="8px">{t('Courses')}</Box>
      </MenuItem>
      {opportunitiesEnabled && (
        <MenuItem destination="/opportunities">
          <HiOutlineRectangleGroup /> <Box paddingLeft="8px">{t('Opportunities')}</Box>
        </MenuItem>
      )}
      <MenuItem destination="/support">
        <FaLightbulb /> <Box paddingLeft="8px">{t('Support')}</Box>
      </MenuItem>
      <ShareFeedbackButton
        as={MenuItemBasicLinkButton}
      />
      <LogOutMenuItem />
    </>
  )
}

const DesktopHeader = () => {
  const { data: currentUserData } = useGetCurrentUserQuery()
  const id = currentUserData?.talent_profile?.id
  const { data, isFetching } = useGetTalentProfileQuery({ id })
  const { t } = useTranslation()
  const { isEnabled: opportunitiesEnabled } = useFeatureFlags(FEATURE_FLAGS.OPPORTUNITIES)

  if (isFetching) {
    return <AppLoadingState />
  }

  return (
    <>
      <Center flexGrow="1">
        <Button
          as={NavLink}
          end
          to="/courses"
          variant="link"
          leftIcon={<HiTv />}
          fontWeight="500"
          style={{
            textDecoration: 'none'
          }}
          color="#0F204B"
          opacity="0.6200000047683716"
          _activeLink={{
            color: '#5000E8',
            opacity: '1'
          }}
        >
          {t('Courses')}
        </Button>
        <Box flexBasis="50px" />
        {opportunitiesEnabled && (
          <Box>
            <OpportunitiesButton />
          </Box>
        )}
      </Center>
      <HeaderDropdown headerType="talent" photoUrl={data?.photo_url} name={data?.name} experienceType={data?.experience_type} />
    </>
  )
}

export const TalentsHeader = ({ backTo }) => (
  <HeaderLayout backTo={backTo}>
    <Show breakpoint="(max-width: 767px)">
      <MobileHeader />
    </Show>
    <Show above="md">
      <DesktopHeader />
    </Show>
  </HeaderLayout>
)
