import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Center,
  Grid,
  GridItem,
  FormControl,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { PhotoInput } from '../PhotoInput'
import { StyledFormLabel } from 'components'
import { StyledTextArea } from '../StyledTextArea'
import { ProfileInputFields } from '../ProfileInputFields'
import { UploadResumeInput } from '../UploadResumeInput'
import { SkillsPane } from 'TalentProfile/Edit/SkillsPane'
import { FEATURE_FLAGS } from 'constants'
import useFeatureFlags from 'hooks/useFeatureFlags'

export const TalentFormContent = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()
  const { isEnabled: compassEnabled } = useFeatureFlags(
    FEATURE_FLAGS.COMPASS
  )

  const Tape = () => (
    <Box
      position="absolute"
      width="100%"
      top={['-6px', null, '-42px']}
      left={['50%', null, 'unset']}
      zIndex="100"
    >
      <Box
        as="img"
        alt=""
        margin={[null, null, '0 auto']}
        maxWidth={['190px', null, 'unset']}
        src={`${process.env.PUBLIC_URL}/profile-photo-tape.svg`}
      />
    </Box>
  )

  return (
    <Grid
      gap={30}
      templateAreas={[
        `"photo-skills"
         "main"
         "resume"`,
        null,
        `"main photo-skills"
         "resume photo-skills"`,
      ]}
      gridTemplateColumns={[null, null, '1.6fr 1fr']}
    >
      <GridItem area="photo-skills">
        <Center>
          <Box
            position="relative"
            mt={['-12px', null, '-94px']}
            mb={8}
            width={[null, null, '100%']}
          >
            <Tape />
            <PhotoInput />
          </Box>
        </Center>
        <FormControl mb={8}>
          <StyledFormLabel>{t('Alt text for photo')}</StyledFormLabel>
          <StyledTextArea
            placeholder={t('Alternative text for your photo')}
            {...register('altText')}
          />
        </FormControl>

        {compassEnabled && <SkillsPane />}
      </GridItem>

      <GridItem area="main">
        <ProfileInputFields />
      </GridItem>

      <GridItem area="resume">
        <UploadResumeInput />
      </GridItem>
    </Grid>
  )
}
