import { Box, Button } from '@chakra-ui/react'
import { useCourseId, useGetStepInfo, useStepId } from 'CourseFeature/hooks'
import { NextStepButton } from 'components/talent/quiz/modal-elements/NextStepButton'
import { ModalBase } from 'components/talent/quiz/sectionCompleteModal/ModalBase'
import { AppLoadingState } from 'AppLoadingState'
import { DIRECTION } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'
import { useNavigate } from 'react-router-dom'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useCurrentCourseQuery } from 'CourseFeature/CoursePage/useCurrentCourseQuery'
import { useCreateOpportunityApplicationMutation } from 'features/opportunitiesApi'

const SectionCompleteModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const courseId = useCourseId()
  const stepId = useStepId()
  const navigate = useNavigate()
  const nextStepInfo = useGetStepInfo(stepId, DIRECTION.NEXT)
  const isFinalStep = nextStepInfo?.isAssignment

  const { data: userData } = useGetCurrentUserQuery()
  const talentUserId = userData?.talent_profile?.id
  const { data: courseData, isLoading: isCourseLoading } = useCurrentCourseQuery()
  const [createOpportunityApplication] = useCreateOpportunityApplicationMutation()

  const handleNextStepClick = (nextStepUrl) => {
    onClose()
    navigate(nextStepUrl)
  }

  const handleApply = async () => {
    if (courseData?.opportunity_id && talentUserId) {
      try {
        await createOpportunityApplication({
          talentUserId: talentUserId,
          opportunityId: courseData?.opportunity_id,
          status: 'applied'
        }).unwrap()
        onClose()
        navigate('/courses')
      } catch (error) {
        console.error('Failed to apply for opportunity:', error)
      }
    }
  }

  if (isCourseLoading) {
    return <AppLoadingState />
  }

  const isCourseComplete = isFinalStep || !nextStepInfo
  const hasOpportunity = !!courseData?.opportunity_id

  let mainCopy
  if (isCourseComplete && hasOpportunity) {
    mainCopy = t(`You've completed the requirements for ${courseData?.opportunity?.name}! Apply now to be considered for this exciting opportunity.`)
  } else if (isCourseComplete) {
    mainCopy = t('You\'ve reached the end of this course!')
  } else {
    mainCopy = t('You\'ve reached the end of this episode! Let\'s continue to the next part of the course.')
  }

  const footerButtons = (
    <Box className="footer-buttons">
      <Button
        size="lg"
        fontSize="18px"
        variant="outline"
        colorScheme="zettaBlue"
        onClick={onClose}
        mr={5}
        borderColor="#5000E8"
        color="#5000E8"
      >
        {t('Maybe Later')}
      </Button>
      <NextStepButton
        stepId={stepId}
        courseId={courseId}
        onNextStepClick={handleNextStepClick}
        talentUserId={talentUserId}
        opportunityId={courseData?.opportunity_id}
        isCourseComplete={isCourseComplete}
        hasOpportunity={hasOpportunity}
        onApply={handleApply}
      />
    </Box>
  )

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      headerText={isCourseComplete ? t('Course Complete') : t('Section Complete')}
      bodyText={mainCopy}
      footerButtons={footerButtons}
    />
  )
}

export default SectionCompleteModal
