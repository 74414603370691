import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { addForm, removeForm } from 'utils/forms'

import {
  AddSubtractButton,
  CourseExpertSocialLinks,
  CourseExpertInfo
} from 'components'
import {
  Box,
} from '@chakra-ui/react'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const CourseExpertsForm = ({ formDisabled=false }) => {
  const { unregister } = useFormContext() // retrieve all hook methods
  const totalExpertCount = 3
  const { expertCount, setExpertCount } = useCourseBuilderContext()

  const { t } = useTranslation()

  return (
    <>
      {expertCount.map((index, arrayIndex) => {
        // Declare courseExpertFieldNames here, before the return statement
        const courseExpertFieldNames = {
          course_expert_name: `course_expert_name_${index}`,
          course_expert_image: `course_expert_image_${index}`,
          course_expert_image_alt_text: `course_expert_image_alt_text_${index}`,
          course_expert_title: `course_expert_title_${index}`,
          course_expert_bio: `course_expert_bio_${index}`,
          course_expert_link: `course_expert_link_${index}`,
          course_expert_linkedin: `course_expert_linkedin_${index}`,
          course_expert_instagram: `course_expert_instagram_${index}`,
          course_expert_tiktok: `course_expert_tiktok_${index}`,
        }

        return (
          <Box key={index}>
            <Box id={`course-expert-${index}`}>
              {expertCount.length > 1 && !formDisabled && ( // Conditionally render the button if expertCount is greater than 1
                <AddSubtractButton
                  determineType="minus"
                  textValue={t('Remove Expert')}
                  onClickAction={() => removeForm(index, expertCount, setExpertCount, unregister, Object.values(courseExpertFieldNames))}
                  currentCount={expertCount}
                  totalCount={3}
                />
              )}
              <CourseExpertInfo t={t} courseExpertFieldNames={courseExpertFieldNames} formDisabled={formDisabled} />
              <CourseExpertSocialLinks t={t} courseExpertFieldNames={courseExpertFieldNames} formDisabled={formDisabled} />
            </Box>
          </Box>
        )
      })}
      {!formDisabled && (
        <AddSubtractButton
          determineType="add"
          textValue={t('Add Expert')}
          onClickAction={() => addForm(totalExpertCount, expertCount, setExpertCount)}
          currentCount={expertCount}
          totalCount={2}
          maxAmtTooltipText={t('Maximum experts added.')}
        />
      )}
    </>
  )
}

export default CourseExpertsForm
