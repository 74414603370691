import React, { useRef, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { FormTemplate } from 'components/builder/FormTemplate'
import { useTranslation } from 'i18n/TranslationContext'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useSelector } from 'react-redux'
import {
  Box,
  Button,
  Link as ChakraLink,
  Accordion,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
} from '@chakra-ui/react'
import { HiPencil } from 'react-icons/hi2'
import { getCourseStepsContent } from 'CourseBuilder/getCourseStepsContent'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { useNavigate } from 'react-router-dom'
import { useSubmitCourseMutation } from 'features/coursesApi'

const FormContent = () => {
  const { t } = useTranslation()
  const { jumpToStep, defaultIndex, episodeCount, builderForms: courseBuilderForms } = useCourseBuilderContext()
  const { courseStepsContent } = getCourseStepsContent(episodeCount, t)
  const { disabledCourseSteps } = useSelector(state => state.courseBuilder)
  const fontSize = ['18px', '18px', '20px']

  const filteredContent = courseStepsContent.filter(entry =>
    entry.id !== 'reviewSubmit' && !disabledCourseSteps.includes(entry.id)
  )

  const collapsedProps = {
    color: 'white',
    bg: '#0F204A',
    hoverbg: '#0F204A'
  }

  return (
    <Accordion allowMultiple maxWidth="1000px" defaultIndex={Object.entries(filteredContent).map((_, index) => index)} key={defaultIndex}>
      {Object.entries(filteredContent).map(([index, step]) => {
        const episodeNum = step.id.includes('episode') ? step.id.match(/\d+/g) : null
        const ReviewComponent = step.ReviewComponent
        return (
          <AccordionItem key={step.id} border="none" marginY={2}>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  paddingY={4}
                  fontFamily="Poppins"
                  fontSize={fontSize}
                  fontWeight="500"
                  justifyContent="space-between"
                  position="relative"
                  {...(collapsedProps)}
                  _hover={{
                    bg: collapsedProps.hoverbg // Ensures the hover background color matches the default
                  }}
                >
                  <Box flex="1" textAlign="left" fontFamily="Spline Sans Mono">
                    {episodeNum ? `Episode ${episodeNum} - ${step.label}` : step.label}
                    <Button
                      fontSize={['18px', '18px', '20px']}
                      as={ChakraLink}
                      zIndex="2000"
                      marginLeft="5%"
                      fontWeight="400"
                      fontFamily="Poppins"
                      textDecoration="none"
                      variant="link"
                      textAlign="left"
                      colorScheme="#fff"
                      onClick={() => jumpToStep(step.id)}
                    >
                      <HiPencil style={{ marginRight: '5px' }} />
                      {t('Edit')}
                    </Button>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pt={10} pb={4} pr={0} pl={5}>
                  <FormProvider {...courseBuilderForms[step.id]}>
                    {isExpanded && ReviewComponent && <ReviewComponent formDisabled={true} episodeNum={episodeNum} />}
                  </FormProvider>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export const ReviewAndSubmit = ({ nextStep, formForStep }) => {
  const { t } = useTranslation()
  const courseData = useSelector(state => state.courseBuilder)
  const navigate = useNavigate()
  const [disableButtonUntilBottom, setDisableButtonUntilBottom] = useState(true)
  const { userId, episodeCount } = useCourseBuilderContext()
  const initialized = useRef(false)
  const [submitCourse, { isSuccess }] = useSubmitCourseMutation()

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)


  const submitStep = async () => {
    try {
      await submitCourse({ userId, courseId: courseData.id }).unwrap()
    } catch (error) {
      console.log('error submitting course')
    }
  }

  useEffect(() => {
    const isBuilderComplete = () => {
      let requiredSteps = [
        'courseNameThumbnail',
        'courseDescriptions',
        'courseExperts',
        'courseVisibility',
        'introVideoThumbnail',
        'introAccessibilityFiles'
      ]

      // Dynamically add episode-specific steps
      for (let i = 1; i <= episodeCount; i++) {
        requiredSteps.push(`episode${i}UploadVideoThumbnail`)
        requiredSteps.push(`episode${i}UploadAccessibility`)
        requiredSteps.push(`episode${i}Quiz`)
      }

      // Check for any invalid course steps
      if (courseData.invalid_course_steps.length > 0) {
        return false // There are invalid steps that need filling out
      }

      // Verify all required steps are valid
      const isComplete = requiredSteps.every(step =>
        courseData.valid_course_steps.includes(step) ||
        courseData.disabledCourseSteps.includes(step)
      )

      return isComplete
    }

    // Function to handle the scroll event
    const handleScroll = () => {
      const isUserAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100
      if (isUserAtBottom && isBuilderComplete()) {
        setDisableButtonUntilBottom(false) // Enable the button when user has scrolled to the bottom and the builder is complete
      }
    }

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Navigation logic triggered by isSuccess becoming true
    if (isSuccess) {
      navigate('/courses')
      // Additional actions upon successful submission can go here
    }

    // Cleanup function to remove the scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isSuccess, navigate, setDisableButtonUntilBottom, courseData.invalid_course_steps.length, courseData.valid_course_steps, episodeCount, courseData.disabledCourseSteps])

  return (
    <FormTemplate
      buttonDisabled={disableButtonUntilBottom}
      formTitle={t('Review & Submit')}
      FormContent={FormContent}
      formForStep={formForStep}
      nextStep={submitStep}
      ctaText={t('Submit for Review')}
    >
      <FormContent />
    </FormTemplate>
  )
}
