import { useTranslation } from 'i18n/TranslationContext'
import { Center } from '@chakra-ui/react'
import { ResultsCounts } from './ResultsCounts'
import { PaginationButtons } from './PaginationButtons'

export const Pagination = ({ listQuery }) => {
  const { t } = useTranslation()
  const {
    data,
    isSuccess
  } = listQuery

  if (!isSuccess) { return null }
  if (data.pagination.count === 0) { return null }

  return (
    <>
      <ResultsCounts pagination={data.pagination} />
      <nav role="navigation" aria-label={t('List pages')}>
        <Center as="ul" marginTop="12px">
          <PaginationButtons pagination={data.pagination} />
        </Center>
      </nav>
    </>
  )
}
