import { Spacer } from '@chakra-ui/react'
import CancelButton from './CancelButton'
import { FormControlContainer } from './FormControlContainer'
import StepIndicator from './StepIndicator'
import { ContinueSubmitButton } from './ContinueSubmitButton'

export const FormControls = ({ currentStep }) => (
  <FormControlContainer>
    <CancelButton width={['100%', null, 'unset']} />
    <Spacer flexBasis={['8px', null, 'unset']} />
    <StepIndicator currentStep={currentStep} />
    <Spacer flexBasis={['8px', null, 'unset']} />
    <ContinueSubmitButton width={['100%', null, 'unset']} />
  </FormControlContainer>
)
