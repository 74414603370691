import { apiSlice } from './apiSlice'

const camelToSnake = (str) => {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

const convertFormParamsToData = (props) => {
  const formData = new FormData()

  const regularProps =[
    'name',
    'companyName',
    'companyBenefits',
    'companyAccessibilityPolicy',
    'companyDescription',
    'companyWebsite',
    'greenhouseApiKey',
    'greenhouseApiUserId',
    'greenhouseApiValid'
  ]

  regularProps.forEach((prop) => {
    if (props[prop] === null || typeof props[prop] === 'undefined') { return }
    const snakeCaseKey = camelToSnake(prop)
    formData.append(snakeCaseKey, props[prop])
  })

  if (props.deletePhoto) {
    formData.append('delete_photo', true)
  } else if (props.photo) {
    formData.append('photo', props.photo[0])
  }

  if (props.location) {
    formData.append('location.place_id', props.location.place_id)
  } else {
    formData.append('location.place_id', '')
  }

  return formData
}

export const partnerProfilesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchGreenhouseJobs: builder.query({
      query: (partnerProfileId) => `partner_profiles/${partnerProfileId}/greenhouse/jobs`,
    }),
    getGreenhouseUsers: builder.mutation({
      query: ({ partnerProfileId, greenhouseApiKey }) => ({
        url: `partner_profiles/${partnerProfileId}/greenhouse/users`,
        method: 'GET',
        params: { greenhouse_api_key: greenhouseApiKey }
      }),
    }),
    updatePartnerProfile: builder.mutation({
      query: ({ partnerProfileId, ...restProps }) => ({
        url: `/partner_profiles/${partnerProfileId}`,
        method: 'PATCH',
        formData: true,
        body: convertFormParamsToData(restProps)
      }),
      invalidatesTags: (result, error) => error ? [] : ['CurrentPartnerProfile']
    })
  })
})

export const {
  useFetchGreenhouseJobsQuery,
  useGetGreenhouseUsersMutation,
  useUpdatePartnerProfileMutation,
} = partnerProfilesApi
