import { useTranslation } from 'i18n/TranslationContext'
import { PageOuterContainer } from 'shared/TalentsLayout'
import { usePageTitle } from 'usePageTitle'
import { EmailConfirmationProcess, useSecretParam } from './EmailConfirmationProcess'
import { EmailConfirmContent } from 'EmailConfirmationPage/EmailConfirmContent'

const PageContent = () => {
  const { t } = useTranslation()
  usePageTitle(t('Confirm your email'))
  const secret = useSecretParam()

  if (secret) {
    return (
      <EmailConfirmationProcess secret={secret} />
    )
  }

  return (
    <PageOuterContainer>
      <EmailConfirmContent />
    </PageOuterContainer>
  )
}

export const EmailConfirmationPage = () => (
  <PageContent />
)
