import { VStack } from '@chakra-ui/react'
import { AnswerOption } from 'components/talent/quiz/modal-elements/AnswerOption'
import { WrongAnswer } from 'components/talent/quiz/modal-elements/WrongAnswer'
import { TALENT_QUIZ_MODE } from 'constants'
import { setSelectedAnswer } from 'features/quiz/talentQuizSlice'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { attemptsContainsAnswer, hasCorrectAnswer } from 'utils/quizzes/talentQuiz'

export const AnswerOptions = ({ question }) => {
  const dispatch = useDispatch()
  const { selectedAnswer, quizMode } = useSelector((state) => state.talentQuiz)
  const [locallySelectedAnswer, setLocallySelectedAnswer] = useState(null)
  const attempts = useMemo(() => question.talent_answer_round?.attempts || [], [question])

  const iconLetters = ['A', 'B', 'C', 'D']
  const isCorrectlyAnswered = hasCorrectAnswer(question)
  const isActiveInstance = !attemptsContainsAnswer(attempts, locallySelectedAnswer) && !isCorrectlyAnswered
  const isReviewMode = quizMode === TALENT_QUIZ_MODE.REVIEW

  const handleClick = (answer) => {
    if (!isCorrectlyAnswered && isActiveInstance) {
      setLocallySelectedAnswer(answer)
      dispatch(setSelectedAnswer(answer))
    }
  }

  const sorted = [...question.answers].sort()
  const possibleAnswers = sorted.map((answer, index) => {
    const isWrongAttempt = attempts.some((attempt) => attempt.attempted_answer === answer && !attempt.correct)
    const isCorrectAttempt = attempts.some((attempt) => attempt.attempted_answer === answer && attempt.correct)
    const iconLetter = iconLetters[index]

    let showAllIncorrect
    let showThisIncorrect
    let showIsSelected

    if ( isReviewMode ) {
      showIsSelected = isCorrectAttempt
    }
    else {
      showAllIncorrect = (isActiveInstance && isWrongAttempt)
      if ( isCorrectlyAnswered ) {
        // CORRECTLY ANSWERED
        if ( !isActiveInstance ) {
          // NOT ACTIVE INSTANCE
          showIsSelected = locallySelectedAnswer ? locallySelectedAnswer === answer : isCorrectAttempt
          showThisIncorrect = isWrongAttempt && locallySelectedAnswer === answer
        }
      } else {
        // NOT CORRECTLY ANSWERED YET
        if ( isActiveInstance ) {
          // ACTIVE INSTANCE
          showIsSelected = isCorrectAttempt || selectedAnswer === answer
          showThisIncorrect = isWrongAttempt
        } else {
          // NOT ACTIVE INSTANCE
          showIsSelected = locallySelectedAnswer === answer
          showThisIncorrect = isWrongAttempt && locallySelectedAnswer === answer
        }
      }
    }

    return showAllIncorrect || showThisIncorrect ? (
      <WrongAnswer key={answer} answer={answer} iconLetter={iconLetter} />
    ) : (
      <AnswerOption
        key={answer}
        answer={answer}
        iconLetter={iconLetter}
        isSelected={showIsSelected}
        onClick={() => handleClick(answer)}
        isDisabled={!isActiveInstance || isReviewMode}
        isActiveInstance={isActiveInstance}
      />
    )
  })

  return (
    <VStack spacing={2} alignItems="normal" className="quiz-answer-options">
      {possibleAnswers}
    </VStack>
  )
}