import { Box } from '@chakra-ui/react'

export const Header1 = ({ children }) => (
  <Box
    pb="16px"
    maxWidth="800px"
    flexDirection="column"
    width="100%"
  >
    <Box as="h1"
      fontFamily="Spline Sans Mono"
      fontWeight="bold"
      fontSize={[
        '24px',
        null,
        '40px'
      ]}
      textTransform="uppercase"
      textAlign={[
        null, null,
        'center'
      ]}
    >
      {children}
    </Box>
  </Box>
)
