import { useTranslation } from 'i18n/TranslationContext'
import { Show, Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react'
import { CourseItem } from './CourseItems'
import { tableBreakpoint } from './CourseItems/shared'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListCourseForPartnerQuery } from 'features/coursesApi'
import { TableItemMap } from 'shared/TableItemMap'
import { StyledTh } from 'shared/StyledTh'

const CourseTableWrapper = ({ children }) => {
  const { t } = useTranslation()
  return (
    <TableContainer>
      <Table
        borderBottom={[
          null, null,
          '1px solid #D7D7D7'
        ]}
      >
        <colgroup>
          <col></col>
          <Show above={tableBreakpoint}>
            <col width="0"></col>
          </Show>
        </colgroup>
        <Thead>
          <Tr>
            <StyledTh padding="16px">
              {t('Courses')}
            </StyledTh>
            <Show above={tableBreakpoint}>
              <StyledTh
                textAlign="right"
                borderColor="#D7D7D7"
                padding="0"
              >
                {t('Talent enrolled')}
              </StyledTh>
              <StyledTh textAlign="right">
                {t('Actions')}
              </StyledTh>
            </Show>
          </Tr>
        </Thead>
        <Tbody>
          <Tr aria-hidden="true">
            <Td
              border="0"
              padding={[
                '6px 0',
                null,
                '8px 0'
              ]}
            />
          </Tr>
          {children}
          <Show above={tableBreakpoint}>
            <Tr aria-hidden="true"><Td border="0" padding="8px 0" /></Tr>
          </Show>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export const CourseTable = () => {
  const { t } = useTranslation()
  const {
    data: { partner_profile: { id } }
  } = useGetCurrentUserQuery()
  const listQuery = useListCourseForPartnerQuery(id)

  return (
    <CourseTableWrapper>
      <TableItemMap
        collection={listQuery.data}
        listQuery={listQuery}
        emptyStateCopy={t('No courses found')}
      >
        {
          ({ id, ...restProps }) => (
            <Tr key={id}>
              <CourseItem
                id={id}
                {...restProps}
              />
            </Tr>
          )
        }
      </TableItemMap>
    </CourseTableWrapper>
  )
}
