import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Center, Flex } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { InputField } from 'components'
import { useGetTalentProfileQuery } from 'features/talentProfilesApi'
import { AppLoadingState } from 'AppLoadingState'
import { usePageTitle } from 'usePageTitle'
import { useBackTo } from '../../useBackTo'
import { useTalentProfileId } from 'Partner/useTalentProfileId'

export const ShowContactDetails = () => {
  const { t } = useTranslation()
  usePageTitle(t('Contact Details'))
  const backTo = useBackTo()

  const id = useTalentProfileId()
  const { data, isLoading } = useGetTalentProfileQuery({
    id, mode: 'show_contacts'
  })

  if (isLoading) {
    return <AppLoadingState />
  }

  const { name, email, photo_url: photoUrl, photo_alt_text: photoAltText } = data

  return (
    <Box as="main" role="main"
      position="relative"
      paddingTop={['16px', null, '68px']}
      paddingLeft={['24px', null, '64px']}
      paddingRight={['24px', null, '64px']}
    >
      <Flex
        flexDirection={['column-reverse', null, 'row']}
        justifyContent="space-between"
      >
        <Flex
          flexDirection={['column-reverse', null, 'column']}
          flexGrow="1"
        >
          <Box width={['100%', null, 'unset']}>
            <Button
              as={Link}
              to={backTo}
              colorScheme="zettaPurple"
              width={['100%', null, 'unset']}
              fontWeight="500"
            >
              {t('Back')}
            </Button>
          </Box>
          <Box
            marginTop={['16px', null, '92px']}
            fontFamily="Poppins"
          >
            <InputField
              label={t('Name')}
              inputProps={{
                value: name,
                isReadOnly: true
              }}
            />
            <InputField
              label={t('Email')}
              inputProps={{
                value: email,
                isReadOnly: true
              }}
            />
          </Box>
        </Flex>
        <Box
          flexBasis={['0', null, '48px']}
          flexGrow="1"
        />
        <Box>
          <Box
            marginLeft={['-32px', null, '0']}
            marginRight={['-22px', null, '0']}
          >
            <Box as="img"
              width="100%"
              opacity="0.85"
              src={`${process.env.PUBLIC_URL}/contact-details-tape.svg`}
              alt={t('Contact details')}
            />
          </Box>
          <Center>
            <Box
              maxWidth={['300px', null, '390px']}
              border="2px solid var(--navy-blue, #0F204A)"
              borderRadius="6px"
              mt={['-28px', null, '-36px']}
              ml="4px"
            >
              <img
                src={photoUrl}
                alt={photoAltText}
              />
            </Box>
          </Center>
        </Box>
      </Flex>
    </Box>
  )
}
