import { Flex, HStack } from '@chakra-ui/react'
import Avatar from 'components/talent/quiz/modal-elements/Avatar'
import { ChatBubble } from 'components/talent/quiz/modal-elements/ChatBubble'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

// "messsage" is an object with either a .component or .html property

export const SystemBubble = ({ message, isBeacon, isCorrectAnswer, isIncorrectAnswer }) => {
  const iconUrl = `${process.env.PUBLIC_URL}/ma-sp.png`

  return (
    <HStack spacing="4" alignItems="flex-start" mt={isBeacon ? 0 : 5} className="system-bubble-container">
      <Avatar photoUrl={iconUrl} className="quiz-modal-avatar" mt={3} isBeacon={isBeacon} />
      <ChatBubble message={message} isUserMessage={false}
        isCorrectAnswer={isCorrectAnswer} isIncorrectAnswer={isIncorrectAnswer}
      />
    </HStack>
  )
}

export const UserBubble = ({ message }) => {
  const { data: currentUser } = useGetCurrentUserQuery()
  const photoUrl = currentUser?.talent_profile?.photo_url

  return (
    <Flex justifyContent="flex-end" mt={7} className="user-bubble-container">
      <HStack spacing="4" alignItems="flex-start">
        <ChatBubble message={message} isUserMessage={true} />
        <Avatar photoUrl={photoUrl} className="quiz-modal-avatar" isUser />
      </HStack>
    </Flex>
  )
}