import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useDeleteOpportunityApplicationMutation } from 'features/opportunitiesApi'

const DismissButton = ({ opportunity }) => {
  const [deleteOpportunityApplication] = useDeleteOpportunityApplicationMutation()
  const { t } = useTranslation()
  const opportunityApplication = opportunity.opportunity_applications[0]

  const handleDismiss = async () => {
    try {
      await deleteOpportunityApplication(opportunityApplication.id).unwrap()
    } catch (error) {
      console.error('Failed to dismiss opportunity:', error)
    }
  }

  // Check if the status is 'applied' and return null to hide the button
  if (opportunityApplication.status === 'applied') {
    return null
  }

  return (
    <Button
      onClick={handleDismiss}
      variant="solid"
      bgColor="white"
      color="purple.600"
      width={['100%', null, 'fit-content']}
      minWidth={[null, null, '220px']}
      fontWeight="normal"
      _hover={{
        bgColor: 'rgba(128, 90, 213, 0.1)'
      }}
    >
      {t('Dismiss')}
    </Button>
  )
}

export default DismissButton
