import { Box, Divider, Flex, HStack, VStack, useDisclosure } from '@chakra-ui/react'
import { FilterChipRow } from 'Partner/TalentListPage/FilterChipRow'
import { ExportCsvButton } from 'components/partner/talent/list_page/ExportCsvButton'
import { SearchProfilesButton } from 'components/partner/talent/list_page/SearchProfilesButton'
import SearchDrawer from 'components/partner/talent/list_page/search_drawer/SearchDrawer'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'i18n/TranslationContext'
import { Route, Routes, useLocation, useMatch, useSearchParams } from 'react-router-dom'
import { usePageTitle } from 'usePageTitle'
import { useLoadCourseStatsQuery } from 'features/coursesApi'
import { Pagination } from '../Pagination'
import { PageOuterContainer } from '../PartnerLayout'
import { TalentProfileView } from '../TalentProfileView'
import { ContactDetails } from './ContactDetails'
import { TalentTable } from './TalentTable'
import { useTalentProfileListData } from './useTalentProfileListData'
import { useCourseTalentListSwitchConfigs } from 'hooks/partnerUx/useSwitchConfigs'
import { useTalentProfileCsvExport } from 'hooks/opportunities/useApplicationListData'

const useHideProps = () => {
  const match = useMatch('/talent/list')
  const isMain = !!match

  return (isMain ? {} : {
    'aria-hidden': true,
    as: 'div',
    role: undefined
  })
}

const CustomizedPageContainer = (props) => (
  <PageOuterContainer
    {...props}
    {...useHideProps()}
  />
)

const FallbackRoute = () => {
  const { t } = useTranslation()
  usePageTitle(t('Qualified Talent'))

  return null
}

const ViewProfileRoutes = () => {
  const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/view_profile/:talentProfileId"
          element={
            <TalentProfileView />
          }
        />
        <Route
          path="/view_profile/:talentProfileId/contact_request"
          element={
            <ContactDetails />
          }
        />
        <Route
          path="*"
          element={<FallbackRoute />}
        />
      </Routes>
    </AnimatePresence>
  )
}


const PageContent = () => {
  let [searchParams] = useSearchParams()
  const courseId = searchParams.get('course_id')
  const { data } = useLoadCourseStatsQuery(courseId)
  const { isOpen, onOpen, onClose } = useDisclosure() // for search drawer
  const switchConfigs = useCourseTalentListSwitchConfigs()

  const { t } = useTranslation()
  return (
    <CustomizedPageContainer>
      <SearchDrawer isOpen={isOpen} onClose={onClose} switchConfigs={switchConfigs} />
      <Box
        bgColor="white"
        pt={['32px', null, null, '64px']}
        pl={['16px', null, null, '48px']}
        pr={['16px', null, null, '48px']}
        className="page-content"
      >
        <Flex justifyContent="space-between" alignItems="center" direction={['column', null, 'row']}>
          <VStack display="block">
            <Box as="h1"
              pl="16px"
              fontFamily="Spline Sans Mono"
              fontSize={['40px', null, null, '48px']}
              fontWeight="bold"
              textTransform="uppercase"
            >
              {t('Qualified Talent')}
            </Box>
            <Flex gap="30px"
              pl="16px"
              fontFamily="Spline Sans Mono"
              fontSize={['14px', null, null, '18px']}
              fontWeight="bold"
              textTransform="uppercase"
              float="left"
            >
              {data?.name}
            </Flex>
          </VStack>

          <Divider display={['block', null, 'none']} my={5} />

          <HStack spacing="16px">
            <SearchProfilesButton onOpen={onOpen} />
            <ExportCsvButton useExportOptions={useTalentProfileCsvExport} />
          </HStack>
        </Flex>

        <FilterChipRow switchConfigs={switchConfigs} />

        <Box pb="128px">
          <TalentTable />
          <Pagination listQuery={useTalentProfileListData()} />
        </Box>
      </Box>
    </CustomizedPageContainer>
  )
}

export const TalentListPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Qualified Talent'))

  return (
    <Flex
      position="relative"
      flex="1"
      flexDirection="column"
      justifyContent="space-between"
      className="talent-list-page"
    >
      <ViewProfileRoutes />
      <PageContent />
    </Flex>
  )
}
