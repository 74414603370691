import { Text, Box, Center } from '@chakra-ui/react'
import { NoisyBackground } from './shared/Layout'
import { QuoteSign } from './QuoteSign'
import { CourseAuthor } from './CourseAuthor'
import { H2 } from 'shared/typography'

export const MeetYourTeacherBlock = ({
  specification: {
    name,
    photo,
    photo_alt_text: photoAltText,
    block_title: blockTitle,
    job_title: jobTitle,
    links,
    quote
  }
}) => (
  <Center
    position="relative"
    color="white"
    width="100%"
    pb="124px"
    className="meet-your-teacher-block"
  >
    <NoisyBackground
      color="rgba(15, 32, 74, 1)"
      opacity="0.09"
    />
    <Box
      flex="1"
      maxWidth="1440px"
    >
      <Center flexDirection="column">
        <Box paddingBottom="64px">
          <img src={`${process.env.PUBLIC_URL}/teacher-block-separator.svg`} alt="" />
        </Box>
        <H2
          paddingBottom="64px"
          fontSize="48px"
          textAlign="center"
        >
          {blockTitle}
        </H2>
        <CourseAuthor
          author={{
            name,
            title: jobTitle,
            photo,
            photoAltText,
            links
          }}
          hoverColor="#FF7D19"
        />
        <Box paddingTop="40px">
          <Center>
            <QuoteSign color="#FF7D19" />
          </Center>
          <Box
            pt="24px"
            maxWidth="860px"
          >
            <Text
              textAlign="center"
              fontFamily="Poppins"
              fontSize="30px"
            >
              {quote}
            </Text>
          </Box>
        </Box>
      </Center>
    </Box>
  </Center>
)
