import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Flex, Show, Tr, Text, useDisclosure } from '@chakra-ui/react'
import { TableItemMap } from 'shared/TableItemMap'
import { TableItem } from 'shared/TableItem'
import { TableWrapper } from 'shared/TableWrapper'
import { PrimaryButton, PartnerOpportunityApplicationModal } from 'components'
import ProfileDrawer from 'components/partner/talent/list_page/profile_drawer/ProfileDrawer'

import { setCurrentTalentProfileId } from 'features/partner_ux/partnerUxSlice'

// TODO: move these to their own components for reuse
import { ProfileColumn } from 'Partner/TalentListPage/TalentItems/ProfileColumn'

import { useApplicationListData } from 'hooks/opportunities/useApplicationListData'

const ViewProfileButton = ({ id, onClick, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      colorScheme="zettaBlue"
      fontSize="16px"
      fontWeight="500"
      padding="0 12px"
      onClick={onClick}
      {...props}
    >
      {t('View profile')}
    </Button>
  )
}

const Column1 = ({
  id,
  name,
  photo_url: photoUrl,
  photo_alt_text: photoAltText,
  profession,
  location,
  pronouns,
  enrolled_count: enrolledCount,
  course_progress: courseProgress,
  onProfileClick
}) => (
  <ProfileColumn
    name={name}
    photoAltText={photoAltText}
    photoUrl={photoUrl}
    profession={profession}
    location={location}
    pronouns={pronouns}
    onProfileClick={() => onProfileClick(id)}
  />
)

const Column2 = (props) => {
  const status = props.opportunity_applications?.[0]?.status || 'No status available'

  const getStatusDisplay = (status) => {
    switch (status) {
    case 'applied':
      return 'Reviewing'
    case 'interviewing':
      return 'Interviewing'
    case 'offer_sent':
      return 'Offer Sent'
    case 'hired':
      return 'Hired'
    case 'rejected':
      return 'Rejected'
    default:
      return 'No status available'
    }
  }

  return (
    <Show above="md">
      <Box>
        <Text float="left">
          {getStatusDisplay(status)}
        </Text>
      </Box>
    </Show>
  )
}

const Column3 = ({
  id,
  onProfileClick,
  opportunityApplication
}) => {

  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      alignItems={['center', 'center', 'flex-end']}
      marginTop={['16px', '16px', 'unset']}
    >
      <ViewProfileButton id={id} onClick={() => onProfileClick(id)}
        width={['100%', '100%', 'unset']}
        minWidth={[null, null, '178px']}
      />
      <PrimaryButton
        onClick={() => setIsModalOpen(true)}
        variant="solid"
        bgColor="white"
        color="purple.600"
        fontSize="16px"
        fontWeight="500"
        padding="0 12px"
        height="40px"
        width={['100%', '100%', 'unset']}
        minWidth={[null, null, '178px']}
        _hover={{
          bgColor: 'rgba(128, 90, 213, 0.1)',
        }}
        textValue={t('Change Status')}
        size="lg"
      />
      <PartnerOpportunityApplicationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        opportunityApplication={opportunityApplication}
      />
    </Flex>
  )
}

const ApplicationTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure() // for search drawer

  // on click of view profile, set the talent profile id to the store,
  // and open the profile drawer
  const handleViewProfileClick = (id) => {
    dispatch(setCurrentTalentProfileId(id))
    onOpen()
  }

  return (
    <>
      <ProfileDrawer isOpen={isOpen} onClose={onClose} />
      <TableWrapper
        headings={[
          t('talent'),
          t('status'),
          t('actions')
        ]}
      >
        <TableItemMap listQuery={useApplicationListData()}
          emptyStateCopy={t('No talent found')}
        >
          {
            (props) => (
              <Tr key={props.id}>
                <TableItem
                  columns={[
                    <Column1 key={1} {...props} onProfileClick={handleViewProfileClick} />,
                    <Column2 key={2} {...props} />,
                    <Column3 key={3} {...props} onProfileClick={handleViewProfileClick} opportunityApplication={props.opportunity_applications[0]} />
                  ]}
                />
              </Tr>
            )
          }
        </TableItemMap>
      </TableWrapper>
    </>

  )
}

export default ApplicationTable
