import { Box, Center } from '@chakra-ui/react'
import { HiUser } from 'react-icons/hi'

const TalentPhoto = ({ photoUrl, photoAltText }) => {
  if (!photoUrl) {
    return (
      <Center
        minWidth="236px"
        minHeight="332px"
        border="2px solid var(--navy-blue, #0F204A)"
        borderRadius="6px"
        bgColor="var(--OutcastHover, #F8F4FF)"
        mt={['-28px', null, '-36px']}
        ml="4px"
        fontSize="30px"
      >
        <HiUser aria-hidden />
      </Center>
    )
  }

  return (
    <Center>
      <Box
        border="2px solid var(--navy-blue, #0F204A)"
        borderRadius="6px"
        mt={['-28px', null, '-36px']}
        ml="4px"
        maxWidth={['300px', null, '70%']}
      >
        <img
          src={photoUrl}
          alt={photoAltText || ''}
        />
      </Box>
    </Center>
  )
}

export default TalentPhoto
