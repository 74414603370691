import { useTranslation } from 'i18n/TranslationContext'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ReactComponent as GreenRoundMarker } from './green-round-marker.svg'

const Course = ({ name }) => {
  const { t } = useTranslation()
  return (
    <Box
      width="260px"
      height="172px"
      borderRadius="6px"
      boxShadow="0px 53px 64px -40px rgba(0, 0, 0, 0.25)"
      background="var(--White, #FFF)"
      marginRight="20px"
      marginBottom="20px"
      padding="24px"
      role="listitem"
    >
      <Flex alignItems="center">
        <GreenRoundMarker aria-hidden />
        <Box flexBasis="8px" />
        <Box
          color="var(--Navy-Blue, #0F204A)"
          fontFamily="Poppins"
          fontSize="12px"
          fontWeight="500"
          textTransform="uppercase"
        >
          {t('Enrolled')}
        </Box>
      </Flex>
      <Box
        color="var(--Navy-Blue, #0F204A)"
        fontFamily="Spline Sans Mono"
        fontSize="24px"
        fontWeight="bold"
        paddingTop="34px"
      >
        <Text noOfLines={2}>
          {name}
        </Text>
      </Box>
    </Box>
  )
}

const CourseItems = ({ courses }) =>
  courses.map(({ id, name }) => (
    <Course
      key={id}
      name={name}
    />
  ))

export const EnrolledCourses = ({ courses }) => {
  const { t } = useTranslation()
  return (
    <Flex
      flexDirection={['column', null, 'row']}
      alignItems="flex-start"
      p={4}
    >
      <Box
        id="enrolled-to-your-courses-label"
        color="var(--Navy-Blue, #0F204A)"
        fontFamily="Spline Sans Mono"
        fontSize="32px"
        fontWeight="bold"
        textTransform="uppercase"
        marginBottom={['20px', null, '0']}
        marginRight={['0', null, '24px']}
        flexShrink="0"
        width={['100%', null, '250px']}
      >
        {t('Enrolled to your courses')}
      </Box>
      <Flex
        flexWrap="wrap"
        role="list"
        aria-labelledby="enrolled-to-your-courses-label"
        justifyContent="flex-start"
      >
        <CourseItems courses={courses} />
      </Flex>
    </Flex>
  )
}
