import { Box } from '@chakra-ui/react'

export const H2 = ({ children, ...restProps }) => (
  <Box as="h2"
    fontFamily="Spline Sans Mono"
    fontSize={['20px', null, null, '24px']}
    fontWeight="bold"
    textTransform="uppercase"
    {...restProps}
  >
    {children}
  </Box>
)

export const H3 = ({ children, ...restProps }) => (
  <Box as="h3"
    fontFamily="Spline Sans Mono"
    textTransform="uppercase"
    fontSize={['16px', null, null, '20px']}
    fontWeight="bold"
    {...restProps}
  >
    {children}
  </Box>
)