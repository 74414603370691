import React from 'react'
import { Box, Button, Flex, Container, VStack, Heading } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import HeaderSection from './HeaderSection'
import DescriptionSection from './DescriptionSection'
import PartnerSection from './PartnerSection'
import { AppLoadingState } from 'AppLoadingState'
import { useTranslation } from 'i18n/TranslationContext'
import { useGetOpportunityQuery } from 'features/opportunitiesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

export const PageContent = () => {
  const { opportunityId } = useParams()
  const { t } = useTranslation()

  const { data: userData, isLoading: userLoading, error: userError } = useGetCurrentUserQuery()
  const talentUserId = userData?.talent_profile?.id

  const opportunityQueryParams = { id: opportunityId }
  if (talentUserId) {
    opportunityQueryParams.talentUserId = talentUserId
  }

  const { data: opportunity, isLoading, isError } = useGetOpportunityQuery(opportunityQueryParams)

  if (isLoading || userLoading) {
    return <AppLoadingState />
  }

  if (isError || userError || !opportunity) {
    return (
      <Flex direction="column" align="center">
        <Heading>{t('Not Found')}</Heading>
        <Box>
          <Button as={Link} to="/opportunities">
            {t('Go back')}
          </Button>
        </Box>
      </Flex>
    )
  }

  const formattedEmploymentType = opportunity.employment_type
    ? opportunity.employment_type
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-')
    : t('Employment Type Not Available')

  return (
    <Container maxW="100%" py={8}>
      <VStack spacing={8} align="stretch">
        <HeaderSection opportunity={opportunity} talentUser={userData?.talent_profile} formattedEmploymentType={formattedEmploymentType} />
        <DescriptionSection opportunity={opportunity} formattedEmploymentType={formattedEmploymentType} />
        <PartnerSection partnerProfile={opportunity.partner_profile} />
      </VStack>
    </Container>
  )
}

export default PageContent
