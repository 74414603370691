import React, { useRef } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { Body } from 'components'
import { FormTemplate } from 'components/builder/FormTemplate'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useSelector } from 'react-redux'
import { IntroVideoThumbnailForm } from 'components/courseBuilder/formContent'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

const IntroText = () => {
  const { t } = useTranslation()
  return (
    <>
      <Body>
        {t('Introduction videos give Talent users an overview of your course that lets them better decide whether this course is a good fit for them or not.')}
      </Body>
      <Body>
        {t('Introduction videos do not count toward course progress and cannot have quizzes associated with them.')}
      </Body>
    </>
  )}

export const IntroVideoThumbnail = ({ nextStep, formForStep }) => {
  const { t } = useTranslation()
  const courseData = useSelector(state => state.courseBuilder || {})
  const initialized = useRef(false)
  const { isFileUploading } = useCourseBuilderContext()

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)

  return (
    <FormTemplate
      introTextComponent={IntroText}
      formTitle={t('Upload Video & Thumbnail')}
      nextStep={nextStep}
      buttonDisabled={isFileUploading}
      formForStep={formForStep}
      disabledNavTooltipText={t('Please wait for file to finish uploading before proceeding.')}
    >
      <IntroVideoThumbnailForm />
    </FormTemplate>
  )
}
