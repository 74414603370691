import { useTranslation } from 'i18n/TranslationContext'
import { Box, FormControl, SimpleGrid } from '@chakra-ui/react'
import { StyledFormLabel, InputField } from 'components'
import { useFormContext } from 'react-hook-form'
import { StyledTextArea } from './StyledTextArea'
import { LocationInput } from 'components/forms/LocationInput'

export const ProfileInputFields = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <>
      <SimpleGrid columns={[1, null, 2]} gap={[0, null, '16px']}>
        <Box>
          <InputField
            label={t('First Name')}
            name="first_name"
            inputProps={{
              placeholder: t('Charlotte'),
              ...register(
                'first_name', {
                  required: t('First Name can’t be empty')
                }
              )
            }}
          />
        </Box>
        <Box>
          <InputField
            label={t('Last Name')}
            name="last_name"
            inputProps={{
              placeholder: t('Smith'),
              ...register(
                'last_name', {
                  required: t('Last Name can’t be empty')
                }
              )
            }}
          />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={[1, null, 2]} gap={[0, null, '16px']}>
        <Box>
          <InputField
            label={t('Pronouns')}
            name="pronouns"
            inputProps={{
              placeholder: t('She/Her'),
              ...register(t('pronouns'))
            }}
          />
        </Box>
        <Box>
          <LocationInput />
        </Box>
      </SimpleGrid>
      <InputField
        label={t('Profession')}
        name="profession"
        inputProps={{
          placeholder: t('Director'),
          ...register(
            'profession', {
              required: t('Profession can’t be empty')
            }
          )
        }}
      />
      <InputField
        label={t('Portfolio link')}
        name="portfolioUrl"
        inputProps={{
          placeholder: 'www.charlottesmith.com',
          ...register('portfolioUrl')
        }}
      />
      <InputField
        label={t('Additional link')}
        name="otherUrl"
        inputProps={{
          placeholder: 'www.behance.net/charlottesmith',
          ...register('otherUrl')
        }}
      />
      <FormControl>
        <StyledFormLabel>
          {t('Bio')}
        </StyledFormLabel>
        <StyledTextArea
          placeholder={t('Tell us about yourself')}
          rows={6}
          { ...register('bio') }
        />
      </FormControl>
    </>
  )
}
