import { Button } from '@chakra-ui/react'
import { submitAnswer } from 'features/quiz/quizzesApi'
import { useTranslation } from 'i18n/TranslationContext'
import { useDispatch, useSelector } from 'react-redux'

export const SendButton = ({ question }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedAnswer, loading: answerLoading } = useSelector((state) => state.talentQuiz)

  const handleAnswerSubmit = () => {
    dispatch(submitAnswer({ questionId: question.id, attemptedAnswer: selectedAnswer }))
  }

  return (
    <Button
      onClick={handleAnswerSubmit}
      size="lg"
      colorScheme="zettaBlue"
      width={['100%', null, 'unset']}
      fontWeight="500"
      isDisabled={!selectedAnswer}
      isLoading={answerLoading}
      mt={10}
      tabIndex={0}
    >
      {t('Send')}
    </Button>
  )
}
