import { useForm } from 'react-hook-form'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useGetTalentProfileQuery } from 'features/talentProfilesApi'

export const SetupPartnerProfile = (data) => {
  return {
    id: data?.talent_profile?.id,
    name: data?.talent_profile?.name,
    deletePhoto: null,
    companyName: data.partner_profile?.company_name,
    companyBenefits: data.partner_profile?.company_benefits,
    companyAccessibilityPolicy: data.partner_profile?.company_accessibility_policy,
    companyDescription: data.partner_profile?.company_description,
    companyWebsite: data.partner_profile?.company_website,
    location: data.partner_profile?.location,
    partnerProfileId: data?.partner_profile?.id,
    photoUrl: data?.partner_profile?.profile_photo_url,
    greenhouseApiKey: data?.partner_profile?.greenhouse_api_key,
    greenhouseApiUserId: data?.partner_profile?.greenhouse_api_user_id,
    greenhouseApiValid: data?.partner_profile?.greenhouse_api_valid,
    partnerProfileOwner: data?.partner_profile?.partner_profile_owner
  }
}

export const SetupTalentProfile = (id) => {
  const {
    data: {
      name,
      first_name,
      last_name,
      pronouns,
      profession,
      location,
      bio,
      resume_filename: resumeFilename,
      photo_url: photoUrl,
      photo_alt_text: altText,
      portfolio_url: portfolioUrl,
      other_url: otherUrl,
    }
  } = useGetTalentProfileQuery({ id })

  return {
    id,
    name,
    first_name,
    last_name,
    pronouns,
    profession,
    location,
    bio,
    deletePhoto: null,
    photo: null,
    photoUrl,
    deleteResume: null,
    resume: null,
    resumeFilename,
    altText,
    portfolioUrl,
    otherUrl,
  }
}

export const useProfileForm = () => {
  const { data } = useGetCurrentUserQuery()
  let defaultValues = {}
  if(data.feature === 'partner_experience') {
    defaultValues = SetupPartnerProfile(data)
  } else {
    defaultValues = SetupTalentProfile(data.talent_profile.id)
  }

  return useForm({
    defaultValues: defaultValues
  })
}
