import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { BgVideo, BgVideoControlButton } from 'BgVideo'
import { ShiftedBox } from './shared/Layout'

import { useCoursePageContext } from './CoursePageContext'
import { useCourseFile } from '../hooks'

const CourseLevelTape = () => {
  return (
    <Flex
      position="absolute"
      width="100%"
      height="100%"
      zIndex={-1}
      _before={{
        display: 'block',
        content: '" "',
        width: '14px',
        bgImage: `${process.env.PUBLIC_URL}/tape2-left.svg`,
        bgSize: 'cover'
      }}
      _after={{
        display: 'block',
        content: '" "',
        width: '11px',
        bgImage: `${process.env.PUBLIC_URL}/tape2-right.svg`,
        bgSize: 'cover'
      }}
    >
      <Box
        bg="#5000E8"
        opacity="0.85"
        flex="1"
      />
    </Flex>
  )
}

const TopBadge = ({ text }) => (
  <Box ml="16px">
    <Box
      pt="10px" pb="10px"
      display="inline-flex"
      maxWidth="50%"
      fontFamily="Spline Sans Mono"
      fontWeight="bold"
      textTransform="uppercase"
      alignItems="center"
      position="relative"
      zIndex={20}
    >
      <CourseLevelTape />
      <Box pl="24px" pr="24px">
        {text}
      </Box>
    </Box>
  </Box>
)

const CourseNameTape = () => (
  <Flex
    position="absolute"
    width="100%"
    height="100%"
    zIndex={-1}
    _before={{
      display: 'block',
      content: '" "',
      width: '37px',
      bgImage: `${process.env.PUBLIC_URL}/tape1-left.svg`,
      bgSize: 'cover'
    }}
    _after={{
      display: 'block',
      content: '" "',
      width: '28px',
      bgImage: `${process.env.PUBLIC_URL}/tape1-right.svg`,
      bgSize: 'cover'
    }}
  >
    <Box
      bg="#FF7D19"
      opacity="0.85"
      flex="1"
    />
  </Flex>
)

const CourseTitle = ({ title }) => (
  <Box
    pr={12}
    mt={-1}
    mr={12}
    fontFamily="Spline Sans Mono"
    fontSize={[44, 62]}
    fontWeight="bold"
    lineHeight="60px"
    textTransform="uppercase"
    display="inline-flex"
    position="relative"
    zIndex={10}
  >
    <CourseNameTape />
    <Center
      as="h1"
      pl={['22px', '32px', '40px']}
      pt="16px" pb="18px"
    >
      {title}
    </Center>
  </Box>
)

const CourseDescriptionBlock = ({ description }) => (
  <Box
    pt={8} pb={9}
    pl={['12px', 0]}
    pr={['12px', 0]}
    maxWidth="720px"
    fontFamily="Poppins"
    fontSize={[18, 22]}
    className="course-description-block"
  >
    {description}
  </Box>
)

const ProvidedByBlock = ({ providedBy, providedByAltText }) => {
  const providedBySrc = useCourseFile(providedBy)
  const { t } = useTranslation()
  return (
    <>
      <ShiftedBox
        aria-hidden
        fontFamily="Spline Sans Mono"
        fontSize="16px"
        fontWeight="bold"
        lineHeight="26px"
        textTransform="uppercase"
      >
        {t('taught by: ') + (providedBy === undefined ? providedByAltText : '')}
      </ShiftedBox>
      <ShiftedBox
        role="list"
        aria-label={t('Authors')}
        mt="18px"
        pb="128px"
        fontSize="20px"
        fontWeight="bold"
        lineHeight="50px"
        textTransform="uppercase"
      >
        {providedBy && (
          <img
            src={providedBySrc}
            alt={providedByAltText}
          />
        )}
      </ShiftedBox>
    </>
  )
}

const CTABlock = () => {
  const { mainCTAElement, backDashboardButton } = useCoursePageContext()

  return (
    <Box pr={['12px', '12px', '0']} flex="1 1 300px" className="cta-block">
      {mainCTAElement}
      {backDashboardButton}
    </Box>
  )
}

const CourseInformationBlock = ({
  specification: {
    providedBy,
    providedByAltText,
    badge,
    title,
    description
  }
}) => (
  <Center
    width="100%"
    minHeight="1012px"
    className="course-information-block"
  >
    <Box
      flex="1"
      maxWidth={['480px', '1440px']}
      width="100%"
    >
      <ShiftedBox pt={105}>
        <TopBadge text={badge} />
        <CourseTitle title={title} />
        <CourseDescriptionBlock description={description} />
      </ShiftedBox>
      <ProvidedByBlock
        providedBy={providedBy}
        providedByAltText={providedByAltText}
      />
      <ShiftedBox display="flex" flexWrap="wrap" maxWidth="700px">
        <CTABlock />
        <Box flex="1">
          <BgVideoControlButton />
        </Box>
      </ShiftedBox>
    </Box>
  </Center>
)

const CourseBgVideo = ({ bgVideo, bgVideoAltText }) => (
  <BgVideo
    src={useCourseFile(bgVideo)}
    aria-label={bgVideoAltText}
  />
)

export const HeaderBlock = ({
  specification: {
    bgVideo,
    bgVideoAltText,
    ...specification }
}
) => (
  <Box
    bg="linear-gradient(99.39deg, rgba(0, 0, 0, 0.5) 23.96%, rgba(0, 0, 0, 0) 65.18%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))"
    position="relative"
    color="white"
    className="header-block">
    <CourseBgVideo
      bgVideo={bgVideo}
      bgVideoAltText={bgVideoAltText}
    />
    <CourseInformationBlock specification={specification} />
  </Box>
)
