import { useEffect, useState } from 'react'
import { usePasswordCheck } from './usePasswordCheck'

const useHideShowPassword = (watch, trigger) => {
  // Hide or show password input to the user
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleShowPassword = () => {setShowPassword(!showPassword)}
  const handleShowConfirmPassword = () => {setShowConfirmPassword(!showConfirmPassword)}

  // Watching password fields to show confirmation text if they match
  const password = watch('password')
  const confirm = watch('confirmation')
  const isPasswordMatch = usePasswordCheck(password, confirm)
  useEffect(() => {
  // When password input changes, trigger confirmation field to refresh error state
    (password !== '' && confirm !== '') && trigger('confirmation')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, trigger])

  return {
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    handleShowConfirmPassword,
    isPasswordMatch,
    password,
    confirm
  }
}

export default useHideShowPassword