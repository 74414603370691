import React from 'react'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { UploadMediaField } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { useGetMediaS3Path } from 'hooks/courseBuilder/useMediaField'

const IntroAccessibilityFilesForm = ({ formDisabled=false }) => {
  const { t } = useTranslation()
  const mediaType = React.useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  const { courseSlug } = useCourseBuilderContext()

  return (
    <>
      <UploadMediaField
        name="intro_transcript"
        label={t('Video Transcript')}
        helperText={!formDisabled && t('VTT only.')}
        mediaType={mediaType.VTT}
        fieldRequired
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(0, courseSlug, 'intro_transcript')}
      />
      <UploadMediaField
        name="intro_audio_description"
        label={t('Video Audio Description')}
        helperText={!formDisabled && t('VTT only.')}
        mediaType={mediaType.VTT}
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(0, courseSlug, 'intro_audio_description')}
      />
      <UploadMediaField
        name="intro_sign_language_video"
        label={t('Sign Language Video')}
        helperText={!formDisabled && t('Video length is limited to 10 minutes with a maximum file size of 250 MB. MP4 only.')}
        mediaType={mediaType.EPISODE_VIDEO}
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(0, courseSlug, 'intro_sign_language_video')}
      />
    </>
  )
}

export default IntroAccessibilityFilesForm
