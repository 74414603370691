import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, useToast } from '@chakra-ui/react'
import { useCreateSessionMutation } from '../../features/sessionsApi'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { InputField } from '../InputField'
import { SubmitButton } from '../SubmitButton'
import { Link } from 'react-router-dom'
import { GoogleOauthButton } from '../GoogleOAuthButton'

export const SignInForm = () => {
  const { t } = useTranslation()
  const methods = useForm()
  const { handleSubmit } = methods
  const toast = useToast()
  const [createSession, { error } ] = useCreateSessionMutation()

  // Hide or show password input to the user
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => {setShowPassword(!showPassword)}

  useEffect(() => {
    if (error) {
      toast({
        title: t('Invalid username or password, try again'),
        status: 'error',
        position: 'top',
        containerStyle: {
          position: 'absolute',
          maxWidth: 'unset',
          width: '100%',
          margin: '0'
        }
      })
    }
  }, [error, toast, t])

  return (
    <FormProvider {...methods}>
      <Box as="form"
        pb="54px"
        onSubmit={handleSubmit(createSession)}
      >
        <InputField
          label={t('Your email')}
          name="email"
          inputProps={{
            type: 'email',
            autoComplete: 'email'
          }}
        />
        <InputField
          label={t('Password')}
          name="password"
          inputProps={{
            type: showPassword ? 'text' : 'password',
            autoComplete: 'current-password',
          }}
          clickShowPw={{
            setShowPw: handleShowPassword,
            showPw: showPassword
          }}
        />
        <Box pt="16px">
          <SubmitButton>{t('Sign In')}</SubmitButton></Box>
        <Box pt="16px">
          <GoogleOauthButton isSignUp={false} />
        </Box>
        <Box pt="16px">
          <Button
            as={Link}
            to="/forgot_password"
            size="lg"
            width="100%"
            variant="link"
            colorScheme="zettaBlue"
            fontWeight="500"
          >
            {t('Forgot your password?')}
          </Button>
        </Box>
      </Box>
    </FormProvider>
  )
}
