import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center } from '@chakra-ui/react'
import { Header1 } from '../Header1'
import { Details } from '../Details'
import { SignUpForm } from './SignUpForm'

export const TalentPageContent = () => {
  const { t } = useTranslation()
  return (
    <Box
      background="#F0E9E6"
      pt="84px" pb="40px"
    >
      <Box
        padding={[
          '0 24px',
          null,
          '0 140px'
        ]}
      >
        <Center flexDirection="column">
          <Header1>
            {t('Tell us more about yourself')}
          </Header1>
          <Center
            maxWidth="460px"
            flexDirection="column"
          >
            <Details>
              {t('Congratulations! You have been invited to')}{' '} Making Space.{' '}
              {t('Create account to unlock your potential.')}
            </Details>
            <SignUpForm />
          </Center>
        </Center>
      </Box>
    </Box>
  )
}
