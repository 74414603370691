import { UserForm } from 'components'
import { GoogleOauthButton } from '../GoogleOAuthButton'
import { useCreateUserMutation } from 'features/sessionsApi'
import useSignUpTrackParams from 'hooks/useSignUpTrackParams'
import { Box } from '@chakra-ui/react'

export const SignUpForm = () => {
  const [createUser, { error }] = useCreateUserMutation()
  const { course_id, source, partner_profile_id } = useSignUpTrackParams()

  const handleCreateUser = (data) => {
    createUser({
      ...data,
      track: {
        course_id,
        source,
        partner_profile_id
      }
    })
  }

  return (
    <UserForm
      error={error}
      onSubmit={handleCreateUser}
      email=""
      additionalFields={() => (
        <Box pt="16px" pb="54px">
          <GoogleOauthButton isSignUp />
        </Box>
      )}
    />
  )
}
