import { useTranslation } from 'i18n/TranslationContext'
import { Center } from '@chakra-ui/react'

const Container = (props) => (
  <Center
    pt="24px"
    fontFamily="Poppins"
    fontWeight="500"
    fontSize="18px"
    color="#070F22"
    opacity="0.8"
    {...props}
  />
)

const ResultsFromToTotal = ({ from, to, total }) => {
  const { t } = useTranslation()

  return t('resultsFromToTotal', {
    from,
    to,
    total
  })
}

const ResultsInTotal = ({ total }) => {
  const { t } = useTranslation()

  return t('resultsInTotal', {
    count: total
  })
}

export const ResultsCounts = ({
  pagination: {
    pages,
    from,
    to,
    count: total
  }
}) => {
  if (pages === 1) {
    return (
      <Container>
        <ResultsInTotal total={total} />
      </Container>
    )
  }

  return (
    <Container>
      <ResultsFromToTotal
        from={from}
        to={to}
        total={total}
      />
    </Container>
  )
}
