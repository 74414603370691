import { useForm } from 'react-hook-form'

// Function to create forms for a given episode number
function useCreateEpisodeForms(episodesForms, episodeNumber) {
  episodesForms[`episode${episodeNumber}UploadVideoThumbnail`] = useForm(`episode${episodeNumber}UploadVideoThumbnail`)
  episodesForms[`episode${episodeNumber}UploadAccessibility`] = useForm(`episode${episodeNumber}UploadAccessibility`)
  episodesForms[`episode${episodeNumber}Quiz`] = useForm(`episode${episodeNumber}Quiz`)
}

export const useBuildMultiForm = () => {
  // Base forms that are not related to episodes
  const baseForms = {
    courseNameThumbnail: useForm('courseNameThumbnail'),
    courseDescriptions: useForm('courseDescriptions'),
    courseExperts: useForm('courseExperts'),
    courseVisibility: useForm('courseVisibility'),
    introVideoThumbnail: useForm('introVideoThumbnail'),
    introAccessibilityFiles: useForm('introAccessibilityFiles'),
    assignment: useForm('assignment')
  }

  const episodesForms = {}
  useCreateEpisodeForms(episodesForms, 1)
  useCreateEpisodeForms(episodesForms, 2)
  useCreateEpisodeForms(episodesForms, 3)
  useCreateEpisodeForms(episodesForms, 4)
  useCreateEpisodeForms(episodesForms, 5)
  useCreateEpisodeForms(episodesForms, 6)
  useCreateEpisodeForms(episodesForms, 7)
  useCreateEpisodeForms(episodesForms, 8)
  useCreateEpisodeForms(episodesForms, 9)
  useCreateEpisodeForms(episodesForms, 10)
  useCreateEpisodeForms(episodesForms, 11)
  useCreateEpisodeForms(episodesForms, 12)
  useCreateEpisodeForms(episodesForms, 13)
  useCreateEpisodeForms(episodesForms, 14)
  useCreateEpisodeForms(episodesForms, 15)
  useCreateEpisodeForms(episodesForms, 16)
  useCreateEpisodeForms(episodesForms, 17)
  useCreateEpisodeForms(episodesForms, 18)
  useCreateEpisodeForms(episodesForms, 19)
  useCreateEpisodeForms(episodesForms, 20)
  useCreateEpisodeForms(episodesForms, 21)
  useCreateEpisodeForms(episodesForms, 22)
  useCreateEpisodeForms(episodesForms, 23)
  useCreateEpisodeForms(episodesForms, 24)
  useCreateEpisodeForms(episodesForms, 25)
  useCreateEpisodeForms(episodesForms, 26)
  useCreateEpisodeForms(episodesForms, 27)
  useCreateEpisodeForms(episodesForms, 28)
  useCreateEpisodeForms(episodesForms, 29)
  useCreateEpisodeForms(episodesForms, 30)
  useCreateEpisodeForms(episodesForms, 31)
  useCreateEpisodeForms(episodesForms, 32)
  useCreateEpisodeForms(episodesForms, 33)
  useCreateEpisodeForms(episodesForms, 34)
  useCreateEpisodeForms(episodesForms, 35)
  useCreateEpisodeForms(episodesForms, 36)
  useCreateEpisodeForms(episodesForms, 37)
  useCreateEpisodeForms(episodesForms, 38)
  useCreateEpisodeForms(episodesForms, 39)
  useCreateEpisodeForms(episodesForms, 40)
  useCreateEpisodeForms(episodesForms, 41)
  useCreateEpisodeForms(episodesForms, 42)
  useCreateEpisodeForms(episodesForms, 43)
  useCreateEpisodeForms(episodesForms, 44)
  useCreateEpisodeForms(episodesForms, 45)
  useCreateEpisodeForms(episodesForms, 46)
  useCreateEpisodeForms(episodesForms, 47)
  useCreateEpisodeForms(episodesForms, 48)
  useCreateEpisodeForms(episodesForms, 49)
  useCreateEpisodeForms(episodesForms, 50)

  return { ...baseForms, ...episodesForms }
}
