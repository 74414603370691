import { TableWrapperBase } from './TableWrapperBase'
import { StyledTh } from './StyledTh'

export const TableWrapper = ({
  children,
  headings
}) => (
  <TableWrapperBase
    headings={[
      <StyledTh
        key={1}
        padding="16px"
      >
        {headings[0]}
      </StyledTh>,
      <StyledTh
        key={2}
        textAlign="left"
        borderColor="#D7D7D7"
        padding="0"
      >
        {headings[1]}
      </StyledTh>,
      <StyledTh
        key={3}
        textAlign="right"
      >
        {headings[2]}
      </StyledTh>
    ]}
  >
    {children}
  </TableWrapperBase>
)
