import { PageContent } from './PageContent'
import { Global } from '@emotion/react'

export const OpportunityPage = () => (
  <>
    <Global
      styles={{
        body: {
          backgroundColor: '#ffffff'
        }
      }}
    />
    <main>
      <PageContent />
    </main>
  </>
)
