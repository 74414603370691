import { apiSlice } from './apiSlice'

export const opportunitiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listOpportunities: builder.query({
      query: ({ talentUserId }) => `/opportunities?talent_user_id=${talentUserId}`,
      providesTags: ['OpportunityList']
    }),
    listOpportunitiesForPartner: builder.query({
      query: (id) => `/opportunities?for_partner=${id}`,
      providesTags: ['OpportunityList']
    }),
    getOpportunity: builder.query({
      query: ({ id, talentUserId }) => {
        let url = `/opportunities/${id}`
        if (talentUserId) {
          url += `?talent_user_id=${talentUserId}`
        }
        return url
      },
      providesTags: ['Opportunity']
    }),
    showOpportunityForPartner: builder.query({
      query: ({ id, partnerId }) => `/opportunities/${id}?for_partner=${partnerId}`,
      providesTags: ['Opportunity']
    }),
    showOpportunityDraftForPartner: builder.query({
      query: ({ id, partnerId }) => `/opportunities/${id}/draft?for_partner=${partnerId}`,
      providesTags: ['Opportunity']
    }),
    publishOpportunityFromDraft: builder.mutation({
      query: ({ id, statusChange }) => ({
        url: `/opportunities/${id}/publish`,
        method: 'POST',
        body: { status_change: statusChange }
      }),
      invalidatesTags: ['OpportunityList']
    }),
    cloneOpportunity: builder.mutation({
      query: (id) => ({
        url: `/opportunities/${id}/clone`,
        method: 'POST',
      }),
      invalidatesTags: ['OpportunityList']
    }),
    upsertOpportunity: builder.mutation({
      query: ({ opportunityData, id }) => ({
        url: id ? `/opportunities/${id}` : '/opportunities',
        method: id ? 'PUT' : 'POST',
        body: opportunityData,
      }),
      invalidatesTags: ['OpportunityList', 'Opportunity']
    }),
    createOpportunityApplication: builder.mutation({
      query: ({ talentUserId, opportunityId, status }) => ({
        url: '/opportunity_applications',
        method: 'POST',
        body: { opportunity_application: { talent_profile_id: talentUserId, opportunity_id: opportunityId, status } },
      }),
      invalidatesTags: ['OpportunityList', 'Opportunity']
    }),
    updateOpportunityApplication: builder.mutation({
      query: ({ id, status }) => ({
        url: `/opportunity_applications/${id}`,
        method: 'PATCH',
        body: { opportunity_application: { status } },
      }),
      invalidatesTags: ['OpportunityList', 'Opportunity']
    }),
    deleteOpportunityApplication: builder.mutation({
      query: (id) => ({
        url: `/opportunity_applications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OpportunityList']
    })
  })
})

export const {
  useListOpportunitiesQuery,
  useListOpportunitiesForPartnerQuery,
  useGetOpportunityQuery,
  useShowOpportunityForPartnerQuery,
  useCloneOpportunityMutation,
  useUpsertOpportunityMutation,
  useCreateOpportunityApplicationMutation,
  useUpdateOpportunityApplicationMutation,
  useDeleteOpportunityApplicationMutation,
  useShowOpportunityDraftForPartnerQuery,
  usePublishOpportunityFromDraftMutation
} = opportunitiesApi
