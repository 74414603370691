import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const translatableTagTypes = [
  'UserGroups',
  'CurrentAssignment',
  'CourseList',
  'CurrentCourse',
  'Opportunity',
  'OpportunityList'
]

export const apiSlice = createApi({
  reducerPath: 'apiSlice',
  baseQuery: fetchBaseQuery({
    // base url of backend API
    baseUrl: '/api',
    tagTypes: [
      'CurrentTalentProfile',
      'CurrentPartnerProfile',
      'TalentList',
      'CurrentUser',
      'Comments',
      'ContactRequest',
      'Invitations',
      'CompassMessages',
      'Quiz',
      'QuizQuestion',
      ...translatableTagTypes
    ],
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json')
      return headers
    },
  }),
  endpoints: () => ({}),
})
