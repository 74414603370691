import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { translatableTagTypes } from './apiSlice'

const invalidateCurrentUser = (result, error) => error ? []: ['CurrentUser']

export const sessionsApi = createApi({
  reducerPath: 'sessionsApi',
  baseQuery: fetchBaseQuery(
    {
      baseUrl: '/api',
      prepareHeaders: (headers) => {
        headers.set('Accept', 'application/json')
        return headers
      }
    }
  ),
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => 'users/current',
      providesTags: ['CurrentUser']
    }),
    sendEmailConfirmation: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}/send_email_confirmation`,
        method: 'POST'
      })
    }),
    performEmailConfirmation: builder.query({
      query: (secret) => `users/confirmation?confirmation_token=${secret}`
    }),
    passwordResetRequest: builder.mutation({
      query: ({ email }) => ({
        url: '/users/password',
        method: 'POST',
        body: {
          user: { email }
        }
      })
    }),
    setNewPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: '/users/password',
        method: 'PATCH',
        body: {
          user: {
            password,
            reset_password_token: token
          }
        }
      })
    }),
    setUserLanguage: builder.mutation({
      query: (language) => ({
        url: '/users/language',
        method: 'PATCH',
        body: {
          user: {
            language
          }
        }
      }),
      invalidatesTags: ['CurrentUser', ...translatableTagTypes]
    }),
    createUser: builder.mutation({
      query: (user, track) => ({
        url: '/users',
        method: 'POST',
        body: { user, track }
      }),
      invalidatesTags: invalidateCurrentUser
    }),
    omniauthCallback: builder.mutation({
      query: (result) => ({
        url: '/users/omniauth_callbacks',
        method: 'POST',
        body: result,
      }),
      invalidatesTags: invalidateCurrentUser
    }),
    createSession: builder.mutation({
      query: (credentials) => ({
        url: '/users/sign_in',
        method: 'POST',
        body: credentials
      }),
      invalidatesTags: invalidateCurrentUser
    }),
    destroySession: builder.mutation({
      query: () => ({
        url: '/users/sign_out',
        method: 'DELETE'
      }),
      invalidatesTags: invalidateCurrentUser
    }),
    getInvitation: builder.query({
      query: (arg) => `/invitations/${arg}`
    }),
    acceptInvitation: builder.mutation({
      query: ({ token, ...restProps }) => ({
        url: `/invitations/${token}`,
        method: 'POST',
        body: restProps
      }),
      invalidatesTags: invalidateCurrentUser
    })
  })
})

export const {
  usePasswordResetRequestMutation,
  useSetNewPasswordMutation,
  useSetUserLanguageMutation,
  useCreateSessionMutation,
  useOmniauthCallbackMutation,
  useDestroySessionMutation,
  usePerformEmailConfirmationQuery,
  useSendEmailConfirmationMutation,
  useCreateUserMutation,
  useGetCurrentUserQuery,
  useGetInvitationQuery,
  useAcceptInvitationMutation
} = sessionsApi
