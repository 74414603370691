import { Box, Flex } from '@chakra-ui/react'
import { DownloadAssignmentButton } from 'components/buttons/DownloadAssignmentButton'
import { getAssignmentKey } from 'utils/storage'

const AssignmentDownloadButtons = ({ assignments, talentProfileId }) => {
  return (
    <Flex flexDirection="column" ml={[0, null, 8]} mt={[2, null, 0]}>
      {assignments.map((assignment) => {
        const { step_id, download_url, filename } = assignment
        const assignmentKey = getAssignmentKey(talentProfileId, step_id)
        return (
          <Box role="listitem" key={step_id}>
            <DownloadAssignmentButton
              assignmentKey={assignmentKey}
              downloadUrl={download_url}
              filename={filename}
              key={step_id}
              isTalentList
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default AssignmentDownloadButtons
