import React, { useState, useEffect } from 'react'
import { REGEX_PATTERNS } from 'constants'
import { useTranslation } from 'i18n/TranslationContext'
import {
  Box,
  Center,
  Grid,
  GridItem,
  SimpleGrid,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { EditorState } from 'draft-js'
import { PhotoInput } from '../PhotoInput'
import { InputField, SelectField, TextEditorField, CheckboxGroupField, TestGreenhouseApiButton } from 'components'
import { LocationInput } from 'components/forms/LocationInput'
import { FEATURE_FLAGS } from 'constants'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { useGetFormTextValues } from 'hooks/partnerUx/useGetFormTextValues'
import { removeHtmlTags } from 'utils/string'
import { useGetGreenhouseUsersMutation } from 'features/partnerProfilesApi'
import { convertHtmlToEditorState } from 'utils/builder'

export const PartnerFormContent = () => {
  const { t } = useTranslation()
  const { register, watch, setValue, control } = useFormContext()
  const partnerProfileOwner = watch('partnerProfileOwner')
  const partnerProfileId = watch('partnerProfileId')
  const greenhouseApiKey = watch('greenhouseApiKey')
  const greenhouseApiValid = watch('greenhouseApiValid')
  const greenhouseApiUserId = watch('greenhouseApiUserId')
  const formCompanyBenefits = watch('companyBenefits')
  const { isEnabled: greenhouseEnabled } = useFeatureFlags(
    FEATURE_FLAGS.GREENHOUSE_INTEGRATION
  )
  const [apiTestError, setApiTestError] = useState(null)
  const [isTestSuccessful, setIsTestSuccessful] = useState(false)
  const [greenhouseUsers, setGreenhouseUsers] = useState([])
  const [companyDescriptionEditorState, setCompanyDescriptionEditorState] = useState(EditorState.createEmpty())
  const [companyAccessibilityPolicyEditorState, setCompanyAccessibilityPolicyEditorState] = useState(EditorState.createEmpty())
  const [companyBenefits] = useState(formCompanyBenefits && typeof formCompanyBenefits === 'string' ? formCompanyBenefits.split(',') : [])
  const [getGreenhouseUsers, { isLoading: isTestingApi }] = useGetGreenhouseUsersMutation()

  const { placeholderTextVals } = useGetFormTextValues()

  const testGreenhouseApi = async () => {
    setIsTestSuccessful(false)
    setApiTestError(null)
    setValue('greenhouseApiValid', false)

    try {
      const result = await getGreenhouseUsers({
        partnerProfileId,
        greenhouseApiKey,
      }).unwrap()
      if (result) {
        setGreenhouseUsers(result)
        setIsTestSuccessful(true)
        setValue('greenhouseApiValid', true)
      }
    } catch (error) {
      setApiTestError('Failed to validate API key')
      setValue('greenhouseApiValid', false)
    }
  }

  useEffect(() => {
    const companyDescription = watch('companyDescription')
    if (companyDescription) {
      const descriptionEditorState = convertHtmlToEditorState(companyDescription)
      setCompanyDescriptionEditorState(descriptionEditorState)
    }

    const companyAccessibilityPolicy = watch('companyAccessibilityPolicy')
    if (companyAccessibilityPolicy) {
      const accessibilityEditorState = convertHtmlToEditorState(companyAccessibilityPolicy)
      setCompanyAccessibilityPolicyEditorState(accessibilityEditorState)
    }

    const fetchGreenhouseUsers = async () => {
      try {
        const result = await getGreenhouseUsers({
          partnerProfileId,
          greenhouseApiKey,
        }).unwrap()
        if (result) {
          setGreenhouseUsers(result)
          setValue('greenhouseApiValid', true)
        }
      } catch (error) {
        console.error('Failed to fetch Greenhouse users:', error)
        setValue('greenhouseApiValid', false)
      }
    }

    if (greenhouseApiValid || greenhouseApiUserId) {
      fetchGreenhouseUsers()
    }

  }, [
    greenhouseApiValid,
    greenhouseApiUserId,
    partnerProfileId,
    greenhouseApiKey,
    getGreenhouseUsers,
    setValue,
    watch,
  ])

  const checkboxValues = {
    companyBenefits: [
      t('Medical'),
      t('Vision'),
      t('Dental'),
      t('401(k)'),
      t('Generous/Unlimited PTO'),
      t('Fully Remote'),
      t('Stock Options/Equity'),
      t('Career Development/Education'),
      t('Home Office Stipend'),
      t('Wellness Stipend'),
      t('On-Site Amenities')
    ]
  }


  const Tape = () => (
    <Box
      position="absolute"
      width="100%"
      top={['-6px', null, '-42px']}
      left={['50%', null, 'unset']}
      zIndex="100"
    >
      <Box
        as="img"
        alt=""
        margin={[null, null, '0 auto']}
        maxWidth={['190px', null, 'unset']}
        src={`${process.env.PUBLIC_URL}/profile-photo-tape.svg`}
      />
    </Box>
  )

  return (
    <Grid
      gap={30}
      templateAreas={[
        `"photo"
         "main"
         "resume"`,
        null,
        `"main photo"
         "resume photo"`,
      ]}
      gridTemplateColumns={[null, null, '1.6fr 1fr']}
    >
      <GridItem area="photo">
        <Center>
          <Box
            position="relative"
            mt={['-12px', null, '-94px']}
            mb={8}
            width={[null, null, '100%']}
          >
            <Tape />
            <PhotoInput />
          </Box>
        </Center>
      </GridItem>

      <GridItem area="main">
        <InputField
          label="Partner name"
          name="name"
          inputProps={{
            placeholder: placeholderTextVals.partner_name,
            ...register('name', {
              required: t('Name can\'t be empty'),
            }),
          }}
        />
        {partnerProfileOwner && (
          <>
            <InputField
              label="Company Name"
              name="companyName"
              inputProps={{
                placeholder: placeholderTextVals.company_name,
                ...register('companyName', {
                  required: t('Name can\'t be empty'),
                }),
              }}
            />
            <SimpleGrid columns={[1, null, 2]} gap={[0, null, '16px']}>
              <InputField
                label={t('Company Website')}
                name="companyWebsite"
                inputProps={{
                  placeholder: placeholderTextVals.company_website,
                  ...register(
                    'companyWebsite', {
                      pattern: {
                        value: REGEX_PATTERNS.URL,
                        message: t('Invalid URL')
                      }
                    })
                }}
              />
              <Box>
                <LocationInput />
              </Box>
            </SimpleGrid>
            <TextEditorField
              name="companyDescription"
              editorState={companyDescriptionEditorState}
              setEditorState={setCompanyDescriptionEditorState}
              textFieldProps={{
                label: t('Company Description'),
                placeholder: placeholderTextVals.company_description,
                width: '100%',
                maxLength: { value: 500, message: 'Max 500 characters allowed.' }
              }}
              inputProps={{
                ...register(
                  'companyDescription', {
                    validate: value => removeHtmlTags(value)?.length > 500 ? t('Max 500 characters allowed') : true
                  }
                )
              }}
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'subscript', 'superscript'],
                },
                blockType: {
                  options: ['Blockquote'],
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
                link: {
                  options: ['link', 'unlink'],
                  showOpenOptionOnHover: true,
                },
              }}
            />
            <CheckboxGroupField
              name="companyBenefits"
              defaultValue={companyBenefits}
              label={t('Available Benefits')}
              checkboxValues={checkboxValues}
            />
            <TextEditorField
              name="companyAccessibilityPolicy"
              editorState={companyAccessibilityPolicyEditorState}
              setEditorState={setCompanyAccessibilityPolicyEditorState}
              textFieldProps={{
                label: t('How We\'re Accessible'),
                placeholder: placeholderTextVals.company_accessibility_policy,
                width: '100%',
                maxLength: { value: 500, message: 'Max 500 characters allowed.' }
              }}
              inputProps={{
                ...register(
                  'companyAccessibilityPolicy', {
                    validate: value => removeHtmlTags(value)?.length > 500 ? t('Max 500 characters allowed') : true
                  }
                )
              }}
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link'],
                inline: {
                  options: ['bold', 'italic', 'subscript', 'superscript'],
                },
                blockType: {
                  options: ['Blockquote'],
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
                link: {
                  options: ['link', 'unlink'],
                  showOpenOptionOnHover: true,
                },
              }}
            />

            {greenhouseEnabled && (
              <>
                <InputField
                  label={t('Greenhouse API Key')}
                  name="greenhouseApiKey"
                  paddingBottom="20px"
                  inputProps={{
                    placeholder: placeholderTextVals.greenhouse_api_key,
                    ...register('greenhouseApiKey'),
                  }}
                />
                <Box mt={1} mb={4}>
                  <TestGreenhouseApiButton
                    onTest={testGreenhouseApi}
                    isLoading={isTestingApi}
                    isSuccess={isTestSuccessful}
                    error={apiTestError}
                  />
                </Box>
                <SelectField
                  label={t('Greenhouse API User')}
                  name="greenhouseApiUserId"
                  helperText={t(
                    'Select a user from your Greenhouse instance to serve as the proxy that sends data to and from Making Space. Note: This user must have Site Admin or Job Admin permissions.'
                  )}
                  control={control}
                  options={greenhouseUsers.map((user) => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  isDisabled={!greenhouseApiValid && !greenhouseApiUserId}
                  placeholder={placeholderTextVals.greenhouse_api_user_id}
                  width="100%"
                />
                <input type="hidden" {...register('greenhouseApiValid')} />
              </>
            )}
          </>
        )}
      </GridItem>
    </Grid>
  )
}
