import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'
import SkillsGrid from './SkillsGrid'
import { PurpleTapeDisplay, SubTitleLabel } from 'components/display'
import { useTranslation } from 'i18n/TranslationContext'
import { formatAsOrdinal } from 'utils/string'

const OpportunityDetails = ({ opportunity, formattedEmploymentType }) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Box mb={8}>
        <PurpleTapeDisplay as="h2" text={t('Opportunity Details')} fontSize="20px" pb="0px" pr="40px" />
        <Box mt={6} ml={4}>
          <Text mb={4}>
            Date Posted:{' '}
            {opportunity?.created_at
              ? new Date(opportunity.created_at)
                .toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
                .replace(/\d+/, day => `${formatAsOrdinal(parseInt(day))}`)
              : t('Date Not Available')}
          </Text>
          <Text mb={4}>
            Company: {opportunity?.partner_profile?.company_name || t('Company Not Available')}
          </Text>
          <Text mb={4}>
            Location:{' '}
            {opportunity?.location?.formatted_address ||
              opportunity?.partner_profile?.location?.formatted_address ||
              t('Location Not Available')}
            {opportunity?.workplace_model &&
              ` (${opportunity.workplace_model.charAt(0).toUpperCase() +
                opportunity.workplace_model.slice(1)})`}
          </Text>
          <Text mb={4}>{formattedEmploymentType || t('Employment Type Not Available')}</Text>
          <Text>
            {opportunity?.salary_min && opportunity?.salary_max
              ? `$${opportunity.salary_min} to $${opportunity.salary_max} per ${opportunity.salary_frequency}`
              : t('Salary Not Available')}
          </Text>
        </Box>
      </Box>

      <Box mb={8}>
        <PurpleTapeDisplay as="h2" text={t('Skills')} fontSize="20px" pb="0px" pr="50%" />
        <SkillsGrid skills={opportunity?.skills || []} />
      </Box>

      <Box>
        <PurpleTapeDisplay as="h2" text={t('Course')} fontSize="20px" pb="0px" pr="50%" />
        <Box mt={6} ml={4} mb={8}>
          <Flex gap={4}>
            <Image
              src={opportunity?.course?.poster || ''}
              alt={opportunity?.course?.name || t('Course Poster')}
              width="100px"
              height="70px"
              borderRadius="md"
            />
            <Box>
              <Text fontWeight="bold">
                {opportunity?.course?.name || t('Course Name Not Available')}
              </Text>
              <SubTitleLabel
                authors={opportunity?.course?.authors || []}
                visibility={opportunity?.course?.visibility || ''}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default OpportunityDetails
