import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Center, Show, Spacer } from '@chakra-ui/react'
import { Link, useMatch } from 'react-router-dom'
import { HeaderLayout, MenuItemBasicLinkButton, MenuItemRouterLinkButton } from '../shared/HeaderParts'

const SignInButton = (props) => {
  const { t } = useTranslation()
  const isSignInPage = !!useMatch('/sign_in')

  if (isSignInPage) { return null }

  return (
    <Button
      {...props}
      to="/sign_in"
      colorScheme="zettaWhite"
      fontWeight="500"
      variant="solid"
    >
      {t('Sign In')}
    </Button>
  )
}

const BecomeAPartnerButton = (props) => {
  const { t } = useTranslation()
  return (
    <Button
      {...props}
      href="https://making-space.com/partners"
      colorScheme="zettaBlue"
      fontWeight="500"
      variant="solid"
    >
      {t('Become a partner')}
    </Button>
  )
}

const SignUpButton = (props) => {
  const { t } = useTranslation()
  const isSignUpPage = !!useMatch('/sign_up')

  if (isSignUpPage) { return null }

  return (
    <Button
      {...props}
      to="/sign_up"
      colorScheme="zettaWhite"
      fontWeight="500"
      variant="solid"
    >
      {t('Sign Up')}
    </Button>
  )
}

const HeaderButtons = () => (
  <Center flexWrap="wrap">
    <Box ml={2} mr={2}>
      <BecomeAPartnerButton as="a" />
    </Box>
    <SignInButton as={Link} />
    <SignUpButton as={Link} />
  </Center>
)

export const PageHeader = () => (
  <HeaderLayout>
    <Show breakpoint="(max-width: 767px)">
      <SignInButton as={MenuItemRouterLinkButton} />
      <BecomeAPartnerButton as={MenuItemBasicLinkButton} />
      <SignUpButton as={MenuItemRouterLinkButton} />
    </Show>
    <Show above="md">
      <Spacer />
      <HeaderButtons />
    </Show>
  </HeaderLayout>
)
