import { createSlice } from '@reduxjs/toolkit'
import { TALENT_QUIZ_SYSTEM_MSG_TYPE } from 'constants'
import { TALENT_QUIZ_BUBBLE_TYPE } from 'constants'
import { TALENT_QUIZ_MODE } from 'constants'
import { quizzesApi, submitAnswer } from 'features/quiz/quizzesApi'
import { remainingQuestions } from 'utils/quizzes/talentQuiz'

const initialState = {
  selectedAnswer: null,
  quizMode: TALENT_QUIZ_MODE.ACTIVE,
  liveBubbles: [],
  loading: false,
  error: null,
  remainingQuestionCount: 3,
  modalIsOpen: false,
  quizStepId: null,
}

const talentQuizSlice = createSlice({
  name: 'talentQuiz',
  initialState,
  reducers: {
    setSelectedAnswer: (state, action) => {
      state.selectedAnswer = action.payload
    },
    setQuizMode: (state, action) => {
      state.quizMode = action.payload
    },
    addLiveBubble: (state, action) => {
      state.liveBubbles.push(action.payload)
    },
    resetState: (state) => {
      Object.assign(state, initialState)
    },
    setRemainingQuestionCount: (state, action) => {
      state.remainingQuestionCount = action.payload
    },
    setModalIsOpen: (state, action) => {
      state.modalIsOpen = action.payload
    },
    openQuizForStepId: (state, action) => {
      state.quizStepId = Number(action.payload)
      state.modalIsOpen = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitAnswer.fulfilled, (state, action) => {
      const { questionId, isCorrect } = action.payload
      const { CORRECT_ANSWER, INCORRECT_ANSWER } = TALENT_QUIZ_SYSTEM_MSG_TYPE

      if ( isCorrect ) state.remainingQuestionCount -= 1
      const bubble = {
        bubbleType: TALENT_QUIZ_BUBBLE_TYPE.SYSTEM,
        questionId,
      }
      bubble.messageType = isCorrect ? CORRECT_ANSWER : INCORRECT_ANSWER
      bubble.remainingQuestionCount = state.remainingQuestionCount

      state.selectedAnswer = null
      state.liveBubbles.push(bubble)
      state.loading = false
      state.error = null
    })
    builder.addCase(submitAnswer.rejected, (state, action) => {
      console.error('ERROR Submitting Answer: ', action)
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(submitAnswer.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addMatcher(
      quizzesApi.endpoints.fetchQuizByStepId.matchFulfilled,
      (state, action) => {
        state.remainingQuestionCount = remainingQuestions(action.payload).length
      }
    )
  },
})

export const {
  setSelectedAnswer,
  setQuizMode,
  setRandomizedAnswers,
  addLiveBubble,
  resetState,
  setRemainingQuestionCount,
  setModalIsOpen,
  openQuizForStepId,
} = talentQuizSlice.actions
export const talentQuizReducer = talentQuizSlice.reducer