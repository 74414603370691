import { useTranslation } from 'i18n/TranslationContext'

export const useCourseTalentListSwitchConfigs = () => {
  const { t } = useTranslation()
  return (
    [
      { id: 'course-completed', name: 'courseCompleted', label: t('Course Completed') },
      { id: 'assignment-completed', name: 'assignmentSubmitted', label: t('Assignment Completed') },
      { id: 'resume-uploaded', name: 'resumeUploaded', label: t('Resume Uploaded') },
      { id: 'has-link', name: 'hasPortfolioLink', label: t('Portfolio Added') },
    ]
  )
}

export const useOpportunityAppListSwitchConfig = () => {
  const { t } = useTranslation()
  return (
    [
      { id: 'app-status-reviewing', name: 'applied', label: t('Reviewing') },
      { id: 'app-status-interviewing', name: 'interviewing', label: t('Interviewing') },
      { id: 'app-status-offer-sent', name: 'offer_sent', label: t('Offer Sent') },
      { id: 'app-status-hired', name: 'hired', label: t('Hired') },
      { id: 'app-status-rejected', name: 'rejected', label: t('Rejected') },
    ]
  )
}
