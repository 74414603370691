import { useTranslation } from 'i18n/TranslationContext'
import { useCoursePageContext } from '../CoursePageContext'
import { CourseStartButton } from '../CourseStartButton'
import { Box } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import { HiPlay } from 'react-icons/hi'

export const PlayEpisodeButton = ({ to }) => {
  const { hidePlayEpisodeButton } = useCoursePageContext()
  const { t } = useTranslation()
  if (hidePlayEpisodeButton) { return null }

  return (
    <CourseStartButton
      mt={1}
      colorScheme="zettaDark"
      variant="solid"
      fontWeight="500"
      to={to}
    >
      <Box>{t('Play episode')}</Box>
      <Icon
        fontSize="20px"
        as={HiPlay}
        ml="42px"
      />
    </CourseStartButton>
  )
}
