import React from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { UploadMediaField } from 'components'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import { useGetFormTextValues } from 'hooks/courseBuilder/useGetFormTextValues'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { useGetMediaS3Path } from 'hooks/courseBuilder/useMediaField'

const EpisodeUploadAccessibilityForm = ({ episodeNum, formDisabled=false, stepDisabled }) => {
  const { t } = useTranslation()
  const mediaType = React.useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  const { helperTextVals } = useGetFormTextValues()
  const { courseSlug } = useCourseBuilderContext()

  return (
    <>
      <UploadMediaField
        name={`episode_${episodeNum}_transcript`}
        label={t('Episode Transcript')}
        helperText={!formDisabled && t('VTT only.')}
        mediaType={mediaType.VTT}
        fieldRequired={!stepDisabled}
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(episodeNum, courseSlug, `episode_${episodeNum}_transcript`)}
      />
      <UploadMediaField
        name={`episode_${episodeNum}_audio_description`}
        label={t('Video Audio Description')}
        helperText={!formDisabled && t('VTT only.')}
        mediaType={mediaType.VTT}
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(episodeNum, courseSlug, `episode_${episodeNum}_audio_description`)}
      />
      <UploadMediaField
        name={`episode_${episodeNum}_sign_language_video`}
        label={t('Sign Language Video')}
        helperText={!formDisabled && helperTextVals.sign_language_video}
        mediaType={mediaType.EPISODE_VIDEO}
        inputProps={{ disabled: formDisabled }}
        s3MediaPaths={useGetMediaS3Path(episodeNum, courseSlug, `episode_${episodeNum}_sign_language_video`)}
      />
    </>
  )
}

export default EpisodeUploadAccessibilityForm
