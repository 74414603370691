import { useTranslation } from 'i18n/TranslationContext'
import { Box, Flex } from '@chakra-ui/react'
import { usePageTitle } from '../../usePageTitle'
import { CourseTable } from './CourseTable'
import { PrimaryButton, PageOuterContainer } from 'components'

const PageContent = () => {
  const { t } = useTranslation()

  const buildNewCourseButton = (
    <PrimaryButton
      float="right"
      margin="10px 10px 0px 0px"
      textValue={t('Build New Course')}
      linkValue="/courses/new"
    />
  )

  return (
    <PageOuterContainer greenBg="true">
      <Box
        bgColor="white"
        padding={[
          '40px 24px 0',
          null,
          '40px 32px 0'
        ]}
      >
        <Box>
          <Box as="h1"
            pl="16px"
            fontFamily="Spline Sans Mono"
            fontSize="40px"
            fontWeight="bold"
            textTransform="uppercase"
            float="left"
          >
            {t('Courses')}
          </Box>
          {buildNewCourseButton}
        </Box>
        <Box
          display="block"
          padding="24px 0"
          style={ { clear: 'both' } }
        >
          <CourseTable />
        </Box>
      </Box>
    </PageOuterContainer>
  )
}

export const CoursesList = () => {
  const { t } = useTranslation()
  usePageTitle(t('Courses'))

  return (
    <Flex
      flex="1"
      flexDirection="column"
      justifyContent="space-between"
    >
      <PageContent />
    </Flex>
  )
}
