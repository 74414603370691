import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addFormField, resetOpportunityBuilder } from 'features/opportunities/opportunityBuilderSlice'
import { useParams } from 'react-router-dom'
import { useShowOpportunityDraftForPartnerQuery } from 'features/opportunitiesApi'
import { useOpportunityBuilderContext } from 'OpportunityBuilder/OpportunityBuilderContext'
import { getFormIdFromFieldKey } from 'OpportunityBuilder/getOpportunityStepsContent'
import { convertHtmlToEditorState } from 'utils/builder'

const useRebuildFormData = (newInitialized, initialized, isNewOpportunity) => {
  const dispatch = useDispatch()
  let { opportunityId } = useParams()
  const opportunitySliceData = useSelector(state => state.opportunityBuilder || {})
  const [loading, setLoading] = useState(true)
  const {
    builderForms: opportunityBuilderForms,
    partnerProfileId,
    setOpportunityDescriptionEditorState,
    setSkillsValues
  } = useOpportunityBuilderContext()

  const opportunityData = useShowOpportunityDraftForPartnerQuery({
    id: opportunityId,
    partnerId: partnerProfileId,
  }, { skip: isNewOpportunity })

  const processSingleValue = useCallback((key, value) => {
    if (!value || value === '') return

    if (key === 'description') {
      // Convert HTML string to editor state
      const editorState = convertHtmlToEditorState(value)
      setOpportunityDescriptionEditorState(editorState)
    } else if (key === 'skills') {
      setSkillsValues(value)
    }

    dispatch(addFormField({ key, value }))
    let formId = getFormIdFromFieldKey(key)
    if(formId) {
      opportunityBuilderForms[formId].setValue(key, value)
    }
  }, [
    dispatch,
    setSkillsValues,
    opportunityBuilderForms,
    setOpportunityDescriptionEditorState
  ])

  useEffect(() => {
    const processOpportunityData = (data) => {
      Object.entries(data).forEach(([key, value]) => {
        if (['id', 'invalid_builder_steps', 'valid_builder_steps'].includes(key)) {
          dispatch(addFormField({ key, value }))
        } else if (key in opportunitySliceData) {
          processSingleValue(key, value)
        }

        // Disable the editable pages if the status is closed
        if (key === 'status' && value === 'closed') {
          dispatch(addFormField({
            key: 'disabledSteps', value: ['opportunityDetails', 'opportunityDescription']
          }))
        }
      })
    }

    if (!initialized.current && opportunityData.isSuccess) {
      dispatch(resetOpportunityBuilder())
      console.log('Opportunity data is successful:', opportunityData.data)
      processOpportunityData(opportunityData.data)
      initialized.current = true
      setLoading(false)
    } else if (!initialized.current && !opportunityData.isSuccess && isNewOpportunity) {
      if (!newInitialized.current) {
        newInitialized.current = true
        dispatch(resetOpportunityBuilder())
        setLoading(false)
      }
    }
  }, [opportunityData,
    dispatch,
    initialized,
    newInitialized,
    opportunitySliceData,
    processSingleValue,
    isNewOpportunity
  ])

  return loading
}

export default useRebuildFormData

