import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Flex } from '@chakra-ui/react'
import { CustomDrawer } from 'shared/CustomDrawer'
import { Link } from 'react-router-dom'
import { usePageTitle } from 'usePageTitle'
import { AssignmentsList } from './AssignmentList'
import { useBackTo } from '../useBackTo'

export const TalentView = () => {
  const { t } = useTranslation()
  const backTo = useBackTo()
  usePageTitle(t('Assignments'))

  return (
    <CustomDrawer
      backTo={backTo}
    >
      <Box as="main" role="main"
        position="relative"
        paddingTop={['16px', null, '68px']}
        paddingLeft={['24px', null, '64px']}
        paddingRight={['24px', null, '64px']}
      >
        <Flex
          flexDirection={['column-reverse', null, 'row']}
          justifyContent="space-between"
          paddingBottom="38px"
        >
          <Flex
            flexDirection={['column-reverse', null, 'column']}
            flexGrow="1"
          >
            <Box width={['100%', null, 'unset']}>
              <Button
                as={Link}
                to={backTo}
                colorScheme="zettaPurple"
                width={['100%', null, 'unset']}
                fontWeight="500"
              >
                {t('Get back to list')}
              </Button>
            </Box>
          </Flex>
          <Box
            flexBasis={['0', null, '48px']}
            flexGrow="1"
          />
          <Box
            marginLeft={['-32px', null, '0']}
            marginRight={['-22px', null, '0']}
          >
            <Box as="img"
              width="100%"
              opacity="0.85"
              src={`${process.env.PUBLIC_URL}/assignments-tape.svg`}
              alt={t('Assignments')}
            />
          </Box>
        </Flex>
        <Box>
          <AssignmentsList />
        </Box>
      </Box>
    </CustomDrawer>
  )
}
