import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2'
import { useSelector } from 'react-redux'

export const SearchProfilesButton = ({ onOpen, ...props }) => {
  const { t } = useTranslation()
  const { lastSearchFilterCount } = useSelector((state) => state.talentSearch)

  // each keyword counts as a filter, along with each boolean
  const filterLabel = lastSearchFilterCount > 0 ?
    t('Filters ({{lastSearchFilterCount}})', { lastSearchFilterCount }) : t('Search/Filter')

  return (
    <Button
      leftIcon={<HiOutlineMagnifyingGlass style={{ fontSize: '24px' }} />}
      size="lg"
      variant="ghost"
      fontSize="16px"
      fontWeight="500"
      onClick={onOpen}
      {...props}
    >
      {filterLabel}
    </Button>
  )
}
