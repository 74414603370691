import React from 'react'
import { Box } from '@chakra-ui/react'

const ListBlock = ({ children }) => {
  return (
    <Box
      as="section"
      padding={[
        '32px 24px',
        '56px 48px'
      ]}
      marginBottom={['8px', '16px']}
      bgColor="white"
    >
      {children}
    </Box>
  )
}

export default ListBlock
