import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Show } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const PosterImage = ({ poster }) => (
  <img
    style={{
      height: '96px',
      objectFit: 'contain'
    }}
    src={poster}
    alt=""
  />
)

export const CoursePoster = ({ id, name, poster }) => {
  const { t } = useTranslation()
  return (
    <>
      <Show above="md">
        <Box marginRight="16px" flexShrink="0" flexBasis="146px">
          <Link
            to={`/courses/${id}`}
            title={t('View course details for "{{name}}" course', { name })}
          >
            <PosterImage poster={poster} />
          </Link>
        </Box>
      </Show>
      <Show below="767px">
        <Center flexShrink="0" marginRight="8px" flexBasis="146px">
          <PosterImage poster={poster} />
        </Center>
      </Show>
    </>
  )
}