import React from 'react'
import { createConsumer } from '@rails/actioncable'

const CableContext = React.createContext()

function CableProvider({ children }) {
  const actionCableUrl = process.env.REACT_APP_BACKEND_CABLE_PATH

  const CableApp = {}
  CableApp.cable = createConsumer(actionCableUrl)

  return <CableContext.Provider value={CableApp}>{children}</CableContext.Provider>
}

export { CableContext, CableProvider }
