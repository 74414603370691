import { useTranslation } from 'i18n/TranslationContext'
import { Box, Center, Flex } from '@chakra-ui/react'
import { Photo } from './Photo'
import { Link } from 'react-router-dom'
import { DotConnectedLabel } from '../../../shared/DotConnectedLabel'

const StyledSubTitle = ({ profession, location }) => (
  <Box
    color="#070F22"
    opacity="0.6200000047683716"
    fontFamily="Poppins"
    fontWeight="500"
  >
    <DotConnectedLabel
      first={profession}
      second={location}
    />
  </Box>
)

const PronounsLabel = ({ pronouns }) => {
  if (!pronouns) { return null }

  return `(${pronouns})`
}

export const ProfileColumn = ({
  children,
  photoUrl,
  photoAltText,
  name,
  pronouns,
  profession,
  location,
  assignmentDrawerUrl,
  onProfileClick,
}) => {
  const { t } = useTranslation()

  const linkProps = assignmentDrawerUrl ? { to: assignmentDrawerUrl } : { onClick: onProfileClick, cursor: 'pointer' }
  const Component = assignmentDrawerUrl ? Link : Box

  return (
    <Flex
      paddingTop={[null, null, '8px']}
      paddingBottom={['4px', null, '8px']}
      className="profile-column"
    >
      <Box
        marginRight={['8px', null, '32px']}
        flexShrink="0"
      >
        <Component
          {...linkProps}
          title={t('View details for "{{name}}"', { name })}
        >
          <Photo
            photoUrl={photoUrl}
            photoAltText={photoAltText}
          />
        </Component>
      </Box>
      <Center
        whiteSpace="normal"
        flexDirection={['column', null, 'row']}
      >
        <Flex
          flexGrow="1"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Component {...linkProps}>
            <Box
              fontSize={['16px', null, '24px']}
              fontFamily={['Spline Sans Mono', null, 'Poppins']}
              fontWeight={['500', null, '600']}
              color="#0F204A"
            >
              {name} <PronounsLabel pronouns={pronouns} />
            </Box>
          </Component>
          <Box
            paddingTop={[null, null, '16px']}
            fontSize={['14px', null, '18px']}
          >
            <StyledSubTitle
              profession={profession}
              location={location}
            />
          </Box>
          {children}
        </Flex>
      </Center>
    </Flex>
  )
}
