import { useTranslation } from 'i18n/TranslationContext'
import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useUnEnrollFromTheCourseMutation } from 'features/coursesApi'
import { useGetCurrentUserQuery } from '../../features/sessionsApi'

export const tableBreakpoint = 'md'

export const ViewCourseDetails = ({ id, ...props }) => (
  <Button
    as={Link}
    to={`/courses/${id}`}
    bgColor="#0F204A"
    color="white"
    fontWeight="normal"
    _hover={{
      bgColor: '#070F22'
    }}
    {...props}
    width={['100%', null, 'fit-content']}
    minWidth={[null, null, '220px']}
  />
)

export const ContinueWatching = ({ id, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      as={Link}
      to={`/courses/${id}/continue_watching`}
      bgColor="#0F204A"
      color="white"
      fontWeight="normal"
      _hover={{
        bgColor: '#070F22'
      }}
      {...props}
    >
      {t('Continue watching')}
    </Button>
  )
}

export const NotInterested = ({ id: courseId, ...props }) => {
  const { t } = useTranslation()
  const [unEnroll, { isLoading }] = useUnEnrollFromTheCourseMutation()
  const { data: { id: userId } } = useGetCurrentUserQuery()

  return (
    <Button
      colorScheme="zettaWhite"
      fontWeight="500"
      {...props}
      isLoading={isLoading}
      onClick={
        () => {
          unEnroll({
            userId,
            courseId
          })
        }
      }
    >
      {t('Unenroll')}
    </Button>
  )
}
