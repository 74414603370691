import { useTranslation } from 'i18n/TranslationContext'
import { Header1 } from '../Header1'
import { Box, Button, Center, Spinner } from '@chakra-ui/react'
import { Details } from '../Details'
import { useGetInvitation } from './useGetInvitation'
import { Link } from 'react-router-dom'
import { TalentPageContent } from './TalentPageContent'
import { PartnerPageContent } from './PartnerPageContent'
import { usePageTitle } from '../../usePageTitle'

const SignUpFlow = () => {
  const { t } = useTranslation()
  const { data: { feature } } = useGetInvitation()

  switch(feature) {
  case 'talent_experience':
    return <TalentPageContent />
  case 'partner_experience':
    return <PartnerPageContent />
  default:
    return t('Unexpected error: feature {{feature}} unknown', { feature })
  }
}

export const SignUpPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Sign up'))
  const { isLoading, isError } = useGetInvitation()

  if (isLoading) {
    return (
      <Box
        background="#F0E9E6"
        pt="84px" pb="40px"
      >
        <Center minWidth="460px">
          <Spinner />
        </Center>
      </Box>
    )
  }

  if (isError) {
    return (
      <Box
        background="#F0E9E6"
        pt="84px" pb="40px"
      >
        <Box pl="176px" pr="176px">
          <Header1>{t('Not Found')}</Header1>
          <Box pb="40px">
            <Details>
              {t('Already used your invitation link? Try')}{' '}<Button as={Link} to="/sign_in" textDecoration="underline" variant="link">{t('signing in')}</Button>.
            </Details>
          </Box>
        </Box>
      </Box>
    )
  }

  return <SignUpFlow />
}
