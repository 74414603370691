import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { OrangeTapeDisplay, PurpleTapeDisplay } from 'components'
import { useTranslation } from 'i18n/TranslationContext'
import { SaveOpportunityButton, ApplyButton } from 'components'

const HeaderSection = ({ opportunity, talentUser, formattedEmploymentType }) => {
  const { t } = useTranslation()

  const getButtonsComponent = () => {
    if (talentUser.experience_type === 'partner_experience') {
      return null
    }

    const hasApplied = opportunity.opportunity_applications?.some(
      (application) => application.talent_profile_id === talentUser.id
    )

    if (hasApplied) {
      return (
        <ApplyButton
          opportunity={opportunity}
          mt="20px"
          colorScheme="zettaBlue"
          fontSize="16px"
          fontWeight="500"
          padding="0 12px"
        />
      )
    } else {
      return (
        <SaveOpportunityButton
          opportunityId={opportunity.id}
          talentUserId={talentUser.id}
          colorScheme="zettaBlue"
          fontSize="16px"
          fontWeight="500"
          padding="0 12px"
          mt="20px"
        />
      )
    }
  }

  return (
    <Box
      bg="#E5E0DD"
      py={20}
      px={4}
      mt={-8}
      ml={-8}
      mr={-8}
      position="relative"
      overflow="hidden"
      pl="10%"
    >
      <Flex alignItems="flex-start" gap={4}>
        <Image
          src={opportunity?.poster || opportunity.course.poster || ''}
          alt={opportunity?.partner_profile?.company_name || 'Company Logo'}
          boxSize="100px"
          borderRadius="full"
          bg="white"
          mt={7}
        />

        <Box>
          <PurpleTapeDisplay as="h2" text={formattedEmploymentType || t('Unknown Type')} />

          <Box mt={4}>
            <OrangeTapeDisplay
              as="h1"
              title={opportunity?.name || t('Unknown Opportunity')}
              pb="10px"
              pt="10px"
            />
          </Box>

          <Text mt={4} color="gray.700" fontFamily="Poppins">
            {opportunity?.location?.formatted_address || t('Location not available')}
            {opportunity?.workplace_model &&
              ` (${opportunity.workplace_model.charAt(0).toUpperCase() +
                opportunity.workplace_model.slice(1)})`}
          </Text>
          <Text color="gray.700" fontFamily="Poppins">
            {opportunity?.salary_min && opportunity?.salary_max
              ? `$${opportunity.salary_min} to $${opportunity.salary_max} per ${opportunity.salary_frequency}`
              : t('Salary not available')}
          </Text>
          {getButtonsComponent()}
        </Box>
      </Flex>
    </Box>
  )
}

export default HeaderSection
