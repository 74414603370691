import React from 'react'
import { MenuItem, Button } from '@chakra-ui/react'
import { GoSignOut } from 'react-icons/go'
import { useHandleLogout } from 'hooks/useHandleLogout'
import { MenuItemBase } from '../../Partner/HeaderParts'

export const LogOutButton = () => {
  const { logoutUser, t } = useHandleLogout()

  return (
    <Button
      colorScheme="zettaWhite"
      fontWeight="500"
      variant="solid"
      onClick={logoutUser}
    >
      {t('Log Out')}
    </Button>
  )
}

export const LogOutMenuItem = () => {
  const { logoutUser, t } = useHandleLogout()

  return (
    <MenuItemBase
      as="button"
      onClick={logoutUser}
      color="rgba(80, 0, 232, 1)"
      fontWeight="500"
    >
      {t('Log Out')}
    </MenuItemBase>
  )
}

export const LogOutDropdownItem = () => {
  const { logoutUser, t } = useHandleLogout()

  return (
    <MenuItem as="a" className="dropdown-display-sign-out" cursor="pointer" onClick={logoutUser} icon={<GoSignOut />}>
      {t('Sign Out')}
    </MenuItem>
  )
}
