import {
  addFormError,
  removeFormError,
  addFormField,
  addMultipleFormFields,
  removeMultipleFormFields,
  removeValueFromArray,
  addToEpisodeSteps,
  removeFromEpisodeSteps,
} from 'features/courses/courseBuilderSlice' // Import the action creator
import { COURSE_BUILDER } from 'constants'

const resetFormStepsArrays = async(stepId, dispatch) => {
  // remove the step from both success and failed step arrays since it's being revalidated
  dispatch(removeValueFromArray({ key: 'invalid_course_steps', value: stepId }))
  dispatch(removeValueFromArray({ key: 'valid_course_steps', value: stepId }))
}

const resetMultiValueArrays = (stepId, dispatch) => {
  const fieldKeyMap = {
    'courseNameThumbnail': 'course_additional_material',
    'courseExperts': 'course_expert',
    'assignment': 'assignment_additional_material',
  }
  if (fieldKeyMap[stepId]) {
    dispatch(removeMultipleFormFields({ key: fieldKeyMap[stepId] }))
  }
}

const extractSkills = (skills, formToValidate, stepId, dispatch) => {
  // set form value course_skills from the state before validation
  if(stepId === 'courseDescriptions') {
    formToValidate.setValue('course_skills', skills.join(','))
    dispatch(addFormField({ key: 'course_skills', value: '' }))
  }
}

const handleEpisodeQuiz = (stepId, dispatch) => {
  if (stepId.includes('Quiz') && stepId.includes('episode')) {
    const episodeNum = stepId.replace('Quiz', '').replace('episode', '')
    dispatch(removeFromEpisodeSteps({ key: episodeNum, subKeyString: `episode_${episodeNum}_quiz` }))
  }
}

const isEpisodeName = (value) => {
  const regex = /^episode_\d+_name$/
  return regex.test(value)
}

const namePropertyMissing = (property) => {
  return property === 'name' || isEpisodeName(property) ? 'nameMissing' : false
}

const validateProperty = (property, formData, formToValidate, dispatch, episodes, multipleValues) => {
  const isEpisodeField = episodes.some(episodeKey => property.startsWith(episodeKey))
  return formToValidate.trigger(property).then(isValid => {
    if (isValid) {
      handleValidProperty(property, formData, dispatch, episodes, multipleValues, isEpisodeField)
      dispatch(removeFormError({ key: property }))
      return true
    } else {
      handleInvalidProperty(property, formData, formToValidate, dispatch, isEpisodeField)
      return namePropertyMissing(property)
    }
  }).catch(error => {
    console.error('Error occurred during form validation:', error)
    return false
  })
}

const handleValidProperty = (property, formData, dispatch, episodes, multipleValues, isEpisodeField) => {
  // if its empty, don't add to the state - with exception of episode fields and intro files
  const isFilledData = (formData[property] && (formData[property].length > 0 || property === 'opportunity_id'))
  const isIntroFileData = property.includes('intro_') && property !== ('intro_thumbnail_alt_text') && isFilledData

  if (isEpisodeField) {
    if (property.includes('_quiz_') && !isFilledData) {
      return
    }
    handleEpisodeField(property, formData, dispatch)
  } else if ((isFilledData || isIntroFileData) && !property.includes('-file')) {
    handleRegularField(property, formData, dispatch, multipleValues)
  }
}

const handleInvalidProperty = (property, formData, formToValidate, dispatch, isEpisodeField) => {
  const formError = formToValidate.formState.errors[property]
  const errorMessage = formError?.message || ''
  dispatch(addFormError({ [property]: errorMessage }))
  if (isEpisodeField) {
    handleEpisodeField(property, formData, dispatch)
  } else {
    !property.includes('-file') && dispatch(addFormField({ key: property, value: formData[property] || null }))
  }
}

const handleEpisodeField = (property, formData, dispatch) => {
  const episodeNumber = property.match(/\d+/)[0]
  dispatch(addToEpisodeSteps({ key: episodeNumber, value: { [property]: formData[property] || null } }))
}

const handleRegularField = (property, formData, dispatch, multipleValues) => {
  const multipleValuesKey = multipleValues.find(value => property.includes(value))
  if(multipleValuesKey) {
    dispatch(addMultipleFormFields({ key: multipleValuesKey, value: { [property]: formData[property] } }))
  } else {
    dispatch(addFormField({ key: property, value: formData[property] }))
  }
}

const validateAndContinue = async(dispatch, courseBuilderForms, stepId, skills) => {
  if (stepId === 'reviewSubmit') return true

  try {
    const formToValidate = courseBuilderForms[`${stepId}`]
    const episodes = Array.from({ length: COURSE_BUILDER.EPISODE_LIMIT }, (_, i) => `episode_${i + 1}_`)
    const multipleValues = ['course_additional_material', 'course_expert', 'assignment_additional_material']

    resetFormStepsArrays(stepId, dispatch)
    resetMultiValueArrays(stepId, dispatch)
    extractSkills(skills, formToValidate, stepId, dispatch)
    handleEpisodeQuiz(stepId, dispatch)

    const formData = formToValidate.getValues()
    const validationPromises = Object.keys(formData).map(property =>
      validateProperty(property, formData, formToValidate, dispatch, episodes, multipleValues)
    )

    const validationResults = await Promise.all(validationPromises)
    const isValidForm = validationResults.every(result => result === true)
    const nameMissing = validationResults.every(result => result !== 'nameMissing')

    if(isValidForm) {
      dispatch(addMultipleFormFields({ key: 'valid_course_steps', value: stepId }))
    } else {
      dispatch(addMultipleFormFields({ key: 'invalid_course_steps', value: stepId }))
    }

    return nameMissing
  } catch (error) {
    console.error('Error occurred during form validation:', error)
  }
}

export default validateAndContinue
