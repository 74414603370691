import { Box } from '@chakra-ui/react'
import { DotConnectedLabel } from 'shared/DotConnectedLabel'

const Subtitle = ({ pronouns, location }) => {
  return (
    <Box
      fontFamily="Spline Sans Mono"
      fontSize="18px"
      color="var(--navy-blue, #0F204A)"
      opacity="0.7"
    >
      <DotConnectedLabel
        first={pronouns}
        second={location && location.formatted_address}
      />
    </Box>
  )
}

export default Subtitle
