import { useParams } from 'react-router-dom'
import { useCourseData } from './CourseData'
import { useCoursePageContext } from './CoursePage/CoursePageContext'
import { useHasAudioDescriptionFileQuery, useShowEnrollInfoForCourseQuery, useGetAssignmentGrantExistsQuery } from 'features/coursesApi'
import { DEFAULT_LOCALE } from 'constants'
import { useCurrentCourseQuery } from 'CourseFeature/CoursePage/useCurrentCourseQuery'
import { useFetchQuizByStepIdQuery } from 'features/quiz/quizzesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useTranslation } from 'i18n/TranslationContext'

export const useEpisodes = () => {
  const { steps } = useCourseData()

  if (!steps) { return null }

  return steps
    .order
    .filter((stepId) => (steps[stepId].type === 'episode'))
}

export const useStepId = () => {
  const { stepId } = useParams()
  return stepId
}

export const useFindEpisodeNumber = (stepId) => {
  const episodes = useEpisodes()
  return episodes.findIndex((element) => element === parseInt(stepId)) + 1
}

export const useEpisodeNumber = () => {
  const oneVideoCourses = ['Screenwriting 101', 'Directing 101']
  const { name } = useCourseData()
  const params = useParams()
  const stepId = useStepId()
  const episodes = useEpisodes()

  if (oneVideoCourses.includes(name)) {
    return 1
  }

  if (params.episodeNumber) {
    return params.episodeNumber
  }

  return episodes.findIndex((element) => element === parseInt(stepId)) + 1
}

export const useGetStepById = (stepId) => {
  const steps = useCourseData().steps
  return steps[stepId]
}

export const useCurrentEpisodeData = () => {
  const episodeNumber = useEpisodeNumber()
  const stepId = useStepId()
  const courseData = useCourseData()

  if (stepId) {
    return courseData.steps[stepId]
  }

  if (courseData.steps && courseData.steps.order.length > 0) {
    const computedStepId = courseData.steps.order[episodeNumber - 1]

    return courseData.steps[computedStepId]
  }

  return courseData.episodes[episodeNumber - 1]
}

export const useGetSlideDeckData = () => {
  const basePath = useEpisodeBathPath()
  const currentEpisode = useCurrentEpisodeData()
  const { i18n } = useTranslation()
  const translationDeckPath = i18n?.language === DEFAULT_LOCALE ?
    `${basePath}/deck_files/` :
    `${basePath}/deck_files/translations/${i18n?.language}_`

  const slides = []
  for (let slideNum = 1; slideNum <= currentEpisode.deckCount; slideNum++) {
    const slide = {
      screenreaderText: currentEpisode.screenreaderTexts.find(text => Number(text.order) === slideNum)?.content,
      image: `${basePath}/deck_files/${slideNum - 1}.webp`,
      speakerNotes: `${translationDeckPath}speakernotes_${slideNum}.txt`,
    }
    slides.push(slide)
  }
  return slides
}

export const useSteps = () => useCourseData().steps

export const useVideoCompleted = (stepId) => {
  const { data: { feature } } = useGetCurrentUserQuery()
  const isPartner = feature === 'partner_experience'
  const { data: enrolmentData } = useShowEnrollInfoForCourseQuery(useCourseId())

  if (isPartner) return true

  const stepStates = enrolmentData?.enrolment_step_states || []
  const stepState = stepStates.find((state) => Number(state.step_id) === Number(stepId))
  return stepState ? stepState.episode_completed : false
}

export const useShowTakeQuizButton = (stepId) => {
  const { data: quiz, isUninitialized, isLoading, isError } = useFetchQuizByStepIdQuery(stepId)
  const stepMap = useStepMap()

  // If the request is uninitialized, loading, or resulted in an error, don't show the button
  const disableShowButton = isUninitialized || isLoading || isError

  return {
    showTakeQuiz: !disableShowButton && quiz?.id && !stepMap[stepId].isAssignment && !stepMap[stepId].isIntro,
    isLoading
  }
}

const useCompletedStepIds = () => {
  const courseId = useCourseId()
  const { data: courseData } = useCurrentCourseQuery()
  const hasIntroEpisode = courseData.has_intro_episode
  const steps = courseData.content?.steps

  const { data: enrolmentData } = useShowEnrollInfoForCourseQuery(courseId)
  const stepStates = enrolmentData?.enrolment_step_states || []

  const isStepCompleted = (stepId) => {
    const stepState = stepStates.find((state) => state.step_id === stepId)
    return stepState ? stepState.completed : false
  }

  return steps?.order?.reduce((completedSteps, stepId, index) => {
    const isCurrentStepCompleted = index === 0 && hasIntroEpisode ? true : isStepCompleted(stepId)
    if (isCurrentStepCompleted) completedSteps.push(stepId)
    return completedSteps
  }, []) || []
}

export const useGetStepInfo = (stepId, direction) => {
  const steps = useSteps()
  const courseId = useCourseId()
  const stepOrder = steps.order
  const stepIndex = stepOrder.indexOf(Number(stepId))
  const targetStep = direction === 'next' ? steps[stepOrder[stepIndex + 1]] : steps[stepOrder[stepIndex - 1]]
  const { data: grantExistsResponse } = useGetAssignmentGrantExistsQuery({ courseId })
  const assignmentGrantExists = grantExistsResponse?.exists

  const isAssignment = targetStep?.type === 'assignment'
  const isDisabled = isAssignment ? !(assignmentGrantExists || !targetStep?.disabled) : targetStep?.disabled
  return targetStep ?
    { id: targetStep.id, isAssignment, isDisabled } :
    null
}

export const useStepMap = () => {
  const steps = useSteps()
  const courseId = useCourseId()
  const completedStepIds = useCompletedStepIds()
  const { data: grantExistsResponse } = useGetAssignmentGrantExistsQuery({ courseId })
  const assignmentGrantExists = grantExistsResponse?.exists
  const { data: courseData, isPartner } = useCurrentCourseQuery()
  let previousStepCompleted = true

  // The logic for disabling or enabling steps is as follows:
  // 1. If the previous step is not completed, the current step is disabled.
  // 2. The first step is always enabled if there is an intro episode.
  // 3. The step is enabled if it is completed.
  return steps.order.reduce((stepMap, stepId) => {
    const step = steps[stepId]
    const isAssignment = step.type === 'assignment'
    const isCurrentStepCompleted = completedStepIds.includes(stepId)
    const isIntro = stepId === steps.order[0] && courseData.has_intro_episode

    const isAssignmentEnabled = assignmentGrantExists || !step['disabled']
    const isEpisodeEnabled = !step['disabled'] && previousStepCompleted
    const isDisabled = !isPartner && (isAssignment ? !isAssignmentEnabled : !isEpisodeEnabled)
    const stepURL = isDisabled ? '#' : `/courses/${courseId}/steps/${stepId}`

    previousStepCompleted = isCurrentStepCompleted

    stepMap[stepId] = { step, stepURL, isAssignment, isDisabled, isIntro }
    return stepMap
  }, {})
}

export const useIsStepsMode = () => {
  const steps = useSteps()
  return !!(steps && steps.order)
}

export const useCourseId = () => useParams().courseId

export const useCourseBasePath = () => useCoursePageContext().courseContentData.data.mediaBase
export const useCourseFile = (path) => `${useCourseBasePath()}/${path}`

export const useEpisodeBathPath = () => useCourseFile(`episodes/${useEpisodeNumber()}`)

export const useEpisodeRelPath = () => 'courses/' + useEpisodeBathPath().split('courses/')[1]

export const useGetAudioDescriptionFileStatus = ({ VTTPath, MP4Path }) => {
  const relPath = useEpisodeRelPath()
  const keys = {
    typeVTT: (relPath + VTTPath),
    typeExtVideo: (relPath + MP4Path),
  }

  return useHasAudioDescriptionFileQuery(encodeURIComponent(JSON.stringify(keys)))
}

export const useVTTSrcPath = (language) => {

  const vttSrcPath = {
    captions: '/captions.vtt',
    describedCaptions: '/described-captions.vtt',
    chapters: '/chapters.vtt',
    describedChapters: '/described-chapters.vtt',
    descriptions: '/descriptions.vtt',
  }

  if (language === DEFAULT_LOCALE) {
    return vttSrcPath
  } else {
    return Object.entries(vttSrcPath).reduce((acc, [key, path]) => {
      const fileName = path.split('/').slice(-1)[0]
      acc[key] = `/translations/${language}_${fileName}`
      return acc
    }, {})
  }
}
