import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'i18n/TranslationContext'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'
import { getCourseStepsContent } from 'CourseBuilder/getCourseStepsContent'
import StepsAccordion from 'components/builder/StepsAccordion'

const CourseStepsAccordion = () => {
  const { t } = useTranslation()
  const { stepId, jumpToStep, defaultIndex, episodeCount, isFileUploading } = useCourseBuilderContext()
  const { courseStepsContent, headersMap } = getCourseStepsContent(episodeCount, t)
  const {
    disabledCourseSteps,
    valid_course_steps: validCourseSteps,
    invalid_course_steps: invalidCourseSteps
  } = useSelector(state => state.courseBuilder)

  const steps = Object.entries(headersMap).map(([index, header]) => ({
    header,
    links: courseStepsContent.filter(link => link.defaultIndex === parseInt(index))
  }))

  return (
    <StepsAccordion
      title={t('Steps')}
      steps={steps}
      currentStepId={stepId}
      onStepClick={!isFileUploading ? jumpToStep : undefined}
      disabledSteps={disabledCourseSteps}
      validSteps={validCourseSteps}
      invalidSteps={invalidCourseSteps}
      defaultIndex={defaultIndex}
      t={t}
    />
  )
}

export default CourseStepsAccordion
