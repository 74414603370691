import { Box, HStack, Text } from '@chakra-ui/react'
import { coreIconStyles } from 'components/talent/quiz/modal-elements/QuizContainer'

const WRONG_ANSWER_BKG = '#61646B'

export const WrongAnswer = ({ answer, iconLetter }) => {

  // TODO: Move to Chakra variables
  const BORDER_COLOR = '#e5e5e5'

  return (
    <Box
      bgColor={WRONG_ANSWER_BKG}
      borderColor={BORDER_COLOR}
      border="1px"
      borderRadius="2xl"
      className="quiz-wrong-answer"
      color="white"
      p={3}
    >
      <HStack spacing={2}>
        <WrongAnswerIcon iconLetter={iconLetter} />
        <Text>{answer}</Text>
      </HStack>
    </Box>
  )
}
const WrongAnswerIcon = ({ iconLetter }) => {
  return (
    <Box color={WRONG_ANSWER_BKG} { ...coreIconStyles }>
      {iconLetter}
    </Box>
  )
}