import { useTranslation } from 'i18n/TranslationContext'
import { useSelector } from 'react-redux'
import {
  playerLoadedSelector,
  showPlayerCoverSelector
} from 'features/episode/selectors'
import { Box, Button, Center } from '@chakra-ui/react'
import { useCurrentEpisodeData, useEpisodeBathPath } from '../hooks'
import { useVideoPlayer } from './useVideoPlayer'
import { Icon } from '@chakra-ui/icons'
import { HiPlay } from 'react-icons/hi'
import { PlayerWithTranscript } from './PlayerWithTranscript'
import { useCurrentCourseQuery } from '../CoursePage/useCurrentCourseQuery'

const EpisodeName = () => useCurrentEpisodeData().name

const PlayerPreview = () => {
  const { t } = useTranslation()
  const playerLoaded = useSelector(playerLoadedSelector)
  const base = useEpisodeBathPath()

  const { startPlayer } = useVideoPlayer()

  return (
    <Center
      bg={`url(${base}/poster@2x.jpeg)`}
      backgroundSize="cover"
      backgroundPosition="center"
      style={ { 'aspectRatio': '1321 / 697' } }
      width="100%"
      className="player-preview"
    >
      <Center
        minWidth={[null, null, '706px']}
        maxWidth="80%"
        minHeight="248px"
        bg={`url(${process.env.PUBLIC_URL}/tape4.svg)`}
        backgroundSize="cover"
      >
        <Box padding="30px">
          <Box
            pb="24px"
            fontFamily="Spline Sans Mono"
            fontWeight="bold"
            fontSize={['24px', null, '40px']}
            color="white"
            textTransform="uppercase"
            textAlign="center"
          >
            <EpisodeName />
          </Box>
          <Center className="start-watching-btn-container">
            <Button
              colorScheme="zettaBlue"
              fontWeight="500"
              variant="solid"
              size={['sm', 'lg']}
              onClick={startPlayer}
              isLoading={!playerLoaded}
              iconSpacing={['12px', '50px']}
              rightIcon={<Icon
                as={HiPlay}
                fontSize="20px"
              />}
            >
              {t('Start watching')}
            </Button>
          </Center>
        </Box>
      </Center>
    </Center>
  )
}

export const VideoBlock = () => {
  const { isSuccess } = useCurrentCourseQuery()
  const showCover = useSelector(showPlayerCoverSelector)

  if (!isSuccess) { return null }

  return (
    <Box
      position="relative"
      className="player-block"
      paddingBottom={showCover && ['120%', '90%', '75%']}
      height={showCover && '0'}
    >
      <PlayerWithTranscript />
      { showCover &&
        (
          <Center
            position="absolute"
            top="0"
            zIndex="10000"
            width="100%"
            height="100%"
            bgColor="black"
            overflow="hidden"
          >
            <PlayerPreview />
          </Center>
        )
      }
    </Box>
  )
}
