import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { TalentApp } from 'TalentApp'
import { useTranslation } from 'i18n/TranslationContext'
import { PartnerApp } from './Partner'
import { useGetCurrentUserQuery, useSetUserLanguageMutation } from 'features/sessionsApi'
import { LOCALE_OPTIONS } from 'constants'
import { useAutoUpdateLanguage } from 'hooks/useAutoUpdateLanguage'

const AuthenticatedAppSwitch = () => {
  const { data: { feature } } = useGetCurrentUserQuery()
  const { t } = useTranslation()

  switch(feature) {
  case 'talent_experience':
    return <TalentApp />
  case 'partner_experience':
    return <PartnerApp />
  default:
    return t('Unexpected error: feature {{feature}} unknown', { feature })
  }
}

const Shared = ({ children }) => {
  const { t, i18n } = useTranslation()
  const { data: { 'admin?': isAdmin, language, id } } = useGetCurrentUserQuery()
  const [setUserLanguageMutation] = useSetUserLanguageMutation()

  // Using a feature flag, determine whether to force the language to be English or allow auto language detection
  useAutoUpdateLanguage(isAdmin, language, !!id)

  return (
    <>
      {children}

      {isAdmin &&
      <Box role="navigation" position="relative">
        <Box position="absolute" bottom="50px" right="0">
          <Button as="a" href="/admin">Admin</Button>
        </Box>
        <Box position="absolute" bottom="0" right="0">
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              {t('Change Language')}
            </MenuButton>
            <MenuList>
              {Object.entries(LOCALE_OPTIONS).map(([language, values]) => (
                <MenuItem key={values.key} onClick={() => {
                  i18n.changeLanguage(values.key)
                  setUserLanguageMutation(values.key)
                }}>
                  {values.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Box>
      </Box>
      }
    </>
  )
}

export const AuthenticatedApp = () => {
  return (
    <Shared>
      <AuthenticatedAppSwitch />
    </Shared>
  )
}
