import { apiSlice } from './apiSlice'

export const locationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchLocation: builder.query({
      query: (searchText) => `/locations?search=${searchText}`
    })
  })
})

export const {
  useSearchLocationQuery
} = locationsApi
