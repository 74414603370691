import { Box, HStack, ModalHeader, Text } from '@chakra-ui/react'
import { useCoursePageContext } from 'CourseFeature/CoursePage/CoursePageContext'
import { useFindEpisodeNumber } from 'CourseFeature/hooks'
import { useTranslation } from 'i18n/TranslationContext'

const EpisodeTitle = ({ step: { type, id } }) => {
  const { t } = useTranslation()
  const episodeNumber = useFindEpisodeNumber(id)
  const { data: { has_intro_episode } } = useCoursePageContext().courseContentData

  if (type === 'assignment') {
    return t('Assignment')
  }

  if (has_intro_episode) {
    return (episodeNumber === 1) ? null : t('Episode {{episodeNumber}}', { episodeNumber: episodeNumber - 1 })
  } else {
    return t('Episode {{episodeNumber}}', { episodeNumber })
  }
}

const QuizEpisodeNumber = ({ step }) => (
  <Text color="white" fontFamily="Spline Sans Mono" fontSize="40px" textTransform="uppercase">
    <EpisodeTitle step={step} />:
  </Text>
)

const QuizEpisodeName = ({ episodeName }) => (
  <Text color="white" fontFamily="Poppins" fontSize="28px">{episodeName}</Text>
)

const ModalLabel = ({ label }) => (
  <Text color="white" fontFamily="Spline Sans Mono" fontSize="40px" textTransform="uppercase">
    {label}
  </Text>
)

const DARK_BLUE = '#0F204B'

// If a step is passed, it will display the episode number and name. Otherwise, it will display the label.
export const ChatHeader = ({ step, label, onClose, initialRef }) => {
  const { t } = useTranslation()
  return (
    <ModalHeader bgColor={DARK_BLUE} borderTopLeftRadius="6px" borderTopRightRadius="6px" padding="20px" px="25px">
      <HStack justify="space-between" align="center">
        { step ?
          <HStack spacing={4} className="header-tape-number-name">
            <img src={`${process.env.PUBLIC_URL}/quiz-header-tape.svg`} alt={t('Quiz Modal Header')} />
            <QuizEpisodeNumber step={step} />
            <QuizEpisodeName episodeName={step.name} />
          </HStack>
          :
          <ModalLabel label={label} />
        }
        <Box onClick={onClose} cursor="pointer" ref={initialRef}>
          <img src={`${process.env.PUBLIC_URL}/modal-close-white.png`} alt={t('Close Modal')} />
        </Box>
      </HStack>
    </ModalHeader>
  )
}