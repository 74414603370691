import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from '../../usePageTitle'
import { Box, Center, useToast } from '@chakra-ui/react'
import { Header1 } from '../Header1'
import { Details } from '../Details'
import { FormProvider, useForm } from 'react-hook-form'
import { usePasswordResetRequestMutation } from 'features/sessionsApi'
import { useEffect } from 'react'
import { InputField } from '../InputField'
import { SubmitButton } from '../SubmitButton'

const ResetForm = ({ resetPassword }) => {
  const { t } = useTranslation()
  const methods = useForm()
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <Box as="form"
        pb="54px"
        minWidth={[null, null, '440px']}
        onSubmit={handleSubmit(resetPassword)}
      >
        <InputField
          label={t('Your email')}
          name="email"
          inputProps={{
            type: 'email',
            autoComplete: 'email'
          }}
        />
        <Box pt="16px">
          <SubmitButton>{t('Reset password')}</SubmitButton>
        </Box>
      </Box>
    </FormProvider>
  )
}

const PageContent = () => {
  const { t } = useTranslation()
  const toast = useToast()
  const [resetPassword, { isSuccess, error } ] = usePasswordResetRequestMutation()

  useEffect(() => {
    if (error) {
      toast({
        title: t('Something went wrong'),
        status: 'error',
        position: 'top',
        containerStyle: {
          position: 'absolute',
          maxWidth: 'unset',
          width: '100%',
          margin: '0'
        }
      })
    }
  }, [error, toast, t])

  if (isSuccess) {
    return (
      <>
        <Header1>
          {t('Check your email')}
        </Header1>
        <Center
          maxWidth="660px"
          flexDirection="column"
        >
          <Details>
            {t('Follow the instructions in your email to reset the password.')}
          </Details>
        </Center>
      </>
    )
  }

  return (
    <>
      <Header1>
        {t('Enter your email')}
      </Header1>
      <Center
        maxWidth="660px"
        flexDirection="column"
      >
        <Details>
          {t('Please share your email so we can identify your account')}
        </Details>
        <ResetForm resetPassword={resetPassword} />
      </Center>
    </>
  )
}

export const PasswordResetPage = () => {
  const { t } = useTranslation()
  usePageTitle(t('Reset password'))

  return (
    <Box
      background="#F0E9E6"
      pt="84px" pb="40px"
    >
      <Center flexDirection="column">
        <Box pl="64px" pr="64px">
          <PageContent />
        </Box>
      </Center>
    </Box>
  )
}
