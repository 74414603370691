import React, { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/react'

const OverlayBlock = ({ isActive, children, ...restprops }) => {
  const [display, setDisplay] = useState(isActive ? 'block' : 'none')

  useEffect(() => {
    // This is to soften the transition of the overlay block
    // so we can fully show and remove it with display property but still have a fade in effect
    const timeoutId = setTimeout(() => {
      setDisplay(isActive ? 'block' : 'none')
    }, isActive ? 0 : 350)

    return () => clearTimeout(timeoutId)
  }, [isActive])

  return (
    <Box position="relative">
      <Box
        background="white"
        height="100%"
        width="110%"
        left="-10px"
        transition="opacity .25s ease-in-out 0s"
        zIndex="50"
        {...restprops}
        position="absolute"
        opacity={isActive ? 0.90 : 0}
        display={display}
        className="overlay-block"
      >
      </Box>
      {children}
    </Box>
  )
}

export default OverlayBlock
