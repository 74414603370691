import { useTranslation } from 'i18n/TranslationContext'
import { Box, Flex, Link, IconButton } from '@chakra-ui/react'
import { SiImdb, SiInstagram, SiLinkedin } from 'react-icons/si'
import { Icon } from '@chakra-ui/icons'
import { HiGlobeAmericas } from 'react-icons/hi2'

const SocialMediaLinkBase = ({ href, styleProps={}, icon, ariaLabel }) => (
  <IconButton
    as={Link}
    href={href}
    isExternal
    aria-label={ariaLabel}
    colorScheme="zettaBlue"
    isRound
    icon={
      <Icon as={icon}
        role="presentation"
      />
    }
    ml="8px" mr="8px"
    {...styleProps}
  />
)

const WrappedImdb = () => (
  <Box
    overflow="hidden"
  >
    <Box transform="scale(110%)">
      <SiImdb role="presentation" />
    </Box>
  </Box>
)

const ImdbLink = ({ href }) => {
  const { t } = useTranslation()
  return (
    <SocialMediaLinkBase
      href={href}
      styleProps={{ fontSize: '38px' }}
      icon={WrappedImdb}
      ariaLabel={t('IMDB profile')}
    />
  )
}

const InstagramLink = ({ href }) => {
  const { t } = useTranslation()
  return (
    <SocialMediaLinkBase
      href={href}
      icon={SiInstagram}
      ariaLabel={t('Instagram profile')}
    />
  )
}

const LinkedinLink = ({ href }) => {
  const { t } = useTranslation()
  return (
    <SocialMediaLinkBase
      href={href}
      icon={SiLinkedin}
      ariaLabel={t('LinkedIn profile')}
    />
  )
}

const WebsiteLink = ({ href }) => {
  const { t } = useTranslation()
  return (
    <SocialMediaLinkBase
      href={href}
      icon={HiGlobeAmericas}
      ariaLabel={t('Website')}
      styleProps={{ fontSize: '24px' }}
    />
  )
}

const SocialMediaLink = ({ link }) => {
  if (link.includes('imdb.com')) {
    return <ImdbLink href={link} />
  }

  if (link.includes('instagram.com')) {
    return <InstagramLink href={link} />
  }

  if (link.includes('linkedin.com')) {
    return <LinkedinLink href={link} />
  }

  return <WebsiteLink href={link} />
}

const SocialMediaLinksMap = ({ links }) => {
  return links.map((link) => (
    <li key={link}>
      <SocialMediaLink link={link} />
    </li>
  ))
}

export const SocialMediaLinks = ({ links }) => {
  const { t } = useTranslation()
  return (
    <Flex as="ul" pt="16px" aria-label={t('social media links')}>
      <SocialMediaLinksMap links={links} />
    </Flex>
  )
}
