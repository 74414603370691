import { Flex } from '@chakra-ui/react'

export const WelcomeBar = () => {
  return (
    <Flex
      paddingBottom={['44px', null, '30px']}
      alignItems="center"
    >
    </Flex>
  )
}
