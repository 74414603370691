import { useDispatch } from 'react-redux'
import { usePlayerInstance } from './PlayerContext'
import { hidePlayerCover } from 'features/episode/slice'

export const useVideoPlayer = () => {
  const dispatch = useDispatch()
  const playerInstance = usePlayerInstance()

  const startPlayer = ({ time }) => {
    dispatch(hidePlayerCover())
    playerInstance.seekTo(time)
    playerInstance.makePlayerActiveEl()

    if (playerInstance?.paused) {
      playerInstance.handlePlay()
    }

    if (time) {
      // Sign video will be started by the synchronization code in Player.js. Pausing it here will help avoiding
      // ASL twitches.
      if (playerInstance.signVideo) {
        playerInstance?.signVideo.pause()
      }
    }
  }

  const pausePlayer = () => {
    if (!playerInstance?.paused) {
      playerInstance?.handlePlay()
    }
  }

  return {
    startPlayer,
    pausePlayer
  }
}
