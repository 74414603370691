import React from 'react'
import {
  Flex,
  Icon,
  Button,
} from '@chakra-ui/react'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'
import { useTranslation } from 'i18n/TranslationContext'

const TestGreenhouseApiButton = ({ onTest, isLoading, isSuccess, error }) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center">
      <Button
        onClick={onTest}
        colorScheme="zettaWhite"
        fontWeight="500"
        isLoading={isLoading}
        mr={2}
      >
        {t('Test Greenhouse API Key')}
      </Button>
      {isSuccess && !isLoading && (
        <Icon
          as={HiCheckCircle}
          data-testid="greenhouse-api-success-icon"
          boxSize="28px"
          color="green.500"
          ml={2}
        />
      )}
      {error && (
        <Icon
          as={HiXCircle}
          data-testid="greenhouse-api-fail-icon"
          boxSize="28px"
          color="red.500"
          mr={2}
        />
      )}
    </Flex>
  )
}

export default TestGreenhouseApiButton
