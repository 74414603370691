import React, { createContext, useContext, useEffect } from 'react'
import { useTranslation as useI18nextTranslation, Trans as I18nTrans } from 'react-i18next'

const TranslationContext = createContext()

export const TranslationProvider = ({ children }) => {
  const { t, i18n } = useI18nextTranslation('common')

  useEffect(() => {
    // Listen for language changes and update the lang attribute
    const handleLanguageChange = (lng) => {
      try {
        document.documentElement.lang = lng
      } catch (error) {
        console.error('Failed to set lang attribute:', error)
      }
    }

    i18n.on('languageChanged', handleLanguageChange)

    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [i18n])

  const Trans = ({ ...restProps }) => <I18nTrans ns="common" {...restProps} />
  return (
    <TranslationContext.Provider value={{ t, i18n, Trans }}>
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation = () => {
  return useContext(TranslationContext)
}
