import { useCallback, useMemo } from 'react'
import { useTranslation } from 'i18n/TranslationContext'

export const useQuizTakingText = () => {
  const { t } = useTranslation()

  const getCorrectAnswerText = useCallback(
    (remainingCount) => t('That’s correct! Only {{count}} more question(s) to go.', { count: remainingCount })
    , [t])

  const systemText = useMemo(() => ({
    greeting: t('Welcome to your Quiz!<br/><br/>Ok, here is the first question of the three:'),
    quiz_load_error: t('Sorry, we are unable to fetch the quiz at the moment. Please try again later.'),
    correct_answer: getCorrectAnswerText,
    incorrect_answer: t('<p style=\'margin-bottom:15px\'>Unfortunately, that is incorrect. Let’s try that again.</p><p>If you need to refer back to the episode material, feel free to close this chat and come back to it at any time.</p>'),
    quiz_complete: t('Nailed it! This quiz is now complete.<br/><br/><strong>You\'re all set to continue with your course.</strong>'),
  }), [t, getCorrectAnswerText])

  return { systemText }
}
