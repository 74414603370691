import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { Textarea } from '@chakra-ui/react'

export const AutoResizeTextarea = React.forwardRef(function AutoResizeTextarea(props, ref) {
  return (
    <Textarea
      as={TextareaAutosize}
      minRows={2}
      maxRows={10}
      ref={ref}
      {...props}
    />
  )
})
AutoResizeTextarea.displayName = 'AutoResizeTextarea'
