import { RequestFormProvider } from './RequestFormProvider'
import { RequestContactDetails } from './RequestContactDetails'
import { AppLoadingState } from 'AppLoadingState'
import { ShowContactDetails } from './ShowContactDetails'
import { CustomDrawer } from 'shared/CustomDrawer'
import { useBackTo } from '../../useBackTo'
import { useLoadTalentProfileFromStore } from '../../useLoadTalentProfileFromStore'

const ModeSelector = () => {
  const { data: talent, isSuccess } = useLoadTalentProfileFromStore()

  if (!isSuccess) {
    return <AppLoadingState />
  }

  const { contact_requests: [contactRequest] } = talent

  if (!contactRequest || !contactRequest['approved?']) {
    return (
      <RequestContactDetails />
    )
  }

  return <ShowContactDetails />
}

export const ContactDetails = () => (
  <RequestFormProvider>
    <CustomDrawer backTo={useBackTo()}>
      <ModeSelector />
    </CustomDrawer>
  </RequestFormProvider>
)
