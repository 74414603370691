import React, { useRef } from 'react'
import { useTranslation } from 'i18n/TranslationContext'
import { DeleteEpisodeBanner } from 'components'
import { FormTemplate } from 'components/builder/FormTemplate'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useSelector } from 'react-redux'
import { EpisodeUploadVideoThumbnailForm } from 'components/courseBuilder/formContent'
import { useCourseBuilderContext } from 'CourseBuilder/CourseBuilderContext'

export const EpisodeUploadVideoThumbnail = ({ nextStep, formForStep, episodeNum }) => {
  const { t } = useTranslation()

  const courseData = useSelector(state => state.courseBuilder || {})
  const { isFileUploading, episodeCount } = useCourseBuilderContext()
  const initialized = useRef(false)

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)

  return (
    <FormTemplate
      formTitle={t('Upload Episode File & Thumbnail')}
      nextStep={nextStep}
      formForStep={formForStep}
      buttonDisabled={isFileUploading}
      bannerComponent={episodeNum > 1 && episodeNum === episodeCount && (() => <DeleteEpisodeBanner episodeNum={episodeNum} />)}
      disabledNavTooltipText={t('Please wait for file to finish uploading before proceeding.')}
    >
      <EpisodeUploadVideoThumbnailForm episodeNum={episodeNum} />
    </FormTemplate>
  )
}
