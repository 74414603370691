import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { DEFAULT_LOCALE } from 'constants'
import en from './locales/en/common.json'
import frFR from './locales/fr/common.json'
import deDE from './locales/de/common.json'
import ptBR from './locales/pt-br/common.json'
import es419 from './locales/es-419/common.json'
import esES from './locales/es-es/common.json'
import itIT from './locales/it/common.json'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // This is the languageDetector feature that will decide what language to serve a user.
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: { common: en },
      'fr': { common: frFR },
      'de': { common: deDE },
      'pt-br': { common: ptBR },
      'es-419': { common: es419 },
      'es-es': { common: esES },
      'it': { common: itIT },
    },
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['navigator', 'cookie', 'localStorage'],
    },
    fallbackLng: (code) => {
      const fallbacks = [code]

      // English codes and default fallback
      if (!code || code.startsWith('en-')) {
        fallbacks.push(DEFAULT_LOCALE)
      }

      // Dialects to fallback to pure language
      const langPart = code.split('-')[0]
      switch (langPart) {
      case 'fr':
      case 'de':
      case 'it':
        fallbacks.push(langPart) // Add the pure language code
        break
      case 'es': {
        if (code === 'es' || code === 'es-es') {
          fallbacks.push('es-es')
        } else {
          fallbacks.push('es-419')
        }
        break
      }
      case 'pt':
        fallbacks.push('pt-br')
        break
      default: {
        // If the language code is not recognized, set 'en' as the ultimate fallback
        if (!fallbacks.includes(DEFAULT_LOCALE)) {
          fallbacks.push(DEFAULT_LOCALE)
        }
      }
      }
      return fallbacks
    }
  })
  .then(() => {
    // Always align the i18n language with the resolved language determined by the fallbackLng function
    i18n.language = i18n.resolvedLanguage
  })

export default i18n
