import './fonts'

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { App } from './App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store'
import ErrorBoundary from './ErrorBoundary'
import './i18n/i18n'
import { CableProvider } from './context/cable'

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CableProvider>
      <ErrorBoundary>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </ErrorBoundary>
    </CableProvider>
  </React.StrictMode>
)
