// For use with form states with dynamic amounts of fields where you can
// add fields or sets of fields
export const addForm = (totalCount, currentState, setFormState) => {
  if (currentState.length < totalCount) {
    const newIndex = currentState.length > 0 ? currentState[currentState.length - 1] + 1 : 0
    setFormState([...currentState, newIndex])
  }
}

// For use with form states with dynamic amounts of fields where you can
// remove fields or sets of fields
export const removeForm = (indexToRemove, currentState, setFormState, unRegister, fieldsToUnRegister) => {
  if (currentState.length > 1) {
    setFormState(currentState.filter((index) => index !== indexToRemove))

    // Unregister the form fields
    fieldsToUnRegister.forEach((fieldName) => {
      unRegister(fieldName)
    })
  }
}

// Format an object into FormData so that backend can handle nested objects and Files
// This is used for sending File and non-file data together to the backend
export const courseObjectToFormData = (obj, existingFormData = new FormData(), namespace = '') => {

  const excludedSuffix = '-fileFilename'
  const formData = existingFormData
  for (let propertyName in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, propertyName)) {
      // Skip if the key ends with excludedSuffix
      if (propertyName.endsWith(excludedSuffix)) continue

      const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName
      if (Array.isArray(obj[propertyName])) {
        obj[propertyName].forEach((item) => {
          if (typeof item === 'object' && !(item instanceof File)) {
            // If the item is an object, append each property of the object separately
            for (let key in item) {
              // Skip if the key ends with excludedSuffix
              if (key.endsWith(excludedSuffix)) continue

              // Remove the index from the key
              formData.append(`${formKey}[${key}]`, item[key])
            }
          } else {
            // If the item is not an object, append it directly
            formData.append(`${formKey}[]`, item)
          }
        })
      } else if (typeof obj[propertyName] === 'object' && !(obj[propertyName] instanceof File)) {
        courseObjectToFormData(obj[propertyName], formData, formKey)
      } else {
        formData.append(formKey, obj[propertyName])
      }
    }
  }
  return formData
}
