import { useTranslation } from 'i18n/TranslationContext'
import { forwardRef } from 'react'
import * as React from 'react'
import { Button, Link, MenuItem } from '@chakra-ui/react'
import { FaComment } from 'react-icons/fa6'

const feedbackFormURL = 'https://k1l8hbybse1.typeform.com/share-feedback'

export const ShareFeedbackButton = forwardRef(function ShareFeedbackButton(props, ref) {
  const { t } = useTranslation()
  return (
    <Link href={feedbackFormURL} isExternal>
      <Button
        ref={ref}
        {...props}
        colorScheme="zettaBlue"
        fontWeight="500"
        variant="solid"
      >
        {t('Share Feedback')}
      </Button>
    </Link>
  )
})

export const ShareFeedbackMenuItem = forwardRef(function ShareFeedbackButton(props, ref) {
  const { t } = useTranslation()
  return (
    <MenuItem
      target="_blank"
      as="a"
      icon={<FaComment />}
      ref={ref}
      {...props}
      href={feedbackFormURL}
    >
      {t('Share Feedback')}
    </MenuItem>
  )
})

