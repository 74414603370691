import { useTranslation } from 'i18n/TranslationContext'
import { Center, FormControl, Grid, GridItem } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { PhotoInput } from '../PhotoInput'
import { StyledFormLabel } from 'components'
import { ProfileInputFields } from '../ProfileInputFields'
import { UploadResumeInput } from '../UploadResumeInput'
import { StyledTextArea } from 'TalentProfile/StyledTextArea'

export const FormContent = () => {
  const { t } = useTranslation()
  const { register } = useFormContext()

  return (
    <Grid
      gap={30}
      templateAreas={[
        `"photo"
         "main"
         "resume"`,
        null,
        `"main photo"
         "resume photo"`
      ]}
      gridTemplateColumns={[null, null, '2fr 1fr']}
    >
      <GridItem area="photo">
        <Center paddingTop={['0', null, '52px']} mb={8}>
          <PhotoInput />
        </Center>
        <FormControl>
          <StyledFormLabel>
            {t('Alt text for photo')}
          </StyledFormLabel>
          <StyledTextArea
            placeholder={t('Alternative text for your photo')}
            { ...register('altText') }
          />
        </FormControl>
      </GridItem>

      <GridItem area="main">
        <ProfileInputFields />
      </GridItem>

      <GridItem area="resume">
        <UploadResumeInput />
      </GridItem>
    </Grid>
  )
}
