import { useTranslation } from 'i18n/TranslationContext'
import { Link } from 'react-router-dom'
import { Button, Box } from '@chakra-ui/react'

export const EmailTakenMessage = () => {
  const { t } = useTranslation()
  return (
    <Box pl="12px" pb="36px">
      <p>{t('This email address may already have an account.')}</p>
      <Button
        as={Link}
        to="/sign_in"
        size="sm"
        fontFamily="Poppins"
        color="#5000E8"
        variant="link"
        textDecoration="underline"
      >
        {t('Sign In')}
      </Button>
    </Box>
  )
}
