import { useTranslation } from 'i18n/TranslationContext'
import { useState } from 'react'
import { Box, Show } from '@chakra-ui/react'
import { EpisodeList } from './EpisodeList'
import { EpisodePreview } from './EpisodePreview'
import { useEpisodes } from '../../hooks'

export const EpisodesContent = () => {
  const episodes = useEpisodes()
  const [expandedIndex, setExpandedIndex] = useState(0)
  const { t } = useTranslation()

  return (
    <>
      <Box
        width="100%"
        flexGrow={[1, 1, 0]}
        flexBasis="25%"
      >
        <Box
          as="h3"
          fontSize="18px"
          pb="12px"
        >
          {t('Episodes')}
        </Box>
        <EpisodeList
          episodes={episodes}
          onChange={setExpandedIndex}
        />
      </Box>
      <Show above="md">
        <Box
          pl="30px"
          flexGrow="1"
        >
          <Box
            position="sticky"
            top="100px"
          >
            <EpisodePreview stepId={episodes[expandedIndex]} />
          </Box>
        </Box>
      </Show>
    </>
  )

}
