import { useState } from 'react'
import { FormControl, Stack, RadioGroup, Radio } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import FieldHeader from '../FieldHeader'
import { formStyles } from '../formStyles'


const RadioField = ({ helperText, label, name, inputProps, fieldRequired, radioValues, horizontalRadio, setValue, defaultValue }) => {
  const { formState: { errors }, register } = useFormContext()
  const { radioFieldStyles, formControlStyles } = formStyles()
  const [selectedValue, setSelectedValue] = useState(defaultValue.toString())

  const handleChange = (value) => {
    setSelectedValue(value)
    setValue && setValue(value)
  }

  return (
    <FormControl pb="40px" isInvalid={!!errors[name]} isRequired={fieldRequired} {...formControlStyles}>
      <FieldHeader helperText={helperText} label={label} />
      <RadioGroup
        value={selectedValue}
        onChange={handleChange}
        {...radioFieldStyles}
        {...inputProps}
      >
        <Stack direction={horizontalRadio ? 'row' : 'column'}>
          {radioValues.map((option, index) => (
            <Radio padding="5px 10px" key={index} name={name} value={option.value.toString()} {...register(name)} isDisabled={inputProps?.disabled}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export default RadioField
