import React, { useEffect } from 'react'
import { InputField, SelectField, UploadMediaField } from 'components'
import ButtonField from 'components/forms/fields/ButtonField'
import { useTranslation } from 'i18n/TranslationContext'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useGetOpportunityStatusOptions } from 'hooks/opportunityBuilder/useFormHelper'
import { UPLOAD_MEDIA_TYPE } from 'constants'
import OverlayBlock from 'components/OverlayBlock'
import { LocationInput } from 'components/forms/LocationInput/index.js'
import { formStyles } from 'components/forms/formStyles'
import { Box, Text, Link, VisuallyHidden } from '@chakra-ui/react'
import { LOCATION_OPTIONS } from 'constants'
import { HiBuildingOffice, HiHome } from 'react-icons/hi2'
import { HybridIcon } from 'components/icons'
import FieldHeader from 'components/forms/FieldHeader'
import CourseSelectorField from 'components/forms/fields/CourseSelectorField'
import MinMaxNumField from 'components/forms/fields/MinMaxNumField'
import ATSJobIdField from 'components/forms/fields/ATSJobIdField'


const CustomLabelForLocation = ({ isDisabled, ...props }) => {
  const { formLabelStyles } = formStyles()
  const { t } = useTranslation()
  return (
    <Box display="block" as="label" {...formLabelStyles} {...props} mb={isDisabled && '16px'}>
      {t('Location')}
      <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
    </Box>
  )
}

const CustomHelperTextForLocation = () => {
  const { t } = useTranslation()
  return (
    <Text fontFamily="Poppins" fontSize="20px" pb="20px" color="gray.600" mt="16px">
      {t('The general location of the role.')}
    </Text>
  )
}

const numInputSettings = {
  precision: 2,
  step: 1000,
  width: 'calc(8rem + 32px)',
}

export const OpportunityDetailsForm = ({ formDisabled = false }) => {
  const { t, Trans } = useTranslation()
  const { register, control, watch, getValues, trigger, setFocus, setValue } = useFormContext() // retrieve all hook methods
  const errors = useSelector(state => state.opportunityBuilder.errors || {})
  const mediaType = React.useMemo(() => UPLOAD_MEDIA_TYPE(t), [t])
  const workplaceModel = useSelector(state => state.opportunityBuilder.workplace_model) || LOCATION_OPTIONS.IN_PERSON

  const watchedLocation = watch('location')
  useEffect(() => {
    setValue('location_id', watchedLocation?.place_id)
  }, [watchedLocation, setValue, trigger])

  const statusIsDraft = getValues('status') === 'draft' || !getValues('status')

  return (
    <>
      <InputField
        label={t('Opportunity Name')}
        name="name"
        width="60%"
        fieldRequired
        errorVal={errors}
        helperText={
          !formDisabled &&
          t('This name appears in the list of opportunities available for talent and on the opportunity description page.')
        }
        inputProps={{
          disabled: formDisabled,
          placeholder: t('Graphic Designer'),
          ...register(
            'name', {
              required: t('Opportunity Name can’t be empty.'),
              maxLength: { value: 55, message: t('Max 55 characters allowed.') },
            }
          )
        }}
      />
      <OverlayBlock isActive={!(watch('name'))} onClick={() => {
        trigger('name')
        setFocus('name')
      }}>
        <SelectField
          label={t('Opportunity Status')}
          name="status"
          control={control}
          options={useGetOpportunityStatusOptions(t)}
          isDisabled={statusIsDraft || formDisabled}
          width="100%"
          defaultValue="draft"
          fieldRequired
        />
        <UploadMediaField
          name="image"
          label={t('Opportunity Thumbnail')}
          helperText={
            !formDisabled &&
            t('Dress up your opportunity with a custom image. Opportunities without images will utilize the thumbnail from an associated course.')
          }
          inputProps={{ disabled: formDisabled }}
          mediaType={mediaType.THUMBNAIL}
        />
        <LocationInput
          CustomLabel={CustomLabelForLocation}
          CustomHelperText={!formDisabled && CustomHelperTextForLocation}
          mt="16px"
          requiredMessage={t('Location can’t be empty.')}
          disabled={formDisabled}
        />
        <ButtonField
          label={t('Location Options')}
          helperText={t('Select the required working environment for this role.')}
          name="workplace_model"
          fieldRequired
          radioValues={[
            { label: t('In-Person'), value: LOCATION_OPTIONS.IN_PERSON, icon: HiBuildingOffice },
            { label: t('Remote'), value: LOCATION_OPTIONS.REMOTE, icon: HiHome },
            { label: t('Hybrid'), value: LOCATION_OPTIONS.HYBRID, icon: HybridIcon },
          ]}
          defaultValue={workplaceModel}
          inputProps={{
            isDisabled: formDisabled,
          }}
        />
        <ATSJobIdField
          formDisabled={formDisabled}
          fieldName="api_job_id"
          errors={errors}
          helperText={<Trans
            i18nKey="Associate your Opportunity with a job or job post from your linked applicant tracking system. To learn more, <1>please visit our FAQs</1>."
            components={{ 1: <Link href="https://www.making-space.com/partners" isExternal color="rgb(73,9,223)" textDecoration="underline" /> }}
          />}
          placeholder={t('Select one.')}
        />
        <CourseSelectorField formDisabled={formDisabled} errorVal={errors} />
        <SelectField
          label={t('Employment Type')}
          helperText={!formDisabled && t('Select the type of employment for this opportunity.')}
          name="employment_type"
          control={control}
          options={[
            { value: 'full_time', label: t('Full-Time') },
            { value: 'part_time', label: t('Part-Time') },
            { value: 'contract', label: t('Contract') },
            { value: 'contract_to_hire', label: 'Contract-to-Hire' },
            { value: 'temporary', label: t('Temporary') },
            { value: 'intern', label: t('Intern') },
          ]}
          isDisabled={formDisabled}
          width="100%"
          defaultValue="full_time"
          fieldRequired
        />
        <FieldHeader
          label={
            <>{t('Salary')}<Box as="span" role="presentation" ml="4px" color="#CD0050">*</Box></>
          }
        />
        <MinMaxNumField
          label={t('Salary')}
          nameMin="salary_min"
          nameMax="salary_max"
          maxNum={999999}
          formDisabled={formDisabled}
          numInputSettings={numInputSettings}
          symbol="USD"
          courseBuilder
          fieldRequired
        />
        <SelectField
          name="salary_frequency"
          helperText={!formDisabled && t('Select the salary type associated with this opportunity.')}
          control={control}
          options={[
            { value: 'annually', label: t('Per Year') },
            { value: 'monthly', label: t('Per Month') },
            { value: 'weekly', label: t('Per Week') },
            { value: 'hourly', label: t('Per Hour') },
            { value: 'project_basis', label: t('Per Project') },
          ]}
          isDisabled={formDisabled}
          width="100%"
          defaultValue="annually"
          label={
            <VisuallyHidden>{t('Salary Type')}</VisuallyHidden>
          }
        />
        <InputField
          label={t('Close Date')}
          name="close_date"
          width="60%"
          fieldRequired
          errorVal={errors}
          helperText={
            !formDisabled &&
            t('The date this opportunity will no longer be available.')
          }
          inputProps={{
            disabled: formDisabled,
            type: 'date',
            placeholder: t('Select date.'),
            ...register(
              'close_date', {
                required: t('Opportunity close date can’t be empty.'),
                maxLength: { value: 55, message: t('Max 55 characters allowed.') },
              }
            )
          }}
        />
      </OverlayBlock>
    </>
  )
}
