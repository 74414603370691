import { AdditionalMaterialThumbnail } from 'CourseFeature/EpisodePage/AdditionalMaterialsBlock'
import { useCoursePageContext } from '../CoursePageContext'
import { IoDocumentText } from 'react-icons/io5'
import { ResponsiveImage } from 'components/talent/modal-chat-ux/ResponsiveImage'
import { useDoesObjectExistQuery } from 'features/awsApiSlice'

export const EpisodePreviewBase = ({ courseQueryData, stepId, rootProps={}, isPartner, isIcon, ...props }) => {
  const {
    data: {
      mediaBase,
      content: {
        steps: {
          [stepId]: {
            previewAltText,
            type: stepType,
            order: stepOrder
          }
        }
      }
    }
  } = courseQueryData
  const mediaExtension = courseQueryData?.data?.valid_course_steps?.length > 0 ? 'jpeg' : 'jpg'

  const { data: stepOrderImageExists, isFetching } = useDoesObjectExistQuery({
    objectURL: `${mediaBase}/public_profile/steps/${stepOrder}/poster@2x.${mediaExtension}`
  })

  if ( isFetching ) return null

  const assignmentIcon = <IoDocumentText style={
    { color: '#B9FCB6', fontSize: isPartner ? '60px' : 'inherit' }
  } />

  const srcSetPaths = stepOrderImageExists ? `
    ${mediaBase}/public_profile/steps/${stepOrder}/poster@2x.${mediaExtension},
  ` : `
    ${mediaBase}/public_profile/steps/${stepId}/poster@2x.${mediaExtension} 2x,
    ${mediaBase}/public_profile/steps/${stepId}/poster.${mediaExtension}
  `

  return (
    <>
      {stepType === 'assignment' ? (
        <AdditionalMaterialThumbnail
          isLarge={isPartner}
          customBgColor="#0F204B"
          customIcon={assignmentIcon}
        />
      ) : isIcon ? (
        <img
          srcSet={srcSetPaths}
          alt={previewAltText}
        />
      ) : (
        <ResponsiveImage srcSet={srcSetPaths} altText={previewAltText} rootProps={rootProps} {...props} />
      )}
    </>
  )
}

export const EpisodePreview = ({ ...props }) => (
  <EpisodePreviewBase
    courseQueryData={useCoursePageContext().courseContentData}
    {...props}
  />
)
