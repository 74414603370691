import React from 'react'
import {
  Box,
  Tooltip,
} from '@chakra-ui/react'
import { CircleIcon } from 'components'
import { HiCheckCircle, HiExclamation } from 'react-icons/hi'
import { getDisabledStepMessage } from './utils'

const StepIcon = ({ isInvalid, isValid, linkId, currentStepId }) => {
  if (isInvalid) {
    return (
      <HiExclamation
        className={`course-steps-exclamation-icon-${linkId}`}
        fontSize="18px"
        style={{
          float: 'left',
          color: currentStepId === linkId ? '#0F204B' : '#F09E00',
          margin: '0px 10px 0px 0px',
          transform: 'translateY(3px)'
        }}
      />
    )
  } else if (isValid) {
    return (
      <HiCheckCircle
        className={`course-steps-check-icon-${linkId}`}
        fontSize="18px"
        style={{
          float: 'left',
          color: '#007037',
          margin: '0px 10px 0px 0px',
          transform: 'translateY(2px)'
        }}
      />
    )
  } else {
    return <CircleIcon className={`course-steps-circle-icon-${linkId}`} mr="13px" />
  }
}

const AccordionStepLink = ({ link, currentStepId, onStepClick, isDisabled, isValid, isInvalid, t }) => {
  const sharedLinkProps = {
    className: 'course-steps-links',
    textAlign: 'left',
    marginBottom: '5px',
    fontSize: '15px',
    fontWeight: '600',
    fontFamily: 'Spline Sans Mono',
    padding: '12px',
    width: '100%',
  }

  if (isDisabled) {
    const disabledMessage = getDisabledStepMessage(link.id, t)
    return (
      <Tooltip label={disabledMessage || t('This step is not available.')} hasArrow placement="right">
        <Box
          {...sharedLinkProps}
          color="white"
          bg="linear-gradient(90deg, rgba(232, 225, 222, 0.11) 0%, rgba(232, 225, 222, 0.044) 100%)"
          cursor="not-allowed"
          opacity=".5"
          aria-disabled="true"
          tabIndex={-1}
        >
          <CircleIcon mr="13px" />
          {link.label}
        </Box>
      </Tooltip>
    )
  }

  return (
    <Box
      {...sharedLinkProps}
      color={currentStepId === link.id ? '#0F204B' : 'white' }
      bg={currentStepId === link.id ? '#FF7D19' : 'linear-gradient(90deg, rgba(232, 225, 222, 0.11) 0%, rgba(232, 225, 222, 0.044) 100%)'}
      as="button"
      _hover={currentStepId === link.id ? {
        background: '#FF7D19',
      } : {
        background: '#0F204B',
      }}
      onClick={() => onStepClick && onStepClick(link.id)}
    >
      <StepIcon
        isInvalid={isInvalid}
        isValid={isValid}
        linkId={link.id}
        currentStepId={currentStepId}
      />
      {link.label}
    </Box>
  )
}

export default AccordionStepLink