import { Box } from '@chakra-ui/react'


export const BeaconAvatar = () => {
  return (
    <Box
      className="beacon-modal-avatar"
      width="40px"
      height="40px"
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="#0f214b"
      fontSize="26px"
      color="white"
    >
      <Box as="img" padding="4px" src={`${process.env.PUBLIC_URL}/Logo_mobile.png`} />
    </Box>
  )
}