import { useEffect } from 'react'

/**
 * const keyConfig = {
    keys: [13], // 13 is the key code for the Enter key
    includeModifierKey: true // Listen for CMD (Mac) or Windows key (Windows), or the CTRL key
    args: ['arg1', 'arg2'], // Arguments to pass to the callback function
  };
  useKeyListener(keyConfig, handleSendClick);
 */

/**
 * Listens for a key combo and invokes a callback with optional args if combo is pressed
 * @param {*} keyConfig The key configuration object
 * @param {*} callback The function to call when the key combo is pressed
 */
const useKeyListener = (keyConfig, callback) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // uses event.which for Firefox compatibility
      const keyCombo = keyConfig.keys.includes(event.keyCode || event.which)
      const modifierKeyActive = (event.metaKey || event.ctrlKey)
      const checkModifierKey = keyConfig.includeModifierKey
      const isModifierKeyConditionMet = (checkModifierKey && modifierKeyActive) || !checkModifierKey

      if (keyCombo && isModifierKeyConditionMet) {
        event.preventDefault()
        // Only pass arguments if the args array exists and has a length greater than 0
        keyConfig.args?.length > 0 ? callback(...keyConfig.args) : callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [keyConfig, callback])
}

export default useKeyListener