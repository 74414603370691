import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import useSendCourseData from 'hooks/courseBuilder/useSendCourseData'
import { useTranslation } from 'i18n/TranslationContext'
import { FormTemplate } from 'components/builder/FormTemplate'
import { BlurbDescriptionAndSkillsForm } from 'components/courseBuilder/formContent'

export const BlurbDescriptionAndSkills = ({ nextStep, formForStep }) => {
  const { t } = useTranslation()
  const courseData = useSelector(state => state.courseBuilder || {})
  const initialized = useRef(false)

  // When the course form changes, handle the data being sent to the backend
  useSendCourseData(courseData, initialized)

  return (
    <FormTemplate
      nextStep={nextStep}
      formTitle={t('Blurb, Description, & Skills')}
      formForStep={formForStep}
    >
      <BlurbDescriptionAndSkillsForm />
    </FormTemplate>
  )
}

export default BlurbDescriptionAndSkills
