import { Box, Center } from '@chakra-ui/react'
import { HiUser } from 'react-icons/hi'

export const Photo = ({ photoUrl, photoAltText }) => {
  if (!photoUrl) {
    return (
      <Center
        width={['60px', null, '90px']}
        height={['80px', null, '120px']}
        fontSize="20px"
        color="#5000E8"
        bgColor="#F8F4FF"
      >
        <HiUser aria-hidden />
      </Center>
    )
  }

  return (
    <Box as="img"
      height={['80px', null, '120px']}
      maxW={['60px', null, '90px']}
      objectFit="contain"
      src={photoUrl}
      alt={photoAltText || ''}
      className="talent-items-photo"
    />
  )
}
