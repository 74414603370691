import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  pageTitle: ''
}

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload
    }
  }
})

export const {
  setPageTitle
} = pageSlice.actions

export const pageReducer = pageSlice.reducer
